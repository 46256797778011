import { cn } from "@utils/cn";
import { InputHTMLAttributes, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
    required?: boolean,
    tooltip?: { id: string, tip: string, width?: number; },
    label: string,
    error?: string | null,
    register?: UseFormRegisterReturn<any>,
    mainClassName?: string,
    labelClassName?: string;
}

export const RadioInput = forwardRef<HTMLDivElement, Props>(({
    required, className, register, tooltip,
    error = "",
    mainClassName = "",
    labelClassName = "",
    label = "",
    ...props
}, ref) => {

    return (
        <div ref={ref} className={cn("flex items-center", mainClassName)}>

            <input
                {...props}
                type="radio"
                className={cn(`
                    w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
                    focus:ring-blue-500 
                `)}
            />

            <label className={cn(`ms-2 text-sm font-medium text-gray-400 dark:text-gray-500`)}>
                {label}
            </label>

        </div>
    );
});