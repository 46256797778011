
type Props = {
    classNames?: string,
    active?: boolean
}

export const FullPageIcon: React.FC<Props> = ({classNames, active}) => {
    return (
        <svg 
            className={`${classNames} ${active ? 'text-primary' : 'text-font_secondary'}`}
            width="106" 
            height="74" 
            viewBox="0 0 106 74" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor"/>
            <rect x="8" y="8" width="90" height="58" rx="4" fill="currentColor"/>
        </svg>
    );
};

