
type Props = {
    height?: string,
    width?: string;
};

export function OptinlyLogo({ height, width }: Props) {
    return (
        <svg width={height ?? "400"} height={width ?? "400"} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.9871 53.3998C39.5962 38.2594 47.3447 18.6434 70.4698 13.2001C75.567 12.0056 80.8195 11.3605 86.087 11.2849L152.154 10.3638L151.96 107.306L73.472 93.1055C50.9432 89.0281 40.4232 70.2286 39.9871 53.3998Z" fill="#2563EB" />
            <path d="M202.095 116.391L162.198 109.173L162.396 10.2316L322.162 8.00222C341.824 7.8533 360.278 15.2118 374.406 29.072C388.545 42.9373 396.474 61.4394 396.744 81.1713L398.947 241.887L347.865 248.782L298.092 250.6L285.975 198.585C272.298 136.431 245.303 126.88 202.095 116.391Z" fill="#2563EB" />
            <path d="M46.2768 275.84L178.303 152.037C180.377 150.093 180.49 146.829 178.556 144.744C176.623 142.658 173.377 142.544 171.303 144.489L39.2772 268.291C37.2037 270.236 37.0903 273.5 39.0239 275.585C39.9907 276.628 41.2856 277.177 42.5979 277.223C43.9102 277.27 45.2401 276.812 46.2768 275.84Z" fill="#2563EB" />
            <path d="M244.599 218.403L116.955 346.769C114.95 348.785 114.95 352.051 116.955 354.067C117.957 355.075 119.27 355.579 120.583 355.579C121.897 355.579 123.21 355.075 124.212 354.067L251.856 225.701C253.861 223.685 253.861 220.419 251.856 218.403C249.852 216.387 246.604 216.387 244.599 218.403Z" fill="#2563EB" />
            <path d="M182.139 212.441C180.134 210.425 176.887 210.425 174.882 212.441L1.50358 386.793C-0.501195 388.809 -0.501195 392.075 1.50358 394.091C2.50597 395.099 3.81911 395.603 5.13224 395.603C6.44537 395.603 7.7585 395.099 8.76089 394.091L182.139 219.739C184.144 217.723 184.144 214.457 182.139 212.441Z" fill="#2563EB" />
            <path d="M396.128 343.974C389.136 364.709 371.208 371.392 356.779 370.641C342.435 369.94 323.84 361.226 318.658 338.964L300.441 260.761C314.835 264.422 330.638 265.508 347.849 264.022C365.061 262.535 382.135 258.608 399.073 252.241L399.992 319.257C400.112 327.689 398.809 336.006 396.128 343.974Z" fill="#2563EB" />
        </svg>
    );
};