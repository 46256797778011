import Creatable from "react-select/creatable";
import { Props } from 'react-select';
import { cn } from '@utils/cn';

export function SelectOrCreate({ className, ...props }: Props) {
    return (
        <Creatable
            placeholder="Choice a your industry"
            maxMenuHeight={150}
            className={cn(`
                bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg 
                focus:border-blue-500 block w-ful outline-none cursor-pointer mt-2 
                bg-[#dbf3fff6]
            `, className)}
            styles={{
                option: (style) => ({
                    ...style,
                    cursor: "pointer",
                    transition: "background 0.2s ease-in-out",
                    fontWeight: 500,
                    borderRadius: 6,
                    fontSize: 14,
                    ":hover": {
                        background: "#dbf3fff6",
                    },
                }),
                menu: (style) => ({
                    ...style,
                    borderRadius: 6
                }),
                menuList: (style) => ({
                    ...style,
                    padding: "6px",
                    borderRadius: 6
                }),

            }}
            {...props}
        />
    );
}