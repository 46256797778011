import { cn } from "@utils/cn";
import { DayPicker, DayPickerDefaultProps, DayPickerMultipleProps, DayPickerRangeProps, DayPickerSingleProps } from "react-day-picker";
import 'react-day-picker/dist/style.css';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";


export function DatePicker(props: (DayPickerDefaultProps | DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps) & { show?: boolean; }) {

    const { classNames, className } = props;

    return (
        <DayPicker
            {...props}
            showOutsideDays={true}
            className={cn("bg-white rounded-lg p-3 m-0 inline-flex shadow-sm", className)}
            classNames={{
                months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
                month: "space-y-4",
                caption: "flex justify-center pt-1 relative items-center",
                caption_label: "text-sm font-medium",
                nav: "flex items-center",
                nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 border border-grey-200 rounded-md transition-all duration-200 east-in-out flex item-center justify-center",
                nav_button_previous: "absolute left-1 border border-gray-300 hover:bg-gray-200",
                nav_button_next: "absolute right-1 border border-gray-300 hover:bg-gray-200",
                table: "w-full border-collapse space-y-1",
                head_row: "flex",
                head_cell: "text-muted-foreground rounded-md w-8 text-sm font-medium text-gray-500",
                row: "flex w-full mt-2",
                cell: cn(
                    "relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md",
                    props.mode === "range"
                        ? "[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md"
                        : "[&:has([aria-selected])]:rounded-md"
                ),
                day: cn("h-8 w-8 p-0 font-normal aria-selected:opacity-100 rounded-md transition-all duration-200"),
                day_range_start: "day-range-start",
                day_range_end: "day-range-end",
                day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
                day_today: "bg-accent text-accent-foreground",
                day_outside: "day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
                day_disabled: "text-muted-foreground opacity-50",
                day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
                day_hidden: "invisible",
                ...classNames
            }}
            components={{
                IconLeft: () => <MdKeyboardArrowLeft className="w-5 h-full" />,
                IconRight: () => <MdKeyboardArrowRight className="w-5 h-full" />,
            }}
        />
    );
}