import { Button } from "@components/button";
import { useAuthApiClient } from "@hooks";
import { useNavigate, useSearchParams } from "react-router-dom";


export function VerifyEmailPage() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const token = searchParams.get("token") ?? null;

    const { verifyEmail } = useAuthApiClient({});

    if (token === null) {
        navigate("/login");
        return;
    }

    return (
        <div className="w-[66%] h-full flex flex-col items-center justify-center">

            <div className="p-2 w-[50%] flex flex-col items-start ">

                <p className="mb-5 text-xl font-medium text-left">
                    Change Email
                </p>

                <p className="w-full text-sm font-normal">
                    Click the button below to complete your email verification.
                </p>

                <Button
                    disabled={verifyEmail.isPending}
                    loading={verifyEmail.isPending}
                    className="w-auto text-sm font-medium"
                    onClick={() => verifyEmail.mutate(token as string)}
                >
                    {verifyEmail.isPending ? "Verifying..." : "Verify email"}
                </Button>

            </div>

            <p
                className="py-2 mt-3 font-normal text-center text-md"
                onClick={() => navigate("/login", { replace: true })}
            >
                Back to <u className="cursor-pointer text-primary"> Login </u>
            </p>

        </div>
    );
}