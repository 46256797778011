import { SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

type Props = {
    pageIdx: number,
    pageCount: number,
    disablePreviousButton: boolean,
    onPreviousPage: () => void,
    disableNextButton: boolean,
    onNextPage: () => void,
};

export function Pagination({ pageIdx, pageCount, disableNextButton, disablePreviousButton, onNextPage, onPreviousPage }: Props) {
    return (
        <div className={cn(`flex items-center justify-between p-3 rounded-lg bg-white border border-gray-200`)}>

            <div className="flex items-center space-x-2">

                <p className="text-sm font-normal">Page</p>

                <strong>

                    {pageIdx} of{' '}

                    {pageCount}

                </strong>

            </div>

            <div className="flex items-center space-x-3 transition-all duration-300 ease-in-out">

                <SecondaryButton
                    className={cn(`bg-white px-3`)}
                    onClick={() => onPreviousPage()}
                    disabled={disablePreviousButton}
                >

                    <FaAngleLeft className="w-4 h-4 text-gray-700" />

                    <p className="text-sm font-medium mx-2"> Previous </p>

                </SecondaryButton>

                <SecondaryButton
                    className={cn(`bg-white px-3`)}
                    onClick={() => onNextPage()}
                    disabled={!disableNextButton}
                >

                    <p className="text-sm font-medium mx-2"> Next </p>

                    <FaAngleRight className="w-4 h-4 text-gray-700" />

                </SecondaryButton>

            </div>

        </div>
    );
}