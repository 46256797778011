import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";


export function TextPage() {

    const { title, description, collectImages } = useFormStore((store) => ({
        title: store.response.audio.title,
        description: store.response.audio.description,
        collectImages: store.response.text.collectAttachments
    }));

    return (
        <div className="space-y-2 my-2">

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Text Title"
                />

                <QuillInput
                    valueAsHTML={title}
                    placeHolder="Enter your prompt message here."
                    recall={true}
                    onChange={(e) => useFormStore.getState().setResponseTitle("text", e.html)}
                />

            </div>

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Text Description"
                />

                <QuillInput
                    valueAsHTML={description}
                    placeHolder="Enter your prompt message here."
                    recall={true}
                    onChange={(e) => useFormStore.getState().setResponseDescription("text", e.html)}
                />

            </div>

            <TextToggle
                isActive={collectImages}
                name="Collect image attachments"
                onClick={() => useFormStore.getState().setCollectAttachment("text", !collectImages)}
            />

        </div>
    );
}