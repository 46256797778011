import { useSocialProofStore } from "@store";
import { SelectTestimonials } from "../../selectTestimonials/main.select";
import { useActiveSpace } from "@hooks";
import { Config } from "@config";
import { cn } from "@utils/cn";
import { WallOfLovePreviewHeader } from "./header";


export function WallOfLoveTemplatePreview() {

    const activeTab = useSocialProofStore((store) => store.activeTab);

    const { formId } = useActiveSpace();

    const renderEngineUrl = Config.RenderEngineUrl() + "/wall-of-love/" + formId;

    return (
        <div className="w-full h-full">

            {activeTab === "selectTestimonials" ? <SelectTestimonials /> : null}

            {activeTab === "template" ?
                <div className={cn(`w-full h-full max-h-full overflow-auto flex flex-col items-center justify-center`)}>

                    <WallOfLovePreviewHeader />

                    <iframe
                        src={renderEngineUrl}
                        className="w-full h-full"
                    />

                </div>
                : null
            }

        </div>
    );
}