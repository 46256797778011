import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { LuInfo } from "react-icons/lu";
import { Tooltip } from "react-tooltip";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
    text?: string,
    children?: ReactNode,
    variant?: "label" | "primary",
    weight?: "",
    size?: "xs" | "sm" | "md" | "lg" | "xl",
    required?: boolean,
    tooltip?: { id: string, tip: string, width?: number; };
}

export function Text({ className, children, required, text, size, variant, tooltip, ...props }: Props) {
    return (
        <p
            {...props}
            className={cn({
                "block text-sm font-normal leading-6 text-gray-700": variant === "label",
                "text-xs": size === "xs",
                "text-sm": size === "sm",
                "text-base font-normal": size === "md",
                "text-lg": size === "lg",
                "text-xl": size === "xl",
                "ml-[1px]": variant === "label",
                "text-sm font-medium": !size && !variant,
                "flex items-center space-x-1": tooltip ? true : false
            }, className)}
        >

            {children ?? text}

            {required ? <span className="text-red-500 font-medium"> * </span> : null}

            {tooltip ?
                <div className="mx-1">

                    <LuInfo
                        className={cn(`text-gray-600 mx-1`)}
                        id={tooltip.id}
                    />

                    <Tooltip
                        anchorSelect={`#${tooltip.id}`}
                        place="top"
                        style={{
                            width: tooltip.width ?? 300,
                            textAlign: "left"
                        }}
                    >
                        {tooltip.tip}
                    </Tooltip>

                    {/* </span> : null */}

                </div> : null
            }

        </p>
    );
}
