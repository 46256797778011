import { useEmbedStore } from "@store";
import { EmbeddedPreview } from "../ifream";

export const StandardPreview: React.FC = () => {

    const { view, standard } = useEmbedStore((store) => ({
        view: store.view,
        standard: store.standard
    }));

    const { width, widthUnite, height, heightUnit } = standard;

    const _width = width && widthUnite !== "%" && widthUnite !== "px" && window.innerWidth > 1300 ? `${width}${widthUnite}` : "100%";

    const _height = height && heightUnit !== "%" && heightUnit !== "px" && window.innerWidth > 1300 ? `${height}${heightUnit}` : "100%";

    return (
        <div
            className="relative bg-white rounded-xl border border-border overflow-auto"
            style={{
                width: view === "desktop" ? "100%" : "360px",
                height: window.innerWidth < 1400 ? "500px" : "600px"
            }}
        >

            <EmbeddedPreview
                width={_width}
                height={_height}
            />

        </div>
    );
}; 