import { Button } from "@components/button";
import { Text } from "@components/text";
import { MdOutlineAdd } from "react-icons/md";
import { DomainCard } from "./card";
import { useDomainsApiClient } from "@hooks";
import { Fragment, useEffect } from "react";
import { Loading } from "@components/loading";
import { EmptyIcon } from "@components/icons";
import { useCommonStore } from "@store";
import { AddDomainPopup } from "./addDomain.popup";


export function Domain() {

    const { getAllQuery } = useDomainsApiClient({});

    const isLoading = getAllQuery.isLoading || getAllQuery.isFetching;

    useEffect(() => { getAllQuery.refetch(); }, []);

    const domains = getAllQuery.data?.domains ?? [];

    return (
        <div className="p-3">

            <div className="flex items-center justify-between mx-4">

                <Text
                    text="Your Domains"
                    className="text-lg"
                />

                <Button
                    className="w-auto text-sm font-medium tracking-wide px-4"
                    onClick={() => useCommonStore.getState().setActivePopup("add-domain")}
                >

                    <MdOutlineAdd className="w-5 h-5 mr-1" />

                    Add Domain
                </Button>

            </div>

            <div className="">

                {isLoading ? <Loading /> :
                    <Fragment>

                        {domains.length ?
                            <Fragment>

                                {domains.map((domain, idx) => (<DomainCard key={idx} {...domain} verified={false} />))}

                            </Fragment> :
                            <div className="flex items-center justify-center py-4 mt-4">

                                <EmptyIcon />

                            </div>
                        }

                    </Fragment>
                }

            </div>

            <AddDomainPopup />

        </div>
    );
}