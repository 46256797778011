import { CheckBox } from "@components/checkbox";
import { Table } from "@components/table";
import { QueryKeys, useCustomNavigation, useGetQueryData, useGetQueryStatus } from "@hooks";
import { SkeletonLoader } from "@components/skeleton";
import { Fragment } from "react";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { DateTime } from "luxon";
import { CgTranscript } from "react-icons/cg";


export function RecentTestimonials() {

    const navigate = useCustomNavigation();

    const { status } = useGetQueryStatus([QueryKeys.spaces.getDetails]);

    const isLoading = status === "pending";

    const getSpaceDetailsQuery = useGetQueryData<Api.SpacesClient.getDetails>([QueryKeys.spaces.getDetails]);

    const recentTestimonials = getSpaceDetailsQuery?.recentTestimonials || [];

    return (
        <div className="h-[75%] w-full mt-6 py-4">

            <p className="text-lg font-medium px-1">
                Recent Testimonials
            </p>

            <div className="overflow-auto p-0 m-0 my-3 pb-6">

                <Table className="w-full">

                    {isLoading ?
                        <SkeletonLoader
                            count={5}
                            className="w-full h-[50px]"
                        /> :
                        <Fragment>

                            <Table.Head>

                                <Table.Row className="bg-white hover:bg-none">

                                    <Table.HeadCell className="w-[5%] px-6">

                                        <CheckBox onClick={() => { }} />

                                    </Table.HeadCell>

                                    <Table.HeadCell className="w-[18%] text-left">
                                        Person
                                    </Table.HeadCell>

                                    <Table.HeadCell className="w-[40%] text-left">
                                        Review
                                    </Table.HeadCell>

                                    <Table.HeadCell className="w-[10%]">
                                        Date
                                    </Table.HeadCell>

                                    <Table.HeadCell className="w-[10%]">
                                        Status
                                    </Table.HeadCell>

                                </Table.Row>

                            </Table.Head>

                            <Table.Body>

                                {recentTestimonials.map((testimonial, idx) => {

                                    const name = testimonial?.customerDetails["name"]?.answer as string ?? "";
                                    const email = testimonial?.customerDetails["email"]?.answer ?? "";
                                    const profile = testimonial?.customerDetails["profile"]?.answer as string ?? "";

                                    const role = testimonial?.customerDetails["role"]?.answer ?? "";
                                    const company = testimonial?.customerDetails["company"]?.answer ?? "";

                                    const textTestimonial = testimonial?.testimonial?.text?.content ?? "";

                                    const type = testimonial?.type ?? "";

                                    const dateTime = DateTime.fromISO(testimonial?.submittedAt ?? "");

                                    const relativeTime = dateTime.toRelative();

                                    return (
                                        <Table.Row
                                            key={idx}
                                            className="h-10 border-b hover:bg-blue-100 cursor-pointer"
                                            onClick={() => {
                                                navigate({
                                                    path: "/testimonials",
                                                    params: {
                                                        "id": testimonial.id
                                                    }
                                                });
                                            }}
                                        >

                                            <Table.Cell className={"px-6"}>

                                                <CheckBox onClick={() => { }} />

                                            </Table.Cell>

                                            <Table.Cell className="p-2 py-4 flex items-center text-left space-x-2">

                                                <img
                                                    src={profile ? profile : GenerateProfilePic(name[0])}
                                                    className="w-10 h-10 rounded-full"
                                                />

                                                <div>

                                                    <p className="text-sm font-normal tracking-wide">
                                                        {name}
                                                    </p>

                                                    {role && company ?
                                                        <span className="text-xs font-medium text-gray-600">
                                                            {role} of {company}
                                                        </span> :
                                                        <span className="text-xs font-medium text-gray-600">
                                                            {email}
                                                        </span>
                                                    }

                                                </div>

                                            </Table.Cell>

                                            <Table.Cell className="p-2 py-4 font-medium text-left text-sm">

                                                {type === "text" ? textTestimonial : null}

                                                {type === "video" ?
                                                    <Fragment>

                                                        {testimonial?.testimonial?.video?.transcribed ?
                                                            (testimonial?.testimonial?.video?.transcribedText ?? "") :
                                                            <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                                                                <CgTranscript className="w-6 h-6" />

                                                                <p className="text-sm font-normal"> No transcript </p>

                                                            </div>
                                                        }

                                                    </Fragment> : null
                                                }

                                                {type === "audio" ?
                                                    <Fragment>

                                                        {testimonial?.testimonial?.audio?.transcribed ?
                                                            (testimonial?.testimonial?.audio?.transcribedText ?? "") :
                                                            <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                                                                <CgTranscript className="w-6 h-6" />

                                                                <p className="text-sm font-normal"> No transcript </p>

                                                            </div>
                                                        }

                                                    </Fragment> : null
                                                }

                                            </Table.Cell>

                                            <Table.Cell className="text-center text-sm font-normal text-gray-700">

                                                {relativeTime}

                                            </Table.Cell>

                                            <Table.Cell className="text-center">

                                                {testimonial.approved ?
                                                    <div className="text-green-600 bg-green-100 text-xs font-medium p-2 rounded-md inline-block cursor-default">
                                                        Unapproved
                                                    </div> :
                                                    <div className="text-red-600 bg-red-100 text-xs font-medium p-2 rounded-md inline-block cursor-default">
                                                        Unapproved
                                                    </div>
                                                }

                                            </Table.Cell>

                                        </Table.Row>
                                    );
                                })}

                            </Table.Body>

                        </Fragment>
                    }

                </Table>

            </div>

        </div>
    );
}