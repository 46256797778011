import { EmptyIcon } from "@components/icons";
import { motion } from "framer-motion";

export function EmptyScreen() {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="w-full h-full flex flex-col items-center justify-center py-6"
        >

            <EmptyIcon />

            <p className="text-sm text-gray-800 font-medium mt-4">
                Your don't have any saved widgets yet.
            </p>

        </motion.div>
    );
}