import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { useAuthApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { REGX } from "@utils/regx";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";


export function ChangePasswordPopup() {

    const navigate = useNavigate();

    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [err1, setErr1] = useState("");
    const [err2, setErr2] = useState("");

    const { changePassword } = useAuthApiClient({});

    const { isPending } = changePassword;

    const onChange = async () => {
        try {

            if (!password1 || !password2) {
                setErr1(!password1 ? "Password is required" : "");
                setErr2(!password2 ? "Password is required" : "");
                return;
            }

            const isPassword = z.string()
                .min(8, { message: "Password must be at least 8 characters" })
                .refine(password => new RegExp(REGX.lowerCase).test(password), { message: "must contain at least one lowercase character" })
                .refine(password => new RegExp(REGX.upperCase).test(password), { message: "must contain at least one uppercase character" })
                .refine(password => new RegExp(REGX.specialCharacters).test(password), { message: "must contain at least one special character" });

            const isValid = isPassword.safeParse(password1);

            if (isValid.success === false) {
                setErr1(isValid.error.issues[0].message);
                return;
            }

            if (password1 !== password2) {
                setErr2("Passwords don't match");
                return;
            }

            await changePassword.mutateAsync(password1);

            toast.success("password updated successfully.");

            setTimeout(() => { navigate("/logout", { replace: true }); }, 1000);

        } catch (err: any) {
            toast.error(err);
        }
    };

    return (
        <Popup
            width="35%"
            name="Change your password"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <p className="text-sm font-normal text-gray-600 mt-2">
                You'll be logged out after making the change. Use your new password to log back in.
            </p>

            <div className="mt-4 space-y-3">

                <Input
                    type="password"
                    label="Your new password"
                    value={password1}
                    required={true}
                    error={err1}
                    onChange={(e) => setPassword1(e.target.value)}
                />

                <Input
                    type="password"
                    label="Your new password (same again)"
                    value={password2}
                    required={true}
                    error={err2}
                    onChange={(e) => setPassword2(e.target.value)}
                />

            </div>

            <div className="flex items-center justify-end space-x-2 mt-4">

                <SecondaryButton
                    disabled={isPending}
                    className="w-auto text-sm font-medium"
                    onClick={() => useCommonStore.getState().setActivePopup("")}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    disabled={isPending}
                    loading={isPending}
                    className="w-auto text-sm font-medium"
                    onClick={() => onChange()}
                >
                    Change
                </Button>

            </div>
        </Popup>
    );
}