import { WebAuth, } from 'auth0-js';
import { Config } from '../../config';

export class Auth0 {

    private static client = new WebAuth({
        clientID: Config.Auth0().clientId,
        domain: Config.Auth0().domain,
        redirectUri: Config.Auth0().redirect,
        audience: Config.Auth0().audience,
        responseType: "token id_token",
        scope: "openid profile email",
    });

    static login = () => this.client.authorize({ mode: "login" });

    static signInWithGoogle = () => this.client.authorize({ connection: 'google-oauth2' });

    static getAccessToken = () => {
        return new Promise<{ error: string | null, accessToken: string | null; }>((resolve, reject) => {

            this.client.parseHash((err, payload) => {

                const errMessage = JSON.parse(err?.errorDescription ?? "{}")?.message ?? null;

                if (err && errMessage) {
                    resolve({ error: errMessage, accessToken: null });
                    return;
                }

                if (!err && payload && payload.accessToken) {
                    resolve({
                        error: null,
                        accessToken: payload.accessToken
                    });
                    return;
                }

                reject();

            });
        });
    };

    private static clearSession = () => {
        localStorage.removeItem("token");
    };

    static logout = () => {

        this.clearSession();

        this.client.logout({
            returnTo: Config.Auth0().login,
            clientID: Config.Auth0().clientId,
        });

        return;
    };

}