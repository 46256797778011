import { SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { RecordVideoPopup } from "./record/popup";
import { useCommonStore, useFormStore } from "@store";
import { useEffect, useRef, useState, } from "react";
import { UploadVideoPopup } from "./upload/upload.popup";
import ReactHlsPlayer from "react-hls-player";


type Props = {
    type: "welcome" | "thankyou",
};

export function RecordOrUploadVideo({ type }: Props) {

    const playerRef = useRef<any>(null);

    const [showPreview, setShowPreview] = useState(false);

    const { welcome, thankyou } = useFormStore((store) => ({
        welcome: store.welcome.introVideo,
        thankyou: store.thankYou.thankyouVideo
    }));

    const url = type === "welcome" && welcome && welcome.url ? welcome?.url :
        type === "thankyou" && thankyou && thankyou.url ? thankyou.url : "";

    useEffect(() => {


        if (playerRef.current && url) {

            setShowPreview(true);

            playerRef.current.play();

        }

    }, [JSON.stringify(welcome), JSON.stringify(thankyou)]);

    return (
        <div className="flex items-start space-x-4">

            <div
                className={cn(`
                    border-2 rounded-md hover:opacity-80 hover:border-gray-500 
                    cursor-pointer w-[60%] h-[200px]
                `)}
            >

                {(type === "welcome" && welcome && welcome.url) ||
                    (type === "thankyou" && thankyou && thankyou.url) ?
                    <ReactHlsPlayer
                        playerRef={playerRef}
                        src={url}
                        controls={true}
                        className={cn({
                            "w-full h-full": showPreview,
                            "hidden": showPreview === false
                        })}
                    /> : null
                }

            </div>

            <div className="space-y-2 py-2">

                <SecondaryButton
                    text="Upload video"
                    className="text-xs font-medium"
                    onClick={() => useCommonStore.getState().setActivePopup("upload-video")}
                />

                <SecondaryButton
                    text="Record video"
                    className="text-xs font-medium"
                    onClick={() => useCommonStore.getState().setActivePopup("record-video")}
                />

            </div>

            <UploadVideoPopup type={type} />

            <RecordVideoPopup type={type} />

        </div>
    );
}