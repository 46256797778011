import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cn } from "@utils/cn";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

const Root = TabsPrimitive.Root;

const List = forwardRef<ElementRef<typeof TabsPrimitive.List>, ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={cn(`
            inline-flex items-center justify-center rounded-lg bg-muted p-2 text-muted-foreground
            bg-gray-200 h-full transition-all duration-300 ease-in-out
        `,
            className
        )}
        {...props}
    />
));


List.displayName = TabsPrimitive.List.displayName;

const Trigger = forwardRef<ElementRef<typeof TabsPrimitive.Trigger>, ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={cn(`
            flex items-center justify-center whitespace-nowrap rounded-md px-3 py-2 text-sm font-medium ring-offset-background 
            transition-all duration-300 ease-in-out focus-visible:outline-none tracking-wide
            focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 
            disabled:pointer-events-none disabled:opacity-50 
            data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow 
        `,
            className
        )}
        {...props}
    />
));

Trigger.displayName = TabsPrimitive.Trigger.displayName;

const Content = forwardRef<ElementRef<typeof TabsPrimitive.Content>, ComponentPropsWithoutRef<typeof TabsPrimitive.Content>>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={cn(`
            mt-1 ring-offset-background focus-visible:outline-none 
            focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 p-3
        `,
            className
        )}
        {...props}
    />
));

Content.displayName = TabsPrimitive.Content.displayName;


export const Tabs = Object.assign(Root, { List, Trigger, Content });
