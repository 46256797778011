import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./keys";
import axios from "axios";
import { useActiveSpace } from "../useActiveSpace";


export function useIntegrationsApiClient() {

    const activeSpace = useActiveSpace();

    const getAllIntegrationsQuery = useQuery({
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: 1,
        queryKey: [QueryKeys.integrations.getAll],
        queryFn: async () => {
            try {

                return (await axios.get<Api.Integrations.getAll>("/api/v1/integration", {
                    params: {
                        formId: activeSpace.formId
                    }
                })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const deleteIntegrationQuery = useMutation({
        mutationKey: [QueryKeys.integrations.delete],
        mutationFn: async (id: string) => {
            try {

                return (await axios.delete("/api/v1/integration", { params: { id } })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const toggleIntegrationQuery = useMutation({
        mutationKey: [QueryKeys.integrations.toggle],
        mutationFn: async (params: Api.Integrations.toggleParams) => {
            try {

                return (await axios.put("/api/v1/integration", params)).data;

            } catch (err) {
                throw err;
            }
        }
    });

    /* google sheet */
    const getGoogleOAuthUrlQuery = useQuery({
        queryKey: [QueryKeys.integrations.googleSheet.getAuthUrl],
        queryFn: async () => {
            try {

                return (await axios.get<Api.Integrations.getGoogleOAuthUrl>("/api/v1/integration/googleSheet/oauth")).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const createGoogleSheetQuery = useMutation({
        mutationKey: [QueryKeys.integrations.googleSheet.create],
        mutationFn: async (params: Api.Integrations.createGoogleSheetIntegrationParams) => {
            try {

                return await axios.post("/api/v1/integration/googleSheet", params);

            } catch (err) {
                throw err;
            }
        }
    });

    /* webhook */
    const createWebhookIntegrationQuery = useMutation({
        mutationKey: [QueryKeys.integrations.createWebhook],
        mutationFn: async (body: Api.Integrations.createWebhookIntegrationParams) => {
            try {

                return await axios.post("/api/v1/integration/webhook", body);

            } catch (err) {
                throw err;
            }
        }
    });

    /* zapier */
    const getZapierApiKeyQuery = useMutation({
        retry: 1,
        mutationKey: [QueryKeys.integrations.getZapierKey],
        mutationFn: async () => {
            try {

                return (await axios.get<Api.Integrations.getZapierApiKey>("/api/v1/integration/zapier/auth")).data;

            } catch (err) {
                throw err;
            }
        }
    });

    return {
        getAll: getAllIntegrationsQuery,
        googleSheet: {
            create: createGoogleSheetQuery,
            getOAuthUrl: getGoogleOAuthUrlQuery
        },
        createWebhook: createWebhookIntegrationQuery,
        deleteIntegration: deleteIntegrationQuery,
        toggle: toggleIntegrationQuery,
        getZapierApiKeyQuery,
    };

}