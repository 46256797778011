import { FullLogo } from "@components/icons/logo";


export function Intro() {
    return (
        <div className="rounded-xl h-full flex flex-col items-center p-5 shadow-lg bg-white/80 border w-[35%]">

            <div className="h-[20%] flex items-center justify-center w-full">
                <FullLogo />
            </div>

            <div className="w-full h-[50%] flex items-center justify-center">

                <img
                    src="https://form-assets.dev.forms.gozen.io/cdn/login/bg"
                    className="w-[90%] h-[90%]"
                />

            </div>


            <div className="text-center w-full h-[30%] p-2 pt-3">

                <h4 className="mb-12px font-medium text-md mb-3 capitalize">
                    Collect Surveys With Conversion Templates
                </h4>

                <p className="font-normal text-sm text-primary-gray text-13px">
                    Create forms in less than 5 minutes using pre designed form templates,
                    or create one from scratch, and share forms, surveys, and collect responses in no time.
                </p>

            </div>

        </div>
    );
}