import { GoogleSheetIcon, WebHookIcon } from "@components/icons/integration";
import { PabblyConnectIcon } from "@components/icons/integration/pabbly.icon";
import { ZapierIcon } from "@components/icons/integration/zapier.icons";
import { Text } from "@components/text";
import { IntegrationCard } from "./card";
import { GoogleSheetIntegrationPopup } from "./popup/googleSheet/main.popup";
import { ZapierIntegrationPopup } from "./popup/zapier/main.popup";
import { useCommonStore } from "@store";
import { useIntegrationsApiClient } from "@hooks";
import { Fragment, useEffect } from "react";
import { Loading } from "@components/loading";
import { WebhookIntegrationPopup } from "./popup/webhook/main.popup";

const Integrations = [
    {
        icon: <GoogleSheetIcon />,
        title: "Google Sheet",
        description: "Use Google Sheets integration to automate your workflow, and eliminate manual data entry.",
        type: "googleSheet",
    },
    {
        icon: <PabblyConnectIcon />,
        title: "Pabbly ",
        description: "Pabbly streamlines and optimisms your email marketing experience.",
        type: "pabbly",
    },
    {
        icon: <ZapierIcon />,
        title: "Zapier",
        description: "Zapier lets you sync form responses to your CRM, product management boards and more by streamlining your workflow. ",
        type: "zapier",
    },
    {
        icon: <WebHookIcon />,
        title: "Custom WebHook",
        description: "Webhooks lets you send form submissions to a specific URL.",
        type: "webhook",
    },
];

export function IntegrationsPage() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const { getAll } = useIntegrationsApiClient();

    const { isLoading } = getAll;

    useEffect(() => { getAll.refetch(); }, []);

    return (
        <div className="py-6 px-8">

            <div className="px-2 space-y-1">

                <Text
                    text="Integrations"
                    size="lg"
                    className="font-semibold"
                />

                <Text
                    text="Connect GoZen Testimonials to your favorite apps"
                    className="font-normal text-xs text-gray-500"
                />

            </div>

            <div className="pt-2 space-y-4 h-[50%]">

                {isLoading ?
                    <Loading text="" /> :
                    <Fragment>

                        {Integrations.map((item, idx) => <IntegrationCard key={idx} {...item} />)}

                    </Fragment>
                }

            </div>

            {activePopup === "googleSheet" ? <GoogleSheetIntegrationPopup /> : null}

            {activePopup === "pabbly" ? <WebhookIntegrationPopup integrationType="pabbly" /> : null}

            {activePopup === "zapier" ? <ZapierIntegrationPopup /> : null}

            {activePopup === "webhook" ? <WebhookIntegrationPopup integrationType="webhook" /> : null}

        </div>
    );
}