import { Input } from "@components/input";
import { Text, } from "@components/text";
import { QueryKeys, } from "@hooks";
import { useCaseStudyStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { useEffect } from "react";
import { useParams } from "react-router-dom";


export function TestimonialDetails() {

    const { testimonialContent, customerName, title, company } = useCaseStudyStore((store) => ({
        testimonialContent: store.testimonialDetails.testimonialContent,
        customerName: store.testimonialDetails.customerName,
        title: store.testimonialDetails.title,
        company: store.testimonialDetails.company
    }));

    const testimonialId = (useParams()?.tid as string) ?? "";

    const queryClient = useQueryClient();

    useEffect(() => {

        const testimonials = queryClient.getQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

        if (!testimonials) return;

        const testimonialDetails = testimonials?.testimonials.filter(item => item.id === testimonialId)[0];

        if (!testimonialDetails) return;

        const type = testimonialDetails.type;
        const testimonial = testimonialDetails.testimonial;

        if (type === "text") {
            useCaseStudyStore.getState().setTestimonialContent(testimonial.text?.content ?? "");
        }

        if (type === "audio" && testimonial.audio?.transcribed) {
            useCaseStudyStore.getState().setTestimonialContent(testimonial.audio?.transcribedText ?? "");
        }

        if (type === "video" && testimonial.video?.transcribed) {
            useCaseStudyStore.getState().setTestimonialContent(testimonial.video.transcribedText ?? "");
        }

        if (testimonialDetails.customerDetails["name"]) {
            useCaseStudyStore.getState().setCustomerName((testimonialDetails?.customerDetails["name"]?.answer as string) ?? "");
        }

        if (testimonialDetails.customerDetails["company"]) {
            useCaseStudyStore.getState().setCompanyName((testimonialDetails?.customerDetails["company"]?.answer as string) ?? "");
        }

    }, []);

    return (
        <div className="space-y-2">

            <div>

                <Text
                    text="Testimonial Content"
                    variant="label"
                    required={true}
                />

                <textarea
                    className={cn(`focus:ring-blue-400 focus:ring-offset-1 w-full rounded-md border border-gray-200 p-2 outline-none`)}
                    value={testimonialContent}
                    onChange={(e) => useCaseStudyStore.getState().setTestimonialContent(e.target.value)}
                />

            </div>

            <Input
                type="text"
                label="Customer Name"
                placeholder="John Doe"
                value={customerName}
                onChange={(e) => useCaseStudyStore.getState().setCompanyName(e.target.value)}
            />

            <Input
                type="text"
                label="Title"
                placeholder="CEO"
                value={title}
                onChange={(e) => useCaseStudyStore.getState().setTitle(e.target.value)}
            />

            <Input
                type="text"
                label="Company"
                placeholder="Pied piper"
                value={company}
                onChange={(e) => useCaseStudyStore.getState().setCompany(e.target.value)}
            />

        </div>
    );
}