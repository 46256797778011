import { Rating } from "@components/rating";
import { Config } from "@config";
import { cn } from "@utils/cn";
import { CgTranscript } from "react-icons/cg";

type Props = {
    thumbnailKey: string,
    rating: number | null,
    relativeTime: string;
};

export function VideoTestimonial({ rating, relativeTime, thumbnailKey }: Props) {

    const thumbnail = Config.cdn.video() + thumbnailKey;

    return (
        <div className={cn("relative w-full text-start cursor-default")}>

            <div className="flex items-start justify-between">

                <div className="w-[80%] space-y-3 h-[80px]">

                    {rating ?
                        <Rating
                            rated={rating ?? 0}
                            readonly={true}
                        /> : null
                    }

                    <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                        <CgTranscript className="w-6 h-6" />

                        <p className="text-sm font-normal"> No transcript </p>

                    </div>

                </div>

                <div className="w-[20%] h-[80px] ">

                    <img
                        src={thumbnail}
                        className="absolute top-0 right-2 w-auto h-full border rounded-md"
                    />

                </div>

            </div>

            <p
                data-tooltip-id="testimonial-submitted-at"
                className={cn(`
                    text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                    border-b border-dashed border-gray-300 inline-block
                `)}
            >
                {relativeTime}
            </p>

        </div>
    );
}