import { Button, SecondaryButton } from "@components/button";
import { EmptyIcon } from "@components/icons";
import { Text } from "@components/text";
import { useNavigate } from "react-router-dom";


export function EmptyTestimonialsScreen() {

    const navigate = useNavigate();

    return (
        <div className="w-full h-full flex flex-col items-center justify-center bg-gray-100">

            <EmptyIcon />

            <Text variant="primary" size="md" className="font-medium my-2">
                Your don't have any testimonials yey.
            </Text>

            <div className="flex items-center space-x-4 mt-3">

                <SecondaryButton
                    className="w-auto text-sm font-medium tracking-wide"
                    onClick={() => navigate("/form")}
                >

                    Collect Testimonials

                </SecondaryButton>

                <Button
                    className="w-auto text-sm font-medium tracking-wide"
                    onClick={() => navigate("/import-testimonials")}
                >

                    Import Testimonials

                </Button>

            </div>

        </div>
    );
}