import { Intro } from "./intro";
import { cn } from "@utils/cn";
import { Outlet } from "react-router-dom";

interface Props extends React.HTMLAttributes<HTMLDivElement> { }

export function AuthLayout({ className, children, ...props }: Props) {
    return (
        <div
            {...props}
            className={cn(`flex items-center justify-between w-screen h-screen bg-gray-100 p-5`, className)}
        >

            <Intro />

            <Outlet />

        </div>
    );
}