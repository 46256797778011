import { Badge } from "@components/badge";
import { SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { DropdownMenu } from "@components/dropDown";
import { useCommonStore } from "@store";
import { Fragment } from "react";
import { RemoveMemberPopup } from "./remove.popup";
import { MemberPopup } from "./member.popup";

type Props = Api.Teams.GetAllMembers["members"][0];

export function MemberCard({ id, inviteAccepted, member, space }: Props) {

    const activePopup = useCommonStore((store) => store.activePopup);

    const isMember = space.permissions.length === 4;
    const isViewer = space.permissions.length === 1 && space.permissions.includes("read");

    return (
        <Fragment>

            <div className="overflow-auto p-2 fade-in">

                <div className="rounded-md px-4 py-3 my-4 bg-white flex items-center justify-between border shadow-md">

                    <div className="flex items-center">

                        <img
                            src={member.avatarUrl}
                            className="rounded-md h-14 w-14"
                        />

                        <div className="ml-3">

                            <p className="text-lg font-medium"> {member.name} </p>

                            <p className="text-sm font-normal text-font_secondary"> {member.email} </p>

                        </div>

                    </div>

                    <div className="xl:w-[40%] md:w-[60%] flex items-center justify-end space-x-4">

                        <div className="cursor-default">

                            {isMember ?
                                <Badge
                                    type="success"
                                    text="Member"
                                /> : null
                            }

                            {isViewer ?
                                <Badge
                                    type="warning"
                                    text="Viewer"
                                /> : null
                            }

                        </div>

                        <div className="cursor-default">

                            {!inviteAccepted ?
                                <Badge
                                    type="danger"
                                    text="Invite not accepted"
                                /> : null
                            }

                        </div>

                        <SecondaryButton
                            className="w-auto font-medium text-xs px-3 text-gray-700 tracking-wide"
                            onClick={() => useCommonStore.getState().setActivePopup("update-member-permission")}
                        >
                            Manage Access
                        </SecondaryButton>


                        <DropdownMenu dir="ltr">

                            <DropdownMenu.Trigger className="">

                                <div
                                    className={cn(`
                                    p-1 rounded-lg cursor-pointer outline-none hover:bg-gray-100 
                                    transition-all duration-300 ease-in-out
                                `)}
                                >

                                    <HiOutlineDotsVertical className="w-5 h-5" />

                                </div>

                            </DropdownMenu.Trigger>

                            <DropdownMenu.Content
                                side="left"
                                sideOffset={5}
                                align="start"
                                className="absolute top-8 right-[-30px] w-[160px]"
                            >

                                <DropdownMenu.Item
                                    className="hover:bg-red-100 text-red-600"
                                    onClick={() => useCommonStore.getState().setActivePopup("delete-member")}
                                >
                                    Remove
                                </DropdownMenu.Item>

                            </DropdownMenu.Content>

                        </DropdownMenu>

                    </div>

                </div>

            </div>

            <RemoveMemberPopup mid={id} />

            {id && activePopup === "update-member-permission" ?
                <MemberPopup
                    type="update"
                    memberEmail={member.email}
                    oldPermissions={space.permissions}
                    spaceId={space.spaceId}
                    memberId={id}
                /> : null
            }

        </Fragment>
    );
}