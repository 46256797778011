import { Button } from "@components/button";
import { QueryKeys, useGetQueryData } from "@hooks";
import { BillingUtils } from "@utils/billingUtils";
import { cn } from "@utils/cn";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

const planTypes: Api.Billing.SubscriptionPlans[] = ["Basic", "Essential", "Professional", "Enterprise"];

export function Plans() {

    // const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const [interval, setInterval] = useState<Api.Billing.BillingInterval>('monthly');

    return (
        <div className="h-[92%] w-full overflow-y-auto fade-in">

            <div className="h-full xl:w-full md:w-full pt-3 pb-5 flex flex-col items-center">

                {/* <div className="font-medium text-md text-blue-800 bg-blue-100 rounded-lg mb-3 px-4 py-2">
                    save upto 20% in yearly plans
                </div> */}

                <div className="bg-gray-300 flex p-2 rounded-md space-x-2">

                    <div
                        className={cn(`text-sm font-medium tracking-wide py-2 px-4 rounded-md cursor-pointer`, {
                            "bg-primary text-white": interval === "monthly",
                        })}
                        onClick={() => setInterval("monthly")}
                    >
                        Monthly
                    </div>

                    <div
                        className={cn(`text-sm font-medium tracking-wide py-2 px-4 rounded-md cursor-pointer`, {
                            "text-white bg-primary": interval === "yearly"
                        })}
                        onClick={() => setInterval("yearly")}
                    >
                        Yearly
                    </div>

                </div>

                <div className="w-full flex mt-6 pt-2">

                    {planTypes.map(((plan, idx) => (
                        <PlanLayout
                            key={idx}
                            plan={plan}
                            interval={interval}
                        />
                    )))}

                </div>

            </div>

        </div>
    );
}

type Props = {
    plan: Api.Billing.Plans,
    interval: Api.Billing.BillingInterval;
};

const PlanLayout: React.FC<Props> = ({ plan, interval }) => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const isCurrentPlan = plan === billing.plan && billing.billingInterval === interval ? true : false;

    const navigate = useNavigate();

    const onChangePlan = () => {

        if (isCurrentPlan) return;

        navigate({
            pathname: "/checkout",
            search: createSearchParams({
                "plan": plan,
                "interval": interval,
                "action": billing.plan === "free" ? "new" : "update"
            }).toString()
        });

    };

    return (
        <div
            className={cn(`
                h-auto flex flex-col items-center justify-center mt-2 m-auto rounded-md py-5
                mx-2 border-2 hover:shadow-xl hover:bg-gray-100 hover:border-primary w-[260px]
                transition-all duration-300 ease-in-out cursor-default space-y-2
            `, {
                "border-primary bg-gray-100": isCurrentPlan
            })}
        >

            <h3 className="text-center text-lg font-semibold capitalize">
                {plan}
            </h3>

            <div className="flex justify-center items-baseline mt-2">

                <p className="text-xs">

                    $

                    <b className="text-lg ml-1">

                        {interval === "monthly" ? BillingUtils.get(plan).monthlyPrice : BillingUtils.get(plan).yearlyPrice}.00

                    </b>

                </p>

                <p className="text-xs font-normal ml-1">
                    / {interval === "monthly" ? "month" : "year"}
                </p>

            </div>

            <Button
                className={cn(`w-[90%] text-sm font-medium`, {
                    "border border-indigo-500 text-primary bg-white": isCurrentPlan,
                    "bg-primary text-white hover:bg-blue-700 cursor-pointer": isCurrentPlan === false
                })}
                onClick={() => onChangePlan()}
                disabled={isCurrentPlan}
            >

                {isCurrentPlan ? "Your Current Plan" : "Change plan"}

            </Button>

            <div className="my-4 w-[90%] px-2">

                {BillingUtils.get(plan).features.map((feature, idx) => (
                    <div
                        key={idx}
                        className="flex gap-2 my-3 cursor-pointer"
                    >

                        <GreenTick />

                        <p className="text-sm font-normal"> {feature} </p>

                    </div>
                ))}

            </div>


        </div>
    );
};

const GreenTick: React.FC = ({ }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.00033 0.666016C4.40033 0.666016 0.666992 4.39935 0.666992 8.99935C0.666992 13.5993 4.40033 17.3327 9.00033 17.3327C13.6003 17.3327 17.3337 13.5993 17.3337 8.99935C17.3337 4.39935 13.6003 0.666016 9.00033 0.666016ZM6.74199 12.5744L3.75033 9.58268C3.42533 9.25768 3.42533 8.73268 3.75033 8.40768C4.07533 8.08268 4.60033 8.08268 4.92533 8.40768L7.33366 10.8077L13.067 5.07435C13.392 4.74935 13.917 4.74935 14.242 5.07435C14.567 5.39935 14.567 5.92435 14.242 6.24935L7.91699 12.5744C7.60033 12.8994 7.06699 12.8994 6.74199 12.5744Z" fill="#49D57A" />
        </svg>
    );
};