import { Button, SecondaryButton } from "@components/button";
import { useCommonStore, useInvitesStore } from "@store";
import { motion } from "framer-motion";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { LuUpload } from "react-icons/lu";
import { SelectColumns } from "./selectColumns";
import { QueryKeys, useEmailInvitesApiClient, useInvalidateQuery } from "@hooks";
import { UploadFileSection } from "./upload";
import { useQueryClient } from "@tanstack/react-query";


export function UploadFile() {

    const { activeUploadTab, selectedFile, parsedInvites } = useInvitesStore((store) => ({
        activeUploadTab: store.activeUploadTab,
        selectedFile: store.selectedFile,
        parsedInvites: store.parsedFile.rows
    }));

    const { sendInvite, } = useEmailInvitesApiClient({});

    const queryClient = useQueryClient();
    const { invalidate } = useInvalidateQuery();

    const onClick = async () => {

        if (activeUploadTab === "upload" && !selectedFile) {
            toast.error("Please select file to upload");
            return;
        }

        if (activeUploadTab === "upload") {
            useInvitesStore.getState().setActiveUploadTab("selectColumns");
        }

        if (activeUploadTab === "selectColumns") {

            const newInvites = await sendInvite.mutateAsync([...parsedInvites]);

            invalidate([QueryKeys.emailInvites.getAll]);

            queryClient.setQueryData([QueryKeys.emailInvites.getAll], { invites: newInvites });

            useCommonStore.getState().setActivePopup("");

            useInvitesStore.getState().clear();

        }

    };

    const onClose = () => {
        useCommonStore.getState().setActivePopup("");
        useInvitesStore.getState().setActiveTab("upload");
    };

    return (
        <motion.div
            initial={{ opacity: 0, }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            {activeUploadTab === "selectColumns" ? <SelectColumns /> : <UploadFileSection />}

            <div className="mt-4 flex items-center justify-end space-x-4">

                <SecondaryButton
                    disabled={sendInvite.isPending}
                    className="text-sm font-medium tracking-wide w-auto"
                    onClick={() => onClose()}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    loading={sendInvite.isPending}
                    disabled={sendInvite.isPending}
                    className="text-sm font-medium tracking-wide w-auto"
                    onClick={() => onClick()}
                >

                    {activeUploadTab === "selectColumns" ?
                        <Fragment>

                            <LuUpload className="w-4 h-4 mx-1" />

                            <p>
                                Upload
                            </p>

                        </Fragment> :
                        <p>
                            Next
                        </p>
                    }

                </Button>

            </div>

        </motion.div>
    );
}