import { Input } from "@components/input";
import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useFormStore } from "@store";



export function PageSettings() {

    const { title, message, buttonTxt } = useFormStore((store) => ({
        title: store.reward?.page?.title ?? "",
        message: store.reward?.page?.message ?? "",
        buttonTxt: store.reward?.page?.button ?? ""
    }));

    const onChangePreviewScreen = () => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "currentScreen",
                payload: { screen: "reward-page" }
            } as Messages.Types, "*");

        }

    };

    return (
        <div onClick={() => onChangePreviewScreen()}>

            <Text text="Page Settings" />

            <div className="space-y-2 mt-1">

                <div>

                    <Text
                        text={"Title"}
                        variant="label"
                    />

                    <QuillInput
                        valueAsHTML={title}
                        placeHolder="Enter your title"
                        recall={false}
                        onChange={(e) => useFormStore.getState().setRewardPage("title", e.html)}
                    />

                </div>

                <div>

                    <Text
                        text={"Message"}
                        variant="label"
                    />

                    <QuillInput
                        valueAsHTML={message}
                        placeHolder="Enter your message"
                        recall={false}
                        onChange={(e) => useFormStore.getState().setRewardPage("message", e.html)}
                    />

                </div>

                <Input
                    label="Button"
                    type="text"
                    value={buttonTxt}
                    onChange={(e) => useFormStore.getState().setRewardPage("button", e.target.value)}
                />

            </div>

        </div>
    );
}