import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";

export function useImportTestimonialApiClient() {

    const manualImport = useMutation({
        mutationKey: [QueryKeys.importTestimonials.manual],
        mutationFn: async (data: Api.ImportTestimonials.manualImportParams) => {
            try {

                const formData = new FormData();

                formData.set("formId", data.formId);
                formData.set("source", data.source);

                formData.set("name", data.testimonial.name);
                formData.set("email", data.testimonial.email);
                formData.set("avatar", data.testimonial.avatar ?? "");
                formData.set("company", data.testimonial.company);
                formData.set("companyUrl", data.testimonial.companyUrl);
                formData.set("companyLogo", data.testimonial.companyLogo ?? "");
                formData.set("role", data.testimonial.role);
                formData.set("rating", data.testimonial.rating.toString());
                formData.set("type", data.testimonial.type);
                formData.set("date", data.testimonial.date);
                formData.set("sourceUrl", data.testimonial.sourceUrl);
                formData.set("tagline", data.testimonial.tagline);

                const imageAttachments = data.testimonial.testimonial.text.imageAttachments;
                const videoFile = data.testimonial.testimonial.video.file;
                const audioFile = data.testimonial.testimonial.audio.file;

                imageAttachments.forEach((file) => {

                    formData.append("imageAttachments[]", file);

                });

                formData.set("video", videoFile ?? "");
                formData.set("audio", audioFile ?? "");

                formData.set("textTestimonialContent", data.testimonial.testimonial.text.content);

                formData.set("videoTestimonialTranscribedText", data.testimonial.testimonial.video.transcribedText);

                formData.set("audioTestimonialTranscribedText", data.testimonial.testimonial.audio.transcribedText);

                return (await axios.post("/api/v1/import/manual", formData)).data;

            } catch (err: any) {
                console.log(err);
                throw ParseAxiosError(err);
            }
        }
    });

    const importQuery = useMutation({
        mutationKey: [QueryKeys.importTestimonials.import],
        mutationFn: async (body: Api.ImportTestimonials.importParams) => {
            try {

                return (await axios.post("/api/v1/import", body)).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const twitterImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.twitter],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/twitter", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const linkedInImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.linkedIn],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/linkedin", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const instagramImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.instagram],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/instagram", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const facebookImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.faceBook],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/facebook", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const youtubeImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.youtube],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/youtube", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const trustpilotImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.linkedIn],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/trustpilot", {
                    params: { url, formId }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const capterraImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.capterra],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/capterra", {
                    params: { url, formId }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const amazonImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.amazon],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/amazon", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const airbnbImporter = useMutation({
        mutationKey: [QueryKeys.importTestimonials.airbnb],
        mutationFn: async ({ url, formId }: Api.ImportTestimonials.scraperParam) => {
            try {

                return (await axios.get("/api/v1/import/amazon", {
                    params: {
                        url, formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        manualImport,
        importQuery,
        twitterImporter,
        linkedInImporter,
        instagramImporter,
        facebookImporter,
        youtubeImporter,
        trustpilotImporter,
        capterraImporter,
        amazonImporter,
        airbnbImporter
    };

}