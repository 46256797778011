import { QueryKeys, useGetQueryData, useSpacesApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import { MdOutlineDone } from "react-icons/md";
import ImagePlaceholder from "@assets/image-placeholder.png";
import { AiOutlineTeam } from "react-icons/ai";
import Cookies from 'js-cookie';


type Props = {
    space: Api.SpacesClient.SpaceType,
    onClose: () => void,
};

export function SpaceItem({ space, onClose }: Props) {

    const queryClient = useQueryClient();

    const spacesQuery = useGetQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const activeSpaceId = spacesQuery?.activeSpace?.id ?? "";

    const { getDetails } = useSpacesApiClient({});

    const imageRef = useRef<any>(null);

    const [isSharedSpace, setIsSharedSpace] = useState(false);

    useEffect(() => {

        const sharedSpacesIds = spacesQuery.sharedSpaces.flatMap(item => item.id);

        setIsSharedSpace(sharedSpacesIds.includes(space.id));

    }, []);

    const onChangeSpace = async (spaceId: string) => {

        if (spaceId === activeSpaceId) return;

        if (!spacesQuery) return;

        const allSpaces = [...spacesQuery?.spaces ?? [], ...spacesQuery.sharedSpaces ?? []];

        const selectedSpace = allSpaces.filter(space => space.id === spaceId ? space : null)[0];

        if (!selectedSpace) return;

        let updatedData: Api.SpacesClient.getAll = {
            ...spacesQuery,
            activeSpace: selectedSpace
        };

        queryClient.setQueryData([QueryKeys.spaces.getAll], updatedData);

        /* get new space details */
        getDetails.refetch();

        if (isSharedSpace) Cookies.set("_s_s", spaceId);
        else Cookies.remove("_s_s");

        onClose();

    };

    return (
        <div
            className={cn(`flex items-center justify-between p-2 space-x-2 rounded-md`, {
                "bg-gray-100": activeSpaceId === space.id,
                "hover:bg-gray-100 ": activeSpaceId !== space.id
            })}
            onClick={() => onChangeSpace(space.id)}
        >

            <div className="flex items-center space-x-2">

                <img
                    ref={imageRef}
                    src={space.logo ? space.logo : ImagePlaceholder}
                    onError={() => imageRef.current.src = ImagePlaceholder}
                    className="w-7 h-7"
                />

                <p className="text-sm"> {space.name} </p>

            </div>

            {activeSpaceId === space.id ?
                <MdOutlineDone className="text-primary w-5 h-5 mr-4" /> :
                null
            }

            {activeSpaceId === space.id && isSharedSpace ?
                <AiOutlineTeam className="text-primary" /> :
                null
            }

        </div>
    );

};