import { Input } from "@components/input";
import { Text } from "@components/text";
import { useFormStore } from "@store";
import { DefaultLabels } from "./defaultLabels";

export function CustomDetailsPageLabels() {

    const {
        nameLabel, namePlaceHolder,
        emailLabel, emailPlaceholder,
        companyLabel, companyPlaceholder,
        companyLogoLabel,
        jobLabel, jobPlaceholder,
        photoLabel,
        websiteLabel, websitePlaceholder,
        consent, submit
    } = useFormStore((store) => ({
        nameLabel: store.customLabels?.nameLabel ?? "",
        namePlaceHolder: store.customLabels?.namePlaceHolder ?? "",
        emailLabel: store.customLabels?.emailLabel ?? "",
        emailPlaceholder: store.customLabels?.emailPlaceholder ?? "",
        companyLabel: store.customLabels?.companyLabel ?? "",
        companyPlaceholder: store.customLabels?.companyPlaceholder ?? "",
        companyLogoLabel: store.customLabels?.companyLogoLabel ?? "",
        jobLabel: store.customLabels?.jobLabel ?? "",
        jobPlaceholder: store.customLabels?.jobPlaceholder ?? "",
        photoLabel: store.customLabels?.photoLabel ?? "",
        websiteLabel: store.customLabels?.websiteLabel ?? "",
        websitePlaceholder: store.customLabels?.websitePlaceholder ?? "",
        consent: store.customLabels?.consent ?? "",
        submit: store.customLabels?.responseSubmitBtn ?? ""
    }));

    return (
        <div className="space-y-2 pb-4 border-b">

            <Text text="Custom Details" />

            {/* name */}
            <Input
                type="text"
                label="Name label"
                placeholder="Enter new label"
                className="h-auto"
                value={nameLabel ? nameLabel : DefaultLabels?.nameLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("nameLabel", e.target.value)}
            />

            <Input
                type="text"
                label="Name Placeholder"
                placeholder="Enter new label"
                className="h-auto"
                value={namePlaceHolder ? namePlaceHolder : DefaultLabels?.namePlaceHolder}
                onChange={(e) => useFormStore.getState().setCustomLabel("namePlaceHolder", e.target.value)}
            />

            {/* email */}
            <Input
                type="text"
                label="Email label"
                placeholder="Enter new label"
                className="h-auto"
                value={emailLabel ? emailLabel : DefaultLabels?.emailLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("emailLabel", e.target.value)}
            />

            <Input
                type="text"
                label="Email Placeholder"
                placeholder="Enter new label"
                className="h-auto"
                value={emailPlaceholder ? emailPlaceholder : DefaultLabels?.emailPlaceholder}
                onChange={(e) => useFormStore.getState().setCustomLabel("emailPlaceholder", e.target.value)}
            />

            {/* company */}
            <Input
                type="text"
                label="Company label"
                placeholder="Enter new label"
                className="h-auto"
                value={companyLabel ? companyLabel : DefaultLabels?.companyLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("companyLabel", e.target.value)}
            />

            <Input
                type="text"
                label="Company Placeholder"
                placeholder="Enter new label"
                className="h-auto"
                value={companyPlaceholder ? companyPlaceholder : DefaultLabels?.companyPlaceholder}
                onChange={(e) => useFormStore.getState().setCustomLabel("companyPlaceholder", e.target.value)}
            />

            {/* company logo */}
            <Input
                type="text"
                label="Company Logo label"
                placeholder="Enter new label"
                className="h-auto"
                value={companyLogoLabel ? companyLogoLabel : DefaultLabels?.companyLogoLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("companyLogoLabel", e.target.value)}
            />

            {/* job title */}
            <Input
                type="text"
                label="Job Title Label"
                placeholder="Enter new label"
                className="h-auto"
                value={jobLabel ? jobLabel : DefaultLabels?.jobLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("jobLabel", e.target.value)}
            />

            <Input
                type="text"
                label="Job Title Placeholder"
                placeholder="Enter new placeholder"
                className="h-auto"
                value={jobPlaceholder ? jobPlaceholder : DefaultLabels?.jobPlaceholder}
                onChange={(e) => useFormStore.getState().setCustomLabel("jobPlaceholder", e.target.value)}
            />

            {/* photo */}
            <Input
                type="text"
                label="Photo Label"
                placeholder="Enter new label"
                className="h-auto"
                value={photoLabel ? photoLabel : DefaultLabels?.photoLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("photoLabel", e.target.value)}
            />

            {/* website */}
            <Input
                type="text"
                label="Website Label"
                placeholder="Enter new label"
                className="h-auto"
                value={websiteLabel ? websiteLabel : DefaultLabels?.websiteLabel}
                onChange={(e) => useFormStore.getState().setCustomLabel("websiteLabel", e.target.value)}
            />

            <Input
                type="text"
                label="Website Placeholder"
                placeholder="Enter new label"
                className="h-auto"
                value={websitePlaceholder ? websitePlaceholder : DefaultLabels?.websitePlaceholder}
                onChange={(e) => useFormStore.getState().setCustomLabel("websitePlaceholder", e.target.value)}
            />

            {/* consent */}
            <Input
                type="text"
                label="Consent"
                placeholder="Enter new label"
                className="h-auto"
                value={consent ? consent : DefaultLabels?.consent}
                onChange={(e) => useFormStore.getState().setCustomLabel("consent", e.target.value)}
            />

            {/* submit */}
            <Input
                type="text"
                label="Custom Details Submit"
                placeholder="Enter new label"
                className="h-auto"
                value={submit ? submit : DefaultLabels?.responseSubmitBtn}
                onChange={(e) => useFormStore.getState().setCustomLabel("customDetailsSubmitBtn", e.target.value)}
            />

        </div>
    );
}