import { useFormStore } from "@store";
import { ColorPicker } from "@components/colorPicker";
import { Text } from "@components/text";

export function Theme() {

    const { color1, color2, fontColor } = useFormStore((state) => ({
        color1: state.reward.color1,
        color2: state.reward.color2,
        fontColor: state.reward.fontColor
    }));

    return (
        <div className="space-y-2">

            <Text text="Wheel Design" />

            <div className="flex space-x-4">

                <ColorPicker
                    label='Color 1'
                    defaultColor={color1}
                    onChange={(color) => useFormStore.getState().setRewardWheelColor({
                        color1: color
                    })}
                />

                <ColorPicker
                    label='Color 2'
                    defaultColor={color2}
                    onChange={(color) => useFormStore.getState().setRewardWheelColor({
                        color2: color
                    })}
                />

            </div>


            <ColorPicker
                label='Font Size'
                defaultColor={fontColor}
                onChange={(color) => useFormStore.getState().setRewardWheelColor({
                    fontColor: color
                })}
            />

        </div>
    );
}