import { useImportTestimonialStore } from "@store";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { z } from "zod";
import { QueryKeys, useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    content: z.string().min(1, "Testimonial cannot be empty.")
});

export function WatsupImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const queryClient = useQueryClient();

    /*
        - TODO append to testimonials preview section 
    */

    const onImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId: activeSpace.formId,
                source: "watsup"
            });

            queryClient.invalidateQueries({ queryKey: [QueryKeys.testimonials.getAll] });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import from Watsupapp"
            onImport={() => onImport()}
        >

            <ManuallyImport
                fields={{
                    rating: false,
                    email: null,
                    sourceUrl: null,
                    role: false,
                    testimonial: {
                        text: { content: true, imageAttachments: true },
                        audio: { url: false, transcribedText: false },
                        video: { url: false, transcribedText: false }
                    },
                    company: false,
                    companyLogo: false,
                }}
            />

        </ImportCard>
    );
}