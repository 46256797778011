import { UndefinedInitialDataOptions, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";

type Props = {
    getSession?: {
        apiParams?: Api.Billing.getSessionParams,
        params?: Partial<UndefinedInitialDataOptions>,
    },
    getNewPlanDetails?: {
        apiParams?: Api.Billing.getNewPlanDetailsParams,
        params?: Partial<UndefinedInitialDataOptions>,
    };
};

export function useBillingApiClient({ getSession, getNewPlanDetails }: Props) {

    const getCurrentPlanDetails = useQuery({
        queryKey: [QueryKeys.billings.getCurrentPlan],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Billing.getBillingDetails>("/api/v1/billing")).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const getNewPlanDetailsQuery = useQuery({
        queryKey: [QueryKeys.billings.getNewPlan],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Billing.getNewPlanDetails>("/api/v1/billing/plan", {
                    params: getNewPlanDetails?.apiParams
                })).data;
            } catch (err) {
                throw err;
            }
        },
        ...getNewPlanDetails?.params
    });

    const getSessionQuery = useQuery({
        enabled: false,
        queryKey: [QueryKeys.billings.getSession],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Billing.getSession>("/api/v1/billing/session", { params: getSession?.apiParams })).data;
            } catch (err) {
                throw err;
            }
        },
        ...getSession?.params
    });

    const subscriptionCreate = useMutation({
        mutationKey: [QueryKeys.billings.subscription.create],
        mutationFn: async (body: Api.Billing.subscriptionCreateParams) => {
            try {
                return (await axios.post<Api.Billing.subscriptionCreate>("/api/v1/billing", body)).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const subscriptionUpdate = useMutation({
        mutationKey: [QueryKeys.billings.subscription.update],
        mutationFn: async (body: Api.Billing.subscriptionUpdateParams) => {
            try {
                return (await axios.put<Api.Billing.subscriptionUpdateParams>("/api/v1/billing", body)).data;
            } catch (err) {
                throw err;
            }
        }
    });

    return {
        getCurrentPlanDetails,
        getNewPlanDetails: getNewPlanDetailsQuery,
        getSession: getSessionQuery,
        subscriptionCreate,
        subscriptionUpdate,
    };

};