import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { QueryKeys, useActiveSpace, useGetQueryData, useInvalidateQuery, useTeamsApiClient } from "@hooks";
import { ParseAxiosError } from "@lib/axios";
import { useCommonStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

type Props = {
    mid: string;
};

export function RemoveMemberPopup({ mid }: Props) {

    const showPopup = useCommonStore((store) => store.activePopup === "delete-member");

    const { invalidate } = useInvalidateQuery();

    const teamQuery = useGetQueryData<Api.Teams.GetAllMembers>([QueryKeys.teams.getAll]);

    const queryClient = useQueryClient();

    const { id, name } = useActiveSpace();

    const { deleteMember } = useTeamsApiClient({});

    const onDelete = async () => {
        try {

            const teamId = teamQuery.teamId as string;

            await deleteMember.mutateAsync({
                mid,
                sid: id,
                tid: teamId
            });

            invalidate([QueryKeys.teams.getAll]);

            const updatedMembers = teamQuery.members.filter(item => item.id !== mid ? item : null);

            queryClient.setQueryData<Api.Teams.GetAllMembers>([QueryKeys.teams.getAll], (oldData) => ({
                ...oldData,
                members: [...updatedMembers]
            } as Api.Teams.GetAllMembers));

            useCommonStore.getState().setActivePopup("");

        } catch (err: any) {
            toast.error(ParseAxiosError(err));
        }
    };

    if (showPopup === false) return null;

    return (
        <Popup
            width={"32%"}
            name="Leave Team"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <div className="text-sm font-normal mt-4 space-y-2 text-gray-800">

                <p>
                    You are about to leave <span className="font-semibold text-black"> {name} </span>.
                    In order to regain access at a later time, a Team Owner must invite you.
                </p>

                <p>
                    Are you sure you want to continue?
                </p>

            </div>


            <div className="mt-5 flex items-center justify-end space-x-4">

                <SecondaryButton
                    disabled={deleteMember.isPending}
                    className="text-sm font-medium w-auto"
                    onClick={() => useCommonStore.getState().setActivePopup("")}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    disabled={deleteMember.isPending}
                    loading={deleteMember.isPending}
                    variant="danger"
                    className="text-sm font-medium w-auto"
                    onClick={() => onDelete()}
                >
                    Confirm
                </Button>

            </div>

        </Popup>
    );
}