import { Input } from "@components/input";
import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore } from "@store";


export function EditTestimonialContent() {

    const { selectedTestimonial, showProfile, showRating } = useSocialProofStore((store) => ({
        selectedTestimonial: store.selectedTestimonials[0],
        showProfile: store.imageTemplate.settings.showProfile,
        showRating: store.imageTemplate.settings.showRating
    }));

    console.log(selectedTestimonial);

    return (
        <div className="space-y-4">

            {selectedTestimonial?.type === "text" ?
                <div>

                    <Text
                        variant="label"
                        text={"Text Testimonial"}
                    />

                    <QuillInput
                        recall={false}
                        valueAsHTML={selectedTestimonial?.testimonial?.text?.content ?? ""}
                        placeHolder=""
                        className="mt-0"
                        style={{
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem"
                        }}
                        onChange={(e) => useSocialProofStore.getState().setImageTemplateContent({
                            text: e.text
                        })}
                    />

                </div> : null
            }

            {selectedTestimonial?.type === "audio" ?
                <div>

                    <Text
                        variant="label"
                        text={"Audio Transcript"}
                    />

                    <QuillInput
                        recall={false}
                        valueAsHTML={selectedTestimonial?.testimonial?.audio?.transcribedText ?? ""}
                        placeHolder=""
                        className="mt-0"
                        style={{
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem"
                        }}
                        onChange={(e) => useSocialProofStore.getState().setImageTemplateContent({
                            text: e.text
                        })}
                    />


                </div> : null
            }

            {selectedTestimonial?.type === "video" ?
                <div>

                    <Text
                        variant="label"
                        text={"Video Transcript"}
                    />

                    <QuillInput
                        recall={false}
                        valueAsHTML={selectedTestimonial?.testimonial?.video?.transcribedText ?? ""}
                        placeHolder=""
                        className="mt-0"
                        style={{
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem"
                        }}
                        onChange={(e) => useSocialProofStore.getState().setImageTemplateContent({
                            text: e.text
                        })}
                    />


                </div> : null
            }

            <Input
                label="Customer Name"
                value={"Peter Parker"}
                onChange={(e) => useSocialProofStore.getState().setImageTemplateContent({
                    customerName: e.target.value
                })}
            />

            <TextToggle
                isActive={showProfile}
                name="Show Profile Picture"
                onClick={() => useSocialProofStore.getState().setImageTemplateSettings({
                    showProfile: !showProfile
                })}
            />

            <TextToggle
                isActive={showRating}
                name="Show Rating"
                onClick={() => useSocialProofStore.getState().setImageTemplateSettings({
                    showRating: !showRating
                })}
            />

        </div>
    );
}