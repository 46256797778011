import { useParams } from "react-router-dom";
import { ImageSettings } from "./image/settings/main.settings";
import { WallOfLoveSettings } from "./wol/settings/main.settings";
import { VideoSettings } from "./video/settings/main.settings";
import { WidgetSettings } from "./widgets/settings/main.settings";



export function TemplateSettings() {

    const { type } = useParams<EditWidgetPageParams>();

    return (
        <div className="w-[30%] h-full bg-white">

            {type === "image" ? <ImageSettings /> : null}

            {type === "wall-of-love" ? <WallOfLoveSettings /> : null}

            {type === "video" ? <VideoSettings /> : null}

            {type === "widget" ? <WidgetSettings /> : null}

        </div>
    );
}