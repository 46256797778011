import { Text } from "@components/text";
import { useImportTestimonialStore } from "@store";
import { cn } from "@utils/cn";
import { useRef } from "react";
import toast from "react-hot-toast";
import { IoMdImages } from "react-icons/io";
import { LuImagePlus } from "react-icons/lu";
import { MdOutlineClose } from "react-icons/md";


export function TextTestimonialImageAttachmentsField() {

    const imageAttachments = useImportTestimonialStore((store) => store.testimonial.testimonial.text.imageAttachments);

    const ref = useRef<any>(null);

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const files = e.target.files;

            if (!files?.length) {
                toast.error("no images selected.");
                return;
            }

            const arr = Array.from(files);

            const totalFileSize = arr.reduce((prev, file) => prev + file.size, 0);

            const sizeInMb = Math.floor((totalFileSize / 1024) / 1024);

            if (sizeInMb > 10) {
                toast.error("selected images exceeds 10Mb limit.");
                e.target.value = "";
                return;
            }

            const parsedFiles: File[] = [];

            arr.forEach(file => parsedFiles.push(file));

            useImportTestimonialStore.getState().setTextTestimonial({
                imageAttachments: parsedFiles
            });

        } catch (err) {
            console.log(err);
        }
    };

    const onDelete = (fileIdx: number) => {

        const newAttachments = imageAttachments.filter((_file, idx) => idx !== fileIdx);

        useImportTestimonialStore.getState().setTextTestimonial({
            imageAttachments: [...newAttachments]
        });

    };

    return (
        <div className="space-y-2">

            <Text
                text="Image Attachments"
                variant="label"
            />

            <input
                id="image-attachments"
                type="file"
                name="image-attachments"
                ref={ref}
                multiple={true}
                accept=".png,.jpg,.jpeg"
                className={cn("hidden")}
                onChange={(e) => onFileChange(e)}
            />

            <div
                className={cn(`
                    border border-dashed border-gray-400 w-full h-[140px]
                    rounded-md font-normal tracking-wide text-sm 
                    flex items-center justify-start
                    hover:border-blue-500
                `)}
            >

                {imageAttachments.length ?
                    <div className="flex justify-start space-x-2 px-4">

                        <div
                            className={cn(`
                                w-[110px] h-[110px] p-2 
                                border border-dashed border-gray-300 
                                rounded-lg flex items-center justify-center 
                            `)}
                            onClick={() => ref.current.click()}
                        >

                            <LuImagePlus className="w-6 h-6 text-gray-400" />

                        </div>

                        {imageAttachments.map((file, idx) => {
                            return (
                                <div
                                    key={idx}
                                    className="bg-gray-100 p-1 rounded-lg border relative"
                                >

                                    <img
                                        onInvalid={() => { }}
                                        src={URL.createObjectURL(file)}
                                        className={cn("w-auto h-[100px]")}
                                    />

                                    <div
                                        className={cn(`
                                        bg-red-100 absolute right-2 top-2 rounded-full p-1 hover:bg-red-200 
                                        duration-300 transition-all ease-in-out cursor-pointer
                                    `)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onDelete(idx);
                                        }}
                                    >
                                        <MdOutlineClose className="w-5 h-5 text-red-700" />
                                    </div>

                                </div>
                            );
                        })}

                    </div> :
                    <label
                        htmlFor="image-attachments"
                        className={cn(`
                        w-full h-full                    
                        flex flex-col items-center justify-center

                        transition-all duration-200 ease-in-out cursor-pointer  
                        hover:bg-blue-100 hover:text-primary 

                        group 
                    `)}
                    >

                        <IoMdImages className="w-6 h-6 text-gray-400 group-hover:text-primary" />

                        <p className="text-sm text-gray-500 mt-2 group-hover:text-primary">
                            Max file size 10MB; (PNG, JPEG, JPG)
                        </p>

                    </label>
                }


            </div>

        </div>
    );
}