import { useEffect, useMemo, useRef, useState } from "react";
import { useTestimonialStore } from "@store";
import {
    ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel,
    getPaginationRowModel, useReactTable
} from "@tanstack/react-table";
import { cn } from "@utils/cn";
import { Rating } from "@components/rating";
import { CgTranscript } from "react-icons/cg";
import { DateTime } from "luxon";
import { IoMusicalNotes } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { TestimonialSelect } from "./select";
import { Pagination } from "./pagination";
import { QueryKeys, useGetQueryData, useGetQueryStatus } from "@hooks";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useSearchParams } from "react-router-dom";
import { TestimonialStatus } from "./status";
import { TestimonialDetails } from "../details/main.details";


export function TestimonialsTable() {

    const ref = useRef<HTMLDivElement>(null);

    const width = ref.current?.offsetWidth ?? 1000;

    const [searchParams, setSearchParams] = useSearchParams();

    const { showColumns, collapsed } = useTestimonialStore((store) => ({
        collapsed: store.collapsedRow,
        showColumns: store.showColumns
    }));

    const status = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const testimonialsRequest = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState<Api.Testimonials.Type[]>(
        (status.isLoading === false && testimonialsRequest && testimonialsRequest?.testimonials?.length) ?
            [...testimonialsRequest.testimonials] :
            []
    );

    const columns = useMemo<ColumnDef<Api.Testimonials.Type>[]>(() => (
        [
            {
                id: "select",
                size: 30,
                header: ({ table }) => (
                    <div className="w-full h-full flex items-center justify-center cursor-pointer">

                        <TestimonialSelect
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />

                    </div>
                ),
                cell: ({ row }) => (
                    <div className="w-full h-full flex items-center justify-center cursor-pointer">

                        <TestimonialSelect
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />

                    </div>
                ),
                enableResizing: false
            },
            {
                id: "customDetails",
                accessorKey: "customDetails",
                enableResizing: true,
                size: Math.floor(width * 0.30),
                header: () => <p className="text-base font-medium tracking-wide"> Custom </p>,
                cell: col => {

                    const customer = col.row.original.customerDetails as any;

                    if (!customer) return;

                    const name = customer["name"]?.answer ?? null;
                    const email = (customer["email"]?.answer as string)?.substring(0, 24) ?? "";
                    const role = (customer["role"]?.answer as string)?.substring(0, 24) ?? "";

                    const profilePicture = customer["photo"]?.answer ?
                        customer["photo"]?.answer :
                        GenerateProfilePic(name ? name[0] : email[0]);

                    return (
                        <div className="flex items-center justify-start space-x-3 cursor-default">

                            <img
                                src={profilePicture}
                                className="w-16 h-16 rounded-full mx-1 border border-gray-300 bg-gray-300"
                            />

                            <div className="text-left space-y-2">

                                <p className="text-sm font-medium tracking-wide">
                                    {customer["name"]?.answer ?? ""}
                                </p>

                                <p className="text-xs font-medium text-gray-600 tracking-wide">

                                    {role ? role : null}

                                </p>


                                <p className="text-xs font-medium text-gray-600 tracking-wide">

                                    {email ? email : null}

                                </p>


                            </div>

                        </div>
                    );
                }
            },
            {
                id: "testimonial",
                accessorKey: "testimonial",
                enableResizing: true,
                size: Math.floor(width * 0.60),
                header: () => <p className="text-base font-medium tracking-wide"> Testimonial </p>,
                cell: col => {

                    const type = col.row.original.type;

                    const submittedAt = col.row.original.submittedAt;

                    const dateTime = DateTime.fromISO(submittedAt);

                    const relativeTime = dateTime.toRelative();

                    const testimonial = col.getValue() as Api.Testimonials.Type["testimonial"];

                    if (type === "text" && testimonial.text) {
                        return (
                            <div className={cn("space-y-3 relative w-full text-start cursor-default")}>

                                {testimonial.rating ?
                                    <Rating
                                        rated={testimonial.rating ?? 0}
                                        readonly={true}
                                    /> : null
                                }

                                <p className="text-sm font-normal text-gray-800 text-left cursor-default break-words px-1 min-h-[32px]">
                                    {collapsed && testimonial.text?.content.length > 160 ?
                                        testimonial.text.content.slice(0, 160) + "..." :
                                        testimonial.text.content
                                    }
                                </p>

                                <p
                                    data-tooltip-id="testimonial-submitted-at"
                                    className={cn(`
                                        text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                                        border-b border-dashed border-gray-300 inline-block 
                                    `)}
                                >
                                    {relativeTime}
                                </p>

                            </div>
                        );
                    }

                    if (type === "video" && testimonial.video) {

                        const thumbnail = testimonial.video.thumbnail;

                        return (
                            <div className={cn("relative w-full text-start cursor-default")}>

                                <div className="flex items-start justify-between">

                                    <div className="w-[80%] space-y-3 h-[80px]">

                                        {testimonial.rating ?
                                            <Rating
                                                rated={testimonial.rating ?? 0}
                                                readonly={true}
                                            /> : null
                                        }

                                        <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                                            <CgTranscript className="w-6 h-6" />

                                            <p className="text-sm font-normal"> No transcript </p>

                                        </div>

                                    </div>

                                    <div className="w-[20%] h-[80px] ">

                                        <img
                                            src={thumbnail}
                                            className="absolute top-0 right-2 w-auto h-full border rounded-md"
                                        />

                                    </div>

                                </div>

                                <p
                                    data-tooltip-id="testimonial-submitted-at"
                                    className={cn(`
                                        text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                                        border-b border-dashed border-gray-300 inline-block
                                    `)}
                                >
                                    {relativeTime}
                                </p>

                            </div>
                        );
                    }

                    if (type === "audio" && testimonial.audio) {
                        return (
                            <div className={cn("relative w-full text-start cursor-default")}>

                                <div className="flex items-start justify-between">

                                    <div className="w-[80%] space-y-3 h-[80px]">

                                        {testimonial.rating ?
                                            <Rating
                                                rated={testimonial.rating ?? 0}
                                                readonly={true}
                                            /> : null
                                        }

                                        <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                                            <CgTranscript className="w-6 h-6" />

                                            <p className="text-sm font-normal"> No transcript </p>

                                        </div>

                                    </div>

                                    <div className="w-[20%] h-[80px] flex items-center justify-center">

                                        <div className="border flex items-center justify-center h-full w-[64%] rounded-md">

                                            <IoMusicalNotes className="w-10 h-10 text-blue-600" />

                                        </div>

                                    </div>

                                </div>

                                <p
                                    data-tooltip-id="testimonial-submitted-at"
                                    className={cn(`
                                        text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                                        border-b border-dashed border-gray-300 inline-block
                                    `)}
                                >
                                    {relativeTime}
                                </p>

                                <Tooltip id="testimonial-submitted-at">
                                    {new Date(submittedAt).toLocaleDateString("EN", { dateStyle: "medium" })}
                                </Tooltip>

                            </div>
                        );
                    }

                },
            },
            {
                id: "status",
                accessorKey: "status",
                enableResizing: true,
                size: Math.floor(width * 0.10),
                header: () => <p className="text-base font-medium tracking-wide"> Status </p>,
                cell: col => {
                    return (
                        <TestimonialStatus
                            id={col.row.original.id}
                            approved={col.row.original.approved}
                        />
                    );
                }
            }
        ]
    ), [collapsed]);

    useEffect(() => {

        setData(status.isLoading === false && testimonialsRequest && testimonialsRequest?.testimonials ?
            [...testimonialsRequest.testimonials] :
            []
        );

    }, [status.isLoading]);

    useEffect(() => {

        const testimonialsIds: string[] = [];

        Object.keys(rowSelection).forEach(idx => testimonialsIds.push(testimonialsRequest.testimonials[parseInt(idx)].id));

        useTestimonialStore.getState().setSelectedRows(testimonialsIds);

    }, [JSON.stringify(rowSelection)]);

    useEffect(() => {

        if (testimonialsRequest && testimonialsRequest?.testimonials) {

            setData([...testimonialsRequest.testimonials]);

        }

    }, [JSON.stringify(testimonialsRequest?.testimonials ?? {})]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
            columnVisibility: showColumns,
        },
        enableRowSelection: true,
        columnResizeDirection: "ltr",
        columnResizeMode: "onChange",
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        // debugTable: import.meta.env.DEV,
        // debugHeaders: import.meta.env.DEV,
        // debugColumns: import.meta.env.DEV,
    });

    const onShowDetails = (id: string) => {

        searchParams.set("id", id);

        setSearchParams(searchParams);

        useTestimonialStore.getState().setShowDetailsPopup(true);

    };

    return (
        <div
            ref={ref}
            className={cn(`space-y-4 overflow-x-auto rounded-lg min-h-[70vh] max-h-max`)}
        >

            <div className="min-h-[68vh] w-full">

                <table
                    style={{
                        width: "100%",
                    }}
                    className="w-full rounded-md bg-white"
                >

                    <thead className="select-none w-full">

                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>

                                {headerGroup.headers.map(column => (
                                    <th
                                        {...{
                                            key: column.id,
                                            colSpan: column.colSpan,
                                            style: {
                                                width: column.getSize(),
                                            },
                                        }}
                                        className="relative p-4 border fade-in-30"
                                    >

                                        {column.isPlaceholder ?
                                            null :
                                            flexRender(
                                                column.column.columnDef.header,
                                                column.getContext()
                                            )
                                        }

                                        {column.getContext().column.getCanResize() ?
                                            <div
                                                {...{
                                                    onDoubleClick: () => column.column.resetSize(),
                                                    onMouseDown: column.getResizeHandler(),
                                                    onTouchStart: column.getResizeHandler(),
                                                    className: `resizer ${table.options.columnResizeDirection} ${column.column.getIsResizing() ? 'isResizing' : ''}`,
                                                    style: {
                                                        transform: '',
                                                    },
                                                }}
                                                className="cursor-col-resize w-2 h-full absolute top-0 right-0"
                                            /> : null
                                        }

                                    </th>
                                ))}
                            </tr>
                        ))}

                    </thead>

                    <tbody>

                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr
                                    key={row.id}
                                    className="hover:bg-gray-100 transition-all duration-300 ease-in-out"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >

                                    {row.getVisibleCells().map((cell, idx) => (
                                        <td
                                            key={cell.id}
                                            className="border text-center px-4 py-3"
                                            style={{
                                                maxWidth: Math.floor(width * 0.40)
                                            }}
                                            onClick={() => {

                                                const headers = table.getHeaderGroups()[0].headers.flatMap(col => col.id);

                                                const isSelectColumn = idx === 0 && headers[idx] === "select";
                                                const isStatusColumn = idx === 3 && headers[idx] === "status";

                                                if (isSelectColumn === false && isStatusColumn === false) {
                                                    onShowDetails(row.original.id);
                                                }

                                            }}
                                        >
                                            {/* {cell.renderValue("cell")} */}
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}

                                </tr>
                            );
                        })}

                    </tbody>

                </table>

            </div>

            <Pagination
                pageCount={table.getPageCount()}
                pageIdx={table.getState().pagination.pageIndex + 1}
                disableNextButton={table.getCanPreviousPage()}
                onPreviousPage={() => table.previousPage()}
                disablePreviousButton={table.getCanNextPage()}
                onNextPage={() => table.nextPage()}
            />

            <TestimonialDetails />

        </div>
    );
}


