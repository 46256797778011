import { Text } from "@components/text";
import { MdClose } from "react-icons/md";
import { cn } from "@utils/cn";
import { MdOutlineContentCopy } from "react-icons/md";
import toast from "react-hot-toast";
import { MdOutlineLink } from "react-icons/md";
import { Button, SecondaryButton } from "@components/button";
import { MdOutlineCode } from "react-icons/md";
import { Drawer } from "vaul";
import { QueryKeys, useActiveSpace, useGetQueryData } from "@hooks";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCommonStore, useTestimonialStore } from "@store";
import { useEffect, useState } from "react";
import { TestimonialStatus } from "../table/status";
import { Config } from "@config";

export function Header() {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const testimonialId = searchParams.get("id") as string;

    const { formId } = useActiveSpace();

    const testimonialsRequest = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [photoSrc, setPhotoSrc] = useState(GenerateProfilePic("g"));
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [approved, setApproved] = useState(false);

    const testimonial = (testimonialsRequest?.testimonials?.filter((testimonial) => testimonial.id === testimonialId)[0]) ?? null;

    useEffect(() => {

        if (!testimonial) return;

        const photo = testimonial.customerDetails ? (testimonial.customerDetails as any)["photo"]?.answer : null;

        const name = (testimonial.customerDetails as any)["name"]?.answer as string ?? null;
        const email = (testimonial.customerDetails as any)["email"]?.answer as string ?? "";

        setPhotoSrc(photo && photo.length ?
            photo :
            GenerateProfilePic(name ? name : email[0])
        );

        const customer = testimonial.customerDetails as any;

        setName(customer["name"]?.answer as string);

        setEmail(customer["email"]?.answer as string);

        setApproved(testimonial.approved);

    }, [JSON.stringify(testimonial)]);

    const onClose = () => {

        searchParams.delete("id");

        setSearchParams(searchParams);

        useTestimonialStore.getState().setShowDetailsPopup(false);

    };

    const onCopyName = () => {

        window.navigator.clipboard.writeText(name);

        toast.success("Name copied.", { position: "bottom-center", className: "z-[1000]" });

    };

    const onCopyEmail = () => {

        window.navigator.clipboard.writeText(email);

        toast.success("Email copied.", { position: "bottom-center", className: "z-[1000]" });

    };

    return (
        <div className="w-full border-b flex items-center justify-between py-5 space-x-4 px-6">

            <div className="flex items-center space-x-3">

                <img
                    src={photoSrc}
                    className="rounded-full w-[60px] h-[60px] border border-gray-100 bg-gray-500"
                />

                <div className="flex flex-col items-start justify-center text-left space-y-1">

                    {name ?
                        <div className="flex items-center space-x-2">

                            <Text
                                variant="primary"
                                size="lg"
                                className="font-semibold tracking-wide"
                            >
                                {name}
                            </Text>

                            <div
                                className={cn(`
                                    cursor-pointer p-1 bg-gray-200 hover:bg-gray-100 rounded-md
                                    transition-all duration-300 ease-in-out
                                `)}
                                onClick={() => onCopyName()}
                            >

                                <MdOutlineContentCopy className="w-4 h-4 text-gray-600" />

                            </div>

                        </div> : null
                    }

                    {email ?
                        <div className="flex items-center space-x-2">

                            <Text
                                size="sm"
                                className="text-gray-600 font-normal tracking-wide"
                            >
                                {email}
                            </Text>

                            <div
                                className={cn(`
                                cursor-pointer p-1 bg-gray-200 hover:bg-gray-100 rounded-md
                                transition-all duration-300 ease-in-out
                            `)}
                                onClick={() => onCopyEmail()}
                            >

                                <MdOutlineContentCopy className="w-4 h-4 text-gray-600" />

                            </div>

                        </div> : null
                    }


                </div>

            </div>

            <div className="space-x-2 flex">

                <div className="px-4 space-x-3 flex items-center justify-evenly">

                    <SecondaryButton
                        className="text-sm w-auto"
                        onClick={() => navigate(`/testimonials/case-study/${testimonialId}`)}
                    >

                        <p>
                            Generate
                        </p>

                    </SecondaryButton>

                    <SecondaryButton
                        className={cn(`text-sm font-medium tracking-wide w-auto space-x-2`)}
                        onClick={() => {

                            navigator.clipboard.writeText(Config.RenderEngineUrl() + `/testimonial/${formId}/${testimonialId}`);

                            toast.success("Copied to clipboard");
                        }}
                    >

                        <MdOutlineLink className="w-4 h-4" />

                        <p className="mx-2"> Copy </p>

                    </SecondaryButton>

                    <Button
                        className={cn(`font-medium text-sm w-auto tracking-wide`)}
                        onClick={() => {
                            useTestimonialStore.getState().setShowDetailsPopup(false);
                            useCommonStore.getState().setActivePopup("get-embed-code");
                        }}
                    >

                        <MdOutlineCode className="w-5 h-5" />

                        <p className="mx-2"> Embed </p>

                    </Button>

                    <TestimonialStatus
                        id={testimonialId}
                        approved={approved}
                    />

                </div>

                <Drawer.Close>

                    <SecondaryButton
                        className={cn(`p-2 bg-gray-50 hover:bg-gray-100`)}
                        onClick={() => onClose()}
                    >

                        <MdClose className="w-5 h-5" />

                    </SecondaryButton>

                </Drawer.Close>


            </div>

        </div>
    );
}