import { ColorPicker } from "@components/colorPicker";
import { FontFamily } from "@components/fontFamily";
import { UploadPicture } from "@components/upload";
import { useSocialProofStore } from "@store";
import { useState } from "react";


export function WallOfLove_Branding() {

    const [file, setFile] = useState(null);


    return (
        <div className="space-y-4">

            <UploadPicture
                id="wall-of-love-branding"
                file={file}
                title="Logo"
                titleClassName="text-sm font-medium text-gray-700"
                imgClassName="w-[50px] h-[50px]"
                onChange={() => setFile(null)}
            />

            <ColorPicker
                defaultColor={"#fff"}
                label="Background Color"
                onChange={(val) => useSocialProofStore.getState().setWolTemplateBranding({
                    primaryColor: val
                })}
            />

            <FontFamily
                defaultFontFamily=""
                onChange={(font) => useSocialProofStore.getState().setWolTemplateBranding({
                    fontFamily: font
                })}
            />

        </div>
    );
}