import { Button, SecondaryButton } from "@components/button";
import { DatePicker } from "@components/datePicker";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { useTestimonialsApiClient } from "@hooks";
import { useTestimonialStore } from "@store";
import { useClickAway } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { useState } from "react";
import { MdOutlineDateRange, MdKeyboardArrowDown } from "react-icons/md";



export function DateRangeFilter() {

    const range = useTestimonialStore((store) => store.filter.range);

    const { getAll } = useTestimonialsApiClient({});

    const clickOutSideRef = useClickAway<any>(() => setShow(!show));

    const [show, setShow] = useState(false);

    const onCancel = () => {
        useTestimonialStore.getState().setFilter({
            range: {
                from: null,
                to: null
            }
        });
        getAll.refetch();
        setShow(false);
    };

    return (
        <Popover open={show}>

            <PopoverTrigger>

                <SecondaryButton
                    className={cn(`py-2 px-3 bg-gray-200 w-full hover:bg-gray-200/80`)}
                    onClick={() => setShow(!show)}
                >

                    <div className="space-x-2 flex items-center justify-center mr-2">

                        <MdOutlineDateRange className="w-5 h-5" />

                        <p className="text-sm font-medium"> All time </p>

                    </div>

                    <MdKeyboardArrowDown className="w-6 h-6" />

                </SecondaryButton>

            </PopoverTrigger>

            <PopoverContent
                ref={clickOutSideRef}
                side="bottom"
                align="start"
                className="w-full h-full relative p-2 m-0 bg-white mt-2"
            >

                <DatePicker
                    mode="range"
                    numberOfMonths={2}
                    selected={{
                        from: ((range.from as any) ?? new Date().toISOString()),
                        to: ((range.to as any) ?? new Date().toISOString())
                    }}
                    onSelect={(e) => {
                        useTestimonialStore.getState().setFilter({
                            range: {
                                from: e?.from as any,
                                to: e?.to as any
                            }
                        });
                    }}
                />

                <div className="flex items-center justify-end space-x-3 px-4">

                    <SecondaryButton
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        text="Cancel"
                        onClick={() => onCancel()}
                    />

                    <Button
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        onClick={() => getAll.refetch()}
                    >
                        Apply
                    </Button>

                </div>


            </PopoverContent>

        </Popover>
    );
}