import { useParams } from "react-router-dom";
import { Widget1_Settings } from "./w1";
import { CommonSettings } from "./w2,w4,w6,w7/main.common";
import { Widget3_Settings } from "./w3/w3";
import { Widget5_Settings } from "./w5/w5";

export function WidgetSettings() {

    const { id } = useParams();

    return (
        <div className="p-4">

            {id === "w1" ? <Widget1_Settings /> : null}

            {id === "w2" ? <CommonSettings /> : null}

            {id === "w3" ? <Widget3_Settings /> : null}

            {id === "w4" ? <CommonSettings /> : null}

            {id === "w5" ? <Widget5_Settings /> : null}

            {id === "w6" ? <CommonSettings /> : null}

            {id === "w7" ? <CommonSettings /> : null}

        </div>
    );
}