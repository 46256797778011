import { Popup } from "@components/popup";
import { QueryKeys, useActiveSpace, useGetQueryData, useIntegrationsApiClient, } from "@hooks";
import { useCommonStore, } from "@store";
import { useState } from "react";
import { NewIntegration } from "./new";
import { ListExistingPabblyIntegration } from "./list";
import { useQueryClient } from "@tanstack/react-query";

type Props = {
    integrationType: "pabbly" | "webhook",
};

export function WebhookIntegrationPopup({ integrationType }: Props) {

    const queryClient = useQueryClient();

    const activeSpace = useActiveSpace();

    const getAllIntegrationsQuery = useGetQueryData<Api.Integrations.getAll>([QueryKeys.integrations.getAll]);

    const integrations = (getAllIntegrationsQuery?.integrations[integrationType] ?? []);

    const options = integrations.map(item => ({ value: item.id, label: item.name }));

    const { createWebhook } = useIntegrationsApiClient();

    const [createNewIntegration, setCreateNewIntegration] = useState(false);

    const onCreate = async ({ hookUrl, name, integrationId, }: { hookUrl?: string, name?: string, integrationId?: string; }) => {
        try {

            if (integrationId) {

                await createWebhook.mutateAsync({
                    name,
                    type: integrationType,
                    formId: activeSpace.formId
                });

            } else {

                await createWebhook.mutateAsync({
                    hookUrl,
                    name,
                    type: integrationType,
                    formId: activeSpace.formId
                });

            }

            queryClient.invalidateQueries({ queryKey: [QueryKeys.integrations.getAll] });

            useCommonStore.getState().setActivePopup("");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Popup
            width="35%"
            name={integrationType === "pabbly" ? "Pabbly" : "Custom Webhook"}
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <div>

                {integrations.length || createNewIntegration ?
                    <ListExistingPabblyIntegration
                        type={integrationType}
                        options={options}
                        onCreateNew={() => setCreateNewIntegration(true)}
                        onClick={(id) => onCreate({ integrationId: id })}
                    /> :
                    <NewIntegration
                        type={integrationType}
                        onClick={(params) => onCreate(params)}
                    />
                }

            </div>

        </Popup>
    );
}