import Src from '@assets/integrations/Pabbly.png';

export const PabblyConnectIcon: React.FC = () => {
    return (
        <img
            height="60"
            width="60"
            src={Src}
            alt="pabbly connect"
        />
    );
}

