import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormStore } from "@store";
import { EmbedPreview } from "@utils/embedPreview";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type Props = {
    id: string,
    idx: number;
};

const schema = z.object({
    title: z.string().min(1, "field required."),
    message: z.string().min(1, "field required."),
});

const ctaSchema = z.object({
    text: z.string().min(1, "field required."),
    url: z.string().min(1, "field required.")
});

export function RewardPopup({ id, idx }: Props) {

    const segment = useFormStore((state) => state.reward.segments[idx]);

    const [showCta, setShowCta] = useState(segment.popup.cta.text.length ? true : false);

    useEffect(() => EmbedPreview.changeScreen("reward-popup"), []);

    const popupForm = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            title: segment.popup.title,
            message: segment.popup.message,
        }
    });

    const ctaForm = useForm<z.infer<typeof ctaSchema>>({
        resolver: zodResolver(ctaSchema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            text: segment.popup.cta.text,
            url: segment.popup.cta.url,
        }
    });

    const title = popupForm.watch("title");
    const message = popupForm.watch("message");

    const ctaText = ctaForm.watch("text");
    const ctaUrl = ctaForm.watch("url");

    useEffect(() => EmbedPreview.sendActiveRewardSegmentId(id), []);

    useEffect(() => {

        useFormStore.getState().setRewardPopup({
            id: segment.id,
            popup: {
                title,
                message,
                cta: {
                    text: ctaText,
                    url: ctaUrl
                }
            },
        });

    }, [title, message, ctaText, ctaUrl]);

    return (
        <motion.div
            className="space-y-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Input
                {...popupForm.register("title")}
                type="text"
                placeholder="title"
                label="Popup Title"
                required={true}
                error={popupForm.formState.errors.title?.message}
            />

            <Input
                {...popupForm.register("message")}
                type="text"
                placeholder="Enter your message"
                label="Message"
                error={popupForm.formState.errors.message?.message}
            />

            <div className="mt-2">

                <TextToggle
                    isActive={showCta}
                    name="Call To Action"
                    onClick={() => setShowCta(!showCta)}
                />

                {showCta ?
                    <div className="space-y-2 py-2">

                        <Input
                            {...ctaForm.register("text")}
                            type="text"
                            placeholder="Enter your coupon here"
                            label="Button Text"
                            required={true}
                            error={ctaForm.formState.errors.text?.message}
                        />

                        <Input
                            {...ctaForm.register("url")}
                            type="url"
                            placeholder="Redirect Url"
                            label="Redirect Url"
                            required={true}
                            error={ctaForm.formState.errors.url?.message}
                        />

                    </div> : null
                }

            </div>

        </motion.div>
    );
}