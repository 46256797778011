import { Rating } from "@components/rating";
import { useRef } from "react";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useSocialProofStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";


export function Template9() {

    const ref = useRef<null>(null);

    const { name, role, company, rating, text } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text
    }));

    return (
        <div
            ref={ref}
            id="preview-template"
            className="rounded-lg relative h-[1080px] w-[1080px] scale-[0.4] shadow-lg"
            style={{
                background: "#FFA943"
            }}
        >

            <div className="h-full max-h-full flex flex-col items-start justify-evenly p-12 space-y-6">

                {rating ?
                    <Rating
                        rated={rating}
                        iconProps={{
                            stroke: "black",
                            strokeWidth: 10
                        }}
                        className="w-[80px] h-[80px] mx-2 text-white"
                    /> : null
                }

                <p
                    className="text-black text-4xl leading-normal font-medium tracking-wide break-words p-4"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                />

                <div className="flex items-center justify-start space-x-6 w-full h-auto">

                    <img
                        src={GenerateProfilePic("A")}
                        className="w-[7rem] h-[7rem] rounded-full border border-gray-400 shadow-md"
                    />

                    <div className="space-y-4">

                        <p className="text-4xl font-semibold tracking-wider text-black">
                            {name}
                        </p>

                        {role && company ?
                            <p className="text-3xl font-normal text-gray-100">

                                {role && company ? <> {role} of {company} </> : null}

                                {role ? role : null}

                                {company ? company : null}

                            </p> : null
                        }

                    </div>

                </div>


            </div>

        </div >
    );

}
