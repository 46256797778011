import { QueryKeys, useGetQueryData } from "@hooks";
import { useSearchParams } from "react-router-dom";
import { BillingUtils } from "@utils/billingUtils";
import { cn } from "@utils/cn";
import { MdOutlineLink } from "react-icons/md";


export function Summary() {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const plan = billing?.plan ?? "free";

    return (
        <div className="flex w-full h-full px-2 py-5 fade-in">

            <div className="w-[70%] h-auto">

                <div className="flex space-x-4 justify-evenly">

                    <CurrentPlan />

                    {plan !== "free" && billing.cards ? <CardInfo /> : null}

                </div>

                {plan !== "free" ? <Invoices /> : null}

            </div>

            {plan !== "free" ? <UpcomingInvoice /> : null}

        </div>
    );
}

const CurrentPlan: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const [searchParams, setSearchParams] = useSearchParams();

    const onChangePlan = () => {
        searchParams.set("tab", "plans");
        setSearchParams(searchParams);
    };

    return (
        <div className="relative w-[45%] px-6 py-5 rounded-2xl bg-primary space-y-3 shadow-lg">

            <div className="absolute top-7 right-7">
                {/* <StartIcon /> */}
            </div>

            <div className="text-sm font-medium text-white">
                Your current subscription plan.
            </div>


            <h1 className="text-4xl font-bold text-white">

                <span className="text-sm"> $ </span>

                {billing.billingInterval === "monthly" ?
                    BillingUtils.get(billing.plan).monthlyPrice.toFixed(2) :
                    BillingUtils.get(billing.plan).yearlyPrice.toFixed(2)
                }

                <span className="text-sm">
                    / {billing.billingInterval === "monthly" ? "month" : "year"}
                </span>

            </h1>

            <div className="font-medium tracking-wide text-white capitalize">
                {billing.plan}
            </div>

            {BillingUtils.isBundlePlan(billing.plan) === false ?
                <div
                    className={cn(`
                        bg-white text-primary text-sm font-normal px-4 py-2 rounded-lg inline-block 
                        hover:bg-gray-200 cursor-pointer
                    `)}
                    onClick={() => onChangePlan()}
                >
                    Change Plan
                </div> : null
            }

        </div>
    );
};

const CardInfo: React.FC = () => {

    const user = useGetQueryData<Api.Auth.Login>([QueryKeys.user.getProfile]);

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    const name = user?.user?.name ?? "";

    const card = billing.cards;

    return (
        <div className={cn(`border rounded-xl w-[45%] py-4 px-6 relative shadow-lg`, {
            "blur-sm": billing.plan === "free"
        })}>

            <p className="mt-3 text-sm font-medium"> Card Information </p>

            <div className="absolute top-10 right-10">

                {card.brand === "mastercard" ? <></> : null}

                {card.brand === "Visa" ? <></> : null}

                {card.brand === "amex" ? <></> : null}

            </div>

            <div className="h-[85%] flex flex-col justify-between">

                <div className="mt-10 text-xl">
                    ****  ****  ****  {card.last4}
                </div>


                <div className="flex items-center justify-between mb-2">

                    <div className="text-sm font-normal capitalize">
                        {name}
                    </div>

                    <div className="flex items-center justify-center space-x-2">

                        <p className="text-xxs"> VALID UPTO </p>

                        <p className="text-sm">
                            {`${card.expMonth} / ${card.expYear}`}
                        </p>

                    </div>

                </div>

            </div>

        </div>
    );
};

const Invoices: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    return (
        <div className="mt-3 px-5 h-[40%] overflow-y-scroll no-scrollbar">

            <p className="my-4 ml-4 font-medium">
                Payment history
            </p>

            <table className={cn(`w-full bg-gray-100 rounded-lg`, {
                "blur-sm": billing.plan === "free"
            })}>

                <thead>

                    <tr className="flex flex-row items-start text-center bg-gray-200 rounded-t-lg">

                        <th className="w-1/3 px-5 py-2 font-normal rounded-l-lg">
                            Order Id
                        </th>

                        <th className="w-1/3 px-5 py-2 font-normal">
                            Date
                        </th>

                        <th className="w-1/3 px-5 py-2 font-normal rounded-r-lg">
                            Invoice
                        </th>

                    </tr>
                </thead>

                <tbody className="overflow-y-scroll text-center h-[90%]">

                    {billing.invoices.map(invoice => {
                        return (
                            <tr className="flex items-center justify-center py-3 hover:bg-white">

                                <p className="w-1/3 px-5 text-sm font-normal text-font_primary">
                                    {invoice.id}
                                </p>

                                <p className="w-1/3 px-5 text-sm font-normal text-font_primary">
                                    {invoice.createdAt}
                                </p>

                                <a
                                    className={cn(`
                                        w-[30%] px-5 text-primary font-normal text-sm 
                                        flex items-center space-x-2 group cursor-pointer
                                    `)}
                                    href={invoice.url}
                                    target="blank"
                                >

                                    <span>View and download</span>

                                    <MdOutlineLink className="w-5 h-4" />

                                </a>

                            </tr>
                        );
                    })}

                </tbody>

            </table>

        </div>
    );
};

const UpcomingInvoice: React.FC = () => {

    const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    return (
        <div className={cn(`w-[30%] h-[80%] rounded-xl py-2 border shadow-lg`, {
            "blur-sm": billing.plan === "free"
        })}>

            <div className="px-5 py-3 border-b">

                <p className="text-lg font-medium">
                    Upcoming invoice
                </p>

                <p className="mt-1 text-xs font-normal text-font_secondary">
                    {billing.upcomingInvoice.nextPaymentOn}
                </p>

            </div>

            <div className="px-5 py-3 border-b">

                <p className="py-2 text-xs"> Billed items </p>

                {billing.upcomingInvoice.items.map(item => (
                    <div className="px-4 my-2">

                        <p className="text-sm font-normal">
                            {item.description}
                        </p>

                    </div>
                ))}

            </div>

            <div className="py-3 my-4 px-7">

                <div className="flex justify-between">

                    <p className="text-sm font-normal">
                        Sub total
                    </p>

                    <p className="text-sm font-medium">
                        {billing.upcomingInvoice.subtotal}
                    </p>

                </div>

                <div className="flex justify-between my-3">

                    <p className="text-sm font-normal">
                        Discount
                    </p>

                    <p className="text-sm font-medium">
                        {billing.upcomingInvoice.discount}
                    </p>

                </div>

                <div className="flex justify-between pt-3 border-t">

                    <p className="text-sm font-normal">
                        Total
                    </p>

                    <p className="text-sm font-medium">
                        {billing.upcomingInvoice.total}
                    </p>
                </div>

            </div>

        </div>
    );
};