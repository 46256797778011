import { Select } from "@components/select";
import { Text } from "@components/text";
import { useFormStore } from "@store";

const options = [
    { label: "Video", value: "video" },
    { label: "Text", value: "text" },
    { label: "Audio", value: "audio" }
];

export function Condition() {

    const onlyReward = useFormStore(state => state.reward.onlyReward);

    const selectedOptions = options.filter(option => onlyReward.includes(option.value as any));

    const onChange = (options: { label: string, value: string; }[]) => {

        const values = options.map(option => option.value);

        useFormStore.getState().setRewardOnly(values as any[]);

    };

    return (
        <div className="flex items-center justify-between">

            <Text
                variant="label"
                text={"Reward Only"}
            />

            <Select
                isMulti={true}
                className="w-[70%] text-sm mt-0"
                defaultValue={selectedOptions}
                options={options}
                onChange={(e: any) => onChange(e)}
            />

        </div>
    );
}