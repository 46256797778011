
type Props = {
    height?: string,
    width?: string;
};

export function ContentAiLogo({ height, width }: Props) {
    return (
        <svg width={height ?? "400"} height={width ?? "400"} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M138.17 106.3C137.636 106.673 136.98 106.828 136.335 106.734C135.691 106.64 135.107 106.304 134.701 105.794C134.296 105.284 134.1 104.639 134.154 103.99C134.208 103.341 134.507 102.736 134.99 102.3L233.59 9.10003C233.764 8.93676 233.887 8.72806 233.948 8.49774C234.008 8.26741 234.003 8.02476 233.932 7.79745C233.861 7.57014 233.727 7.36732 233.547 7.21218C233.366 7.05704 233.146 6.95582 232.91 6.92004C223.81 5.63706 214.631 4.99547 205.44 5.00002H194.56C87.1102 5.00002 0.000197222 92.1 0.000197222 199.56C-0.0688545 247.994 17.9968 294.699 50.6402 330.48C50.7646 330.616 50.9169 330.723 51.0867 330.795C51.2565 330.867 51.4398 330.901 51.624 330.895C51.8082 330.89 51.989 330.844 52.154 330.762C52.319 330.68 52.4644 330.563 52.5802 330.42L142.26 218.29C142.419 218.081 142.509 217.827 142.518 217.564C142.527 217.301 142.454 217.042 142.309 216.822C142.164 216.603 141.954 216.434 141.709 216.338C141.464 216.243 141.195 216.226 140.94 216.29L49.9902 240.15C49.7104 240.227 49.4127 240.207 49.1459 240.093C48.8791 239.978 48.6591 239.777 48.5219 239.521C48.3847 239.265 48.3386 238.971 48.3909 238.685C48.4433 238.4 48.5911 238.14 48.8102 237.95L288.47 25.51C288.628 25.3707 288.748 25.1944 288.821 24.9971C288.893 24.7997 288.916 24.5874 288.886 24.3792C288.856 24.171 288.775 23.9735 288.65 23.8043C288.525 23.6352 288.36 23.4997 288.17 23.41C283.323 21.13 278.37 19.0467 273.31 17.16C273.12 17.0913 272.916 17.0673 272.715 17.0898C272.514 17.1124 272.321 17.1809 272.15 17.29L138.17 106.3Z" fill="#2563EB" />
            <path d="M165.84 389.93C165.667 390.093 165.543 390.302 165.483 390.532C165.422 390.763 165.428 391.005 165.499 391.233C165.57 391.46 165.703 391.663 165.884 391.818C166.064 391.973 166.285 392.074 166.52 392.11C175.794 393.443 185.151 394.112 194.52 394.11H205.4C312.85 394.11 399.96 307 399.96 199.55C400.019 150.947 381.816 104.095 348.96 68.28C348.836 68.1449 348.684 68.0383 348.515 67.9675C348.345 67.8968 348.163 67.8636 347.979 67.8702C347.796 67.8769 347.616 67.9232 347.452 68.006C347.288 68.0889 347.144 68.2062 347.03 68.35L257.33 180.49C257.176 180.701 257.09 180.955 257.084 181.217C257.079 181.478 257.153 181.736 257.298 181.954C257.443 182.172 257.651 182.34 257.895 182.436C258.138 182.532 258.406 182.551 258.66 182.49L349.69 158.64C349.968 158.567 350.262 158.59 350.525 158.705C350.788 158.819 351.005 159.019 351.141 159.271C351.277 159.524 351.324 159.815 351.275 160.097C351.226 160.38 351.084 160.638 350.87 160.83L111.09 373.39C110.932 373.529 110.811 373.704 110.738 373.901C110.665 374.098 110.641 374.31 110.67 374.518C110.699 374.726 110.779 374.924 110.903 375.094C111.027 375.263 111.191 375.399 111.38 375.49C116.214 377.777 121.147 379.867 126.18 381.76C126.369 381.829 126.571 381.853 126.77 381.83C126.97 381.807 127.162 381.739 127.33 381.63L261.51 292.5C262.044 292.127 262.7 291.972 263.345 292.066C263.989 292.16 264.574 292.496 264.979 293.006C265.384 293.516 265.58 294.161 265.527 294.81C265.473 295.459 265.174 296.064 264.69 296.5L165.84 389.93Z" fill="#2563EB" />
        </svg>
    );
};