import { Button } from "@components/button";
import { CustomerDetails } from "./customerDetails/main.customer";
import { Header } from "./header";
import { TestimonialCard } from "./testimonial";
import { cn } from "@utils/cn";
import { SocialProof } from "./socialProof/main.socialProf";
import { MdDeleteOutline } from "react-icons/md";
import { Drawer } from "vaul";
import { useTestimonialStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { QueryKeys, useActiveSpace, useClickOutside, useGetQueryData, useTestimonialsApiClient } from "@hooks";


export function TestimonialDetails() {

    const clickOutSideRef = useClickOutside(() => useTestimonialStore.getState().setShowDetailsPopup(false));

    const [searchParams, setSearchParams] = useSearchParams();

    const { formId } = useActiveSpace();

    const showDetailsPopup = useTestimonialStore((store) => store.showDetailsPopup);

    const testimonialId = searchParams.get("id") as string;

    const queryClient = useQueryClient();

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const { deleteQuery } = useTestimonialsApiClient({});

    const isPending = deleteQuery.isPending;

    const onDelete = async () => {

        await deleteQuery.mutateAsync({
            formId,
            ids: [testimonialId]
        });

        if (deleteQuery.isError) {
            toast.error(deleteQuery.error.message);
            return;
        }

        const updatedTestimonials = testimonialsReq;

        updatedTestimonials.testimonials = updatedTestimonials.testimonials.filter(item => item.id !== testimonialId ? item : null);

        queryClient.setQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll], oldData => ({
            ...oldData,
            testimonials: oldData?.testimonials ? [...oldData.testimonials.filter(item => item.id !== testimonialId ? item : null)] : []
        } as Api.Testimonials.getAll));

        useTestimonialStore.getState().setShowDetailsPopup(false);

        searchParams.delete("id");

        setSearchParams(searchParams);

    };

    return (
        <Drawer.Root
            open={showDetailsPopup}
            direction="right"
            dismissible={false}
            shouldScaleBackground
        >

            <Drawer.Portal>

                <Drawer.Overlay
                    draggable={false}
                    className="fixed inset-0 bg-black/40"
                />

                <Drawer.Content
                    draggable={false}
                    className={cn(`
                        bg-white flex flex-col h-full w-[80%] rounded-l-lg
                        fixed right-0 bottom-0 z-[100] outline-none
                    `)}
                >

                    <div
                        ref={clickOutSideRef}
                        className="h-full w-full relative overflow-hidden max-h-full"
                    >

                        <Header />

                        <div className={cn(`
                            h-full w-full space-y-4 bg-gray-50/90
                            flex items-start justify-center space-x-4 
                        `)}>

                            <div className="w-[60%] h-full space-y-4 overflow-y-scroll p-4 border-r">

                                <TestimonialCard />

                                <CustomerDetails />

                                <Button
                                    loading={isPending}
                                    disabled={isPending}
                                    variant="danger"
                                    className="text-sm font-normal w-auto px-3"
                                    onClick={() => onDelete()}
                                >

                                    <MdDeleteOutline className="w-4 h-4" />

                                    <p className="ml-0.5"> Delete </p>

                                </Button>

                            </div>

                            <div className="w-[40%] h-full pr-4">

                                <SocialProof />

                            </div>

                        </div>


                    </div>

                </Drawer.Content>

            </Drawer.Portal>

        </Drawer.Root>
    );
}