import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useFormStore } from "@store";


export function TitleAndMessage() {

    const { title, message } = useFormStore(state => ({
        title: state.thankYou.title,
        message: state.thankYou.message
    }));

    return (
        <div className="space-y-2">

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Title"
                />

                <QuillInput
                    valueAsHTML={title}
                    placeHolder="Enter your title"
                    recall={true}
                    onChange={(e) => useFormStore.getState().setThankyouTitle(e.html)}
                />

            </div>

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Message"
                />

                <QuillInput
                    valueAsHTML={message}
                    placeHolder="Enter your message"
                    recall={true}
                    onChange={(e) => useFormStore.getState().setThankyouMessage(e.html)}
                />

            </div>

        </div >
    );
}