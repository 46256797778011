import { MdAddCircle } from "react-icons/md";
import { Text } from "@components/text";
import { useCommonStore } from "@store";
import { Button } from "@components/button";
import { MemberPopup } from "./member.popup";
import { useTeamsApiClient } from "@hooks";
import { useEffect } from "react";
import { MemberCard } from "./member.card";
import { EmptyIcon } from "@components/icons";


export function TeamsPage() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const { getAll } = useTeamsApiClient({});

    const getAllMembersQuery = (getAll.data as Api.Teams.GetAllMembers) ?? [];

    useEffect(() => { if (!getAll.data) getAll.refetch(); }, []);

    return (
        <div className="px-6 py-6 bg-gray-50 h-[90%] max-h-[90%]">

            <div className="flex items-center justify-between px-3 border-b pb-4">

                <Text
                    variant="primary"
                    className="text-xl font-semibold tracking-wide"
                >
                    Teams
                </Text>

                <Button
                    className="w-auto text-sm font-medium tracking-wide"
                    onClick={() => useCommonStore.getState().setActivePopup("add-member")}
                >

                    <MdAddCircle className="mr-2 w-4 h-4" />

                    <p>
                        Invite Team Member
                    </p>

                </Button>

            </div>

            {getAllMembersQuery.members && getAllMembersQuery.members.length ?
                getAllMembersQuery.members.map((member, idx) => (<MemberCard key={idx} {...member} />)) :
                <div className="w-full h-full flex items-center justify-center">

                    <EmptyIcon />

                </div>
            }

            {activePopup === "add-member" ? <MemberPopup type="add" /> : null}

        </div>
    );
}