import { Input } from "@components/input";
import { useCommonStore, useUserStore } from "@store";
import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { ChangeEmailPopup } from "./changeEmail.popup";
import { SecondaryButton } from "@components/button";
import { ChangePasswordPopup } from "./changePassword.popup";


const PhoneInput: React.FC<PhoneInputProps> = (ReactPhoneInput as any)?.default || ReactPhoneInput;


export function ProfileDetails() {

    const activePopup = useCommonStore((store) => store.activePopup);

    const { name, email, phoneNumber } = useUserStore((store) => ({
        name: store.name,
        email: store.email,
        phoneNumber: store.phoneNumber
    }));

    const [firstName, lastName] = name.split(" ");


    return (
        <div className="w-full h-full space-y-4">

            <div className="w-full h-full border border-gray-200 bg-gray-50 rounded-md px-3 py-2">

                <div className="flex">

                    <div className="w-[50%] p-5 space-y-4">

                        <Input
                            label="First name"
                            placeholder="Ex: Gavin"
                            value={firstName}
                            // error={error.firstName}
                            required={true}
                            type="text"
                        />

                        <Input
                            label="Last name"
                            placeholder="Ex: Smith"
                            value={lastName}
                            // error={error.lastName}
                            type="text"
                        />


                    </div>

                    <div className="w-[50%] p-5 space-y-4">

                        <Input
                            label="Email"
                            value={email}
                            type="email"
                            readOnly={true}
                            className="cursor-pointer"
                            onClick={() => useCommonStore.getState().setActivePopup("change-email")}
                        />

                        <div className="mb-4 w-full">

                            <label className="block mb-2 text-sm font-normal text-gray-600">
                                Phone number
                            </label>

                            <PhoneInput
                                inputStyle={{
                                    width: "100%",
                                }}
                                inputClass="text-gray-600 font-normal"
                                country="us"
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={(val) => useUserStore.getState().setPhoneNumber(val)}
                            />

                        </div>

                    </div>

                </div>

                {activePopup === "change-email" ? <ChangeEmailPopup /> : null}


            </div>

            <div className="w-full h-full border border-gray-200 bg-gray-50 rounded-md p-5">

                <p className="text-sm font-medium">
                    Login Details <span className="text-gray-600 text-xs tracking-wide"> ({email}) </span>
                </p>

                <div className="mt-4 flex items-center space-x-4">

                    <SecondaryButton
                        className="w-auto text-sm font-medium tracking-wide bg-white"
                        onClick={() => useCommonStore.getState().setActivePopup("change-password")}
                    >
                        Change Password

                    </SecondaryButton>

                </div>

            </div>

            {activePopup === "change-password" ? <ChangePasswordPopup /> : null}

        </div>
    );
}