import { cn } from "@utils/cn";
import { HTMLMotionProps, motion } from "framer-motion";
import { ReactNode } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

interface HeadProps extends HTMLMotionProps<"div"> {
    icon?: ReactNode,
    title: string,
    show: boolean,
    onClick?: () => void;
}

export const Head: React.FC<HeadProps> = ({ icon, title, children, show, className, ...props }) => {
    return (
        <motion.div
            {...props}
            initial={false}
            className={cn(`py-4 px-4 flex items-center justify-between`, className)}
        >

            <div className="flex items-center  space-x-3">

                {icon}

                <p className="text-lg font-medium"> {title} </p>

            </div>

            <MdOutlineKeyboardArrowDown
                className={cn("w-6 h-6 transition-all duration-300 ease-in-out mr-2", {
                    "rotate-180": show,
                    "rotate-0": !show
                })}
            />

        </motion.div>
    );
};