import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore } from "@store";


export function WallOfLove_Navigation() {

    const { hide, homeLink } = useSocialProofStore((store) => ({
        hide: store.wallOfLoveTemplate.navigation.hideNavBar,
        homeLink: store.wallOfLoveTemplate.navigation.homeLink
    }));

    return (
        <div className="my-4">

            <TextToggle
                isActive={hide}
                name="Hide Navigation"
                onClick={() => useSocialProofStore.getState().setWolTemplateNavigation({
                    hideNavBar: !hide,
                })}
            />

            {hide === false ?
                <div className="space-y-2 my-2">

                    <Input
                        label="Title"
                        placeholder="Title"
                        value={homeLink ?? ""}
                        onChange={(e) => useSocialProofStore.getState().setWolTemplateNavigation({
                            homeLink: e.target.value,
                        })}
                    />

                </div> : null
            }


        </div>
    );
}