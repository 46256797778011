import { useSocialProofStore } from "@store";
import { SelectTestimonials } from "../../selectTestimonials/main.select";
import { VideoPreviewTemplates } from "./templates";

export function VideoTemplatePreview() {

    const activeTab = useSocialProofStore((store) => store.activeTab);

    return (
        <div className="h-full w-full max-h-full overflow-y-scroll">

            {activeTab === "selectTestimonials" ? <SelectTestimonials /> : null}

            {activeTab === "template" ? <VideoPreviewTemplates /> : null}

        </div>
    );
}