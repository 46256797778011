import { cn } from "@utils/cn";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useRef } from "react";

type Props = {
    profileUrl: string;
    name: string,
    secondaryName: string;
};

export function TestimonialProfile({ profileUrl, secondaryName, name, }: Props) {

    const imgRef = useRef<any>(null);

    return (
        <div className="flex items-center justify-between space-x-1">

            <img
                ref={imgRef}
                src={profileUrl ?? GenerateProfilePic(name)}
                className={cn(`
                    flex-shrink-0 object-cover object-center w-12 h-12 
                    border border-gray-300 rounded-full cursor-pointer
                `, {
                    // "ml-2": rtl
                })}
                onInvalid={() => {

                    if (imgRef.current) {
                        imgRef.current.src = GenerateProfilePic(name[0]);
                    }

                }}
            />

            <div className="flex flex-col items-start justify-start">

                <p className={cn("text-base font-medium tracking-wide", {
                    // "mr-2": rtl
                })}>
                    {name}
                </p>

                <p className={cn("text-sm font-normal tracking-wide")}>
                    {secondaryName}
                </p>

            </div>

        </div>
    );
}