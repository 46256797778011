import { Fragment, useEffect, useState } from 'react';
import { ShowCaseTestimonial } from './showcase';
import { useMutationState } from '@tanstack/react-query';
import { QueryKeys } from '@hooks';
import { Loading } from '@components/loading';
import { EmptyIcon } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import { CaseStudyPublishedPopup } from '../published.popup';

type Props = {
    testimonial: Api.Testimonials.Type;
};

export function PreviewCaseStudy({ testimonial }: Props) {

    const navigate = useNavigate();

    const queryState = useMutationState({
        filters: {
            mutationKey: [QueryKeys.testimonials.caseStudy.generate]
        },
    });

    const isLoading = queryState.length && queryState[0]?.status === "pending" ? true : false;

    const [caseStudy, setCaseStudy] = useState<NonNullable<Api.Testimonials.Type["caseStudy"]>>({
        title: "",
        published: false,
        highlights: [],
        introduction: "",
        problem: { title: "", content: "" },
        conclusion: { title: "", content: "" },
        solution: { title: "", content: "" },
        result: { title: "", content: "" }
    });

    useEffect(() => {

        if (!testimonial) {
            navigate("/testimonials");
            return;
        }

        if (testimonial?.caseStudy) {
            setCaseStudy({ ...testimonial.caseStudy });
            return;
        }

        const generatedCaseStudy = (queryState[0]?.data as Api.Testimonials.generateCaseStudy) ?? null;

        if (queryState.length && generatedCaseStudy !== null) {

            setCaseStudy({ ...generatedCaseStudy, published: false });

        }

    }, [isLoading]);

    const coreSections = Object.keys(caseStudy).filter(item => item !== "title" && item !== "highlights");

    return (
        <div className="border w-[64%] h-full max-h-full overflow-y-scroll bg-white rounded-lg shadow-sm px-6 py-8">

            {isLoading ? <Loading text="generating your case study..." /> :
                <Fragment>

                    {testimonial.caseStudy === null ?
                        <div className='flex flex-col items-center justify-center h-full'>

                            <EmptyIcon />

                            <p className="text-sm font-medium tracking-wide text-gray-500 mt-2">
                                Your case study looks empty, Let's generate one now.
                            </p>

                        </div> :
                        <Fragment>

                            <h1 className="text-2xl font-bold text-center tracking-wide px-6 cursor-default">
                                {caseStudy.title}
                            </h1> <br /> <br />

                            {caseStudy.highlights.length ?
                                <div>

                                    <h2 className="text-lg font-bold tracking-wide cursor-default">
                                        Highlight
                                    </h2>

                                    <ul className="list-disc px-7 mt-2">
                                        {caseStudy.highlights.map((item, idx) => (
                                            <li
                                                key={idx}
                                                className="text-base font-normal tracking-wide cursor-default"
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul> <br />

                                </div> : null
                            }

                            {testimonial?.caseStudy ?
                                <Fragment>

                                    <ShowCaseTestimonial testimonial={testimonial} />

                                </Fragment> : null
                            }

                            {coreSections.map((item: any, idx) => (
                                <Fragment>

                                    <div key={idx}>

                                        <h2 className="text-xl font-bold tracking-wide cursor-default">
                                            {(caseStudy as any)[item].title}
                                        </h2>

                                        <p className="text-base font-normal mt-2 text-left tracking-wide cursor-default">
                                            {(caseStudy as any)[item].content}
                                        </p>

                                    </div>

                                    {idx !== coreSections.length ? <br /> : null}

                                </Fragment>
                            ))}

                        </Fragment>
                    }


                </Fragment>
            }

            <CaseStudyPublishedPopup />

        </div>
    );
}