import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useInvitesStore } from "@store";


export function Message() {

    const { message, subject } = useInvitesStore((store) => ({
        subject: store.template.subject,
        message: store.template.message
    }));

    return (
        <div className="space-y-2">

            <div>

                <Text
                    variant="label"
                    text="Subject"
                />

                <QuillInput
                    valueAsHTML={subject}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "1.5rem",

                    }}
                    placeHolder="Send a Testimonial"
                    recall={true}
                    onChange={(e) => useInvitesStore.getState().setSubject(e.html)}
                />

            </div>

            <div>

                <Text
                    variant="label"
                    text="Message"
                />

                <QuillInput
                    valueAsHTML={message}
                    style={{
                        fontSize: "1rem",
                        lineHeight: "1.5rem",
                    }}
                    placeHolder="Write a message"
                    recall={true}
                    onChange={(e) => useInvitesStore.getState().setMessage(e.html)}
                />

            </div>

        </div>
    );
}