import { Text } from "@components/text";
import { useFormStore } from "@store";
import { MdOutlineAdd } from "react-icons/md";
import { v4 as uuid } from "uuid";
import { Segment } from "./segment/main.segment";
import { Button } from "@components/button";

export function Segments() {

    const segments = useFormStore((state) => state.reward.segments);

    const onClick = () => {

        useFormStore.getState().addRewardSegment(uuid());

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "currentScreen",
                payload: { screen: "reward-preview" }
            } as Messages.Types, "*");

        }

    };

    return (
        <div className="space-y-2">

            <div className="flex items-center justify-between">

                <Text text={"Segments"} />

                <div
                    className="bg-gray-200 rounded-md p-2 inline-block hover:bg-gray-300"
                    onClick={() => onClick()}
                >

                    <MdOutlineAdd className="w-5 h-5" />

                </div>

            </div>

            {segments.map((segment, idx) => <Segment key={idx} id={segment.id} idx={idx} />)}

            <Button
                className="text-sm font-medium"
                onClick={() => onClick()}
            >

                Add Segment

            </Button>

        </div>
    );
}
