import { cn } from "@utils/cn";
import SelectComponent, { Props } from "react-select";

export function Select({ options, className, ...props }: Props) {
    return (
        <SelectComponent
            placeholder="Select an option"
            isSearchable={false}
            className={cn(`
                bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  
                block w-full outline-none cursor-pointer mt-4
                focus:outline-none hover:ring-1 hover:ring-blue-400 hover:ring-offset-1
            `, className)}
            options={options}
            styles={{
                option: (style) => ({
                    ...style,
                    cursor: "pointer",
                    borderRadius: "8px",
                }),
                menuList: (style) => ({
                    ...style,
                    maxHeight: "150px",
                    borderRadius: "10px",
                    padding: "4px"
                }),
                control: (style) => ({
                    ...style,
                    // borderWidth: "0.4px"
                    border: '0px',
                    borderRadius: '8px',
                    outline: "none"
                })
            }}
            {...props}
        />
    );
}
