import { CustomDomainCard } from "./customDomain";
import { FormLinkCard } from "./link";
import { QrCodeCard } from "./qr";
import { SocialCard } from "./social/social.main";
import { WallOfLoveCard } from "./wallOfLove";
import { motion } from "framer-motion";

export function ShareSection() {
    return (
        <motion.div
            className="flex items-center justify-center h-full w-full"
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >

            <div className="w-[60%] space-y-4 pl-2 pr-3 border-r h-full">

                <FormLinkCard />

                <WallOfLoveCard />

                <CustomDomainCard />

            </div>

            <div className="w-[40%] space-y-4 pr-2 pl-3 h-full">

                <SocialCard />

                <QrCodeCard />

            </div>

        </motion.div>
    );
}