import { Button, SecondaryButton } from "@components/button";
import { cn } from "@utils/cn";
import { forwardRef, useState } from "react";
import { useCommonStore, useFormStore } from "@store";
import { FileUploadingGIF } from "../uploadingGif/main.uploading";
import { useActiveSpace, useFormApiClient } from "@hooks";

type Props = {
    type: "welcome" | "thankyou";
    blobChunks: React.MutableRefObject<BlobPart[]>,
    onPlay: (play: boolean) => void,
    onRestart: () => void,
};

export const RecordedVideo = forwardRef<HTMLVideoElement, Props>(({ type, blobChunks, onRestart, onPlay }, ref) => {

    const activeSpace = useActiveSpace();

    const [play, setPlay] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const rawBlob = new Blob(blobChunks.current, { type: "video/webm" });

    const { uploadVideo } = useFormApiClient({});

    const handlePlay = () => {
        setPlay(!play);
        onPlay(!play);
    };

    const onSubmit = async () => {
        try {

            setUploading(true);

            const data = await uploadVideo(
                {
                    fid: useFormStore.getState().id,
                    file: rawBlob,
                    sid: activeSpace.id,
                    type
                },
                (val) => setProgress(val)
            );

            if (type === "welcome") {
                useFormStore.getState().setWelcomeIntroVideo({
                    url: data.url,
                    autoPlay: true
                });
            }

            if (type === "thankyou") {
                useFormStore.getState().setThankyouVideo({
                    url: data.url,
                    autoPlay: true
                });
            }

            useCommonStore.getState().setActivePopup("");

        } catch (err) {
            console.log(err);
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="relative space-y-3 my-2">

            <div className="group">

                <div className={cn(`    
                    rounded-xl w-full h-[300px] border-2 border-gray-800 
                    flex flex-col items-center justify-center 
                `)}>

                    <FileUploadingGIF
                        show={uploading}
                        progress={progress}
                    />

                    <video
                        id="testimonial-preview"
                        autoPlay
                        controls={true}
                        ref={ref}
                        className={cn(`w-full h-full object-cover rounded-xl`, {
                            "hidden": uploading,
                            "block": uploading === false
                        })}
                        onClick={() => handlePlay()}
                        onEnded={() => setPlay(false)}
                    />

                </div>

            </div>

            <div className="flex items-center space-x-3">

                <SecondaryButton
                    disabled={uploading}
                    onClick={() => onRestart()}
                    text="Restart"
                    className="text-sm font-medium tracking-wide space-x-2"
                />

                <Button
                    disabled={uploading}
                    loading={uploading}
                    text="Upload"
                    onClick={() => onSubmit()}
                    className="text-sm font-medium tracking-wide"
                />


            </div>



        </div>
    );

});;