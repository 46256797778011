import { FontFamily } from "@components/fontFamily";
import { Logo } from "./logo";
import { useFormStore } from "@store";
import { PrimaryColorAndBackgroundColor } from "./primaryAndBackground";
import { FontAndCardColor } from "./fontAndCard";
import { GradientBackground } from "./gradient";
import { EnableRTL } from "./rtl";
import { ButtonProps } from "./button";

export function Design() {

    const fontFamily = useFormStore((store) => store.design.fontFamily);

    return (
        <div className="space-y-4">

            <Logo />

            <FontFamily
                defaultFontFamily={fontFamily}
                onChange={val => useFormStore.getState().setFontFamily(val)}
            />

            <FontAndCardColor />

            <PrimaryColorAndBackgroundColor />

            <ButtonProps />

            <GradientBackground />

            <EnableRTL />

        </div>
    );
}