import { Rating } from "@components/rating";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { useRef } from "react";
import Background from "./t1.png";
import { PurifyHTML } from "@utils/purifyHTML";
import { useSocialProofStore } from "@store";


export function Template1() {

    const ref = useRef<null>(null);

    const { name, role, company, rating, text } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text
    }));

    return (
        <div
            ref={ref}
            id="preview-template"
            className="rounded-lg bg-gray-300 relative w-[1080px] h-[1080px] scale-[0.4]"
        >

            <img
                src={Background}
                className="w-full h-full rounded-md"
            />

            <div className="absolute top-0 left-[100px] w-[90%] h-[75%] flex flex-col items-start p-6">

                <div className="flex items-center justify-center space-x-3 mt-2">

                    <img
                        src={GenerateProfilePic("A")}
                        className="w-[6rem] h-[6rem] rounded-full"
                    />

                    <div className="space-y-3">

                        <p className="text-4xl font-semibold text-white">
                            {name}
                        </p>

                        {role || company ?
                            <p className="text-3xl font-normal text-gray-100">

                                {role && company ?
                                    <>
                                        {role} of {company}
                                    </> :
                                    null
                                }

                                {role ? role : null}

                                {company ? company : null}

                            </p> : null
                        }


                    </div>

                </div>

                {rating ?
                    <Rating
                        rated={rating}
                        iconProps={{
                            stroke: "black",
                            strokeWidth: 8,
                            className: "mt-4 w-[60px] h-[60px] mx-2"
                        }}
                    /> : null
                }

                <p
                    className="text-white text-[36px] font-medium tracking-wide break-words p-6"
                    dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                />

            </div>

        </div>
    );
}