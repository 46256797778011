import { cn } from "@utils/cn";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

type Props = {
    pageIdx: number,
    pageCount: number,
    disablePreviousButton: boolean,
    onPreviousPage: () => void,
    disableNextButton: boolean,
    onNextPage: () => void,
};

export function Pagination({ pageIdx, pageCount, disableNextButton, disablePreviousButton, onNextPage, onPreviousPage }: Props) {
    return (
        <div className={cn(`flex items-center justify-between p-3 rounded-lg bg-white border border-gray-200`)}>

            <div className="flex items-center">

                <p className="text-sm font-normal">Page</p>

                <strong>

                    {pageIdx} of{' '}

                    {pageCount}

                </strong>

            </div>

            <div className="flex items-center space-x-3 transition-all duration-300 ease-in-out">

                <button
                    className={cn(`
                        border border-gray-300 rounded-md hover:bg-gray-200 
                        flex items-center justify-center p-2 space-x-2
                    `)}
                    onClick={() => onPreviousPage()}
                    disabled={disablePreviousButton}
                >

                    <FaAngleLeft className="w-4 h-4 text-gray-700" />

                    <p className="text-sm font-medium"> Previous </p>

                </button>

                <button
                    className={cn(`
                        border border-gray-300 rounded-md hover:bg-gray-200 
                        flex items-center justify-center p-2 space-x-2 cursor-pointer
                    `)} onClick={() => onNextPage()}
                    disabled={!disableNextButton}
                >

                    <p className="text-sm font-medium"> Next </p>

                    <FaAngleRight className="w-4 h-4 text-gray-700" />

                </button>

            </div>

        </div>
    );
}