import Quill from "quill";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { RecallCard, RecallParam } from "./card";
import { cn } from "@utils/cn";
import "quill/dist/quill.bubble.css";
import "./style.css";
import { motion, AnimatePresence } from "framer-motion";

type ToolBar = "bold" | "italic" | "underline" | "link" | "recall";

type Props = {
    placeHolder: string,
    valueAsHTML: string,
    recall: boolean,
    className?: string,
    error?: string,
    toolBar?: ToolBar[],
    formats?: ToolBar[],
    style?: CSSProperties,
    onChange: (params: { text: string, html: string; }) => void;
};

export function QuillInput({
    placeHolder = "Enter your values here",
    valueAsHTML,
    className,
    error,
    toolBar = ['bold', 'italic', 'underline', 'link'],
    formats = ['bold', 'italic', 'underline', 'link', 'recall'],
    style,
    onChange
}: Props) {

    const [showCard, setShowCard] = useState(false);

    const quillRef = useRef<Quill | null>(null);
    const cursorIdxRef = useRef(-1);
    const eleRef = useRef<any>(null);

    useEffect(() => init(), []);

    const init = useCallback(() => {

        if (!quillRef.current) {

            const quill = new Quill(eleRef.current, {
                formats,
                modules: {
                    toolbar: [toolBar],
                },
                placeholder: placeHolder,
                theme: 'bubble',
            });

            quill.root.setAttribute("spellcheck", 'false');

            quillRef.current = quill;

            const toolTip = document.querySelector(".ql-tooltip-editor>input[data-link]") as any;

            toolTip.dataset.link = "https://gozen.io";

            onUpdateRecallTag();

            quillRef.current.on("text-change", () => onTextChange());

            quillRef.current.root.style.fontSize = (style?.fontSize as string) ?? "1rem";
            quillRef.current.root.style.lineHeight = (style?.lineHeight as string) ?? "1.2rem";
            quillRef.current.root.style.letterSpacing = (style?.letterSpacing as string) ?? "0.2px";

        }

        return () => {

            quillRef.current?.off("text-change", () => onTextChange());

            quillRef.current = null;

        };

    }, []);

    const onUpdateRecallTag = () => {

        if (!quillRef.current) return [];

        const delta = quillRef.current.clipboard.convert(valueAsHTML as any);

        const newDelta: any = delta.ops?.filter((ops: any) => {

            if (typeof (ops.insert) === "object" && "recall" in ops.insert) {

                const id = ops.insert["recall"].id as string;

                if (!id) return null;

                // const field = useFormStore.getState().customDetails.

                ops.insert["recall"].value = "value";

                return ops;
            }

            return ops;

        }) ?? [];

        quillRef.current.setContents(newDelta);

        // onChange({
        //     text: toText(quillRef.current.root.innerHTML),
        //     html: quillRef.current.root.innerHTML
        // });

    };

    const onTextChange = () => {

        if (!quillRef.current) return;

        const range = quillRef.current.getSelection();

        if (!range) return;

        const char = quillRef.current.getText(range.index - 1, 1);

        if (char === "@") {

            setShowCard(true);

            cursorIdxRef.current = range.index;

            onChange({
                text: toText(quillRef.current.root.innerHTML),
                html: quillRef.current.root.innerHTML
            });

        } else {

            onCloseCard();

            onChange({
                text: toText(quillRef.current.root.innerHTML),
                html: quillRef.current.root.innerHTML
            });

        }
    };

    const onCloseCard = () => {
        setShowCard(showCard ? false : showCard);
        cursorIdxRef.current = -1;
    };

    const onRecallQuestion = (param: RecallParam) => {

        if (!quillRef.current) return;

        quillRef.current.deleteText(cursorIdxRef.current - 1, 1);

        quillRef.current.insertEmbed(cursorIdxRef.current - 1, "recall", {
            id: param.id,
            value: param.label,
            type: param.type,
        }, "silent");

        onChange({
            text: toText(quillRef.current.root.innerHTML),
            html: quillRef.current.root.innerHTML
        });

        onCloseCard();

    };

    const onPaste = (e: React.ClipboardEvent) => {

        if (!quillRef.current) return;

        e.preventDefault();

        const currLen = quillRef.current?.getLength() ?? 0;

        const txt = e.clipboardData.getData("Text");

        quillRef.current?.insertText((currLen + txt.length), txt);

        onChange({
            text: toText(quillRef.current.root.innerHTML),
            html: quillRef.current.root.innerHTML
        });

    };

    const toText = (html: string) => {

        if (!html) return "";

        const ele = document.createElement("div");

        ele.innerHTML = html;

        ele.querySelectorAll(".ql-recall").forEach(tag => tag.innerHTML = "_");

        const txt = ele.textContent ?? "";

        ele.remove();

        return txt;
    };

    return (
        <AnimatePresence initial={false}>

            <div>

                <div
                    ref={eleRef}
                    className={cn(`
                        relative border border-gray-300 rounded-md w-full p-2 text-lg
                        hover:ring-1 hover:ring-blue-400 hover:ring-offset-1
                        transition-all duration-300 ease-in-out
                    `, className)}
                    onPaste={(e) => onPaste(e)}
                />

                {error &&
                    <motion.span
                        className="text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 text-sm font-normal text-left"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        * {error}
                    </motion.span>
                }

                {showCard ?
                    <RecallCard
                        quillRef={quillRef.current}
                        parentRef={eleRef.current}
                        // fieldId={fieldId}
                        onRecall={onRecallQuestion}
                        onClose={onCloseCard}
                    /> : null
                }

            </div>

        </AnimatePresence>
    );
}