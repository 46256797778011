import { Accordion } from "@components/accordion";
import { cn } from "@utils/cn";
import { PiPaintBrushHouseholdBold } from "react-icons/pi";
import { PiHandWaving } from "react-icons/pi";
import { TbMessage2Heart } from "react-icons/tb";
import { LuContact2 } from "react-icons/lu";
import { PiHandHeart } from "react-icons/pi";
import { LuPartyPopper } from "react-icons/lu";
import { GrSettingsOption } from "react-icons/gr";
import { Design } from "./design/main.Design";
import { WelcomePage } from "./welcome/main.welcomePage";
import { ResponsePage } from "./response/main.responsePage";
import { CustomDetails } from "./customDetails/main.customDetails";
import { ThankyouPage } from "./thankYou/main.thankYouPage";
import { Reward } from "./reward/main.rewards";
import { Settings } from "./settings/main.settings";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Fragment } from "react";

type Props = {
    isLoading: boolean;
};

const sections = [
    {
        label: "Design",
        value: "design",
        icon: <PiPaintBrushHouseholdBold className="w-6 h-6 text-purple-500" />,
        body: <Design />
    },
    {
        label: "Welcome Page",
        value: "welcome",
        icon: <PiHandWaving className="w-6 h-6 text-pink-500" />,
        body: <WelcomePage />
    },
    {
        label: "Response Page",
        value: "response",
        icon: <TbMessage2Heart className="w-6 h-6 text-red-500" />,
        body: <ResponsePage />
    },
    {
        label: "Custom Details",
        value: "customerDetails",
        icon: <LuContact2 className="w-6 h-6 text-green-500" />,
        body: <CustomDetails />
    },
    {
        label: "Thankyou Page",
        value: "thankyou",
        icon: <PiHandHeart className="w-6 h-6 text-red-500" />,
        body: <ThankyouPage />
    },
    {
        label: "Reward Page",
        value: "reward",
        icon: <LuPartyPopper className="w-6 h-6 text-blue-500" />,
        body: <Reward />
    },
    {
        label: "Settings",
        value: "settings",
        icon: <GrSettingsOption className="w-6 h-6 text-gray-500" />,
        body: <Settings />
    },
];

export function FormSettings({ isLoading }: Props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const active = searchParams.get("active") ?? "";

    const onOpenSection = (section: string) => {

        if (active === section) {
            searchParams.delete("active");
            setSearchParams(searchParams);
            return;
        }

        searchParams.set("active", section);

        setSearchParams(searchParams);

    };

    return (
        <div className="w-[40%] h-full bg-gray-100 p-2 overflow-y-scroll">

            <Accordion className={cn("shadow-md mb-10")}>

                {sections.map((section, idx) => (
                    <Accordion.Item
                        key={idx}
                        className={cn("border", {
                            "rounded-t-lg": idx === 0,
                            "rounded-b-lg": idx === (sections.length - 1),
                            "cursor-default": isLoading
                        })}
                    >

                        {isLoading ?
                            <Skeleton
                                className="h-full m-3 py-2 px-2"
                                style={{
                                    width: "95%",
                                    borderRadius: "8px"
                                }}
                            /> :
                            <Fragment>

                                <Accordion.Head
                                    icon={section.icon}
                                    title={section.label}
                                    show={active === section.value}
                                    onClick={() => {

                                        document.getElementById("thankyou")?.scrollIntoView({ behavior: "smooth", block: "center" });

                                        onOpenSection(section.value);

                                    }}
                                    className={cn({
                                        "border-b": active === section.value
                                    })}
                                />

                                <Accordion.Body show={active === section.value}>

                                    <div>

                                        {section.body}

                                    </div>

                                </Accordion.Body>

                            </Fragment>
                        }


                    </Accordion.Item>
                ))}

            </Accordion>

        </div>
    );
}