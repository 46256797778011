import { Button } from "@components/button";
import { FullLogo } from "@components/icons/logo";
import { Text } from "@components/text";
import { useTeamsApiClient } from "@hooks";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";


export function JoinTeamPage() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { joinTeam } = useTeamsApiClient({});

    const onJoin = async () => {
        try {

            const token = searchParams.get("token");

            if (!token) {

                toast.error("Invalid invite link.");

                navigate("/", { replace: true });

                return;

            }

            await joinTeam.mutateAsync(token);

            navigate("/", { replace: true });

            toast.success("Successfully, join the team.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section className="w-screen h-screen overflow-hidden bg-gray-100">

            <div className="xl:h-[6%] md:h-[8%] bg-white flex items-center justify-between border-b">

                <div
                    className='w-auto mx-2 p-2 flex items-center justify-center'
                    onClick={() => navigate("/")}
                >
                    <FullLogo />
                </div>

            </div>

            <div className="w-full h-full flex items-center justify-center">

                <div className="w-[30%] shadow-sm border rounded-lg bg-white p-4 space-y-4">

                    <Text
                        text="Join Team"
                        variant="primary"
                        className="text-lg font-medium "
                    />

                    <Button
                        loading={joinTeam.isPending}
                        disabled={joinTeam.isPending}
                        text="Join"
                        className="text-sm font-medium"
                        onClick={() => onJoin()}
                    />

                </div>



            </div>


        </section>
    );
}