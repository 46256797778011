import { Select } from "@components/select";


export function SelectSpaces() {

    return (
        <Select
            options={[
                { label: "test", value: "test" },
                { label: "test1", value: "test1" },
                { label: "test2", value: "test2" },
            ]}
            className="w-[30%] ml-auto mt-0 mr-2"
        />
    );
}