import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";


export function DateField() {

    const date = useImportTestimonialStore((store) => store.testimonial.date);

    const parsedData = date.split("T")[0];

    return (
        <Input
            type="date"
            label="Date"
            required={true}
            mainClassName="w-full"
            value={parsedData}
            onChange={(e) => {
                useImportTestimonialStore
                    .getState()
                    .setDate(new Date(e.target.value).toISOString());
            }}
        />
    );
}