import { UploadPicture } from "@components/upload";
import { useState } from "react";

export function ImageTemplateBranding() {

    // const { logo } = useSocialProofStore((store) => store.imageTemplate.branding);

    const [file, setFile] = useState(null);

    return (
        <div className="px-1">

            <UploadPicture
                id="logo"
                file={file}
                title="Logo"
                titleClassName="text-sm font-medium text-gray-700"
                imgClassName="w-[50px] h-[50px]"
                onChange={() => setFile(null)}
            />

        </div>
    );
}