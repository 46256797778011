import { ColorPicker } from "@components/colorPicker";
import { useFormStore } from "@store";


export function PrimaryColorAndBackgroundColor() {

    const { backgroundColor, primaryColor } = useFormStore((store) => ({
        backgroundColor: store.design.background.color,
        primaryColor: store.design.primaryColor,
    }));

    return (
        <div className="flex items-center justify-between space-x-4">

            <ColorPicker
                label='Primary Color'
                defaultColor={primaryColor}
                onChange={(color) => useFormStore.getState().setPrimaryColor(color)}
            />

            <ColorPicker
                label="Background Color"
                defaultColor={backgroundColor}
                onChange={(val) => useFormStore.getState().setBackground(val, "color")}
            />

        </div>
    );
}