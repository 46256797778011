import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from 'zustand/middleware/immer';
import { StoreKeys } from "../enum";


const State: Onboarding.State = {
    action: "create",
    businessType: "",
    role: "",
    website: ""
};

export const useOnboardingStore = create<Onboarding.Type>()(devtools(persist(immer(set => ({
    ...State,
    setAction: (action) => set((state) => {
        state.action = action;
    },
        false,
        "onBoarding/setAction"
    ),
    setBusinessType: (businessType) => set((state) => {
        state.businessType = businessType;
    },
        false,
        "onBoarding/setBusinessType"
    ),
    setRole: (role) => set((state) => {
        state.role = role;
    },
        false,
        "onBoarding/setRole"
    ),
    setWebsite: (website) => set((state) => {
        state.website = website;
    },
        false,
        "onBoarding/setWebsite",
    ),
})), {
    name: StoreKeys.onboarding,
}), {
    name: StoreKeys.onboarding,
    enabled: import.meta.env.DEV
}));
