import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useFormStore } from "@store";


export function VideoPage() {

    const { title, description } = useFormStore((store) => ({
        title: store.response.video.title,
        description: store.response.video.description
    }));

    return (
        <div className="space-y-2 my-2">

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Video Title"
                />

                <QuillInput
                    valueAsHTML={title}
                    placeHolder="Enter your prompt message here."
                    recall={true}
                    onChange={(e) => useFormStore.getState().setResponseTitle("video", e.html)}
                />

            </div>

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Video Description"
                />

                <QuillInput
                    valueAsHTML={description}
                    placeHolder="Enter your prompt message here."
                    recall={true}
                    onChange={(e) => useFormStore.getState().setResponseDescription("video", e.html)}
                />

            </div>

        </div>
    );
}