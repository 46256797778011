import { Button, SecondaryButton } from "@components/button";
import { Config } from "@config";
import { useActiveSpace } from "@hooks";
import { cn } from "@utils/cn";
import QRcode from "qrcode.react";
import { FiDownload } from "react-icons/fi";
import { MdContentCopy } from "react-icons/md";

export function QrCodeCard() {

    const { formId } = useActiveSpace();

    const formUrl = Config.RenderEngineUrl() + formId;

    const onDownloadQrCode = () => {

        const qrcode: any = document.getElementById("qr-code");

        const url = qrcode.toDataURL("image/png").replace("image/png", "image/octet-stream");

        const a = document.createElement("a");

        a.href = url;

        a.setAttribute("download", `${name}.png`);

        a.click();

    };

    const onCopy = () => {

    };

    return (
        <div className={cn(`
            flex items-start justify-between p-4 px-5 bg-white rounded-lg
            border border-gray-200 shadow-sm space-y-2
        `)}>

            <div className="w-[60%] h-full space-y-3">

                <div>

                    <h2 className="text-lg font-semibold tracking-wide">
                        Share your form's QR code
                    </h2>

                    <p className="text-sm font-normal text-gray-500 tracking-wide">
                        Download or copy a QR code that loads your form.
                    </p>

                </div>

                <div className="flex items-center justify-center space-x-4">

                    <Button
                        className="inline-block text-sm font-medium py-3"
                        onClick={() => onDownloadQrCode()}
                    >

                        <FiDownload className="w-4 h-4 mx-2" />

                        Download

                    </Button>

                    <SecondaryButton
                        className="inline-block text-sm font-medium py-3"
                        onClick={() => onCopy()}
                    >

                        <MdContentCopy className="w-4 h-4 mx-2" />

                        Copy

                    </SecondaryButton>

                </div>


            </div>

            <div className="p-2 inline-flex items-center justify-center my-3">
                <QRcode
                    id="qr-code"
                    renderAs="canvas"
                    level="L"
                    bgColor="#fff"
                    value={formUrl}
                    size={250}
                    style={{
                        background: "white",
                        height: "100px",
                        width: "100px"
                    }}
                />
            </div>

        </div>
    );
}