import { useInvitesStore } from "@store";
import { cn } from "@utils/cn";
import { ChangeEvent, useRef } from "react";
import toast from "react-hot-toast";
import CSV from "papaparse";


export function UploadFileSection() {

    const ref = useRef<any>(null);

    const onSelect = async (e: ChangeEvent<HTMLInputElement>) => {
        try {

            const file = (e.target.files as FileList)[0];

            if (!file) return;

            const format = file.type.split("/")[1].toLowerCase();

            const allowedFormats = ["csv", "xls", "xlsx"];

            if (!allowedFormats.includes(format)) {
                toast.error("Invalid file format. Only allowed CSV, XLS and XLSX");
                return;
            }

            const sizeInMb = Math.floor((file.size / 1024) / 1024);

            const maxAllowedSize = Math.floor((5 * 1024) * 1024);

            if (sizeInMb > maxAllowedSize) {
                toast.error("File too large. File should less then 5mb.");
                e.target.value = "";
                return;
            }

            useInvitesStore.getState().setSelectedFile(file);

            useInvitesStore.getState().setActiveUploadTab("selectColumns");

            await onParseFile(file);

        } catch (err) {
            console.log(err);
            toast.error("Something went wrong");
        }
    };

    const onParseFile = (file: File) => {

        return new Promise<void>((resolve, reject) => {
            try {

                if (!file) {
                    reject();
                    return;
                }

                CSV.parse(file, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    complete: (results) => {

                        const columns = results.meta.fields?.map(item => item.trim().replace(/['"]+/g, '')) as string[];

                        const rows = results.data.map((item: any) => {

                            Object.keys(item).forEach((key: string) => {

                                const newKey = key.trim().replace(/['"]+/g, '');

                                const oldValue = item[key];

                                delete item[key];

                                item[newKey] = oldValue;

                            });

                            return item;

                        });

                        useInvitesStore.getState().setParsedFile({ columns, rows });

                        resolve();
                    }
                });

            } catch (err) {
                console.log(err);
                reject(err);
            }
        });

    };


    return (
        <div
            className={cn(`
                border border-dashed border-gray-300 bg-gray-100 h-[200px] w-full rounded-lg 
                flex flex-col items-center justify-center mt-4 
            `)}
        >

            <label
                className="text-sm font-normal text-gray-700 leading-6"
                htmlFor="file-upload"
            >

                <span
                    className="text-primary font-medium underline cursor-pointer mx-1"
                >
                    Click to upload
                </span>
                your file
                <br />
                CSV, XLSX and XLS files accepted

            </label>

            <input
                ref={ref}
                type="file"
                id="file-upload"
                className="hidden"
                onChange={(e) => onSelect(e)}
            />

        </div>
    );
}