import { Player } from "@remotion/player";
import { useEffect, useRef } from "react";
import Bg from "./t1.png";
import { cn } from "@utils/cn";
import { Video } from "remotion";
import { PoweredBy } from "@components/branding";

function Test() {

    const src = "https://video.dev.testimonial.gozen.io/ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8";

    const ref = useRef<HTMLVideoElement>(null);

    useEffect(() => {

        // const hls = new Hls();

        // hls.on(Hls.Events.MANIFEST_PARSED, () => hls.startLoad());

        // hls.loadSource(src);

        // hls.attachMedia(ref.current!);

        // return () => hls.destroy();

    }, []);

    return (
        <div className={cn(`h-[530px] w-full relative`)}>

            <img
                src={Bg}
                className="h-full w-full"
            />

            <Video
                ref={ref}
                src={src}
                className="absolute top-0 rounded-lg p-4 h-[60%]"
            >

                <track
                    id="track1"
                    label="English Subtitle"
                    kind="subtitles"
                    src="./file.srt"
                    default
                />

            </Video>

            <PoweredBy className={`scale-[0.5]`} />

        </div>
    );
}

export function VideoTemplate1() {

    return (
        <Player
            component={Test}
            durationInFrames={120}
            compositionWidth={300}
            compositionHeight={530}
            fps={30}
            style={{
                width: 300,
                height: 530,
            }}
            controls
        />
    );
}