import { SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { TextToggle } from "@components/toggle";
import { useCommonStore, useTestimonialStore } from "@store";
import { cn } from "@utils/cn";
import { Fragment } from "react";
import { MdSettings } from "react-icons/md";


export function TableSettings() {

    const show = useCommonStore((store) => store.activePopup === "testimonial-table-settings");

    const showColumns = useTestimonialStore((store) => store.showColumns);

    return (
        <Fragment>

            <SecondaryButton
                className={cn(`py-2 px-3 bg-gray-200 hover:bg-gray-200/80`)}
                onClick={() => useCommonStore.getState().setActivePopup("testimonial-table-settings")}
            >

                <MdSettings className="w-5 h-5" />

            </SecondaryButton>

            {show ?
                <Popup
                    width="30%"
                    name="Table Settings"
                    onClose={() => useCommonStore.getState().setActivePopup("")}
                >

                    <div className="space-y-4 p-3 mt-3">

                        <TextToggle
                            isActive={showColumns["customerDetails"]}
                            name="Customer Details"
                            onClick={() => useTestimonialStore.getState().setTableColumns("customerDetails")}
                        />

                        <TextToggle
                            isActive={showColumns["testimonial"]}
                            name="Testimonial"
                            onClick={() => useTestimonialStore.getState().setTableColumns("testimonial")}
                        />

                        <TextToggle
                            isActive={showColumns["submittedAt"]}
                            name="Submitted At"
                            onClick={() => useTestimonialStore.getState().setTableColumns("submittedAt")}
                        />

                        <TextToggle
                            isActive={showColumns["status"]}
                            name="Status"
                            onClick={() => useTestimonialStore.getState().setTableColumns("status")}
                        />

                    </div>

                </Popup> : null
            }

        </Fragment>
    );
}