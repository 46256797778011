import { Text } from "@components/text";
import { useClickAway, useDebounce } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { SketchPicker, ChromePicker } from "react-color";

type Props = {
    type?: "chrome" | "sketch",
    label: string,
    defaultColor: string,
    className?: string,
    onChange: (color: string) => void,
};

export function ColorPicker({ type = "sketch", label, defaultColor, className, onChange }: Props) {

    const [color, setColor] = useState(defaultColor ?? "#eee");
    const colorChanged = useDebounce(color, 100);

    const [show, setShow] = useState(false);

    const ref = useClickAway<any>(() => setShow(false));

    useEffect(() => { onChange(color); }, [colorChanged]);

    const onColorChange = (val: string) => setColor(val[0] !== "#" ? `#${val}` : val);

    return (
        <div className="flex flex-col items-start justify-between relative w-full">

            <Text
                variant="label"
                text={label}
            />

            <div className={cn(`
                border border-gray-300 bg-white rounded-lg space-x-1 py-1 px-2
                flex justify-between items-center w-full
                hover:ring-1 hover:ring-blue-400 hover:ring-offset-1
            `)}>

                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        setShow(!show);
                    }}
                    className="w-7 h-7 border-border border-0.5px border rounded-full"
                    style={{
                        background: color
                    }}
                />

                <input
                    type='text'
                    value={color}
                    className='inline-block border-none outline-none w-[90%]'
                    onChange={(e) => onColorChange(e.target.value)}
                />

            </div>

            <AnimatePresence>

                {show &&
                    <motion.div
                        ref={ref}
                        className={cn('absolute right-14 top-0 z-30', className)}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {type === "sketch" ?
                            <SketchPicker
                                disableAlpha={true}
                                className="cursor-auto "
                                color={color}
                                onChange={(newColor) => setColor(newColor.hex)}
                            /> : null
                        }

                        {type === "chrome" ?
                            <ChromePicker
                                disableAlpha={true}
                                className="cursor-auto "
                                color={color}
                                onChange={(newColor) => setColor(newColor.hex)}
                            /> : null
                        }
                    </motion.div>
                }

            </AnimatePresence>

        </div>
    );

}