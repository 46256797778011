import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore } from "@store";
import { cn } from "@utils/cn";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";


export function InlineCTA() {

    const inlineCTA = useSocialProofStore((store) => store.wallOfLoveTemplate.content.inlineCTA);

    console.log(inlineCTA);

    return (
        <div>

            <TextToggle
                isActive={inlineCTA ? true : false}
                name="Enable Inline CTA"
                onClick={() => useSocialProofStore.getState().setWolTemplateContent({
                    inlineCTA: inlineCTA ? null : {
                        title: "Get Started",
                        subTitle: "",
                        btnText: "Get Started",
                        btnUrl: "",
                    }
                })}
            >

                <FiInfo
                    className={cn(`text-gray-600`)}
                    id={`allow-sharing-tooltip`}
                />

                <Tooltip
                    anchorSelect={`#allow-sharing-tooltip`}
                    place="top"
                    style={{
                        width: 340,
                        textAlign: "left"
                    }}
                >
                    Add a call to action block that shows up as people scroll down your Wall of Love.
                    This needs 4 testimonials or more to work.
                </Tooltip>;

            </TextToggle>

            {inlineCTA ?
                <div className="space-y-2 my-2">

                    <Input
                        label="Title"
                        placeholder="Title"
                        value={inlineCTA?.title ?? ""}
                        onChange={(e) => useSocialProofStore.getState().setWolTemplateContent({
                            inlineCTA: {
                                ...inlineCTA,
                                title: e.target.value
                            }
                        })}
                    />

                    <Input
                        label="Sub Title"
                        placeholder="Sub Title"
                        value={inlineCTA?.subTitle ?? ""}
                        onChange={(e) => useSocialProofStore.getState().setWolTemplateContent({
                            inlineCTA: {
                                ...inlineCTA,
                                subTitle: e.target.value
                            }
                        })}
                    />

                    <Input
                        label="Button Text"
                        placeholder="Button Text"
                        value={inlineCTA?.btnText ?? ""}
                        onChange={(e) => useSocialProofStore.getState().setWolTemplateContent({
                            inlineCTA: {
                                ...inlineCTA,
                                btnText: e.target.value
                            }
                        })}
                    />

                    <Input
                        label="Button Url"
                        placeholder="Button Url"
                        value={inlineCTA?.btnUrl ?? ""}
                        onChange={(e) => useSocialProofStore.getState().setWolTemplateContent({
                            inlineCTA: {
                                ...inlineCTA,
                                btnUrl: e.target.value
                            }
                        })}
                    />

                </div> : null
            }

        </div>
    );
}