import { Input } from "@components/input";
import { Text } from "@components/text";
import { useTestimonialStore } from "@store";
import { Fragment, ReactNode, useEffect, useState } from "react";

type Props = {
    id: string;
    edit?: boolean,
    icon?: ReactNode,
    question: string,
    field: Api.Testimonials.Type["customerDetails"][string],
    children?: ReactNode;
};

export function CustomerField({ edit, icon, question, field, id }: Props) {

    const updatedCustomerDetails = useTestimonialStore(state => state.customerDetails);

    const { answer, type, varType } = field;

    const [value, setValue] = useState<string | number | object>();

    useEffect(() => {

        setValue(edit && id in updatedCustomerDetails ? (updatedCustomerDetails[id].answer ?? answer) : answer);

    }, [edit]);

    const onChange = (val: string) => {

        setValue(val);

        useTestimonialStore.getState().setUpdatedCustomerDetails(
            id,
            { answer: val, type, varType }
        );

    };


    console.log({ question });

    return (
        <div className="flex items-start space-x-1">

            {edit ?
                <div className="w-full space-y-2">

                    <div className="flex items-center space-x-1">

                        {icon ?? null}

                        <Text
                            variant="primary"
                            size="sm"
                            className="font-medium mt-0"
                            text={question}
                        />

                    </div>

                    {typeof value === "string" || typeof value === "number" ?
                        <Input
                            value={value ?? ""}
                            onChange={(e) => onChange(e.target.value)}
                        /> : null
                    }

                    {typeof value === "object" ?
                        <div>

                            handle rendering for array / objects

                        </div> : null
                    }

                </div> :
                <Fragment>

                    {icon ?? null}

                    <Text
                        variant="primary"
                        size="sm"
                        className="font-medium mt-0"
                        text={question}
                    />

                    {typeof answer === "string" ?
                        <Text
                            variant="primary"
                            size="sm"
                            className="font-normal mt-0 text-gray-700"
                            text={answer}
                        /> : answer
                    }

                </Fragment>
            }

        </div>
    );
}