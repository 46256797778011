import { useSocialProofStore } from "@store";
import { SelectTestimonials } from "../../selectTestimonials/main.select";
import { ImageTemplates } from "./main.template";



export function ImageTemplatePreview() {

    const { activeTab } = useSocialProofStore((store) => ({
        activeTab: store.activeTab,
    }));

    return (
        <div className="w-full h-full">

            {activeTab === "selectTestimonials" ? <SelectTestimonials /> : null}

            {activeTab === "template" ? <ImageTemplates /> : null}

        </div>
    );
}