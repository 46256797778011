import { TestimonialSourceTypes } from "@components/icons";
import { Logo } from "@components/icons/logo";
import { Text } from "@components/text";
import { useTestimonialStore } from "@store";
import { cn } from "@utils/cn";


export function TestimonialSourcesFilter() {

    const sources = useTestimonialStore((store) => store.filter.sources);

    const onClick = (val: string) => {

        if (sources.includes(val)) {

            useTestimonialStore.getState().setFilter({
                sources: ([...sources.filter(source => source !== val)])
            });

        } else {

            useTestimonialStore.getState().setFilter({ sources: ([...sources, val]) });

        }

    };

    return (
        <div className="w-[55%]">

            <Text
                variant="label"
                text="Testimonial Source"
                size="sm"
                className="font-medium tracking-wide text-gray-900"
            />

            <div className="grid grid-cols-4 gap-2 mt-2">

                <div
                    className={cn(`
                        border bg-gray-50 hover:bg-gray-200 cursor-pointer p-1 
                        flex items-center justify-center rounded-md 
                        transition-all duration-300 ease-in-out
                    `, {
                        "bg-blue-100 border-blue-500": sources.includes("gozenTestimonial")
                    })}
                    onClick={() => onClick("gozenTestimonial")}
                >
                    <Logo className="w-6 h-6" />
                </div>

                {TestimonialSourceTypes.map((item, idx) => {

                    if (item.value !== "text" && item.value !== "video") {
                        return (
                            <div
                                key={idx}
                                className={cn(`
                                    border bg-gray-50 hover:bg-gray-200 cursor-pointer p-1 
                                    flex items-center justify-center rounded-md 
                                    transition-all duration-300 ease-in-out
                                `, {
                                    "bg-blue-100 border-blue-500": sources.includes(item.value)
                                })}
                                onClick={() => onClick(item.value)}
                            >
                                {item.icon}
                            </div>
                        );
                    }

                })}

            </div>

        </div>
    );
}