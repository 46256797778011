import { useEmbedStore } from "@store";
import { cn } from "@utils/cn";
import { useState } from "react";
import { EmbeddedPreview } from "../ifream";


export const SliderPreview: React.FC = () => {

    const { view, slider } = useEmbedStore((store) => ({
        view: store.view,
        slider: store.slider
    }));

    const { buttonColor, dir, text, textColor } = slider;

    const [show, setShow] = useState(false);

    return (
        <div
            className={cn(`
                relative bg-white rounded-xl border border-border p-5 overflow-auto 
                flex items-center justify-center
            `)}
            style={{
                width: view === "desktop" ? "100%" : "400px",
                height: window.innerWidth < 1400 ? "500px" : "600px"
            }}
        >

            {show ?
                <div className="absolute h-full w-full bg-[#3d3d3d54] flex items-center justify-center">

                    <div
                        className={cn(`h-full w-full flex items-start`, {
                            "flex-row-reverse": dir === "right"
                        })}
                    >

                        <EmbeddedPreview
                            className={cn(`h-full z-20 relative bg-white w-[60%]`, {
                                "absolute right-0": dir === "right",
                                "w-[100%]": view === "mobile"
                            })}
                        />

                        <div
                            className={cn(`bg-white px-3 py-2 rounded-md inline-block z-50`, {
                                "absolute top-5 right-5": view === "mobile"
                            })}
                            onClick={() => setShow(!show)}
                        >
                            close
                        </div>

                    </div>


                </div> : null
            }

            <div className="flex flex-col space-y-2">

                <p className="text-sm font-normal"> click here to launch slider </p>

                <div
                    className="bg-primary text-white rounded-md text-sm font-normal px-5 py-2 cursor-pointer text-center"
                    onClick={() => setShow(!show)}
                    style={{
                        background: buttonColor,
                        color: textColor
                    }}
                >
                    {text ?? "launch"}
                </div>

            </div>

        </div>
    );
}; 