import { cn } from "@utils/cn";
import { HTMLProps, useRef, useEffect } from "react";

export const TableSelect = ({ indeterminate, className = '', ...props }: { indeterminate?: boolean; } & HTMLProps<HTMLInputElement>) => {

    const ref = useRef<HTMLInputElement>(null!);

    useEffect(() => {

        if (indeterminate && ref.current?.indeterminate && typeof indeterminate === 'boolean') {
            ref.current.indeterminate = (!props.checked ?? false) && indeterminate;
        }

    }, [ref, indeterminate]);

    return (
        < div className="flex items-center">

            <input
                type="checkbox"
                checked={props.checked}
                className={cn(`w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded-lg cursor-pointer`)}
                onChange={props.onChange}
            />

        </div>
    );
};