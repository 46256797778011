import { TextToggle } from "@components/toggle";
import { useState } from "react";
import { RecordOrUploadVideo } from "@components/recordOrUploadVideo/upload.main";
import { useFormStore } from "@store";

export function WelcomeVideo() {

    const introVideo = useFormStore((store) => store.welcome.introVideo);

    const [show, setShow] = useState(introVideo ? true : false);

    const onChange = () => {

        if (show) useFormStore.getState().setWelcomeIntroVideo(null);

        setShow(!show);

    };

    return (
        <div className="space-y-2 py-1">

            <TextToggle
                isActive={show}
                name="Welcome Intro Video"
                onClick={() => onChange()}
            />

            {show ? <RecordOrUploadVideo type="welcome" /> : null}

            <TextToggle
                isActive={introVideo && introVideo.autoPlay ? true : false}
                name="Auto Play Video"
                onClick={() => useFormStore.getState().setWelcomeIntroVideo({ autoPlay: !introVideo?.autoPlay })}
            />

        </div>
    );
}