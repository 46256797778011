import { Input } from "@components/input";
import { Select } from "@components/select";
import { TextToggle } from "@components/toggle";
import { useEmbedStore } from "@store";

const options = [
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" }
];

export const PopupSettings: React.FC = () => {

    const { size, text, removeBackground } = useEmbedStore((store) => store.popup);

    const activeOption = options.filter(item => item.value === size);

    return (
        <div className="font-normal text-font_secondary text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">

            <span className="w-full">Popup Size</span>

            <div className="w-full">

                <Select
                    defaultValue={activeOption}
                    options={options}
                    onChange={(val: any) => useEmbedStore.getState().setPopup({ size: val })}
                />

                <Input
                    label="Button Text"
                    value={text}
                    onChange={(e) => useEmbedStore.getState().setPopup({ text: e.target.value })}
                />

                <TextToggle
                    name="Remove Background"
                    isActive={removeBackground}
                    onClick={() => useEmbedStore.getState().setPopup({ removeBackground: !removeBackground })}
                />

            </div>

        </div>
    );
};