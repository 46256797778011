

interface Props extends React.SVGProps<SVGSVGElement> { }

export function CapterraIcon(props: Props) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_184_3925)">
                <path d="M0.238831 7.36294L8.50679 7.36459L13.5343 7.36545V2.38477L0.238831 7.36294Z" fill="#FF9D28" />
                <path d="M13.5342 2.38409V19.9684L19.8134 0.0322266L13.5342 2.38409Z" fill="#68C5ED" />
                <path d="M13.5342 7.36511L8.50677 7.36426L13.5342 19.9687V7.36511Z" fill="#044D80" />
                <path d="M0.238831 7.3623L9.79603 10.5981L8.50679 7.36396L0.238831 7.3623Z" fill="#E54747" />
            </g>
            <defs>
                <clipPath id="clip0_184_3925">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};















