import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const State: ChromeExtension.State = {
    customerDetails: {},
    source: "gozenTestimonial",
    submittedAt: "",
    type: "text",
    testimonial: {
        rating: null,
        audio: null,
        text: null,
        video: null
    }
};

export const useChromeExtensionStore = create<ChromeExtension.Type>()(devtools(immer((set => ({
    ...State,
    set: (val) => set((store) => {

        store.customerDetails = val.customerDetails;
        store.source = val.source;
        store.submittedAt = val.submittedAt;
        store.testimonial = val.testimonial;
        store.type = val.type;

    },
        false,
        "useChromeExtensionStore/set"
    ),
    setAttachments: (attachments) => set((store) => {

        if (store.testimonial.text) {

            store.testimonial.text.imageAttachments = [...attachments];

        }


    },
        false,
        "useChromeExtensionStore/setAttachments"
    )
}))), {
    name: "Chrome Extension - fe",
    enabled: import.meta.env.DEV,
}));