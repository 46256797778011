import { Text } from "@components/text";
import { Settings } from "./settings/main.settings";
import { PreviewCaseStudy } from "./preview/main.preview";
import { Button } from "@components/button";
import { QueryKeys, useGetQueryData, useTestimonialsApiClient } from "@hooks";
import { useParams } from "react-router-dom";
import { useCaseStudyStore } from "@store";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { RiSparkling2Fill } from "react-icons/ri";


export function CaseStudyPage() {

    const testimonialId = useParams().id as string;

    const testimonialsQuery = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const testimonial = testimonialsQuery.testimonials.filter(item => item.id === testimonialId)[0];

    const { generateCaseStudyQuery, publishCaseStudyQuery } = useTestimonialsApiClient({});

    const queryClient = useQueryClient();

    const [publish, setPublish] = useState(false);

    useEffect(() => {

        if (testimonial) {

            const type = testimonial.type;

            if (type === "video" && testimonial.testimonial.video?.transcribed) {
                useCaseStudyStore.getState().setTestimonialContent(testimonial.testimonial.video?.transcribedText as string);
            }

            if (type === "audio" && testimonial.testimonial.audio?.transcribed) {
                useCaseStudyStore.getState().setTestimonialContent(testimonial.testimonial.audio?.transcribedText as string);
            }

            if (type === "text") {
                useCaseStudyStore.getState().setTestimonialContent(testimonial.testimonial.text?.content as string);
            }

            useCaseStudyStore.getState().setCompanyName((testimonial.customerDetails["name"]?.answer as string) ?? "");

            useCaseStudyStore.getState().setCompanyName((testimonial.customerDetails["company"]?.answer as string) ?? "");

            useCaseStudyStore.getState().setTitle((testimonial.customerDetails["title"]?.answer as string) ?? "");

        }

    }, []);

    const onGenerate = async () => {
        try {

            const store = useCaseStudyStore.getState();

            const result = await generateCaseStudyQuery.mutateAsync({
                tid: testimonialId,
                myDetails: store.myDetails,
                settings: store.settings,
                testimonialDetails: store.testimonialDetails,
            });

            const oldTestimonialsData = testimonialsQuery.testimonials;

            const updatedTestimonials = oldTestimonialsData.map(item => ({
                ...item,
                caseStudy: {
                    ...result,
                    published: false
                }
            }));

            const data: Api.Testimonials.getAll = {
                lastResponseId: testimonialsQuery.lastResponseId,
                testimonials: updatedTestimonials
            };

            queryClient.setQueryData([QueryKeys.testimonials.getAll], data);

        } catch (err) {
            console.log(err);
        }
    };

    const onPublish = async () => {
        try {

            await publishCaseStudyQuery.mutateAsync(testimonialId);

            const oldTestimonialsData = testimonialsQuery.testimonials;

            const updatedTestimonials = oldTestimonialsData.map((item: any) => ({
                ...item,
                caseStudy: {
                    ...item,
                    published: !publish
                }
            }));

            const data: Api.Testimonials.getAll = {
                lastResponseId: testimonialsQuery.lastResponseId,
                testimonials: updatedTestimonials
            };

            queryClient.setQueryData([QueryKeys.testimonials.getAll], data);

            setPublish(!publish);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="px-8 py-4 h-full w-full overflow-auto">

            <div className="flex items-center justify-between">

                <Text
                    text="AI Case Study Generator"
                    size="xl"
                    className="font-bold tracking-wider"
                />

                <div className="flex items-center space-x-4">

                    <Button
                        disabled={publishCaseStudyQuery.isPending}
                        loading={publishCaseStudyQuery.isPending}
                        variant="outline"
                        className="w-auto font-medium text-sm tracking-wide"
                        onClick={() => onPublish()}
                    >
                        {publish ? "Un publish" : "Publish"}
                    </Button>

                    <Button
                        disabled={generateCaseStudyQuery.isPending}
                        loading={generateCaseStudyQuery.isPending}
                        className="w-auto font-medium text-sm tracking-wide"
                        onClick={() => onGenerate()}
                    >

                        <RiSparkling2Fill className="w-5 h-5" />

                        <p className="ml-1">
                            {testimonial.caseStudy ? "Regenerated" : "Generate"}
                        </p>

                    </Button>

                </div>

            </div>


            <div className="h-[90%] w-full mt-4 flex items-center space-x-4">

                <Settings />

                <PreviewCaseStudy testimonial={testimonial} />

            </div>

        </div>
    );
}