import { cn } from "@utils/cn";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

type Props = {
    type: TabsTypes;
    previewImage: string;
    templateId: string;
    name: string,
    className?: string,
    imgClassName?: string,
};

export function PreviewCard({ type, previewImage, templateId, className, imgClassName }: Props) {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const onClick = () => {

        let redirect = `edit/${type}/${templateId}`;

        navigate(redirect);

    };

    return (
        <div
            key={templateId}
            className={cn(`
                border border-gray-300 bg-gray-100 rounded-md 
                w-[260px] h-[240px] relative
                flex items-center justify-center
                cursor-pointer group shadow-lg overflow-hidden
            `, className)}
            onClick={() => onClick()}
        >

            {loading ? <Skeleton className="w-full h-full absolute top-0 left-0" /> : null}

            <img
                src={previewImage}
                className={cn(`
                    group-hover:scale-[1.03] w-full rounded-lg
                    transition-all duration-300 ease-in-out 
                `, imgClassName)}
                onLoad={() => setLoading(false)}
                onError={() => setLoading(false)}
            />

        </div>
    );
}