import { Config } from "@config";
import { useRef } from "react";

export function usePlaceholderImage() {

    const ref = useRef<HTMLImageElement | null>(null);

    if (ref.current) {

        ref.current.onerror = () => {

            if (ref.current) {

                ref.current.src = Config.misc.placeHolderImage;
            }
        };

    }

    if (ref.current && !ref.current.src) {

        ref.current.src = Config.misc.placeHolderImage;

    }


    return { imgPlaceholder: ref };

}