import { Select } from "@components/select";
import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";

const options = [
    { label: "1 minute", value: 1 * 60 },
    { label: "3 minutes", value: 3 * 60 },
    { label: "5 minutes", value: 5 * 60 },
    { label: "10 minutes", value: 10 * 60 },
    { label: "15 minutes", value: 15 * 60 }
];

export function CommonSettings() {

    const settings = useFormStore((store) => store.settings);

    const selectedOption = options.filter(option => option.value === settings.maxRecordDuration)[0] ?? [];

    return (
        <div className="space-y-3">

            <Text
                text="General"
            />

            <TextToggle
                isActive={settings.autoTranscribeVideoTestimonials}
                name="Auto-transcribe video testimonials"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    autoTranscribeVideoTestimonials: !settings.autoTranscribeVideoTestimonials
                })}
            />

            <TextToggle
                isActive={settings.autoTranscribeAudioTestimonials}
                name="Auto-transcribe audio testimonials"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    autoTranscribeAudioTestimonials: !settings.autoTranscribeAudioTestimonials
                })}
            />

            <TextToggle
                isActive={settings.applyWatermarkOnVideo}
                name="Remove water mark on video"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    applyWatermarkOnVideo: !settings.applyWatermarkOnVideo
                })}
            />

            <TextToggle
                isActive={settings.autoApproveTestimonials}
                name="Auto approve testimonials"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    autoApproveTestimonials: !settings.autoApproveTestimonials
                })}
            />

            <TextToggle
                isActive={settings.disableVideoRecordingForIphone}
                name="Disable video recording on Iphone"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    disableVideoRecordingForIphone: !settings.disableVideoRecordingForIphone
                })}
            />

            <TextToggle
                isActive={settings.preferLandscapeRecordingOnMobile}
                name="Prefer landscape recording on mobile"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    preferLandscapeRecordingOnMobile: !settings.preferLandscapeRecordingOnMobile
                })}
            />

            <TextToggle
                isActive={settings.removeBranding}
                name="Remove branding"
                onClick={() => useFormStore.getState().setGeneralSettings({
                    removeBranding: !settings.removeBranding
                })}
            />

            <div className="flex items-center justify-between">

                <Text
                    variant="label"
                    text={"Maximum video recording duration"}
                    tooltip={{
                        id: "max-recording-duration",
                        tip: "Maximum video recording duration"
                    }}
                />


                <Select
                    className="w-auto text-sm mt-0"
                    defaultValue={selectedOption}
                    options={options}
                    onChange={(e: any) => useFormStore.getState().setGeneralSettings({
                        maxRecordDuration: e.value
                    })}
                />

            </div>


        </div>
    );
}