import { Text } from "@components/text";
import { useImportTestimonialStore } from "@store";
import { cn } from "@utils/cn";
import { useRef } from "react";


export function UploadVideoTestimonialField() {

    const video = useImportTestimonialStore((store) => store.testimonial.testimonial.video.file);

    const ref = useRef(null);

    const onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (!e.target.files?.length) return;

        const files = Array.from(e.target.files);

        useImportTestimonialStore.getState().setVideoTestimonial({
            file: files[0]
        });

    };

    return (
        <div className={cn("space-y-3")}>

            <Text
                text="Pick a video"
                variant="label"
            />

            <input
                id="video-testimonial"
                type="file"
                ref={ref.current}
                accept="video/webm,video/mp4"
                className="hidden"
                onChange={(e) => onSelect(e)}
            />

            <div className="flex items-center space-x-3">

                <label
                    htmlFor="video-testimonial"
                    className={cn(`
                        inline-block border-2 border-gray-300 py-1 px-4 rounded-md font-medium tracking-wide
                        transition-all duration-200 ease-in-out cursor-pointer text-sm 
                        hover:bg-blue-100 hover:text-primary hover:border-blue-500
                    `)}
                >
                    Change
                </label>

                <p className="text-sm font-normal text-gray-500 cursor-default">
                    {video ? video.name : "No file selected"}
                </p>

            </div>

        </div>
    );
}