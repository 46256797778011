import { useQueryClient } from "@tanstack/react-query";

export function useGetQueryData<T>(keys: string[]) {

    const queryClient = useQueryClient();

    const query = queryClient.getQueryData<T>([...keys]);

    return query as T;

}