import { Button } from "@components/button";
import { Text } from "@components/text";
import { ReactNode } from "react";

type Props = {
    icon: ReactNode,
    title: string,
    isLoading: boolean,
    show: boolean,
    onMigrate: () => void,
    children: ReactNode;
};

export function MigrateCard({ children, icon, isLoading, show, onMigrate, title }: Props) {
    return (
        <div className="bg-gray-100 h-full w-full max-h-full overflow-hidden flex p-4">

            <div className="bg-white shadow-lg w-[80%] h-full rounded-lg border border-gray-200">

                <div className="border-b py-4 px-6 flex items-center justify-between">

                    <div className="flex items-center space-x-2">

                        {icon}

                        <Text
                            text={title}
                            size="lg"
                            className="font-semibold tracking-wide cursor-default"
                        />

                    </div>

                    {show ?
                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            text="Migrate"
                            className="w-auto text-sm font-medium"
                            onClick={() => onMigrate()}
                        /> : null
                    }

                </div>

                <div className="px-5 py-4 space-y-4 h-[86%] overflow-y-scroll">
                    {children}
                </div>

            </div>

        </div>
    );
}