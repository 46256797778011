import { SocialProof } from "./socialProof.type";

export const SocialProofState: SocialProof.State = {
    widgetTemplate: {
        customerDetails: {
            showGravatarPhotoIfNotAvailable: false,
            showProfile: false
        },
        designId: "",
        selectedTestimonials: [],
        settings: {
            widget3_layout: {
                itemsPerRow: 3,
                numberOfRows: 1,
                showQuote: false,
                speed: 39
            },
            widget5_layout: {
                layout: "grid",
                spacing: 2,
                width: 40
            },
        },
        templateId: ""
    },
    activeTab: "selectTestimonials",
    testimonialFilter: {
        range: { from: null, to: null },
        sources: [],
        type: [],
        approved: null,
        rating: null
    },
    selectedTestimonials: [],
    imageTemplate: {
        content: {
            text: "",
            customerName: "",
            role: "",
            company: "",
            rating: 0,
            profileUrl: "",
        },
        settings: {
            showProfile: false,
            showRating: false,
        },
        background: {
            color: "",
            image: "",
            transparent: false
        },
        branding: {
            logo: ""
        },
        removeBranding: false,
        templateId: "",
    },
    wallOfLoveTemplate: {
        templateId: "",
        branding: {
            fontFamily: "",
            logo: "",
            logoSize: 50,
            primaryColor: "",
            removeBranding: false,
        },
        filter: {
            approved: null,
            rating: null,
            range: { from: null, to: null },
            sources: [],
            type: []
        },
        content: {
            columnCount: 3,
            inlineCTA: null,
            showCustomerPhoto: true,
            showDates: true,
            showGravatarPhotoIfNotAvailable: true,
            showRatings: true
        },
        hero: {
            title: "",
            subTitle: "",
            cta: {
                title: "",
                url: ""
            }
        },
        navigation: {
            hideNavBar: true,
            homeLink: ""
        }
    },
    videoTemplate: {
        templateId: "",
        background: {
            color: "",
            image: "",
        },
        showBranding: true,
        videoUrl: ""
    }
};