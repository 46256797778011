import { CgSpinner } from "react-icons/cg";

type Props = {
    text?: string,
};

export function Loading({ text, }: Props) {
    return (
        <div className="z-[100] flex items-center justify-center h-full w-full fade-in">

            <div className="flex items-center">

                <CgSpinner className="w-6 h-6 animate-spin text-primary" />

                <p className="text-sm font-medium m-2">
                    {text ?? "Fetching Your Data"}
                </p>

            </div>

        </div>
    );
}