import { RecordOrUploadVideo } from "@components/recordOrUploadVideo/upload.main";
import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";
import { useState } from "react";


export function ThankyouVideo() {

    const thankyouVideo = useFormStore((store) => store.thankYou.thankyouVideo);

    const [show, setShow] = useState(thankyouVideo ? true : false);

    const onChange = () => {

        if (show) useFormStore.getState().setThankyouVideo(null);

        setShow(!show);

    };

    return (
        <div className="space-y-2 py-1">

            <TextToggle
                isActive={show}
                name="Thankyou Video"
                onClick={() => onChange()}
            />

            {show ? <RecordOrUploadVideo type="thankyou" /> : null}

            <TextToggle
                isActive={thankyouVideo && thankyouVideo.autoPlay ? true : false}
                name="Auto Play Video"
                onClick={() => useFormStore.getState().setThankyouVideo({ autoPlay: !thankyouVideo?.autoPlay })}
            />

        </div>
    );
}