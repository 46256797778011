import { Input } from "@components/input";
import { FaRegHeart } from "react-icons/fa";


export function Widget1_Settings() {

    return (
        <div>

            <div className="flex items-center justify-between w-full px-2 py-4 cursor-pointer border-b">

                <div className="flex items-center space-x-2">

                    <FaRegHeart className="w-4 h-4 text-primary" />

                    <p className="font-medium text-sm tracking-wide">
                        Design
                    </p>

                </div>

            </div>

            <div className="space-y-4 mt-2">

                <Input
                    label="Text"
                    value={""}
                />

                <Input
                    label="External Link"
                    value={""}
                />

            </div>


        </div>
    );
}