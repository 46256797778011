import { Config } from "@config";
import { useActiveSpace } from "@hooks";
import { cn } from "@utils/cn";
import toast from "react-hot-toast";
import { MdContentCopy } from "react-icons/md";


export function WallOfLoveCard() {

    const { formId } = useActiveSpace();

    const wallOfLoveUrl = `${Config.RenderEngineUrl()}/wall-of-love/${formId}`;

    const onClick = () => {

        navigator.clipboard.writeText(wallOfLoveUrl);

        toast.success("Link copied");

    };

    return (
        <div
            className={cn(`
                flex-col items-start justify-center p-4 px-5 bg-white rounded-lg
                border border-gray-200 shadow-sm space-y-2
            `)}
        >

            <div>

                <h2 className="text-lg font-semibold tracking-wide">
                    Wall Of Love
                </h2>

                <p className="text-sm font-normal text-gray-500 tracking-wide">
                    Click to copy and paste your form link.
                </p>

            </div>


            <div className="font-normal text-base inline-flex items-center w-full pt-1">

                <p className={cn(`
                    w-full font-medium text-left bg-gray-100 border p-[6px] 
                    text-gray-600 rounded-l-lg px-2 tracking-wide
                `)}>
                    {wallOfLoveUrl.substring(0, 80)}...
                </p>

                <div
                    className={cn(`
                        bg-primary text-white px-4 py-2.5 rounded-lg rounded-l-none 
                        inline-flex space-x-2 items-center text-sm h-10 cursor-pointer
                        transition-all duration-300 ease-in-out
                        hover:bg-primary/80
                    `)}
                    onClick={() => onClick()}
                >
                    <MdContentCopy className="w-5 h-5" />
                </div>

            </div>

        </div>
    );
}