import { QueryKeys, useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { useImportTestimonialStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import toast from "react-hot-toast";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    email: z.string().email(),
    content: z.string().min(1, "Testimonial cannot be empty.")
});


export function TextImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const queryClient = useQueryClient();

    /* 
        - TODO append to testimonials preview section 
    */

    const onImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                email: testimonial.email,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId: activeSpace.formId,
                source: "gozenTestimonial"
            });

            queryClient.invalidateQueries({ queryKey: [QueryKeys.testimonials.getAll] });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import a Text Testimonial"
            onImport={() => onImport()}
        >

            <ManuallyImport
                fields={{
                    testimonial: {
                        text: {
                            content: true,
                            imageAttachments: true
                        }
                    },
                    sourceUrl: null
                }}
            />

        </ImportCard>
    );
}