import { StatsCard } from "@components/card";
import { QueryKeys, useGetQueryData } from "@hooks";

export function Stats() {

    const spaceDetailsQuery = useGetQueryData<Api.SpacesClient.getDetails>([QueryKeys.spaces.getDetails]);

    return (
        <div className="w-full mt-3">

            <div className="w-full flex items-start space-x-6">

                <StatsCard
                    title="Views"
                    isLoading={false}
                    percent={spaceDetailsQuery?.analytics?.views.percentageChangeInLastMonthDays ?? 0}
                    dir={spaceDetailsQuery?.analytics?.views?.up ? "up" : "down"}
                    number={(spaceDetailsQuery?.analytics?.views?.count ?? "").toString()}
                />

                <StatsCard
                    title="Reviews Collected"
                    isLoading={false}
                    percent={spaceDetailsQuery?.analytics?.reviewsCollected?.count ?? 0}
                    dir={spaceDetailsQuery?.analytics?.reviewsCollected?.up ? "up" : "down"}
                    number={(spaceDetailsQuery?.analytics?.reviewsCollected?.count ?? "").toString()}
                />

                <StatsCard
                    title="Response Rate"
                    isLoading={false}
                    percent={spaceDetailsQuery?.analytics?.responseRate?.percentageChangeInLastMonthDays ?? 0}
                    dir={spaceDetailsQuery?.analytics?.responseRate?.up ? "up" : "down"}
                    number={`${spaceDetailsQuery?.analytics?.responseRate?.count ?? 0}%`}
                />

                <StatsCard
                    title="Average review rating"
                    isLoading={false}
                    number={(spaceDetailsQuery?.analytics?.averageReviewRate?.count ?? 0).toFixed(1).toString()}
                    dir={spaceDetailsQuery?.analytics?.averageReviewRate?.up ? "up" : "down"}
                    percent={spaceDetailsQuery?.analytics?.averageReviewRate?.percentageChangeInLastMonthDays ?? 0}
                />

            </div>

        </div>
    );
}