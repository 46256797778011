import { Badge } from "@components/badge";
import { SecondaryButton } from "@components/button";
import { DropdownMenu } from "@components/dropDown";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdContentCopy, MdDone } from "react-icons/md";
import { useState } from "react";
import { QueryKeys, useDomainsApiClient, useInvalidateQuery } from "@hooks";
import toast from "react-hot-toast";
import { DeleteDomainPopup } from "./deleteDomain.popup";

type Props = {
    id: string,
    domain: string,
    verified: boolean,

};

export function DomainCard({ id, domain, verified }: Props) {

    const { invalidate } = useInvalidateQuery();

    const [copy, _] = useState(false);

    const { verifyQuery } = useDomainsApiClient({
        verifyParams: {
            apiParams: {
                id
            },
            params: {
                onError: (err: any) => toast.error(err),
                onSuccess: (data: Api.Domains.verify) => {

                    if (data.verified) {
                        toast.success("Domain verified");
                        return;
                    }

                    invalidate([QueryKeys.domains.verify]);

                }
            }
        }
    });

    const isLoading = verifyQuery.isLoading || verifyQuery.isFetching;

    return (
        <div className="overflow-auto p-2 fade-in">

            <div className={cn(`rounded-md px-4 py-5 my-4 bg-gray-50 flex items-center justify-between border shadow-sm`, {
                "flex-col items-start": verified === false
            })}>

                <div className="w-full flex items-center justify-between">

                    <div className={cn("flex items-center space-x-2")}>

                        <p className="text-base font-semibold tracking-wide">
                            {domain}
                        </p>

                        <Badge
                            type={verified ? "success" : "danger"}
                            text={verified ? "Verified" : "Not Verified"}
                        />

                    </div>

                    <div className="xl:w-[40%] md:w-[60%] flex items-center justify-end space-x-4">

                        <SecondaryButton
                            disabled={isLoading}
                            className="w-auto font-medium text-xs px-3 text-gray-700 tracking-wide"
                            onClick={() => verifyQuery.refetch()}
                        >

                            {isLoading ?
                                <AiOutlineLoading3Quarters className="w-4 h-4 animate-spin mr-2" /> : null
                            }

                            Refresh

                        </SecondaryButton>

                        {verified ?
                            <DropdownMenu dir="ltr">

                                <DropdownMenu.Trigger className="outline-none">

                                    <div
                                        className={cn(`
                                            p-1.5 rounded-lg cursor-pointer outline-none hover:bg-gray-50 
                                            transition-all duration-300 ease-in-out bg-gray-100 border
                                        `)}
                                    >

                                        <HiOutlineDotsVertical className="w-5 h-5" />

                                    </div>

                                </DropdownMenu.Trigger>

                                <DropdownMenu.Content
                                    side="left"
                                    sideOffset={5}
                                    align="start"
                                    className="absolute top-8 right-[-30px] w-[160px]"
                                >

                                    <DropdownMenu.Item
                                        className="hover:bg-red-100 text-red-600"
                                        onClick={() => useCommonStore.getState().setActivePopup("delete-member")}
                                    >
                                        Remove
                                    </DropdownMenu.Item>

                                </DropdownMenu.Content>

                            </DropdownMenu> : null
                        }

                    </div>

                </div>

                {verified === false ?
                    <div className="border-t border-gray-200 w-full mt-3 py-3 space-y-2">

                        <p className="text-sm font-normal">
                            Set the following record on your DNS provider to continue:
                        </p>


                        <div className="bg-black/95 text-white flex p-4 rounded-lg space-x-8 relative w-[40%]">

                            <div >

                                <div className="text-sm font-medium tracking-wide mb-2"> Type </div>

                                <div className="text-base tracking-wide"> CNAME </div>

                            </div>

                            <div>

                                <div className="font-medium mb-2"> Name </div>

                                <div className="text-sm tracking-wide"> WWW </div>

                            </div>

                            <div>

                                <div className="font-medium mb-2"> Value </div>

                                <div className="text-sm tracking-wide"> cname.vercel-dns.com. </div>

                            </div>

                            <div
                                className="absolute right-5 bottom-4 cursor-pointer"
                                onClick={() => { }}
                            >

                                {copy ?
                                    <MdDone className="w-5 h-5 mx-3 transition-all" /> :
                                    <MdContentCopy className="w-5 h-5 mx-3 transition-all" />
                                }

                            </div>

                        </div>

                    </div> : null
                }

            </div>

            <DeleteDomainPopup
                id={id}
                domain={domain}
            />

        </div>
    );
}