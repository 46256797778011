import { UploadFilePopup } from "./upload/uploadPopup";
import { useEmailInvitesApiClient } from "@hooks";
import { ListEmailInvites } from "./list/main.list";
import { NewInvites } from "./upload/main.upload";
import { Fragment, useEffect } from "react";
import { Loading } from "@components/loading";

export function InvitePage() {

    const { getAll } = useEmailInvitesApiClient({});

    useEffect(() => { getAll.refetch(); }, []);

    const invites = getAll && getAll.data && (getAll.data as any).invites ?
        (getAll.data as Api.EmailInvites.GetAll).invites ?? [] :
        [];

    return (
        <section className="px-8 py-6 bg-gray-50 h-[90%] max-h-[90%]">

            {getAll.isLoading ?
                <div className="h-[50vh]">

                    <Loading />

                </div> :
                <Fragment>

                    {(invites.length) ? <ListEmailInvites /> : <NewInvites />}

                </Fragment>
            }


            <UploadFilePopup />

        </section>
    );
}