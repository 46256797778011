
type PlanDetails = {
    monthlyPrice: number,
    yearlyPrice: number,
    isBundle: boolean,
    testimonials: number,
    maxVideoDuration: number,
    spaces: number,
    customDomain: number,
    aiCredits: number,
    users: number,
    features: string[],
};

export class BillingUtils {

    private static _maxLimit = {
        testimonial: 10000,
        spaces: 10000,
        aiCredits: 10000,
    };

    static free: PlanDetails = {
        monthlyPrice: 0,
        yearlyPrice: 0,
        isBundle: false,
        testimonials: 5,
        maxVideoDuration: 1,
        spaces: 1,
        aiCredits: 100,
        customDomain: 0,
        users: 1,
        features: [
            "5 Testimonials",
            "1 Space",
            "Ai - case study",
            "Unlimited imports",
            "Unlimited embed widgets",
            "All Analytics",
            "Spin wheel and rewards"
        ]
    };

    static basic: PlanDetails = {
        monthlyPrice: 9,
        yearlyPrice: 88,
        isBundle: false,
        testimonials: 500,
        maxVideoDuration: 1,
        spaces: 1,
        aiCredits: 100,
        customDomain: 0,
        users: 3,
        features: [
            "500 Testimonials",
            "1 Space",
            "Remove branding",
            "Google rich snippets",
            "Unlimited imports",
            "Unlimited embed widgets",
            "Spin wheel and rewards"
        ]
    };

    static essential: PlanDetails = {
        monthlyPrice: 24,
        yearlyPrice: 230,
        isBundle: false,
        testimonials: 3000,
        maxVideoDuration: 5,
        spaces: this._maxLimit.spaces,
        aiCredits: 1000,
        customDomain: 0,
        users: 5,
        features: [
            "3000 Testimonials",
            "Unlimited Spaces",
            "Remove branding",
            "Google rich snippets",
            "Unlimited imports",
            "Unlimited embed widgets",
            "Spin wheel and rewards"
        ]
    };

    static professional: PlanDetails = {
        monthlyPrice: 49,
        yearlyPrice: 470,
        isBundle: false,
        testimonials: 10000,
        maxVideoDuration: 10,
        spaces: this._maxLimit.spaces,
        aiCredits: 1000,
        customDomain: 0,
        users: 7,
        features: [
            "10,000 Testimonials",
            "Unlimited Spaces",
            "Remove branding",
            "Google rich snippets",
            "Unlimited imports",
            "Unlimited embed widgets",
            "Spin wheel and rewards"
        ]
    };

    static enterprise: PlanDetails = {
        monthlyPrice: 99,
        yearlyPrice: 950,
        isBundle: false,
        testimonials: this._maxLimit.testimonial,
        maxVideoDuration: 15,
        spaces: this._maxLimit.spaces,
        aiCredits: 1000,
        customDomain: 0,
        users: 10,
        features: [
            "Unlimited Testimonials",
            "Unlimited Spaces",
            "Remove branding",
            "Google rich snippets",
            "Unlimited imports",
            "Unlimited embed widgets",
            "Spin wheel and rewards"
        ]
    };

    public static get(plan: Api.Billing.Plans): PlanDetails {

        if (plan === "free") return this.free;
        else if (plan === "Basic") return this.basic;
        else if (plan === "Essential") return this.essential;
        else if (plan === "Professional") return this.professional;
        else if (plan === "Enterprise") return this.enterprise;
        // else if (plan === "basic-bundle") return this.basicBundle;
        // else if (plan === "essential-bundle") return this.essentialBundle;
        // else if (plan === "professional-bundle") return this.professionalBundle;
        // else if (plan === "enterprise-bundle") return this.enterpriseBundle;
        else throw new Error("invalid plan type.");
    }

    static isBundlePlan(plan: string): boolean {

        const bundlePlans = [
            "basic-bundle", "essential-bundle",
            "professional-bundle", "enterprise-bundle"
        ];

        return bundlePlans.includes(plan) ? true : false;
    }

}