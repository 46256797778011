import { useClickAway } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import Quill from "quill";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";


export type RecallParam = {
    id: string,
    label: string,
    type: "field" | "hiddenField";
};

type Props = {
    quillRef: Quill | null,
    parentRef: HTMLDivElement,
    onClose: () => void,
    onRecall: (params: RecallParam) => void;
};

type Item = {
    id: string,
    label: string;
};

export function RecallCard({ quillRef, onClose, onRecall }: Props) {

    // const { customDetails } = useFormStore();

    const [searchParams] = useSearchParams();

    const clickOutSideRef = useClickAway<HTMLDivElement>(() => onClose());

    const screen = searchParams.get("active") ?? "welcome";

    // const { styles, attributes } = usePopper(parentRef, clickOutSideRef.current,);

    const [fields] = useState<Item[]>([]);
    const [hiddenFields] = useState<Item[]>([]);

    useEffect(() => {

        onSetCardPosition();

        // const items: QuestionType[] = [];

        // customDetails.customFields.forEach(field => {

        //     field.type === "";

        // });


        //     const notAllowedFieldsForRecall = new Set([
        //         "stateMent", "fileUpload", "eSign", "payment",
        //         "pictureChoice", "thankYouScreen", "welcomeScreen"
        //     ]);

        //     const allowedField = !notAllowedFieldsForRecall.has(fieldType);


        //     if (includeField && allowedField && layout === "slides") {

        //         items.push({
        //             id: `${comp}`,
        //             label: fields[comp].question.slice(0, 20),
        //         });
        //     }

        // setQuestionItems([...items]);

    }, []);

    const onSetCardPosition = () => {

        if (!quillRef) return;

        const range = quillRef.getSelection();

        if (!range) return;

        // const currPosition = quillRef.getBounds(range.index);

        // let eleWidth = document.getElementById("edit-field")?.getBoundingClientRect().width ?? 0;

        // const newLeft = currPosition.left + 260;

        // const parentEle = parentRef.getBoundingClientRect();

        // const fieldType = FormUtils.getFieldType(fieldId);

        // setPosition({
        //     top: isIntroField ? (parentEle.top - 50) + currPosition.top : currPosition.top,
        //     left: (newLeft > (eleWidth - 80) && eleWidth !== 0) ? (currPosition.left - 200) : currPosition.left
        // });

    };

    return (
        <div
            ref={clickOutSideRef}
            className={cn(`
                w-[260px] max-h-[180px] bg-white z-[100] overflow-auto absolute 
                rounded-md recall-card-shadow py-2 fade-in
            `)}
        >

            {!fields.length && !hiddenFields.length ?
                <div className="text-sm font-medium text-center py-2 cursor-not-allowed">

                    No information to recall

                </div> : null
            }

            {hiddenFields.length ?
                <div>

                    <p className="text-xs font-medium px-3 py-1 text-font_secondary">
                        Hidden Fields
                    </p>

                    {hiddenFields.map((field, idx) => (
                        <Option
                            key={idx}
                            value={field.label}
                            onSelect={() => onRecall({ id: field.id, label: field.label, type: "hiddenField" })}
                        />
                    ))}

                </div> : null
            }

            {screen !== "welcome" && screen !== "response" && fields.length ?
                <div>

                    <p className="text-xs font-medium px-3 py-1 text-font_secondary">
                        Questions
                    </p>

                    {fields.map((field, idx) => (
                        <Option
                            key={idx}
                            value={field.label}
                            onSelect={() => onRecall({ id: field.id, label: field.label, type: "field" })}
                        />
                    ))}

                </div> : null
            }

        </div>
    );
}

type OptionProps = {
    value: string,
    onSelect: (val: string) => void,
};

export const Option: React.FC<OptionProps> = ({ value, onSelect }) => {
    return (
        <div
            onClick={() => onSelect(value)}
            className={`px-4 py-2 text-sm hover:bg-gray-200 cursor-pointer`}
        >
            {value}
        </div>
    );
};