import { SelectOrCreate, } from "@components/select";
import { Button } from "@components/button";
import { useSearchParams } from "react-router-dom";
import { useOnboardingStore } from "@store";
import { motion } from "framer-motion";

const businessTypesOptions = [
    { value: "saas", label: "SaaS" },
    { value: "creator", label: "Creator" },
    { value: "coach", label: "Coach" },
    { value: "ecommerce", label: "E-commerce" },
    { value: "freelance", label: "FreeLance" },
    { value: "consulting", label: "Consulting" },
    { value: "agency", label: "Agency" },
];

const rolesOptions = [
    { value: "founder", label: "Founder" },
    { value: "productManger", label: "Product Manager" },
    { value: "developer", label: "Developer" },
    { value: "designer", label: "Designer" },
    { value: "marketer", label: "Marketer" },
    { value: "customerSuccess", label: "Customer Success" },
    { value: "HR", label: "HR (human resource)" },
];

export function BusinessAndRoleInfo() {

    const [searchParams, setSearchParams] = useSearchParams();

    const { businessType, role, setBusinessType, setRole } = useOnboardingStore();

    const selectedBusinessType = businessTypesOptions.filter(item => item.value === businessType)[0] ?? {};
    const selectedRole = rolesOptions.filter(item => item.value === role)[0] ?? {};


    const onSubmit = (e: any) => {

        e.preventDefault();

        if (!businessType || !role) return;

        searchParams.set("screen", "action");

        setSearchParams(searchParams);

    };

    return (
        <motion.form
            onSubmit={onSubmit}
            initial={false}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3, type: "spring" }}
        >

            <div>

                <p className="text-xl font-semibold text-left tracking-wide mt-4">
                    What type of business do you run?
                </p>

                <p className="text-gray-600 text-sm font-normal mt-2">
                    We'll create a project for you to collect and share testimonials.
                </p>

            </div>

            <SelectOrCreate
                required={true}
                defaultValue={selectedBusinessType}
                options={businessTypesOptions}
                placeholder="Select an option"
                className={"text-base mt-3"}
                onChange={(e: any) => setBusinessType(e.value)}
            />

            <div className="mt-4">

                <p className="text-sm font-normal tracking-wide">
                    What's your role ?
                </p>

                <SelectOrCreate
                    required={true}
                    defaultValue={selectedRole}
                    options={rolesOptions}
                    placeholder="Select an option"
                    className={"text-base mt-2"}
                    onChange={(e: any) => setRole(e.value)}
                />

            </div>

            <Button
                type="submit"
                className="mt-4 text-lg font-medium tracking-wide"
                text="Continue"
            />

        </motion.form>
    );
};