import { cn } from "@utils/cn";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
    disable?: boolean,
    isActive: boolean;
};

export function Toggle({ className, disable = false, isActive, ...props }: Props) {
    return (
        <label className="relative inline-flex items-center cursor-pointer">

            <input
                type="checkbox"
                value=""
                className="sr-only peer"
                // checked={isActive}
                defaultChecked={isActive}
            />

            <div
                className={cn(`
                    w-11 h-6 bg-gray-300 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full
                    peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                    after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 
                    after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 
                `, className, {
                    "cursor-not-allowed peer-focus:ring-white": disable === true
                })}
                {...props}
                onClick={(e) => {

                    if (disable === false) { props.onClick?.(e); }

                }}
            />

        </label>
    );
};