import { useEmbedStore } from "@store";
import { useState } from "react";
import { EmbeddedPreview } from "../ifream";
import { cn } from "@utils/cn";

export const SideTabPreview: React.FC = () => {

    const { view, sideTab } = useEmbedStore((store) => ({
        view: store.view,
        sideTab: store.slideTab
    }));

    const { buttonColor, text, textColor } = sideTab;

    const [sideTabOpen, setSideTabOpen] = useState(false);

    return (
        <div
            className={`
                relative bg-white rounded-xl border border-border 
                overflow-hidden flex items-center justify-center
            `}
            style={{
                width: view === "desktop" ? "100%" : "400px",
                height: window.innerWidth < 1400 ? "500px" : "600px"
            }}
        >

            {sideTabOpen ?
                <div
                    className={`
                        ${view === "mobile" ? "w-[100%] h-[100%]" : "w-[350px] h-[85%] ml-auto border rounded-l-md shadow-md"}
                        overflow-hidden 
                    `}
                >

                    <EmbeddedPreview />

                </div> : null
            }

            <div
                className={cn(`
                    bg-primary text-white px-3 rounded-l-md rounded-r-md text-sm 
                    font-normal absolute py-3 cursor-pointer
                `, {
                    "hidden": view === "mobile",
                    "visible": view !== "mobile",
                    "right-[20px]": view === "desktop" && !sideTabOpen,
                    "right-[425px]": (!(view === "desktop" && !sideTabOpen)),
                    "right-[25px]": view === "mobile" && !sideTabOpen,
                    "right-0": (!(view === "mobile" && !sideTabOpen))
                })}
                style={{
                    transform: "rotate(180deg)",
                    writingMode: "vertical-lr",
                    marginRight: sideTabOpen ? "-75px" : "-22px",
                    background: buttonColor,
                    color: textColor
                }}
                onClick={() => setSideTabOpen(!sideTabOpen)}
            >

                {sideTabOpen ?
                    <p>  close </p> :
                    <p> {text ?? "Feedback"} </p>
                }

            </div>

            {/* mobile close */}
            {view === "mobile" && sideTabOpen ?
                <div
                    className="absolute top-5 right-5 px-4 py-2 bg-white rounded-md"
                    onClick={() => setSideTabOpen(!sideTabOpen)}
                >
                    close
                </div> : null
            }

        </div>
    );
}; 