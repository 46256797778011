import { AppDrawer } from './appDrawer/main';
import { Notifications } from './notification/main';
import { Profile } from './profile/profile.main';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullLogo } from '@components/icons/logo';
import { PublishForm } from './form/publishForm';
import { useSpacesApiClient } from '@hooks';
import { motion } from "framer-motion";

export function Header() {

    const location = useLocation();
    const navigate = useNavigate();

    const { getDetails } = useSpacesApiClient({});

    const path = location?.pathname ?? "";

    const formPage = path === "/form" || path === "/form/" || path === "/form/share";

    return (
        <div className="xl:h-[6%] md:h-[8%] bg-white flex items-center justify-between border-b shadow-md">

            <div
                className='w-auto mx-2 p-2 flex items-center justify-center'
                onClick={() => navigate("/")}
            >
                <FullLogo />
            </div>

            {getDetails.isFetching === false ?
                <motion.div
                    className="flex items-center justify-evenly space-x-4 mx-4"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >

                    {formPage ? <PublishForm /> : null}

                    <AppDrawer />

                    <Notifications />

                    <Profile />

                </motion.div> : null
            }

        </div>
    );
}