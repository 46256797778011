import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";


export function TextTestimonialField() {

    return (
        <div>

            <Text
                text="Text Testimonial"
                variant="label"
            />

            <QuillInput
                recall={false}
                className="bg-white"
                valueAsHTML=""
                placeHolder=""
                onChange={() => { }}
            />

        </div>
    );
}