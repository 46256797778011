import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import { LiaMobileSolid } from "react-icons/lia";
import { TbDeviceLaptop } from "react-icons/tb";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { EmbedPreview } from "@utils/embedPreview";
import { Config } from "@config";

type Props = {
    isLoading: boolean;
};

export function Preview({ isLoading }: Props) {

    const [active, setActive] = useState<"lap" | "mobile">("lap");
    const [iframeLoading, setIframeLoading] = useState(true);

    const [searchParams] = useSearchParams();
    const activeScreen = searchParams.get("active") ?? "welcome";

    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {

        window.addEventListener("message", handleMessages);

        return () => window.removeEventListener("message", handleMessages);

    }, []);

    useEffect(() => {

        const validScreens = new Set(["welcome", "response", "customerDetails", "thankyou", "reward"]);

        if (!iframeRef.current) return;

        if (activeScreen === "settings") EmbedPreview.changeScreen("welcome");

        if (validScreens.has(activeScreen)) EmbedPreview.changeScreen(activeScreen as any);

    }, [activeScreen]);

    const handleMessages = (e: MessageEvent<Messages.Types>) => {

        const data = e.data as Messages.Types;

        const renderEngineUrl = Config.RenderEngineUrl();

        if (!e.origin.startsWith(renderEngineUrl)) return;

        if (data.type === "getMinHeight") {

            iframeRef.current?.contentWindow?.postMessage({
                type: "sendDefaultScreenHeight",
                payload: { height: iframeRef.current.clientHeight }
            } as Messages.Types, "*");

        }

    };

    const onChangePreview = (type: typeof active) => {

        setActive(type);

        if (iframeRef.current) {

            iframeRef.current.contentWindow?.postMessage({
                type: "previewChange",
                payload: { type }
            } as Messages.Types, "*");

        }

    };

    return (
        <div className={cn(`w-[60%] h-full overflow-y-scroll`)}>

            <div className="flex items-center justify-center py-4">

                <div className={cn(`
                    p-[5px] rounded-md bg-gray-300 inline-flex items-center space-x-1 cursor-pointer 
                `)}>

                    <div
                        className={cn(`rounded-md transition-all duration-300 ease-in-out`, {
                            "bg-white": active === "lap"
                        })}
                        onClick={() => onChangePreview("lap")}
                    >

                        <TbDeviceLaptop className="w-6 h-6 m-1" />

                    </div>

                    <div
                        className={cn(`rounded-md transition-all duration-300 ease-in-out`, {
                            "bg-white": active === "mobile"
                        })}
                        onClick={() => onChangePreview("mobile")}
                    >
                        <LiaMobileSolid className="w-6 h-6 m-1" />

                    </div>

                </div>

            </div>

            <div className={cn(`flex flex-col items-center h-full`)}>

                <div className={cn(`
                    bg-white rounded-2xl border-2 border-gray-700
                    transition-all duration-500 ease-in-out shadow-lg relative
                `, {
                    "w-[95%] h-[80%]": active === "lap",
                    "w-[330px] h-[600px] pb-10 mb-5": active === "mobile"
                })}>

                    {isLoading || iframeLoading ?
                        <Skeleton
                            className="w-full mt-0 rounded-2xl p-0 h-full"
                            style={{
                                borderRadius: "16px"
                            }}
                        /> : null
                    }

                    <iframe
                        ref={iframeRef}
                        id="testimonial-preview"
                        className={cn("w-full h-full rounded-2xl", {
                            "invisible": iframeLoading === false,
                            "visible absolute top-0": iframeLoading
                        })}
                        src={`${Config.RenderEngineUrl()}/form/preview`}
                        onLoadedData={() => setIframeLoading(false)}
                    />

                </div>

            </div>

        </div>
    );
}