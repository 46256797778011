import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { QueryKeys, useActiveSpace, useGetQueryData, useSpacesApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export function DeleteSpacePopup() {

    const queryClient = useQueryClient();

    const { id, name } = useActiveSpace();

    const show = useCommonStore((store) => store.activePopup === "delete-space");

    const { deleteQuery } = useSpacesApiClient({});
    const { getDetails } = useSpacesApiClient({});
    const getAllSpacesQuery = useGetQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const { isPending } = deleteQuery;

    const onDelete = async () => {

        const allSpaces = getAllSpacesQuery.spaces;

        if (allSpaces.length === 1) {
            toast.error("Sorry, your cannot delete this space.");
            return;
        }

        await deleteQuery.mutateAsync(id);

        if (deleteQuery.isError) {
            toast.error(deleteQuery.error.message);
            return;
        }

        const newSpaces = allSpaces.filter(space => space.id !== id);

        const newActiveSpace = newSpaces[0];

        const updatedData: Api.SpacesClient.getAll = {
            ...getAllSpacesQuery,
            spaces: newSpaces,
            activeSpace: newActiveSpace
        };

        queryClient.setQueryData([QueryKeys.spaces.getAll], updatedData);

        // queryClient.invalidateQueries({ queryKey: [QueryKeys.spaces.getDetails] });

        getDetails.refetch();

        useCommonStore.getState().setActivePopup("");

    };

    const onClose = () => {
        if (isPending) return;
        useCommonStore.getState().setActivePopup("");
    };

    if (show === false) return null;

    return (
        <Popup
            name="Delete Space"
            width="35%"
            onClose={() => onClose()}
        >

            <p className="text-sm font-normal my-2 cursor-default tracking-wide">
                Your about to delete <b className="font-semibold"> {name} </b> space.
                This action is irreversible. By deleting this space all your testimonials will be deleted.
                <br /> <br />
                Are you sure you want to delete it.
            </p>

            <div className="flex items-center justify-end space-x-2">

                <SecondaryButton
                    disabled={isPending}
                    className="text-sm font-medium w-auto"
                    onClick={() => useCommonStore.getState().setActivePopup("")}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    disabled={isPending}
                    loading={isPending}
                    variant="danger"
                    text="Delete"
                    className="w-auto text-sm font-medium tracking-wide"
                    onClick={() => onDelete()}
                />

            </div>

        </Popup >
    );
}