import { Text } from "@components/text";
import { cn } from "@utils/cn";
// import Template1 from "@assets/test/t1.png";
// import Template2 from "@assets/test/t2.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const templates: string[] = [
    // Template1,
    // Template2,
];

export function RecentTemplates() {

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const tid = searchParams.get("id") ?? "";

    // const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [empty, setEmpty] = useState(false);

    useEffect(() => {

        // const testimonial = testimonialsReq.testimonials.filter(item => item.id === tid)[0];

        setEmpty(true);


    }, []);

    return (
        <div className="space-y-3">

            <Text
                variant="primary"
                size="md"
                className="font-medium tracking-wide"
                text="Recently created templates"
            />

            {empty ?
                <div className={cn(`
                    text-sm font-normal tracking-wide text-gray-600 rounded-lg text-center
                    border border-dashed border-gray-400 px-2 py-4 mt-2 h-[100px] w-full
                    flex items-center justify-center bg-white shadow-sm
                `)}>

                    <p className="text-gray-500 tracking-wide font-medium">
                        The image templates that you create <br /> will be displayed in this section.
                    </p>

                </div> :
                <div className="flex items-center space-x-3 mt-2">

                    {templates.map((template, idx) => (
                        <img
                            key={idx}
                            src={template}
                            className={cn(`
                                w-36 rounded-md cursor-pointer 
                                border-2 hover:ring-blue-200
                                transition-all duration-300 ease-in-out
                            `)}
                            onClick={() => navigate(`/social-proof/edit/image/${tid}`)}
                        />
                    ))}

                </div>
            }


        </div>
    );
}