import { Button } from "@components/button";
import { GoogleAuthIcon } from "@components/icons/integration/google.icon";
import { Select } from "@components/select";
import { Text } from "@components/text";
import { QueryKeys, useGetQueryData, useIntegrationsApiClient } from "@hooks";
import { useIntegrationsStore } from "@store";
import { cn } from "@utils/cn";
import { useState } from "react";


export function AuthScreen() {

    const { googleSheet } = useIntegrationsApiClient();

    const getAllIntegrationsQuery = useGetQueryData<Api.Integrations.getAll>([QueryKeys.integrations.getAll]);

    const integrations = (getAllIntegrationsQuery?.integrations ?? {});

    const googleSheetIntegrations = (integrations["googleSheet"] ?? []);

    const options = googleSheetIntegrations.map(item => ({ value: item.id, label: item.name }));

    const [createNewIntegration, setCreateNewIntegration] = useState(false);

    const onAuth = async () => {

        await googleSheet.getOAuthUrl.refetch();

        const authUrl = googleSheet.getOAuthUrl.data?.url;

        if (!authUrl && googleSheet.getOAuthUrl.isError) {

            console.log("error....");

            return;
        }

        if (authUrl) {

            const width = 600;
            const height = 600;

            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;

            const newWin = window.open(
                authUrl,
                "google oauth",
                `
                    width=${width}, 
                    height=${height}, 
                    top=${top}, 
                    left=${left}
                `
            );

            newWin?.focus();

        }

    };

    return (
        <div>

            {googleSheetIntegrations.length || createNewIntegration ?
                <div>

                    <div className="space-y-2 border-y py-2">

                        <Text
                            text="Integration for Google sheet already exist for this account."
                        />

                        <div className="mt-4">

                            <Text
                                text="Would you like to connect your existing account?"
                                className="text-sm font-normal"
                            />

                            <Select
                                className="mt-1"
                                options={options}
                                onChange={(option: any) => useIntegrationsStore.getState().setUseExistingIntegrationId(option.value)}
                            />

                            <p className="my-1 text-sm text-gray-600 mt-1 px-1">

                                Or connect new account

                                <u
                                    onClick={() => setCreateNewIntegration(true)}
                                    className="text-primary cursor-pointer pl-1"
                                >
                                    click
                                </u>

                            </p>

                        </div>

                    </div>

                    <div className="flex items-center justify-end my-2">

                        <Button
                            text="Create"
                            className="w-auto text-sm"
                            onClick={() => useIntegrationsStore.getState().setGoogleSheetActiveScreen("connect")}
                        />

                    </div>

                </div> :
                <div>

                    <div className="my-2 border-y py-4">

                        <Text
                            text="Click the auth button below to link your google sheet account."
                            className="text-sm font-normal"
                        />

                    </div>

                    <div className="flex items-center justify-end">

                        <button
                            disabled={googleSheet.getOAuthUrl.isLoading}
                            className={cn(`
                                rounded-md flex items-center bg-primary text-white
                                transition-all duration-300 ease-in-out 
                                focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-blue-300 
                                hover:ring-2 hover:ring-offset-1 hover:ring-blue-400 hover:bg-blue-700    
                            `)}
                            onClick={() => onAuth()}
                        >

                            <GoogleAuthIcon />

                            <p
                                style={{
                                    fontFamily: "font-family: 'Roboto', sans-serif",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    marginLeft: "24dp"
                                }}
                                className="pl-3 pr-4"
                            >
                                Sign in With Google
                            </p>

                        </button>

                    </div>

                </div>
            }

        </div>
    );
}