import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { QueryKeys, useDomainsApiClient, useInvalidateQuery } from "@hooks";
import { useCommonStore } from "@store";
import toast from "react-hot-toast";

type Props = {
    id: string,
    domain: string;
};

export function DeleteDomainPopup({ id, domain }: Props) {

    const { removeQuery } = useDomainsApiClient({});

    const { invalidate } = useInvalidateQuery();

    const onDelete = async () => {

        await removeQuery.mutateAsync(id);

        invalidate([QueryKeys.domains.get]);

        toast.success("Domain removed successfully");

    };

    return (
        <Popup
            width="35%"
            name="Remove Domain"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <div className="text-sm font-normal mt-4 space-y-2 text-gray-800 tracking-wide">

                <p>
                    You are about to remove <span className="font-semibold text-black"> {domain} </span>.
                    In order to regain access at a later time.
                </p>

                <p>
                    Are you sure you want to continue?
                </p>

            </div>

            <div className="mt-5 flex items-center justify-end space-x-4">

                <SecondaryButton
                    className="text-sm font-medium w-auto"
                    onClick={() => useCommonStore.getState().setActivePopup("")}
                >
                    Cancel
                </SecondaryButton>

                <Button
                    variant="danger"
                    className="text-sm font-medium w-auto"
                    onClick={() => onDelete()}
                >
                    Confirm
                </Button>

            </div>

        </Popup>
    );
}