import { Button, SecondaryButton } from "@components/button";
import { QueryKeys, useGetQueryData, useGetQueryStatus, useTestimonialsApiClient } from "@hooks";
import { useEffect } from "react";
import { Text } from "@components/text";
import { OtherFilters } from "./otherFilter";
import { DateRangeFilter } from "./dateFilter";
import { ListTestimonials } from "./list/main.list";
import { useSocialProofStore } from "@store";
import { cn } from "@utils/cn";


export function SelectTestimonials() {

    const selectedTestimonials = useSocialProofStore((store) => store.selectedTestimonials);

    const { isLoading } = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const query = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const { getAll } = useTestimonialsApiClient({});

    useEffect(() => {

        if (!query || !query?.testimonials) getAll.refetch();

    }, []);

    const onRefresh = () => {

        getAll.refetch();


    };

    const onApply = () => {

        if (isLoading) return;

        useSocialProofStore.getState().setActiveImageTemplateTab("template");

    };

    const onClear = () => {

        useSocialProofStore.getState().setFilter({
            approved: null,
            range: { from: null, to: null },
            rating: null,
            sources: [],
            type: []
        });

        /* invalidate */

    };

    return (
        <div className="w-full h-full py-4 px-6">

            <div className="flex items-center justify-between">

                <Text
                    text="Select Testimonials"
                    size="lg"
                    className="font-medium"
                />

                <div className="flex items-centers space-x-4">

                    <SecondaryButton
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-sm tracking-wider font-medium px-3 py-2 w-auto"
                        text="Clear"
                        onClick={() => onClear()}
                    />

                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-sm tracking-wider font-medium px-3 py-2 w-auto"
                        text="Save"
                        onClick={() => onApply()}
                    />

                </div>


            </div>

            <div className="flex items-center space-x-4 py-2">

                <DateRangeFilter refresh={() => onRefresh()} />

                <OtherFilters refresh={() => onRefresh()} />

                {selectedTestimonials.length ?
                    <div className={cn(`
                        inline-flex space-x-2 text-xs font-medium bg-blue-100 w-auto py-2 px-4 
                        rounded-xl text-blue-950 cursor-default
                    `)}>

                        <span className="font-bold">
                            {selectedTestimonials.length}
                        </span>

                        <p className="tracking-wide">
                            Testimonial Selected
                        </p>

                    </div> : null
                }

            </div>

            <ListTestimonials />

        </div>
    );
}