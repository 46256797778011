import { Button } from "@components/button";
import { EmptyIcon } from "@components/icons";
import { useCommonStore } from "@store";
import { MdOutlineFileUpload } from "react-icons/md";


export function NewInvites() {

    return (
        <div className="min-h-full w-full flex flex-col items-center justify-center">

            <EmptyIcon />

            <div className="text-center mt-2 space-y-1">

                <p className="text-lg font-semibold tracking-wide">
                    Email Invites
                </p>

                <p className="text-sm font-medium tracking-wide text-gray-600">
                    Invite your customers to leave you a testimonial and track their progress.
                </p>

            </div>


            <Button
                className="w-auto text-sm font-medium mt-4"
                onClick={() => useCommonStore.getState().setActivePopup("invite-emails")}
            >

                <MdOutlineFileUpload className="w-6 h-6" />

                <p className="text-sm font-medium tracking-wide mx-2">
                    Upload File
                </p>

            </Button>

        </div>
    );
}