import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { CustomQueryParams } from "../useCustomQuery";
import { ParseAxiosError } from "@lib/axios";
import { useActiveSpace } from "../useActiveSpace";

type Params = {
    getAll?: {
        apiParams: {
            fid: string;
        },
        params: Partial<CustomQueryParams>,
    };
};

export function useSocialProofApiClient({ getAll }: Params) {

    const { formId } = useActiveSpace();

    const getAllQuery = useQuery({
        enabled: true,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: [QueryKeys.socialProof.getAll],
        queryFn: async () => {
            try {

                return (await axios.get<Api.SocialProof.getAll>("/api/v1/social-proof", {
                    params: {
                        fid: formId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...getAll?.params
    });

    const create = useQuery({
        queryKey: [QueryKeys.socialProof.created],
        queryFn: async () => {
            try {

                return (await axios.post("/api/v1/social-proof")).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.socialProof.delete],
        mutationFn: async (id: string) => {
            try {

                return (await axios.delete(`/api/v1/social-proof/${id}`)).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        getAll: getAllQuery,
        create,
        delete: deleteQuery
    };

}