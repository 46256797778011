import { useSearchParams } from "react-router-dom";
import { RecentTemplates } from "./recent";
import { ImageTemplates } from "./templates";
import { VideoTemplates } from "./videoTemplates";
import { QueryKeys, useGetQueryData } from "@hooks";
import { useEffect, useState } from "react";


export function SocialProof() {

    const [searchParams] = useSearchParams();

    const tid = searchParams.get("id") ?? "";

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const [type, setType] = useState<"image" | "video" | "audio" | "">("");

    useEffect(() => {

        const testimonial = testimonialsReq.testimonials.filter(item => item.id === tid)[0];

        if (testimonial) setType(testimonial.type as any);


    }, []);

    return (
        <div className="space-y-4 h-full max-h-full overflow-y-scroll">

            <RecentTemplates />

            {type === "video" ? <VideoTemplates /> : <ImageTemplates />}

        </div>
    );
}