import { Input } from "@components/input";
import { Text } from "@components/text";
import { useFormStore } from "@store";
import { DefaultLabels } from "./defaultLabels";


export function ResponsePageLabels() {

    const {
        selectMicrophone, selectCamera, recorderErrorMessage, responseSubmitBtn,
        textTestimonialPlaceholder
    } = useFormStore((store) => ({
        selectMicrophone: store.customLabels?.selectMicrophone,
        selectCamera: store.customLabels?.selectCamera,
        recorderErrorMessage: store.customLabels?.recorderErrorMessage,
        responseSubmitBtn: store.customLabels?.responseSubmitBtn,
        textTestimonialPlaceholder: store.customLabels?.textTestimonialPlaceholder
    }));

    return (
        <div className="space-y-2 pb-4 border-b">

            <Text text="Response" />

            <Input
                type="text"
                label="Select Microphone"
                placeholder="Enter new label"
                className="h-auto"
                value={selectMicrophone ? selectMicrophone : DefaultLabels?.selectMicrophone}
                onChange={(e) => useFormStore.getState().setCustomLabel("selectMicrophone", e.target.value)}
            />

            <Input
                type="text"
                label="Select Camera"
                placeholder="Enter new label"
                className="h-auto"
                value={selectCamera ? selectCamera : DefaultLabels?.selectCamera}
                onChange={(e) => useFormStore.getState().setCustomLabel("selectCamera", e.target.value)}
            />

            <Input
                type="text"
                label="Recorder Error Message"
                placeholder="Enter new label"
                className="h-auto"
                value={recorderErrorMessage ? recorderErrorMessage : DefaultLabels?.recorderErrorMessage}
                onChange={(e) => useFormStore.getState().setCustomLabel("recorderErrorMessage", e.target.value)}
            />

            <Input
                type="text"
                label="Response Submit Button"
                placeholder="Enter new label"
                className="h-auto"
                value={responseSubmitBtn ? responseSubmitBtn : DefaultLabels?.responseSubmitBtn}
                onChange={(e) => useFormStore.getState().setCustomLabel("responseSubmitBtn", e.target.value)}
            />

            <Input
                type="text"
                label="Text Testimonial Placeholder"
                placeholder="Enter new label"
                className="h-auto"
                value={textTestimonialPlaceholder ? textTestimonialPlaceholder : DefaultLabels?.textTestimonialPlaceholder}
                onChange={(e) => useFormStore.getState().setCustomLabel("textTestimonialPlaceholder", e.target.value)}
            />

        </div>
    );
}