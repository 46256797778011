import { Input } from "@components/input";


export function Widget3_LayoutSettings() {

    return (
        <div className="space-y-4">

            <Input
                label="Number of Rows"
                type="number"
                value={2}
                onAbort={() => { }}
            />

            <Input
                label="Scroll Speed"
                type="number"
                value={30}
                onAbort={() => { }}
            />

            <Input
                label="Items Per Row"
                type="number"
                value={2}
                onAbort={() => { }}
            />

        </div>
    );
}