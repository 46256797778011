import { cn } from "@utils/cn";
import { PurifyHTML } from "@utils/purifyHTML";

type Props = {
    content: string,
    imageAttachments: string[];
};

export function TextTestimonial({ content, imageAttachments }: Props) {
    return (
        <div className="space-y-2 mx-2">

            <p
                className="text-sm font-normal tracking-wide"
                dangerouslySetInnerHTML={{ __html: PurifyHTML(content) }}
            />

            {imageAttachments && imageAttachments.length ?
                <div className="mt-4">

                    {imageAttachments.map((image, idx) => {
                        return (
                            <img
                                key={idx}
                                src={image}
                                className={cn("rounded-lg w-auto h-auto border border-gray-200", {
                                    "w-auto": imageAttachments.length !== 1
                                })}
                            />
                        );
                    })}

                </div> : null
            }

        </div>
    );
}