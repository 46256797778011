import { useEffect, useRef, useState } from "react";

export function useLatestState<T>(initState: T) {

    const [state, setState] = useState<T>(initState);

    const ref = useRef<T>(state);

    useEffect(() => {

        ref.current = state;

    }, [state]);

    return [state, setState, ref] as const;

}