import { cn } from "@utils/cn";
import { ReactNode } from "react";


type Props = {
    active: string,
    tabs: {
        icon?: ReactNode,
        name: string,
        value: string;
    }[],
    className?: string,
    onChange: (value: string) => void;
};

export function Tab({ active, tabs, className, onChange }: Props) {
    return (
        <div className={cn("text-sm font-medium text-center text-gray-500 border-b border-gray-200", className)} >

            <ul className="flex flex-wrap -mb-px">

                {tabs.map((tab, idx) => (
                    <li
                        key={idx}
                        className={cn(`
                            me-2 p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 
                            hover:border-gray-300 cursor-pointer transition-all duration-300 ease-in-out
                            inline-flex items-center
                        `, {
                            "text-blue-600 border-b-2 border-blue-600 hover:border-blue-300 hover:text-blue-600": active === tab.value
                        })}
                        onClick={() => onChange(tab.value)}
                    >
                        {tab.icon ? tab.icon : null}{tab.name}
                    </li>
                ))}

            </ul>

        </ div>
    );
};