import { useClickOutside } from "@hooks";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";

type Props = {
    name?: string,
    width?: string,
    children: ReactNode,
    onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export function Popup({ name, width, children, onClose }: Props) {

    const activePopup = useCommonStore((store) => store.activePopup);

    const ref = useClickOutside(() => { useCommonStore.getState().setActivePopup(""); });

    return createPortal(
        <AnimatePresence initial={false}>

            {activePopup ?
                <motion.div
                    className={cn(`
                        absolute top-0 left-0 w-screen h-screen overflow-hidden flex items-center justify-center
                        transition-colors duration-100 z-[1000]
                    `)}
                    initial={{
                        backgroundColor: "rgb(55, 65, 81)"
                    }}
                    animate={{
                        backgroundColor: "rgb(55, 65, 81, 0.5)",
                    }}
                >

                    <motion.div
                        ref={ref}
                        id="popup-main"
                        className={cn(`z-[2000] bg-white shadow-md rounded-md p-4`)}
                        style={{ width }}
                        initial={{
                            opacity: 0,
                            scale: 0,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            height: "auto"
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0,
                        }}
                        transition={{
                            delay: 0.1,
                            duration: 0.3,
                        }}
                    >

                        <div
                            className="flex items-center justify-between"
                        >

                            {name ?
                                <h1 className="text-base font-semibold tracking-wider">
                                    {name}
                                </h1> : null
                            }

                            <div
                                className="cursor-pointer rounded-md p-1 transition-colors duration-200 hover:bg-gray-300"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClose(e);
                                }}
                            >

                                <MdClose className="w-6 h-6" />

                            </div>

                        </div>

                        {children}

                    </motion.div>

                </motion.div> : null
            }

        </AnimatePresence>,
        document.getElementById("popup") as HTMLDivElement
    );
}