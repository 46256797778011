import { useEffect, useState } from "react";

export function useDetectPageReload() {

    const [reloaded, setReloaded] = useState(false);

    useEffect(() => {

        const onLoad = () => {

            if (sessionStorage.getItem("reloaded") == 'true') {
                sessionStorage.setItem("reloaded", "false");
                setReloaded(true);
            } else {
                setReloaded(false);
            }

        };

        const onUnload = () => sessionStorage.setItem('reloaded', 'true');

        const onVisibilityChange = () => {

            if (document.hidden) {
                onUnload();
            } else {
                onLoad();
            }

        };

        window.addEventListener("load", onLoad);

        window.addEventListener("beforeunload", onUnload);

        document.addEventListener("visibilitychange", onVisibilityChange);

        return () => {
            window.removeEventListener("beforeunload", onUnload);
            window.removeEventListener("load", onLoad);
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };

    }, []);

    return { hasReloaded: reloaded };
};