import { UndefinedInitialDataOptions, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

export type CustomQueryParams = UndefinedInitialDataOptions & {
    onError?: (err: any) => void,
    onSuccess?: (data: any) => void;
};

export function useCustomQuery(params: CustomQueryParams) {

    const { error, isLoading, isPending, isFetching, data, ...props } = useQuery({ ...params });

    useEffect(() => {

        if (error && params.onError) params?.onError?.(error);

        if (data && params.onSuccess) params?.onSuccess?.(data);

    }, [isLoading, isPending, isFetching]);

    return { ...props, error, data, isLoading, isFetching };

}