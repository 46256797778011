
export const ImportTestimonialState: ImportTestimonial.State = {
    source: "text",
    error: {},
    testimonial: {
        avatar: null,
        sourceUrl: "",
        tagline: "",
        company: "",
        companyUrl: "",
        companyLogo: null,
        date: new Date().toISOString(),
        email: "",
        name: "",
        rating: 0,
        role: "",
        testimonial: {
            audio: {
                transcribedText: "",
                file: null
            },
            text: {
                content: "",
                imageAttachments: []
            },
            video: {
                transcribedText: "",
                file: null
            }
        },
        type: "text",
    }
}; 