import { Button } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { Config } from "@config";
import { useCommonStore } from "@store";
import { useParams } from "react-router-dom";
import { FaRegCopy } from "react-icons/fa6";
import toast from "react-hot-toast";
import { Text } from "@components/text";


export function CaseStudyPublishedPopup() {

    const testimonialId = useParams().id as string;

    const show = useCommonStore((store) => store.activePopup === "published-case-study");

    const caseStudyUrl = Config.RenderEngineUrl() + `/case-study/${testimonialId}`;

    if (!show) return null;

    return (
        <Popup
            name="Case Study Published"
            onClose={() => useCommonStore.getState().setActivePopup("")}
            width="30%"
        >

            <Text
                size="sm"
                variant="label"
            >
                Your case study has been published here. Use this link to share your case study with others.
            </Text>

            <Input
                readOnly={true}
                value={caseStudyUrl}
                className="mt-2"
            />

            <Button
                className="text-sm font-medium mt-4"
                onClick={() => {
                    navigator.clipboard.writeText(caseStudyUrl);
                    toast.success("Copied");
                    useCommonStore.getState().setActivePopup("");
                }}
            >

                <FaRegCopy className="w-4 h-4" />

                <p className="ml-1">
                    Copy
                </p>

            </Button>

        </Popup>
    );
}