import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";


export function RoleField() {

    const role = useImportTestimonialStore((store) => store.testimonial.role);

    return (
        <Input
            type="text"
            placeholder="CEO"
            label="Role"
            mainClassName="w-full"
            value={role}
            onChange={(e) => useImportTestimonialStore.getState().setRole(e.target.value)}
        />
    );
}