import { Tab } from "@components/tab";
import { MdOutlineAccountCircle } from "react-icons/md";
import { PiLinkBold } from "react-icons/pi";
import { MdCreditCard } from "react-icons/md";
import { useState } from "react";
import { Account } from "./account/main.account";
import { Billings } from "./billings/main.billings";
import { motion } from "framer-motion";
import { Domain } from "./domain/main.domain";

const tabs = [
    {
        icon: <MdOutlineAccountCircle className="w-5 h-5 mx-2" />,
        name: "Account",
        value: "account",
    },
    {
        icon: <PiLinkBold className="w-5 h-5 mx-2" />,
        name: "Domain",
        value: "domain",
    },
    {
        icon: <MdCreditCard className="w-5 h-5 mx-2" />,
        name: "Billings",
        value: "billings",
    }
];


export function SettingsPage() {

    const [active, setActive] = useState("account");

    return (
        <motion.div
            className="w-full h-full px-6 py-4 overflow-y-auto"
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
        >

            <div className="p-2 border border-gray-200 bg-white rounded-xl">

                <Tab
                    active={active}
                    tabs={tabs}
                    onChange={(e) => setActive(e)}
                />

                <div>

                    {active === "account" ? <Account /> : null}

                    {active === "domain" ? <Domain /> : null}

                    {active === "billings" ? <Billings /> : null}

                </div>

            </div>

        </motion.div>
    );
}