import { cn } from "@utils/cn";


interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    error: string;
}

export function InputError({ className, error, ...props }: Props) {
    return (
        <span
            {...props}
            className={cn(`
                text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 
                text-sm font-normal text-left
            `, className)}
        >

            * {error}

        </span >
    );
}