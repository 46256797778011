import { Button } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { useIntegrationsApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { useState } from "react";
import toast from "react-hot-toast";
import { MdOutlineRemoveRedEye } from "react-icons/md";

export function ZapierIntegrationPopup() {

    const [apiKey, setApiKey] = useState("");
    const [show, setShow] = useState(false);

    const { getZapierApiKeyQuery } = useIntegrationsApiClient();

    const onShow = async () => {
        try {

            const data = await getZapierApiKeyQuery.mutateAsync();

            if (data) setApiKey(data.key);

            if (data && !show) {

                window.navigator.clipboard.writeText(data?.key);

                toast.success("Copied...");

            }

            setShow(!show);


        } catch (err) {
            console.log(err);
        }
    };


    console.log(apiKey);

    return (
        <Popup
            width="35%"
            name="Zapier App"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <div>

                <div className="border-y py-4 mt-2 flex items-end">

                    <Input
                        disabled={true}
                        readOnly={true}
                        type={show ? "text" : "password"}
                        value={apiKey.length ? apiKey : "12345678901234567890"}
                        label="Your API Key"
                        mainClassName="space-y-1 w-[90%]"
                        className="rounded-none rounded-l-lg font-semibold tracking-wider"
                    />

                    <Button
                        loading={getZapierApiKeyQuery.isPending}
                        disabled={getZapierApiKeyQuery.isPending}
                        className="w-auto rounded-none rounded-r-lg"
                        onClick={() => onShow()}
                    >

                        <MdOutlineRemoveRedEye className="text-white w-6 h-6" />

                    </Button>

                </div>

                <div className="flex justify-end py-2 pt-3">

                    <Button
                        text="Create Zap"
                        className="w-auto text-sm font-medium"
                        onClick={() => window.open("https://zapier.com/apps/gozen-testimonial/integrations", "_blank")}
                    />

                </div>

            </div>

        </Popup>
    );
}