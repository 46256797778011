/* widget */
import rating from "@assets/socialProofPreview/widgets/rating.webp";
import hero from "@assets/socialProofPreview/widgets/hero.webp";
import highLights from "@assets/socialProofPreview/widgets/highlights.webp";
import list from "@assets/socialProofPreview/widgets/list.webp";
import logos from "@assets/socialProofPreview/widgets/logos.webp";
// import popups from "@assets/socialProofPreview/widgets/popup.webp";
import profileCarousal from "@assets/socialProofPreview/widgets/profile-carousel.webp";
import profileGrid from "@assets/socialProofPreview/widgets/profileCard.webp";
import richSnippet from "@assets/socialProofPreview/widgets/rich-snippets.png";


/* photo */
import photoPreview1 from "@assets/socialProofPreview/images/t1.png";
import photoPreview2 from "@assets/socialProofPreview/images/t2.png";
import photoPreview3 from "@assets/socialProofPreview/images/t3.png";
import photoPreview4 from "@assets/socialProofPreview/images/t4.png";
import photoPreview5 from "@assets/socialProofPreview/images/t5.png";
import photoPreview6 from "@assets/socialProofPreview/images/t6.png";
import photoPreview7 from "@assets/socialProofPreview/images/t7.png";
import photoPreview8 from "@assets/socialProofPreview/images/t8.png";
import photoPreview9 from "@assets/socialProofPreview/images/t9.png";


/* video */
import videoPreview1 from "@assets/socialProofPreview/video/v1.png";
import videoPreview2 from "@assets/socialProofPreview/video/v2.png";
// import videoPreview3 from "@assets/socialProofPreview/video/v3.png";
// import videoPreview4 from "@assets/socialProofPreview/video/v4.png";


/* wall of love */
// import wol1 from "@assets/socialProofPreview/wol/w1.png";
// import wol2 from "@assets/socialProofPreview/wol/w2.png";
import wol3 from "@assets/socialProofPreview/wol/w3.png";
// import wol4 from "@assets/socialProofPreview/wol/w4.png";
import { Config } from "@config";

export const ImageTemplates: WidgetType[] = [
    {
        templateId: "i1",
        type: "image",
        name: "photo1",
        previewImage: photoPreview1,
    },
    {
        templateId: "i2",
        type: "image",
        name: "photo2",
        previewImage: photoPreview2,
    },
    {
        templateId: "i3",
        type: "image",
        name: "photo3",
        previewImage: photoPreview3,
    },
    {
        templateId: "i4",
        type: "image",
        name: "photo4",
        previewImage: photoPreview4,
    },
    {
        templateId: "i5",
        type: "image",
        name: "photo5",
        previewImage: photoPreview5,
    },
    {
        templateId: "i6",
        type: "image",
        name: "photo6",
        previewImage: photoPreview6,
    },
    {
        templateId: "i7",
        type: "image",
        name: "photo7",
        previewImage: photoPreview7,
    },
    {
        templateId: "i8",
        type: "image",
        name: "photo8",
        previewImage: photoPreview8,
    },
    {
        templateId: "i9",
        type: "image",
        name: "photo9",
        previewImage: photoPreview9,
    }
];

export const VideoTemplates: WidgetType[] = [
    {
        templateId: "v1",
        type: "video",
        name: "video1",
        previewImage: videoPreview1,
    },
    {
        templateId: "v2",
        type: "video",
        name: "video2",
        previewImage: videoPreview2,
    },
    // {
    //     templateId: "v3",
    //     type: "video",
    //     name: "video3",
    //     previewImage: videoPreview3,
    //     videoType: "reel"
    // },
    // {
    //     templateId: "v4",
    //     type: "video",
    //     name: "video4",
    //     previewImage: videoPreview4,
    // }
];

export const WidgetTemplates: WidgetType[] = [
    {
        templateId: "w1",
        type: "widget",
        name: "rating",
        previewImage: rating,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w1"
    },
    {
        templateId: "w2",
        type: "widget",
        name: "hero",
        previewImage: hero,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w2"

    },
    {
        templateId: "w3",
        type: "widget",
        name: "highlights",
        previewImage: highLights,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w3"
    },
    {
        templateId: "w4",
        type: "widget",
        name: "list",
        previewImage: list,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w4"
    },
    {
        templateId: "w5",
        type: "widget",
        name: "logos",
        previewImage: logos,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w5"
    },
    // {
    //     templateId: "w6",
    //     type: "widget",
    //     name: "popups",
    //     previewImage: popups,
    //     previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w6"

    // },
    {
        templateId: "w7",
        type: "widget",
        name: "profileCarousal",
        previewImage: profileCarousal,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w7"
    },
    {
        templateId: "w8",
        type: "widget",
        name: "profileGrid",
        previewImage: profileGrid,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w8"
    },
    {
        templateId: "w9",
        type: "widget",
        name: "richSnippets",
        previewImage: richSnippet,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "w9"
    },
];

export const WallOfLoveTemplates: WidgetType[] = [
    // {
    //     templateId: "wol1",
    //     type: "wall-of-love",
    //     name: "wol1",
    //     previewImage: wol1,
    //     previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "wol1"
    // },
    // {
    //     templateId: "wol2",
    //     type: "wall-of-love",
    //     name: "wol2",
    //     previewImage: wol2,
    //     previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "wol2"
    // },
    {
        type: "wall-of-love",
        templateId: "wol3",
        name: "wol3",
        previewImage: wol3,
        previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "wol3",
    },
    // {
    //     type: "wall-of-love",
    //     templateId: "wol4",
    //     name: "wol3",
    //     previewImage: wol4,
    //     previewUrl: Config.RenderEngineUrl + "/widget/preview/" + "wol4",
    // },
];