import Src from '@assets/integrations/zapier.png';

export const ZapierIcon: React.FC = () => {
    return (
        <img
            height="60"
            width="60"
            src={Src}
            alt="zapier-icon"
        />
    );
}

