import { Input } from "@components/input";
import { ImportCard } from "../manuallyImport/main.layout";
import { useState } from "react";
import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import toast from "react-hot-toast";
import { z } from "zod";


const schema = z.object({
    url: z.string().url().refine((val) => {
        try {
            return new URL(val).host === "youtu.be";
        } catch (err) {
            return false;
        }
    }, "Enter a valid post url.")
});


export function YoutubeImport() {

    const { formId } = useActiveSpace();

    const [url, setUrl] = useState("");
    const [error, setError] = useState("");

    const { youtubeImporter } = useImportTestimonialApiClient();

    const onImport = async () => {
        try {

            const result = schema.safeParse({ url });

            if (result.success === false) {
                setError(result.error.message);
                return;
            }

            await youtubeImporter.mutateAsync({ formId, url });

            setUrl("");

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <ImportCard
            title="Import from Youtube"
            isLoading={youtubeImporter.isPending}
            onImport={() => onImport()}
        >

            <Input
                type="text"
                label="Youtube Video URL"
                placeholder="https://youtube.com/watch?v=23kj432n"
                required={true}
                value={url}
                error={error}
                onChange={(e) => setUrl(e.target.value)}
            />

        </ImportCard>
    );
}