import { cn } from "@utils/cn";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    containerClassName?: string,
    count?: number;
}

export function SkeletonLoader({ className, containerClassName, count, ...props }: Props) {

    const arr = new Array(count || 1).fill(0);

    return (
        <div className={cn("w-full h-full", {
            "space-y-2": arr.length > 1
        }, containerClassName)}>

            {arr.map((_, idx) => (
                <div
                    key={idx}
                    className={cn("animate-pulse rounded-md bg-gray-400/20", className)}
                    {...props}
                />
            ))}

        </div>
    );
}