import { Config } from "@config";
import { ApiError } from "src/type";


export const RequestInterceptor = (config: any) => {

    const api = Config.Api();

    config.url = api + config.url;

    config.withCredentials = true;

    return config;

};


export const onErrorResponseInterceptor = (error: ApiError) => {

    if (error.response?.status === 401 || error.response?.data.error === "UnauthorizedError") {

        window.location.replace("/logout");

    } else {

        return Promise.reject(error);

    }
};