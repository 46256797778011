import { Text } from "@components/text";
import { UploadPicture } from "@components/upload";
import { useImportTestimonialStore } from "@store";


export function CompanyLogoField() {

    const companyLogo = useImportTestimonialStore((store) => store.testimonial.companyLogo);

    console.log({ companyLogo });

    return (
        <div>

            <Text
                text="Company Logo"
                variant="label"
            />

            <UploadPicture
                id="company-logo"
                file={companyLogo}
                containerClassName="space-y-1"
                imgClassName="w-[40px] h-[40px] rounded-full border"
                buttonText="Pick a logo"
                onChange={(file) => {
                    console.log({ file });
                    useImportTestimonialStore.getState().setCompanyLogo(file);
                }}
            />

        </div>
    );
}