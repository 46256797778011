import { cn } from "@utils/cn";

type Props = {
    width?: number,
    height?: number,
    className?: string;
};

export function Logo({ height, width, className }: Props) {
    return (
        <svg
            className={cn(className)}
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "40"}
            height={height ?? "40"}
            viewBox="0 0 40 40"
            fill="none"
        >

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.0028 0C14.6978 0 9.61005 2.10714 5.85883 5.85786C2.10761 9.60859 0.000193848 14.6957 0.000193848 20C-0.0127181 22.8743 0.61956 25.7149 1.85044 28.3125C2.44941 29.5683 2.56944 30.9995 2.18798 32.3375L0.000193848 40C0.000193848 40 4.77582 38.6375 7.6762 37.85C9.00619 37.4703 10.4292 37.5903 11.6767 38.1875C14.394 39.4303 17.3553 40.0486 20.3429 39.997C23.3305 39.9454 26.2686 39.2252 28.9414 37.8893C31.6141 36.5533 33.9535 34.6357 35.7878 32.2771C37.622 29.9186 38.9045 27.1791 39.541 24.26C40.1774 21.3409 40.1517 18.3163 39.4657 15.4084C38.7797 12.5005 37.4508 9.78322 35.5768 7.45621C33.7027 5.1292 31.331 3.25157 28.6359 1.96128C25.9408 0.670988 22.9909 0.000815003 20.0028 0ZM29.2915 18.225L26.3412 22.0625L26.4787 26.9C26.4867 27.1332 26.4373 27.3647 26.3349 27.5743C26.2324 27.7839 26.0801 27.9652 25.8912 28.1022C25.7024 28.2392 25.4828 28.3278 25.2517 28.3601C25.0206 28.3924 24.7851 28.3674 24.5659 28.2875L20.0028 26.6625L15.4397 28.2875C15.2215 28.3608 14.9891 28.3821 14.7611 28.3495C14.5332 28.317 14.316 28.2315 14.1271 28.1C13.9312 27.9585 13.7745 27.7696 13.6717 27.5509C13.5689 27.3323 13.5234 27.0911 13.5395 26.85L13.677 22.0125L10.7266 18.175C10.585 17.9916 10.4903 17.7765 10.4507 17.5482C10.411 17.3199 10.4276 17.0855 10.499 16.8651C10.5704 16.6447 10.6945 16.445 10.8605 16.2834C11.0265 16.1218 11.2295 16.003 11.4517 15.9375L16.0898 14.575L18.8277 10.5875C18.9666 10.4061 19.1454 10.259 19.3502 10.1579C19.5551 10.0567 19.7806 10.004 20.0091 10.004C20.2376 10.004 20.463 10.0567 20.6679 10.1579C20.8728 10.259 21.0516 10.4061 21.1905 10.5875L23.9283 14.575L28.5665 15.9375C28.7887 16.003 28.9916 16.1218 29.1576 16.2834C29.3236 16.445 29.4477 16.6447 29.5191 16.8651C29.5906 17.0855 29.6072 17.3199 29.5675 17.5482C29.5278 17.7765 29.4331 17.9916 29.2915 18.175V18.225Z"
                fill="#2563EB"
            />

        </svg>
    );
}