import { SecondaryButton } from "@components/button";
import { Text } from "@components/text";
import { Suspense, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { ImportNavigationSection } from "./nav/main.nav";
import { PreviewSection } from "./preview/preview";
import { EditSection } from "./edit/main.edit";
import { SelectSpaces } from "./selectSpaces";
import { Loading } from "@components/loading";

export function ChromeExtensionImportTestimonialsPage() {

    const [active, setActive] = useState<"preview" | "edit">("preview");

    useEffect(() => {

        window.addEventListener("message", () => onHandleMessage());

        return () => window.removeEventListener("message", () => onHandleMessage());

    }, []);

    const onHandleMessage = () => {

    };

    const onClose = () => window.postMessage({ type: "closeImportPopup", payload: {} });

    return (
        <Suspense fallback={
            <div className="h-full w-full">
                <Loading text="" />
            </div>
        }>

            <div className="w-screen h-screen border-x shadow-md relative">

                <div className="flex items-center justify-between border-b px-2 py-3">

                    <div className="flex items-center justify-between w-full">

                        <Text
                            text="Import Testimonial"
                            className="text-lg font-medium tracking-wide mx-2"
                        />

                        <SelectSpaces />

                    </div>

                    <SecondaryButton
                        className="w-auto p-2 mt-0 mx-2"
                        onClick={() => onClose()}
                    >
                        <MdClose className="w-5 h-5" />
                    </SecondaryButton>

                </div>

                <ImportNavigationSection
                    active={active}
                    onClick={(val: any) => setActive(val)}
                />

                <div className="h-full w-full flex justify-center">

                    {active === "preview" ? <PreviewSection /> : <EditSection />}

                </div>

            </div>

        </Suspense>
    );
}