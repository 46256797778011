import { Button, SecondaryButton } from "@components/button";
import { useNavigate, useParams } from "react-router-dom";
import { Template1 } from "./template1/template1";
import { Template2 } from "./template2/template2";
import { Template3 } from "./template3/template3";
import { Template4 } from "./template4/template4";
import { Template5 } from "./template5/template5";
import { Template6 } from "./template6/template6";
import { Template7 } from "./template7/template7";
import { Template8 } from "./template8/template8";
import { Template9 } from "./template9/template9";
import { MdArrowBackIos } from "react-icons/md";


export function ImageTemplates() {

    const { id } = useParams<EditWidgetPageParams>();

    const navigate = useNavigate();

    const onShare = () => { };

    return (
        <div className="w-full h-full max-h-full overflow-hidden p-2 relative">

            <div className="absolute w-[96%] top-4 right-4">

                <div className="flex items-center justify-between w-full bo space-x-4 py-2 px-4">

                    <SecondaryButton
                        className="text-sm font-medium w-auto px-3"
                        onClick={() => navigate("/social-proof")}
                    >

                        <MdArrowBackIos className="w-4 h-4 mr-1" />

                        <p> Back </p>

                    </SecondaryButton>

                    <div className="flex items-center space-x-4">

                        <SecondaryButton
                            className="mt-0 w-auto text-sm font-medium"
                            onClick={() => { }}
                        >
                            Resize
                        </SecondaryButton>

                        <Button
                            className="mt-0 w-auto text-sm font-medium"
                            onClick={() => onShare()}
                        >
                            Share
                        </Button>

                    </div>

                </div>

            </div>


            <div className="h-full max-h-full flex items-center justify-center">

                {id === "i1" ? <Template1 /> : null}

                {id === "i2" ? <Template2 /> : null}

                {id === "i3" ? <Template3 /> : null}

                {id === "i4" ? <Template4 /> : null}

                {id === "i5" ? <Template5 /> : null}

                {id === "i6" ? <Template6 /> : null}

                {id === "i7" ? <Template7 /> : null}

                {id === "i8" ? <Template8 /> : null}

                {id === "i9" ? <Template9 /> : null}

            </div>

        </div>
    );
}