import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";


export function EnableRTL() {

    const rtl = useFormStore((store) => store.design.enableRTL);

    return (
        <TextToggle
            name="Use RTL view"
            isActive={rtl}
            onClick={() => useFormStore.getState().setRtl(!rtl)}
        />
    );
}