import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";

type Props = {
    placeholder?: string,
    label?: string;
};

export function TagLineField({ placeholder, label }: Props) {

    const tagline = useImportTestimonialStore((store) => store.testimonial.tagline);

    return (
        <Input
            type="text"
            placeholder={placeholder ?? "Head of Product"}
            label={label ?? "Tagline"}
            mainClassName="w-full"
            value={tagline}
            onChange={(e) => useImportTestimonialStore.getState().setTagline(e.target.value)}
        />
    );
}