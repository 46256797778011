import { useQueryClient } from "@tanstack/react-query";


export function useGetQueryStatus(keys: string[]) {

    const queryClient = useQueryClient();

    const query = queryClient.getQueryState([...keys]);

    const isLoading = query?.status === "pending" ? true : false;

    const isAlreadyFetched = query?.status === "success" ? true : false;

    return { ...query, isLoading, isAlreadyFetched };

}