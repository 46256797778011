import { TestimonialsHeader } from "./header";
import { Filter } from "./filter/main.filter";
import { EmptyTestimonialsScreen } from "./empty";
import { useTestimonialsApiClient } from "@hooks";
import { TestimonialsTable } from "./table/main.table";
import { Loading } from "@components/loading";
import { useEffect } from "react";
import { useTestimonialStore } from "@store";
import { useSearchParams } from "react-router-dom";
import { EmbedScriptPopup } from "./details/embed.poup";


export function TestimonialsPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const testimonialFilters = useTestimonialStore((store) => store.filter);

    const { getAll } = useTestimonialsApiClient({});

    const testimonials = (getAll.data as Api.Testimonials.getAll)?.testimonials ?? [];

    const { isFetching, isLoading } = getAll;

    useEffect(() => {

        getAll.refetch();

        if (searchParams.get("id")) {
            searchParams.delete("id");
            useTestimonialStore.getState().setShowDetailsPopup(false);
            setSearchParams(searchParams);
        }

    }, []);

    const isFiltersEmpty = () => {

        const { approved, range, rating, sources, type } = testimonialFilters;

        const isEmpty = (approved === null &&
            range.from === null && range.to === null &&
            rating === null &&
            sources.length === 0 &&
            type.length === 0
        );

        return isEmpty ? true : false;

    };

    if (isFetching === false && testimonials.length === 0 && isFiltersEmpty()) {
        return <EmptyTestimonialsScreen />;
    }

    return (
        <div className="px-6 py-4 space-y-5 pb-10 relative">

            <TestimonialsHeader />

            <Filter />

            {(isLoading || isFetching) ?
                <div className="w-full h-[50vh] flex items-center justify-center bg">

                    <Loading text="Gathering the love letters... Hold tight!" />

                </div> : <TestimonialsTable />
            }

            <EmbedScriptPopup />

        </div >
    );
}