import { Input } from "@components/input";
import { MigrateCard } from "../card";
import SenjaLogo from "@assets/migrate/senja-logo.png";
import { useState } from "react";
import { z } from "zod";

const schema = z.object({
    url: z.string().url().refine((val) => {
        try {

            const { host } = new URL(val);

            return host === "senja.io";

        } catch (err) {
            return false;
        }
    }, "Enter a valid senja.io wall of love url.")
});


export function SenjaScraper() {

    const [url, setUrl] = useState("");
    const [error, setError] = useState("");

    const onMigrate = async () => {

    };

    const onChange = (val: string) => {

        setUrl(val);

        if (!val && error) setError("");

        const result = schema.safeParse({ url });

        setError(result.success === false ?
            result.error.issues[0].message :
            ""
        );

    };

    return (
        <MigrateCard
            title="Migrate your Senja.io Wall of Love"
            icon={
                <div className="">
                    <img
                        src={SenjaLogo}
                        className="w-5 h-5"
                    />
                </div>
            }
            show={url && !error ? true : false}
            onMigrate={() => onMigrate()}
            isLoading={false}
        >

            <Input
                type="text"
                label="Wall of Love URL"
                placeholder="https://love.senja.io/1hh123kj"
                required={true}
                value={url}
                error={error}
                onChange={(e) => onChange(e.target.value)}
            />

        </MigrateCard>
    );
}