import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SenjaScraper } from "./senja";
import { TestimonialScraper } from "./testimonial-to";

export function MigrateSection() {

    const [searchParams, setSearchParams] = useSearchParams();

    const importFrom = searchParams.get("tab") ?? "senja";

    useEffect(() => {

        if (!searchParams.get("tab")) {
            searchParams.set("tab", "senja");
            setSearchParams(searchParams);
        }

    }, []);

    return (
        <div className="w-full h-full border overflow-y-auto">

            {importFrom === "senja" ? <SenjaScraper /> : null}

            {importFrom === "testimonial-to" ? <TestimonialScraper /> : null}

        </div>
    );
}