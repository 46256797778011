


interface Props extends React.SVGProps<SVGSVGElement> { }

export function YelpIcon(props: Props) {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
            <linearGradient id="W1Wok9GxEpPyT2KHYYyl7a_S2JJNToo9qHf_gr1" x1="1.323" x2="44.983" y1="5.864" y2="47.991" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#f52537"></stop><stop offset=".293" stopColor="#f32536"></stop><stop offset=".465" stopColor="#ea2434"></stop><stop offset=".605" stopColor="#dc2231"></stop><stop offset=".729" stopColor="#c8202c"></stop><stop offset=".841" stopColor="#ae1e25"></stop><stop offset=".944" stopColor="#8f1a1d"></stop><stop offset="1" stopColor="#7a1818"></stop></linearGradient><path fill="url(#W1Wok9GxEpPyT2KHYYyl7a_S2JJNToo9qHf_gr1)" d="M10.7,32.7c-0.5,0-0.9-0.3-1.2-0.8c-0.2-0.4-0.3-1-0.4-1.7c-0.2-2.2,0-5.5,0.7-6.5	c0.3-0.5,0.8-0.7,1.2-0.7c0.3,0,0.6,0.1,7.1,2.8l1.9,0.8c0.7,0.3,1.1,1,1.1,1.8s-0.5,1.4-1.2,1.6l-2.7,0.9	C11.2,32.7,11,32.7,10.7,32.7z M24,36.3c0,6.3,0,6.5-0.1,6.8c-0.2,0.5-0.6,0.8-1.1,0.9c-1.6,0.3-6.6-1.6-7.7-2.8	c-0.2-0.3-0.3-0.5-0.4-0.8c0-0.2,0-0.4,0.1-0.6c0.1-0.3,0.3-0.6,4.8-5.9l1.3-1.6c0.4-0.6,1.3-0.7,2-0.5c0.7,0.3,1.2,0.9,1.1,1.6	C24,33.5,24,36.3,24,36.3z M22.8,22.9c-0.3,0.1-1.3,0.4-2.5-1.6c0,0-8.1-12.9-8.3-13.3c-0.1-0.4,0-1,0.4-1.4	c1.2-1.3,7.7-3.1,9.4-2.7c0.6,0.1,0.9,0.5,1.1,1c0.1,0.6,0.9,12.5,1,15.2C24.1,22.5,23.1,22.8,22.8,22.9z M27.2,25.9	c-0.4-0.6-0.4-1.4,0-1.9l1.7-2.3c3.6-5,3.8-5.3,4.1-5.4c0.4-0.3,0.9-0.3,1.4-0.1c1.4,0.7,4.4,5.1,4.6,6.7c0,0,0,0,0,0.1	c0,0.6-0.2,1-0.6,1.3c-0.3,0.2-0.5,0.3-7.4,1.9c-1.1,0.3-1.7,0.4-2,0.5v-0.1C28.4,26.9,27.6,26.5,27.2,25.9z M38.9,34.4	c-0.2,1.6-3.5,5.8-5.1,6.4c-0.5,0.2-1,0.2-1.4-0.2c-0.3-0.2-0.5-0.6-4.1-6.4l-1.1-1.7c-0.4-0.6-0.3-1.4,0.2-2.1	c0.5-0.6,1.2-0.8,1.9-0.6l2.7,0.9c6,2,6.2,2,6.4,2.2C38.8,33.4,39,33.9,38.9,34.4z"></path>
        </svg>
    );
}
