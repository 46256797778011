import { useCommonStore, } from '@store';
import { cn } from '@utils/cn';
import { useEffect, useState } from 'react';
import { CreateSpacePopup } from './spaces/createSpace.popup';
import { ChangeSpace } from './spaces/changeSpaces.main';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { QueryKeys, useActiveSpace, usePlaceholderImage, useSpacesApiClient } from '@hooks';
import { Config } from '@config';
import { useQueryClient } from '@tanstack/react-query';


export function CurrentSpace() {

    const { showFullNavbar, activePopup } = useCommonStore();
    const activeSpace = useActiveSpace();

    const [showDropDown, setShowDropDown] = useState(false);

    const queryClient = useQueryClient();

    const queryData = queryClient.getQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const { imgPlaceholder } = usePlaceholderImage();

    const { getAll } = useSpacesApiClient({});

    useEffect(() => {

        if (!queryData?.spaces && !queryData?.activeSpace) {

            getAll.refetch();
        }

    }, []);

    return (
        <div
            className={cn("relative p-2 cursor-pointer", {
                "relative flex items-center justify-between rounded-lg border bg-gray-100 cursor-pointer": showFullNavbar,
                "flex items-center justify-center bg-white border-none ": !showFullNavbar
            })}
        >

            <div
                className={cn("flex items-center justify-between space-x-2 w-full", {
                    "w-full": !showFullNavbar
                })}
                onClick={() => !showDropDown && setShowDropDown(!showDropDown)}
            >

                <div
                    className={cn('flex items-center space-x-1 select-none', {
                        "justify-center w-[35px]": !showFullNavbar
                    })}
                >

                    <img
                        ref={imgPlaceholder}
                        src={activeSpace.logo ? activeSpace.logo : Config.misc.placeHolderImage}
                        className={cn(`
                            w-8 h-8 rounded-md transition-all duration-300 ease-in-out hover:ring-2 
                            hover:ring-blue-400 hover:ring-offset-2 
                        `)}
                    />

                    <span
                        className={cn(`whitespace-nowrap pl-1 transition-all duration-500 ease-in-out font-medium`, {
                            "pl-0 opacity-0 translate-x-1 overflow-hidden pointer-events-none ": !showFullNavbar,
                        })}
                    >
                        {activeSpace.name}
                    </span>

                </div>

                <MdOutlineKeyboardArrowRight className={cn("w-6 h-6")} />

            </div>

            {activePopup === "create-space" ? <CreateSpacePopup /> : null}

            <ChangeSpace
                show={showDropDown && activePopup !== "create-space" ? true : false}
                onClose={() => setShowDropDown(!showDropDown)}
            />

        </div>
    );
}