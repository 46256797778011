import { ColorPicker } from "@components/colorPicker";
import { useFormStore } from "@store";


export function FontAndCardColor() {

    const { fontColor, cardColor } = useFormStore((store) => ({
        fontColor: store.design.fontColor,
        cardColor: store.design.cardColor
    }));

    return (
        <div className="flex items-center justify-between space-x-4">

            <ColorPicker
                label='Font Color'
                defaultColor={fontColor}
                onChange={(color) => useFormStore.getState().setFontColor(color)}
            />

            <ColorPicker
                label='Card Color'
                defaultColor={cardColor}
                onChange={(color) => useFormStore.getState().setCardColor(color)}
            />

        </div>
    );
}