import { useState } from "react";
import { z } from "zod";
import { MigrateCard } from "../card";
import TestimonialToLogo from "@assets/migrate/testimonial-logo.png";
import { Input } from "@components/input";


const schema = z.object({
    url: z.string().url().refine((val) => {
        try {

            const { host } = new URL(val);

            return host === "testimonial.to";

        } catch (err) {
            return false;
        }
    }, "Enter a valid testimoinal.to wall of love url.")
});

export function TestimonialScraper() {

    const [url, setUrl] = useState("");
    const [error, setError] = useState("");

    const onMigrate = async () => {

    };

    const onChange = (val: string) => {

        setUrl(val);

        if (!val && error) setError("");

        const result = schema.safeParse({ url });

        setError(result.success === false ?
            result.error.issues[0].message :
            ""
        );

    };


    return (
        <MigrateCard
            title="Migrate your Testimonial.to Wall of Love"
            icon={
                <div className="">
                    <img
                        src={TestimonialToLogo}
                        className="w-5 h-5"
                    />
                </div>
            }
            show={url && !error ? true : false}
            onMigrate={() => onMigrate()}
            isLoading={false}
        >

            <Input
                type="text"
                label="Wall of Love URL"
                placeholder="https://testimonial.io/1hh123kj"
                required={true}
                value={url}
                error={error}
                onChange={(e) => onChange(e.target.value)}
            />

        </MigrateCard>
    );
}