import { TextToggle } from "@components/toggle";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Input } from "@components/input";
import { useFormStore } from "@store";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const Schema = z.object({
    text: z.string().min(1, "Text Required"),
    url: z.string().url("Enter a valid url")
});


export function CallToAction() {

    const cta = useFormStore((state) => state.thankYou.cta);

    const [show, setShow] = useState(cta.text ? true : false);

    const { formState: { errors }, register, watch } = useForm<z.infer<typeof Schema>>({
        resolver: zodResolver(Schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            text: cta.text,
            url: cta.url
        }
    });

    const fields = watch();

    useEffect(() => {

        useFormStore.getState().setThankyouPageCta({
            text: fields.text,
            url: fields.url
        });

    }, [JSON.stringify(fields)]);

    return (
        <div>

            <TextToggle
                isActive={show}
                name="Call To Action"
                onClick={() => setShow(!show)}
            />

            <AnimatePresence initial={false}>

                {show ?
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="space-y-2 my-2"
                    >

                        <Input
                            {...register("text")}
                            type="text"
                            label="Button Text"
                            placeholder="Visit My Site!"
                            error={errors.text?.message}
                        />

                        <Input
                            {...register("url")}
                            type="url"
                            label="Redirect Url"
                            placeholder="https://goze.io/testimonal"
                            error={errors.url?.message}
                        />

                    </motion.div> : null
                }

            </AnimatePresence>

        </div>
    );
}