import { Rating } from "@components/rating";
import { Text } from "@components/text";
import { useImportTestimonialStore } from "@store";


export function RatingField() {

    const rating = useImportTestimonialStore((store) => store.testimonial.rating);

    return (
        <div className="space-y-2 w-[50%]">

            <Text
                text="Rating"
                variant="label"
            />

            <Rating
                rated={rating}
                iconProps={{
                    style: { width: 30, height: 30 }
                }}
                onChange={(e) => useImportTestimonialStore.getState().setRating(e)}
            />

        </div>
    );
}