import { TextToggle } from "@components/toggle";
import { cn } from "@utils/cn";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tooltip";


export function CommonCustomerDetails() {

    return (
        <div className="space-y-4">

            <TextToggle
                isActive={false}
                name="Show Customer Photo"
                onClick={() => { }}
            />

            <TextToggle
                isActive={false}
                name="Use Gravatar if available"
                onClick={() => { }}
            >

                <FiInfo
                    className={cn(`text-gray-600`)}
                    id={`allow-sharing-tooltip`}
                />

                <Tooltip
                    anchorSelect={`#allow-sharing-tooltip`}
                    place="top"
                    style={{
                        width: 340,
                        textAlign: "left"
                    }}
                >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, atque.
                </Tooltip>

            </TextToggle>

        </div>
    );
}