import { useEmbedStore } from "@store";
import { cn } from "@utils/cn";
import React, { ReactNode } from "react";
import { FullPageIcon } from "./standard/icon";
import { PopupIcon } from "./popup/icon";
import { SliderIcon } from "./slider/icon";
import { SideTabIcon } from "./sideTab/icon";

export const EmbedTypes: React.FC = () => {

    const embedType = useEmbedStore((store) => store.type);

    return (
        <div className="flex flex-col py-3">

            <div className="font-medium text-sm mb-3">
                EMBED TYPE
            </div>

            <div className="flex flex-auto flex-wrap w-full gap-3 justify-between mb-3">

                <Item
                    icon={
                        <FullPageIcon
                            classNames="group-hover:text-primary"
                            active={embedType === 'standard'}
                        />
                    }
                    name="Standard"
                    type="standard"
                />

                <Item
                    icon={
                        <PopupIcon
                            classNames="group-hover:text-primary"
                            active={embedType === 'popup'}
                        />
                    }
                    name="Popup"
                    type="popup"
                />

                <Item
                    icon={
                        <SliderIcon
                            classNames="group-hover:text-primary"
                            active={embedType === 'slider'}
                        />
                    }
                    name="Slider"
                    type="slider"
                />

                <Item
                    icon={
                        <SideTabIcon
                            classNames="group-hover:text-primary"
                            active={embedType === 'sideTab'}
                        />
                    }
                    name="SideTab"
                    type="sideTab"
                />

            </div>

        </div>
    );
};


type Props = {
    type: EmbedStore.State["type"],
    name: string,
    icon: ReactNode;
};

const Item: React.FC<Props> = ({ type, icon, name }) => {

    const isActive = useEmbedStore((store) => store.type === type);

    return (
        <div
            className={cn(`
                group cursor-pointer hover:text-primary font-medium text-xs text-center  
                flex flex-col space-y-1 
            `, {
                "text-primary": isActive,
                "text-gray-600": isActive === false
            })}
            onClick={() => useEmbedStore.getState().setType(type)}
        >

            {icon}

            <span> {name} </span>

        </div>
    );
};