import { cn } from "@utils/cn";
import { InputHTMLAttributes, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";


interface Props extends InputHTMLAttributes<HTMLInputElement> {
    required?: boolean,
    tooltip?: { id: string, tip: string, width?: number; },
    label: string,
    error?: string | null,
    register?: UseFormRegisterReturn<any>,
    mainClassName?: string;
}


export const CheckBoxInput = forwardRef<HTMLDivElement, Props>(({
    required, className, register, tooltip,
    error = "",
    mainClassName = "",
    label = "",
    ...props
}, ref) => {
    return (
        <div ref={ref} className="flex items-center">

            <input
                {...props}
                type="checkbox"
                className={cn(`
                    w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 
                    rounded  focus:ring-2
                `)}
            />

            <label className={cn(`ms-2 text-sm font-medium text-gray-600`)}>
                {label}
            </label>

        </div>
    );
});