import { Input } from "@components/input";
import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { Fragment, useState } from "react";


export function AppStoreImport() {

    const [manuallyImport, setManuallyImport] = useState(false);

    return (
        <ImportCard
            title="Import from App Store"
            onImport={() => { }}
        >

            {manuallyImport ?

                <ManuallyImport
                    fields={{
                        testimonial: {
                            text: { content: false, imageAttachments: false },
                            audio: { url: false, transcribedText: false },
                            video: { url: true, transcribedText: true }
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="App Store Page URL"
                        required={true}
                    />

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                        onClick={() => setManuallyImport(true)}
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>
            }

        </ImportCard>
    );
}