import { useRef, useLayoutEffect, useEffect } from "react";


export function useClickOutside(callback: () => void) {

    const ref = useRef<any>(null);
    const refCb = useRef(callback);

    useLayoutEffect(() => { refCb.current = callback; });

    useEffect(() => {

        const handler = (e: any) => {

            const element = ref.current;

            if (element && !element.contains(e.target)) {
                refCb.current();
            }
        };

        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);

        return () => {
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, []);

    return ref;

}