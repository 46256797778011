
export class EmbedPreview {


    static changeScreen = (screen: Messages.CurrentScreenEvent["payload"]["screen"]) => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (!ele) return;

        ele.contentWindow?.postMessage({
            type: "currentScreen",
            payload: { screen: screen }
        } as Messages.Types, "*");

    };

    static sendActiveRewardSegmentId = (id: string) => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (!ele) return;

        ele.contentWindow?.postMessage({
            type: "sendActiveRewardSegmentId",
            payload: { id }
        } as Messages.Types, "*");

    };

}