import { Input } from "@components/input";
import { Select } from "@components/select";
import { Text } from "@components/text";


const options = [
    { label: "Grid", value: "grid" },
    { label: "Marquee", value: "marquee" }
];

export function Widget5_LayoutSettings() {

    return (
        <div className="space-y-4">

            <div>

                <Text
                    text="Layout"
                    variant="label"
                />

                <Select
                    className="mt-1"
                    options={options}
                    onChange={() => { }}
                />

            </div>


            <Input
                label="Spacing"
                type="number"
                value={2}
                onAbort={() => { }}
            />

            <Input
                label="Logo Width"
                type="number"
                value={30}
                onAbort={() => { }}
            />

        </div>
    );
}