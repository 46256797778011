import { ProfileDetails } from "./details";
import { ProfilePicture } from "./profile";

export function Account() {
    return (
        <div className="h-full p-5 fade-in">

            <div className="h-[80%] flex items-start justify-center">

                <ProfilePicture />

                <ProfileDetails />

            </div>

        </div>
    );
}