import { Button, SecondaryButton } from "@components/button";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { QueryKeys, useGetQueryStatus, useInvalidateQuery, useTestimonialsApiClient } from "@hooks";
import { useTestimonialStore } from "@store";
import { useClickAway } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { useState } from "react";
import { MdFilterList, MdKeyboardArrowDown } from "react-icons/md";
import { StatusFilter } from "./approveStatus";
import { TestimonialTypesFilter } from "./types";
import { RatingFilter } from "./rating";
import { TestimonialSourcesFilter } from "./source";


export function OtherFilters() {

    const { isLoading } = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const clickOutSideRef = useClickAway<any>(() => setShow(!show));

    const [show, setShow] = useState(false);

    const { getAll } = useTestimonialsApiClient({});

    const { invalidate } = useInvalidateQuery([QueryKeys.testimonials.getAll]);

    const onCancel = () => {

        useTestimonialStore.getState().setFilter({
            approved: null,
            rating: null,
            sources: [],
            type: []
        });

        invalidate([QueryKeys.testimonials.getAll]);

        getAll.refetch();

        setShow(false);
    };

    const onApply = () => {

        if (isLoading) return;

        invalidate([QueryKeys.testimonials.getAll]);
        getAll.refetch();
        setShow(false);
    };

    return (
        <Popover open={show}>

            <PopoverTrigger>

                <SecondaryButton
                    className={cn(`p-2 px-3 bg-gray-200 hover:bg-gray-200/80`)}
                    onClick={() => setShow(!show)}
                >

                    <div className="space-x-2 flex items-center justify-center mr-2">

                        <MdFilterList className="w-5 h-5" />

                        <p className="text-sm font-medium"> Filter </p>

                    </div>

                    <MdKeyboardArrowDown className="w-6 h-6" />

                </SecondaryButton>

            </PopoverTrigger>

            <PopoverContent
                ref={clickOutSideRef}
                side="bottom"
                align="start"
                className="mt-2 bg-white w-auto h-full"
                style={{
                    width: "450px"
                }}
            >

                <div className="flex items-start space-x-4">

                    <div className="space-y-3 w-[45%] border-r">

                        <StatusFilter />

                        <TestimonialTypesFilter />

                        <RatingFilter />

                    </div>

                    <TestimonialSourcesFilter />

                </div>

                <div className="flex items-center justify-end space-x-3 mt-4">

                    <SecondaryButton
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        text="Cancel"
                        onClick={() => onCancel()}
                    />

                    <Button
                        disabled={isLoading}
                        loading={isLoading}
                        className="mt-0 text-xs tracking-wider font-medium px-3 py-2 w-auto"
                        text="Apply"
                        onClick={() => onApply()}
                    />

                </div>

            </PopoverContent>

        </Popover>
    );
}