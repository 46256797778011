import { ErrorBadge } from '@components/badge';
import { Button, SecondaryButton } from '@components/button';
import { Input } from '@components/input';
import { Config } from '@config';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuthApiClient } from '@hooks';
import { Auth0 } from '@lib/auth0';
import { cn } from '@utils/cn';
import { REGX } from '@utils/regx';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FcGoogle } from 'react-icons/fc';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const RegisterSchema = z.object({
    name: z.string().min(3).max(50),
    email: z.string().email(),
    acceptCondition: z.boolean(),
    password: z.string()
        .min(8, { message: "Password must be atleast 8 characters" })
        .refine(password => new RegExp(REGX.lowerCase).test(password), { message: "must contain atleast one lowercase character" })
        .refine(password => new RegExp(REGX.upperCase).test(password), { message: "must contain atleast one uppercase character" })
        .refine(password => new RegExp(REGX.specialCharacters).test(password), { message: "must contain atleast one special character" })
});

type RegisterSchemaType = z.infer<typeof RegisterSchema>;

export function SignUpPage() {


    const [searchParams] = useSearchParams();

    const [error, setError] = useState("");

    const navigate = useNavigate();

    const { formState, register, setValue, getValues, handleSubmit, clearErrors } = useForm<RegisterSchemaType>({
        resolver: zodResolver(RegisterSchema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            name: "",
            email: "",
            password: "",
            acceptCondition: true
        }
    });

    const { signUp } = useAuthApiClient({});

    useEffect(() => {

        const name = searchParams.get("name") ?? null;
        const email = searchParams.get("email") ?? null;

        if (name) setValue("name", name);

        if (email) setValue("email", email);

    }, []);

    const onSubmit: SubmitHandler<RegisterSchemaType> = async (data) => {

        if (!data.acceptCondition) {
            setError("Please accept our Terms of Service and Privacy Policy");
            return;
        }

        await signUp.mutateAsync(data);

        if (signUp.isError) {
            setError(signUp.error.message);
            return;
        }

        navigate({
            pathname: "/login",
            search: createSearchParams({
                "email": getValues("email")
            }).toString()
        });

    };

    return (
        <div className={cn(`h-full max-h-full p-5 flex w-[60%] flex-col items-center justify-center`, {
            "w-full": window.innerWidth < 600
        })}>

            <div className={cn(`w-[55%] p-8 overflow-y-hidden bg-white/80 rounded-lg shadow-lg transition-all duration-200 ease-in-out`, {
                "w-full": window.innerWidth < 600
            })}>

                <h4 className="text-xl font-medium capitalize ">
                    Register Account
                </h4>


                {error ?
                    <ErrorBadge
                        className='mt-2'
                        error={error}
                        onClose={() => setError("")}
                    /> : null
                }

                <form
                    className="w-full mt-4 space-y-3 transition-all duration-200 ease-in-out"
                    onSubmit={handleSubmit(onSubmit)}
                >

                    <Input
                        {...register("name")}
                        type='text'
                        error={formState.errors.name?.message}
                        required={true}
                        label='Name'
                        className='w-full'
                        onChange={() => clearErrors("name")}
                    />

                    <Input
                        {...register("email")}
                        error={formState.errors.email?.message}
                        required={true}
                        label='Email'
                        className='w-full'
                        onChange={() => clearErrors("email")}
                    />

                    <Input
                        {...register("password")}
                        type='password'
                        label='Password'
                        error={formState.errors.password?.message}
                        required={true}
                        className='w-full'
                        onChange={() => clearErrors("password")}
                    />

                    <div className="px-2 my-2 space-y-2">

                        <div className="flex items-center space-x-2">

                            <input
                                {...register("acceptCondition")}
                                type="checkbox"
                                className='w-4 h-4'
                            />

                            <p className="text-xs font-normal">

                                I agree to the

                                <u
                                    className="cursor-pointer hover:text-primary mx-1"
                                    onClick={() => window.open(Config.TermsAndConditionLink, "_blank")}
                                >
                                    Terms of Service
                                </u>

                                and

                                <u
                                    className="cursor-pointer hover:text-primary mx-1"
                                    onClick={() => window.open(Config.PrivacyPolicyLink, "_blank")}
                                >
                                    Privacy Policy.
                                </u>
                            </p>

                        </div>

                    </div>

                    <Button
                        type='submit'
                        loading={signUp.isPending}
                        disabled={signUp.isPending}
                    >
                        Create Account
                    </Button>

                </form>


                <div className="flex items-center justify-center p-2 my-1">
                    <div className="w-1/3 border-t border-gray-300"></div>
                    <span className="px-3 text-gray-400">or</span>
                    <div className="w-1/3 border-t border-gray-300"></div>
                </div>

                <SecondaryButton
                    onClick={() => Auth0.signInWithGoogle()}
                    className='py-2 bg-blue-50 border-2 '
                >

                    <FcGoogle className="w-6 h-6" />

                    <p className="ml-3 text-base"> Sign in with Google </p>

                </SecondaryButton>

                <p className="mx-2 mt-5 text-sm text-center text-gray-600 cursor-pointer">
                    Already have an account?
                    <u
                        className="ml-1 text-blue-500"
                        onClick={() => navigate("/login")}
                    >
                        Login
                    </u>
                </p>

            </div>

        </div>
    );
}