import { Outlet } from "react-router-dom";

export function FormLayout() {

    return (
        <section className="w-full h-full overflow-hidden flex items-center justify-center relative">

            <Outlet />

        </section>
    );
}