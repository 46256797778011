import { cn } from "@utils/cn";
import { motion } from "framer-motion";
import { ReactNode } from "react";

/* table */
interface TableProps extends React.TableHTMLAttributes<HTMLTableElement> {
    children?: ReactNode,
    divClassName?: string,
};

const Main: React.FC<TableProps> = ({ children, divClassName, className, ...props }) => {
    return (
        <motion.div
            className={cn("overflow-hidden border border-gray-200 md:rounded-lg", divClassName)}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
            }}
            transition={{
                duration: 0.3
            }}
        >

            <table
                {...props}
                className={cn("min-w-full ", className)}
            >

                {children}

            </table>

        </motion.div>
    );
};

/* head */
interface HeaderProps extends React.HTMLAttributes<HTMLTableSectionElement> {
    children?: ReactNode;
}

const Head: React.FC<HeaderProps> = ({ children, className, ...props }) => {
    return (
        <thead
            {...props}
            className={cn("bg-gray-100", className)}

        >
            {children}
        </thead>
    );
};

/* head cell */
interface HeadCellProps extends React.HTMLAttributes<HTMLTableHeaderCellElement> {
    children?: ReactNode;
}

const HeadCell: React.FC<HeadCellProps> = ({ children, className, ...props }) => {
    return (
        <th
            {...props}
            scope="col"
            className={cn("px-4 font-medium py-3.5 text-center text-sm text-gray-800", className)}
        >
            {children}
        </th>
    );
};

/* body */
interface BodyProps extends React.HTMLAttributes<HTMLTableSectionElement> {
    children?: ReactNode;
}

const Body: React.FC<BodyProps> = ({ children, className, ...props }) => {
    return (
        <tbody
            {...props}
            className={cn("divide-y divide-gray-200 bg-white", className)}
        >
            {children}
        </tbody>
    );
};

/* row */
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    children?: ReactNode;
};

const Row: React.FC<RowProps> = ({ children, className, ...props }) => {
    return (
        <tr
            {...props}
            className={cn(`transition-colors duration-200 cursor-pointer`, className)}
        >

            {children}

        </tr>
    );
};

/* cell */
interface CellProps extends React.TdHTMLAttributes<any> {
    children?: ReactNode;
};

const Cell: React.FC<CellProps> = ({ children, className, ...props }) => {
    return (
        <td
            {...props}
            className={cn("px-4 py-2 text-sm font-normal text-center", className)}
        >
            {children}
        </td >
    );
};



export const Table = Object.assign(Main, { Head, HeadCell, Body, Row, Cell, });