import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { State } from "./state";
import { v4 as uuid } from "uuid";

export const useFormStore = create<Form.Type>()(devtools(immer((set => ({
    ...State,
    setForm: (form: Form.State) => set((state: Form.State) => {

        state.id = form.id;
        state.customDetails = form.customDetails;
        state.design = form.design;
        state.name = form.name;
        state.published = form.published;
        state.publishedVersion = form.publishedVersion;
        state.response = form.response;
        state.reward = form.reward;
        state.settings = form.settings;
        state.tags = form.tags;
        state.thankYou = form.thankYou;
        state.version = form.version;
        state.welcome = form.welcome;

    },
        false,
        "form/setForm"
    ),
    clearForm: () => set((state) => {

        state.name = "";

    },
        false,
        "form/clearForm"
    ),
    setVersion: (params) => set((state: Form.State) => {

        state.version = params.version;
        state.published = params.published ?? state.published;
        state.publishedVersion = params.publishedVersion ?? state.publishedVersion;

    },
        false,
        "form/setVersion"
    ),
    setPublished: (published) => set((state: Form.State) => {

        state.published = published;

    },
        false,
        "form/setPublished",
    ),
    /* design */
    setFontFamily: (fontFamily: string) => set((state) => {

        state.design.fontFamily = fontFamily;

    }, false, "form/design/setFontFamily"),
    setLogo: (param) => set((state) => { state.design.logo = param; },
        false,
        "form.design/setLogo"
    ),
    setAlignment: (val) => set((state) => { state.design.alignment = val; },
        false,
        "form.design/setAlignment"
    ),
    setPrimaryColor: (color: string) => set((state) => {

        state.design.primaryColor = color;

    },
        false, "form/setPrimaryColor"
    ),
    setBackground: (val, type) => set((state) => {

        if (type === "image") state.design.background.image = val;

        if (type === "color") state.design.background.color = val;

    },
        false, "form/setBackground"
    ),
    setFontColor: (color) => set((state) => { state.design.fontColor = color; },
        false,
        "form.design/setFontColor"
    ),
    setCardColor: (color) => set((state) => { state.design.cardColor = color; },
        false,
        "form.design/setCardColor"
    ),
    setGradientBg: (param) => set((state) => {

        if (state.design.background.gradient) {

            const gradient = state.design.background.gradient;

            state.design.background.gradient = {
                animate: param?.animate ?? gradient.animate,
                colors: param?.colors ?? gradient.colors
            };

        } else {

            state.design.background = {
                ...state.design.background,
                gradient: {
                    animate: param?.animate ?? true,
                    colors: param?.colors ?? ["#eeeeee", "#eeeeee", "#eeeeee", "#eeeeee"]
                }
            };

        }

    },
        false,
        "form.design/setGradientColor"
    ),
    setRtl: (val) => set((state) => {

        state.design.enableRTL = val;

    },
        false,
        "forms.design/setRtl"
    ),
    setBtnFontColor: (color) => set((state) => {

        state.design.buttonFontColor = color;

    },
        false,
        "form.design/setBtnFontColor"
    ),
    /* welcome page */
    setWelcomeTitle: (title: string) => set((state) => { state.welcome.title = title; },
        false,
        "form.welcome/setWelcomeTitle"
    ),
    setWelcomeDescription: (desc: string) => set((state) => { state.welcome.description = desc; },
        false,
        "form.welcome/setWelcomeDescription"
    ),
    setAllowedTestimonialType: (key) => set((state) => {

        if (!state.welcome.allowedTestimonialTypes.includes(key)) {
            state.welcome.allowedTestimonialTypes.push(key);
        } else {
            state.welcome.allowedTestimonialTypes = state.welcome.allowedTestimonialTypes.filter(item => item !== key);
        }

    },
        false,
        "form.welcome/setAllowedTestimonialType"
    ),
    setWelcomeIntroVideo: (params) => set((state) => {

        if (!params) {

            state.welcome.introVideo = null;

        } else if (state.welcome.introVideo) {

            state.welcome.introVideo = { ...state.welcome.introVideo, ...params };

        } else {

            state.welcome.introVideo = {
                autoPlay: params?.autoPlay ?? true,
                url: params?.url ?? ""
            };

        }

    },
        false,
        "form.welcome/setWelcomeIntroVideo"
    ),
    /* response */
    setResponseTitle: (key, title) => set((state) => {

        state.response[key].title = title;

    },
        false,
        "form/setResponseTitle"
    ),
    setResponseDescription: (key, description) => set((state) => {

        state.response[key].description = description;

    },
        false,
        "form/setResponseDescription"
    ),
    setResponseCollect: (val) => set((state) => { state.response.collectRating = val; }, false, "form/setResponseCollect"),
    setCollectAttachment: (type, collect) => set((state) => {

        if (type === "audio") state.response.audio.collectAttachments = collect;

        if (type === "text") state.response.text.collectAttachments = collect;

    },
        false,
        "form/setCollectAttachment"
    ),
    setCollectRatings: (collect) => set((state) => { state.response.collectRating = collect; },
        false,
        "form/setCollectRatings"
    ),
    /* custom details */
    setCustomerPageTitle: (title) => set((state) => { state.customDetails.title = title; }, false, "form/setCustomerPageTitle"),
    setCustomerFields: (field, payload) => set((state) => {

        state.customDetails.fields[field] = payload;

    },
        false,
        "form.customDetails/setCustomerFields"
    ),
    setAddCustomField: (id) => set((state) => {

        state.customDetails.customFields.push({
            id,
            hidden: false,
            label: "",
            required: false,
            type: ""
        });

    },
        false,
        "form/setAddCustomField"
    ),
    setCustomField: (idx, field) => set((state) => {

        state.customDetails.customFields[idx] = { ...state.customDetails.customFields[idx], ...field };

    },
        false,
        "form.thankyou/setCustomField"
    ),
    deleteCustomField: (id) => set((state) => {

        state.customDetails.customFields = state.customDetails.customFields.filter(field => field.id !== id);

    },
        false,
        "form/deleteCustomField"
    ),
    /* thankyou */
    setThankyouTitle: (title) => set((state) => { state.thankYou.title = title; },
        false,
        "form/setThankyouTitle"
    ),
    setThankyouMessage: (message) => set((state) => { state.thankYou.message = message; },
        false,
        "form/setThankyouMessage"
    ),
    setAllowSharing: (allow) => set((state) => {

        state.thankYou.allowSharing = allow;

    },
        false,
        "form.thankyou/setAllowSharing"
    ),
    setCustomThankyouPage: (page) => set((state) => {

        state.thankYou.customPage = page;

    },
        false,
        "form/setCustomThankyouPage"
    ),
    setThankyouPageCta: (cta) => set((state) => {

        state.thankYou.cta.text = cta.text;
        state.thankYou.cta.url = cta.url;

    },
        false,
        "form/setThankyouPageCta"
    ),
    setThankyouVideo: (params) => set((state) => {

        if (!params) {

            state.thankYou.thankyouVideo = null;

        } else if (state.thankYou.thankyouVideo) {

            state.thankYou.thankyouVideo = { ...state.thankYou.thankyouVideo, ...params };

        } else {

            state.thankYou.thankyouVideo = {
                autoPlay: params?.autoPlay ?? true,
                url: params?.url ?? ""
            };

        }

    },
        false,
        "form.thankyou/setThankyouVideo"
    ),
    /* reward */
    setAllowReward: (allow) => set((state) => {

        state.reward = allow ? {
            allow: true,
            buttonText: "Claim your reward",
            message: "Send a video testimonial for a chance to spin and win gifts",
            onlyReward: ["video"],
            color1: "#79a3f",
            color2: "#1a5eed",
            fontColor: "#eee",
            segments: [
                {
                    id: uuid(),
                    code: "Coupon 1",
                    title: "🥳 10% OFF",
                    probability: 20,
                    popup: {
                        cta: {
                            text: "Redeem",
                            url: "https://testimonial.gozen.io"
                        },
                        message: "",
                        title: "Congratulations you got 10% off!"
                    },
                },
                {
                    id: uuid(),
                    code: "Coupon 2",
                    title: "😇 50% OFF",
                    probability: 20,
                    popup: {
                        cta: {
                            text: "Redeem",
                            url: "https://testimonial.gozen.io"
                        },
                        message: "",
                        title: "Congratulations you got 50% off!"
                    },
                },
                {
                    id: uuid(),
                    code: "Coupon 3",
                    title: "🤩 80% OFF",
                    probability: 20,
                    popup: {
                        cta: {
                            text: "Redeem",
                            url: "https://testimonial.gozen.io"
                        },
                        message: "",
                        title: "Congratulations you got 80% off!"
                    },
                },
                {
                    id: uuid(),
                    code: "",
                    title: "🫣 Your where soo close...",
                    probability: 20,
                    popup: {
                        cta: {
                            text: "Redeem",
                            url: "https://testimonial.gozen.io"
                        },
                        message: "",
                        title: "Congratulations you got 10% off!"
                    },
                }
            ],
            page: {
                button: "Spin the wheel",
                message: "<p> Spin the wheel and stand a chance to win one of the amazing prizes. </p>",
                title: "<p> Spin the wheel </p>"
            }
        } : {
            allow: false,
            buttonText: "",
            color1: "",
            color2: "",
            fontColor: "",
            message: "",
            onlyReward: [],
            page: {
                button: "",
                message: "",
                title: ""
            },
            segments: []
        };

    },
        false,
        "form/setAllowReward"
    ),
    setRewardOnly: (types) => set((state) => { state.reward.onlyReward = types; },
        false,
        "form/setRewardOnly"
    ),
    setRewardMessage: (message) => set((state) => { state.reward.message = message; },
        false,
        "form/setRewardMessage"
    ),
    setRewardWheelColor: (param) => set((state) => {

        state.reward = {
            ...state.reward,
            ...param
        };

    },
        false,
        "form/setRewardWheelColor"
    ),
    addRewardSegment: (id: string) => set((state) => {

        state.reward.segments.push({
            id,
            code: "ITEM",
            popup: {
                cta: { text: "", url: "" },
                message: "Enter the coupon code to get 30% of your purchase.",
                title: "Coagulations!"
            },
            probability: 0,
            title: "Item",
        });

    },
        false,
        "form/addRewardSegment"
    ),
    setRewardSegment: (coupon) => set((state) => {

        state.reward.segments = state.reward.segments.map(item => item.id === coupon.id ? { ...item, ...coupon } : item);

    },
        false,
        "form/setRewardSegment"
    ),
    setRewardPopup: (popup) => set((state) => {

        state.reward.segments = state.reward.segments.map(item => item.id === popup.id ? { ...item, ...popup } : item);

    },
        false,
        "form/setRewardPopup"
    ),
    deleteRewardSegment: (id: string) => set((state) => {

        state.reward.segments = state.reward.segments.filter((item) => item.id !== id);

    },
        false,
        "form/deleteRewardSegment"
    ),
    setRewardPage: (key, value) => set((state) => {

        state.reward.page[key] = value;

    },
        false,
        "form/setRewardPage"
    ),
    setRewardButtonTxt: (btn) => set((state) => {

        state.reward.buttonText = btn;

    },
        false,
        "form/setRewardButtonTxt"
    ),
    /* settings */
    setGeneralSettings: (params) => set((state) => {

        state.settings = { ...state.settings, ...params };

    },
        false,
        "form/setGeneralSettings"
    ),
    setSubmissionsSettings: (params) => set((state) => {

        state.settings.submissions = { ...state.settings.submissions, ...params };

    },
        false,
        "form/setSubmissionsSettings"
    ),
    /* custom label */
    setCustomLabel: (key, label) => set((state) => {

        if (!state.customLabels) return;

        if (!label) delete (state.customLabels as any)[key];
        else (state.customLabels as any)[key] = label;

    },
        false,
        "form/setCustomLabel"
    ),
    /* set email notification */
    setEmailNotification: (type, payload) => set((state) => {

        if (type === "me" && payload) state.emailNotification[type] = payload;

        if (type === "respondents" && payload) state.emailNotification[type] = payload;

    },
        false,
        "form/setEmailNotification"
    )
}))), {
    name: "FE - Form",
    enabled: import.meta.env.DEV
}));