import { TemplatePreview } from "./preview";
import { TemplateSettings } from "./settings";


export function SocialProofTemplateEditor() {

    return (
        <div className="w-full h-full max-h-full overflow-hidden flex">

            <TemplateSettings />

            <TemplatePreview />

        </div>
    );
}