import { TextToggle } from "@components/toggle";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Input } from "@components/input";
import { useFormStore } from "@store";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({ url: z.string().url("Enter a valid url.") });

export function CustomThankyouPage() {

    const customPage = useFormStore((state) => state.thankYou.customPage);

    const [showCustomPage, setShowCustomPage] = useState(customPage ? true : false);

    const { formState: { errors }, register, watch, setValue } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            url: ""
        },
        mode: "onChange",
        reValidateMode: "onChange"
    });

    const url = watch("url");

    useEffect(() => {

        setValue("url", customPage);

        setShowCustomPage(customPage ? true : false);

    }, []);

    useEffect(() => { useFormStore.getState().setCustomThankyouPage(url); }, [url]);

    return (
        <div>

            <TextToggle
                isActive={showCustomPage}
                name="Use Custom Thank Page"
                onClick={() => setShowCustomPage(!showCustomPage)}
            />

            <AnimatePresence>

                {showCustomPage ?
                    <motion.div
                        className="mt-2"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >

                        <Input
                            {...register("url")}
                            type="url"
                            label="Redirect Url"
                            placeholder="https://gozen.io/testimonials"
                            error={errors.url?.message}
                        />

                    </motion.div> : null
                }

            </AnimatePresence>

        </div>
    );
}