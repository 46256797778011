

export const DefaultLabels: Required<Form.State["customLabels"]> = {
    /* welcome */
    recordVideoBtn: "Record Video",
    writeTestimonialBtn: "Write Testimonial",
    /* response */
    selectMicrophone: "Select your microphone",
    selectCamera: "Select camera",
    recorderErrorMessage: "We can't access your camera. To enable access: click the lock icon in your browser's address bar, allow camera and microphone access, then reload this page. Also make sure that you have a camera connected.",
    responseSubmitBtn: "Submit",
    textTestimonialPlaceholder: "Write something nice",
    /* customer details */
    nameLabel: "Your Name",
    namePlaceHolder: "Richard Hendricks",
    emailLabel: "Email Address",
    emailPlaceholder: "rich@pidepiper.com",
    companyLabel: "Company",
    companyPlaceholder: "ex. LinkedIn",
    companyLogoLabel: "Company Logo",
    jobLabel: "Job Title",
    customFieldPlaceholder: "",
    selectPlaceholder: "",
    jobPlaceholder: "ex. Marketing at Linkedin.",
    photoLabel: "Your Photo",
    websiteLabel: "Your Website",
    websitePlaceholder: "https://yoursite.com",
    consent: "By submitting, you give us permission to use this testimonial across social channels and other marketing efforts",
    customDetailsSubmitBtn: "Submit",
    /* custom details fields */
    companyFieldRequired: "Company required.",
    companyField: "Enter a valid company name.",
    companyLogoFieldRequired: "Company logo required.",
    companyLogoField: "Enter a valid company logo",
    nameFieldRequired: "Name required.",
    nameField: "Enter a valid name",
    roleFieldRequired: "Role required.",
    roleField: "Enter a valid role.",
    emailFieldRequired: "Email required.",
    emailField: "Enter a valid email.",
    photoFieldRequired: "Photo required.",
    photoField: "Upload a photo",
    websiteFieldRequired: "Website required.",
    websiteField: "Enter a valid website.",
    /* custom customer details fields */
    custom_CheckBoxField: "Choose a option",
    custom_EmailField: "Enter a valid email",
    custom_EmailFieldRequired: "Email required.",
    custom_LongTextField: "Long text required.",
    custom_TextField: "Text required.",
    custom_UrlField: "Enter valid url",
    custom_UrlFieldRequired: "Url required.",
    /* file */
    maxFileSize: "Image too large. image should less 3mb.",
    invalidFileType: "invalid file type."
}; 