import { Button, SecondaryButton } from "@components/button";
import { Config } from "@config";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";


export function WidgetTemplatePreview() {

    const { id } = useParams();

    const navigate = useNavigate();

    const renderEngineUrl = Config.RenderEngineUrl() + "/widget/preview/" + id;


    return (
        <div className="h-full w-full bg-red-200">


            <div className="flex items-center justify-between w-full space-x-4 py-2 px-4 bg-white">

                <SecondaryButton
                    className="text-sm font-medium w-auto px-3"
                    onClick={() => navigate("/social-proof")}
                >

                    <MdArrowBackIos className="w-4 h-4 mr-1" />

                    <p> Back </p>

                </SecondaryButton>

                <div className="flex items-center space-x-4">

                    <SecondaryButton
                        className="mt-0 w-auto text-sm font-medium"
                        onClick={() => { }}
                    >
                        Resize
                    </SecondaryButton>

                    <Button
                        className="mt-0 w-auto text-sm font-medium"
                        onClick={() => { }}
                    >
                        Share
                    </Button>

                </div>

            </div>

            <iframe
                src={renderEngineUrl}
                className="w-full h-full"
            />

        </div>
    );
}