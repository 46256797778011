import { useInvitesStore, } from '@store';
import { Config } from '@config';
import { cn } from '@utils/cn';
import { Button } from "@components/button";
import { useActiveSpace } from '@hooks';


export function EmailTemplate() {

    const { formId } = useActiveSpace();

    const { message, logo, buttonColor, buttonText } = useInvitesStore((store) => ({
        message: store.template.message,
        logo: store.template.design.logo,
        buttonText: store.template.buttonText,
        buttonColor: store.template.design.buttonColor
    }));

    const url = `${Config.RenderEngineUrl()}/${formId}`;

    return (
        <div className={cn(`
            border shadow-md rounded-lg w-[75%] m-10 bg-white
            flex flex-col items-center justify-center p-6
        `)}>

            <img
                src={logo}
                className="w-[60px] h-[60px]"
            />

            <p
                className="text-base font-normal mt-3"
                dangerouslySetInnerHTML={{ __html: message }}
            />

            <Button
                disabled={true}
                className="w-full text-sm font-medium tracking-wide mt-2"
                style={{
                    backgroundColor: buttonColor,
                }}
            >
                {buttonText}
            </Button>


            <span className="w-full text-xs font-normal text-gray-600 mt-2 px-1">
                Button not working? Paste this URL in the browser <br />
                <a
                    href={url}
                    className="text-blue-400 hover:underline"
                >
                    {url}
                </a>
            </span>

        </div>
    );
}