
export const QueryKeys = {
    auth: {
        login: "login",
        signUp: "signup",
        resendVerificationMail: "resend-verification-mail",
        verifyToken: "verify-token",
        logout: "logout",
        changeEmail: "change-email",
        changePassword: "change-password",
        verifyEmail: "verify-email"
    },
    user: {
        getProfile: "get-profile",
        uploadAsserts: "upload-asserts",
        generateApiKey: "generate-api-key",
        rollApiKey: "roll-api-key",
        onBoarding: "save-onboarding",
        getKey: "get-api-key"
    },
    spaces: {
        getAll: "get-spaces",
        getDetails: "get-space-details",
        checkSlug: "check-slug-availability",
        create: "create",
        update: "update",
        delete: "delete"
    },
    form: {
        asserts: "upload-form-asserts",
        get: "get-form",
        update: "update-form",
        publish: "publish-form",
        getEmailTemplates: "get-email-notification-template",
        saveEmailTemplate: "save-email-notification-template"
    },
    testimonials: {
        delete: "delete-testimonial",
        getAll: "get-all-testimonials",
        export: "export-testimonials",
        saveContent: "save-testimonial-content",
        approve: "approve-testimonial",
        updateCustomerDetails: "update-customer-details",
        download: "download-testimonial",
        /* case study */
        caseStudy: {
            generate: "generate-case-study",
            delete: "delete-case-study",
            publish: "publish-case-study"
        }
    },
    socialProof: {
        getAll: "get-saved-social-proof",
        created: "create-social-proof",
        update: "update-social-proof",
        delete: "delete-social-proof",
    },
    emailInvites: {
        getAll: "get-all-email-invites",
        upload: "upload",
        sendInvite: "send-email-invites",
        deleteInvites: "delete-email-invites",
        getTemplate: "get-template",
        updateTemplate: "update-template"
    },
    teams: {
        getAll: "get-all-team-members",
        add: "add-team-member",
        update: "update-team-member",
        delete: "delete-team-member",
        reinvite: "reinvite-member",
        join: "join-team"
    },
    billings: {
        getCurrentPlan: "get-current-plan-details",
        getNewPlan: "get-new-plan",
        getSession: "get-session",
        getStatus: "get-status",
        subscription: {
            create: "subscription-create",
            update: "subscription-update",
            delete: "subscription-delete"
        }
    },
    domains: {
        add: "add-domain",
        remove: "remove-domain",
        verify: "verify-domain",
        get: "get-all-domains"
    },
    importTestimonials: {
        manual: "manual-import",
        import: "import-testimonials",
        twitter: "twitter-importer",
        instagram: "instagram-importer",
        linkedIn: "linkedin-importer",
        faceBook: "facebook-importer",
        tikTok: "tiktok-importer",
        youtube: "youtube-importer",
        trustpilot: "trustpilot-importer",
        capterra: "capterra-importer",
        amazon: "amazon-importer",
        airbnb: "airbnb-importer"
    },
    integrations: {
        getAll: "get-all-integrations",
        googleSheet: {
            create: "create-google-sheet-integration",
            getAuthUrl: "get-google-sheet-oauth-url"
        },
        createWebhook: "create-webhook-integration",
        delete: "delete-integration",
        toggle: "toggle-integration",
        getZapierKey: "get-zapier-api-key"
    }
};