import { Button, } from "@components/button";
import { QueryKeys, useSpacesApiClient } from "@hooks";
import { useFormStore } from "@store";
import { useMutationState } from '@tanstack/react-query';
import { cn } from "@utils/cn";
import { useNavigate } from "react-router-dom";
import { LuRefreshCw } from "react-icons/lu";
import { Text } from "@components/text";
import { MdOutlineCloudDone } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";

export function PublishForm() {

    const navigate = useNavigate();

    const published = useFormStore((store) => store.published);

    const savingMutation = useMutationState({
        filters: { status: "pending" },
        select: (mut) => mut.options.mutationKey
    });

    const saving = savingMutation && ((savingMutation[0] as any) == QueryKeys.form.update);

    const { getDetails } = useSpacesApiClient({});

    if (getDetails.isFetching) return null;

    return (
        <div className="flex items-center justify-center space-x-5">


            <AnimatePresence>

                {saving ?
                    <motion.div
                        className="flex items-center justify-center space-x-1 text-gray-400"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: 0.3
                        }}
                        title="saving..."
                    >

                        <LuRefreshCw className="w-5 h-5" />

                        <Text
                            text="Saving..."
                            className="text-xs font-medium"
                        />

                    </motion.div> :
                    <motion.div
                        className="text-gray-400"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            duration: 0.3
                        }}
                        title="saved"
                    >

                        <MdOutlineCloudDone className="w-5 h-5" />

                    </motion.div>
                }

            </AnimatePresence>

            <Button
                disabled={saving}
                text="Share"
                className={cn("w-auto py-1.5 text-sm font-medium tracking-wider mt-0", {
                    "opacity-90": saving
                })}
                onClick={() => {

                    if (published || saving === false) {
                        navigate(`form/share`);
                    }

                }}
            />

        </div>
    );
}