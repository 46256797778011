

export function TwitterIcon() {

    return (
        <svg width="30" height="30" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.7352 10.6244L16.686 17.7058H14.6542L10.6142 11.9274V11.927L10.0211 11.0787L5.30176 4.32812H7.33358L11.1421 9.77613L11.7352 10.6244Z" fill="black" />
            <path d="M19.6229 0H2.37715C1.06432 0 0 1.06432 0 2.37715V19.6229C0 20.9357 1.06432 22 2.37715 22H19.6229C20.9357 22 22 20.9357 22 19.6229V2.37715C22 1.06432 20.9357 0 19.6229 0ZM14.0323 18.6564L9.94411 12.7066L4.82566 18.6564H3.50279L9.35672 11.852L3.50279 3.33227H7.96769L11.8389 8.96639L16.6858 3.33227H18.0086L12.4265 9.82111H12.4261L18.4972 18.6564H14.0323Z" fill="black" />
        </svg>
    );
}