import * as Accordion from "@radix-ui/react-accordion";
import { cn } from "@utils/cn";
import { FaAngleDown } from "react-icons/fa";
import { TestimonialDetails } from "./testimonialDetails";
import { YourDetails } from "./yourDetails";
import { CaseStudySettings } from "./settings";


const tabs = [
    {
        icon: <></>,
        title: "Testimonial Details",
        value: "testimonialDetails",
        content: <TestimonialDetails />
    },
    {
        icon: <></>,
        title: "Your Details (optional)",
        value: "yourDetails",
        content: <YourDetails />
    },
    {
        icon: <></>,
        title: "Settings",
        value: "settings",
        content: <CaseStudySettings />
    },
];

export function Settings() {
    return (
        <div className="border w-[40%] h-full max-h-full overflow-y-auto bg-white rounded-lg shadow-sm relative">

            <Accordion.Root
                collapsible
                type="single"
                defaultValue={"testimonialDetails"}
            >

                {tabs.map((tab, idx) => (
                    <Accordion.Item
                        key={idx}
                        value={tab.value}
                        className={cn("border-b border-gray-200")}
                    >

                        <Accordion.Header
                            className={cn(`p-4 `)}
                        >

                            <Accordion.Trigger
                                className="flex items-center justify-between w-full"
                            >

                                <div className='flex items-center space-x-2'>

                                    {tab.icon}

                                    <p className="font-medium text-base tracking-wide">
                                        {tab.title}
                                    </p>

                                </div>

                                <FaAngleDown
                                    className={cn(`
                                        ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform 
                                        duration-300 group-data-[state=open]:rotate-180 text-gray-700
                                    `)}
                                />

                            </Accordion.Trigger>

                        </Accordion.Header>

                        <Accordion.Content
                            className={cn(`
                                data-[state=open]:border-t
                                data-[state=open]:animate-slideDown 
                                data-[state=closed]:animate-slideUp 
                                overflow-hidden p-4
                            `)}
                        >

                            {tab.content}

                        </Accordion.Content>

                    </Accordion.Item>
                ))}

            </Accordion.Root>

        </div>
    );
}