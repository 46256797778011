
type Props = {
    height?: string,
    width?: string;
};

export function FormsLogo({ height, width }: Props) {
    return (
        <svg width={height ?? "400"} height={width ?? "400"} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M375 0C205.583 0 66.2327 130.334 51.4663 295.966C79.6163 256.266 114.1 218.066 157.15 174.15C160.35 170.85 165.65 170.784 168.933 174.034C172.217 177.25 172.267 182.534 169.05 185.817C162.566 192.417 155.983 199.267 149.366 206.201C145.583 210.151 141.883 214.084 138.25 217.951C137.583 218.667 136.916 219.384 136.25 220.117C83.0499 277.084 45.0163 326.666 17.3991 388.25C15.5155 392.45 17.3991 397.384 21.5827 399.266C22.6991 399.766 23.8491 400 24.9991 400C28.1827 400 31.2327 398.166 32.6155 395.084C42.4491 373.167 53.8655 352.834 66.6991 333.05C183.099 330.066 289.049 265.316 344.583 162.284C345.966 159.7 345.899 156.584 344.399 154.05C342.883 151.55 340.165 150 337.233 150H306.366L361.716 118.366C363.466 117.366 364.816 115.75 365.466 113.85C377.333 79.35 383.333 43.85 383.333 8.33359C383.333 3.73359 379.6 0 375 0Z" fill="#2563EB" />
        </svg>
    );
};