import * as Accordion from '@radix-ui/react-accordion';
import { useSocialProofStore } from '@store';
import { FaAngleDown, FaRegHeart } from 'react-icons/fa';
import { FiEdit3 } from 'react-icons/fi';
import { MdOutlineDesignServices } from 'react-icons/md';
import { WallOfLove_Branding } from './branding';
import { WallOfLove_Content } from './content/main.content';
import { MdDomainVerification } from "react-icons/md";
import { WallOfLove_HeroSection } from './heroSection';
import { IoNavigate } from "react-icons/io5";
import { WallOfLove_Navigation } from './navigation';
import { cn } from '@utils/cn';

const Tabs = [
    // {
    //     icon: <BsBorderWidth />,
    //     title: "Reorder Testimonials",
    //     value: "reorderTestimonials",
    //     content: <ReorderTestimonials />
    // },
    {
        icon: <MdOutlineDesignServices className="w-5 h-5 text-yellow-600" />,
        title: "Branding",
        value: "branding",
        content: <WallOfLove_Branding />
    },
    {
        icon: <FiEdit3 className="w-5 h-5 text-green-600" />,
        title: "Content",
        value: "content",
        content: <WallOfLove_Content />
    },
    {
        icon: <MdDomainVerification className="w-5 h-5 text-blue-600" />,
        title: "Hero Section",
        value: "hero",
        content: <WallOfLove_HeroSection />
    },
    {
        icon: <IoNavigate className="w-5 h-5 text-orange-600" />,
        title: "Navigation",
        value: "navigation",
        content: <WallOfLove_Navigation />
    },
    // {
    //     icon: <IoPersonOutline className="w-5 h-5 text-pink-600" />,
    //     title: "Custom Domain",
    //     value: "customDomain",
    //     content: <WallOfLove_CustomDomain />
    // },
    // {
    //     icon: <FaAngleDown className="w-5 h-5 text-gray-600" />,
    //     title: "SEO",
    //     value: "SEO",
    //     content: <div className="p-4">More</div>
    // },
];

export function WallOfLoveSettings() {

    const selectedTestimonials = useSocialProofStore((store) => store.selectedTestimonials);

    return (
        <div className="p-4 border-r h-full max-h-full overflow-y-auto">

            <Accordion.Root
                type="single"
                defaultValue="item-1"
                collapsible
            >

                <div
                    className="flex items-center justify-between w-full p-4 cursor-pointer"
                    onClick={() => useSocialProofStore.getState().setActiveImageTemplateTab("selectTestimonials")}
                >

                    <div className="flex items-center space-x-2">

                        <FaRegHeart className="w-4 h-4 text-primary" />

                        <p className="font-medium text-sm tracking-wide">
                            Select Testimonials
                        </p>

                    </div>

                    <p className="px-1">
                        {selectedTestimonials.length}
                    </p>

                </div>

                {Tabs.map((tab, idx) => {
                    return (
                        <Accordion.Item
                            key={idx}
                            value={tab.title}
                            className={cn({
                                "border-t": idx === 0,
                                "border-y": idx > 0 && idx < 6,
                            })}
                        >

                            <Accordion.Header className={cn(`p-4 `)}>

                                <Accordion.Trigger
                                    className="flex items-center justify-between w-full"
                                    onClick={() => useSocialProofStore.getState().setActiveImageTemplateTab("template")}
                                >

                                    <div className="flex items-center space-x-2">

                                        {tab.icon}

                                        <p className="font-medium text-sm tracking-wide">
                                            {tab.title}
                                        </p>

                                    </div>

                                    <FaAngleDown
                                        className={cn(`
                                            ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform 
                                            duration-300 group-data-[state=open]:rotate-180 text-gray-700
                                        `)}
                                    />

                                </Accordion.Trigger>

                            </Accordion.Header>

                            <Accordion.Content
                                className={cn(`
                                    data-[state=open]:border-t
                                    data-[state=open]:animate-slideDown 
                                    data-[state=closed]:animate-slideUp 
                                    overflow-hidden p-2
                                `)}
                            >

                                {tab.content}

                            </Accordion.Content>

                        </Accordion.Item>
                    );
                })}

            </Accordion.Root>

        </div>
    );
}