import { Button } from "@components/button";
import { Input, InputError } from "@components/input";
import { Popup } from "@components/popup";
import { cn } from "@utils/cn";
import { z } from "zod";
import toast from "react-hot-toast";
import { useCommonStore } from "@store";
import { UploadPicture } from "@components/upload";
import { useQueryClient } from "@tanstack/react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { REGX } from "@utils/regx";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, } from "react";
import { CgSpinner } from "react-icons/cg";
import { QueryKeys, useSpacesApiClient } from "@hooks";


const maxFileSize = 1024 * 1024; // allowed size in bytes
const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = z.object({
    logo: z.any().optional().or(
        z.any()
            .refine((file: File) => file !== null, "Please choose a file.")
            .refine((file: File) => file?.size <= maxFileSize, "Your image should be under 1mb.")
            .refine((file: File) => allowedImageTypes.includes(file?.type), "Only jpeg, jpg and png formats are allowed.")
    ),
    name: z.string().min(4).max(50),
    slug: z.string().min(4).max(50),
    site: z.string().refine((val: string) => {

        if (val) return REGX.urlWithoutProtocol.test(val);

        return true;

    }, "Enter a valid url.")
});

type SchemaType = z.infer<typeof schema>;

export function CreateSpacePopup() {

    const queryClient = useQueryClient();

    const {
        formState: { errors },
        register, setError, watch, setValue, handleSubmit, clearErrors
    } = useForm<SchemaType>({
        resolver: zodResolver(schema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            logo: null,
            name: "",
            slug: "",
            site: ""
        }
    });

    const spaceLogo = watch("logo") as File | null;

    const slug = useDebounce(watch("name"), 300);

    const { checkSlug, create, getAll, getDetails } = useSpacesApiClient({
        checkSlugParams: {
            apiParams: { slug },
            params: {
                enabled: false,
                retry: 1,
            }
        }
    });

    useEffect(() => {

        setValue("slug", slug);

        if (slug) {
            checkSlug.refetch();
            onCheck();
        }

    }, [slug]);

    const onCheck = async () => {

        await checkSlug.refetch();

        const { isError, error } = checkSlug;
        const data = checkSlug.data as Api.SpacesClient.checkSlug;

        if (isError) {
            toast.error(error?.message ?? "");
            return;
        }

        if (data.available && errors.slug?.message) clearErrors("slug");

        if (data.available === false) {
            setError("slug", { message: "This is slug is not available." });
            return;
        }

    };

    const onCreate = async (data: any) => {
        try {

            const newSpace = await create.mutateAsync(data);

            if (create.isError) {
                toast.error(create.error.message);
                return;
            }

            let queryData = queryClient.getQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

            if (!queryData) return;

            queryData.activeSpace = { ...newSpace };
            queryData.spaces.push(newSpace);

            queryClient.setQueryData([QueryKeys.spaces.getAll], queryData);

            getAll.refetch();

            /* get new space details */
            getDetails.refetch();

            toast.success("spaces created successfully.");

            useCommonStore.getState().setActivePopup("");

        } catch (err) {
            console.log(err);
        }
    };

    const onSubmit: SubmitHandler<SchemaType> = (data) => {

        const form = new FormData();

        form.append("file", data.logo);
        form.append("site", data.site ?? "");
        form.append("name", data.name);
        form.append("slug", data.slug);

        onCreate(form);

    };

    return (
        <Popup
            width="35%"
            name="Create Space"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <form
                className="px-2 py-1 space-y-3"
                onSubmit={handleSubmit(onSubmit)}
            >

                <div className="space-y-2 mt-1 ">

                    <UploadPicture
                        id="upload-new-space-logo"
                        file={spaceLogo}
                        title="Logo"
                        titleClassName="text-sm font-medium text-gray-700"
                        imgClassName="w-[50px] h-[50px]"
                        onChange={(img) => setValue("logo", img)}
                    />

                    <Input
                        {...register("name")}
                        label="Space Name"
                        required={true}
                        type="text"
                        placeholder="Enter your space name"
                        error={(errors.name?.message ?? null)}
                        onClick={() => clearErrors("name")}
                    />

                    <div className="my-2 space-y-[2px] relative">

                        <label className="text-sm font-normal c text-gray-700 mx-1">
                            Public link
                            <span className="text-red-500 text-base"> * </span>
                            <span></span>
                        </label>

                        <div className={cn(`
                            flex items-center p-2 rounded-md border border-gray-300 bg-transparent px-3 text-sm py-2 
                            hover:outline-none hover:ring-1 hover:ring-blue-400 hover:ring-offset-1
                        `, {
                            "border-red-300 hover:ring-red-300": errors.slug?.message
                        })}>

                            <p className="text-sm"> testimonial.gozen.io/ </p>

                            <input
                                {...register("slug")}
                                type="text"
                                className="outline-none border-none w-full px-1 text-sm"
                                placeholder="Enter your public link"
                                onClick={() => clearErrors("slug")}
                            />


                        </div>

                        {checkSlug.isFetching ?
                            <CgSpinner className="absolute top-8 right-2 w-6 h-6 animate-spin text-primary" /> :
                            null
                        }

                        {errors.slug?.message ? <InputError error={errors.slug.message} /> : null}

                    </div>

                    <Input
                        {...register("site")}
                        label="Site (optional)"
                        required={false}
                        type="text"
                        placeholder="gozen.io"
                        error={(errors.site?.message ?? null)}
                        onClick={() => clearErrors("site")}
                    />

                </div>


                <Button
                    disabled={checkSlug.isFetching || create.isPending}
                    loading={create.isPending}
                    type="submit"
                    text="Create New Space"
                    className="tracking-wide text-base font-medium"
                />


            </form>

        </Popup>
    );
}