import { ReactNode } from "react";
import { cn } from "@utils/cn";
import { FacebookIcon } from "@components/icons";
import { TwitterIcon } from "./icons/twitter";
import { LinkedInIcon } from "./icons/linkedIn";
import { EmailIcon } from "./icons/email.icon";


export function SocialCard() {

    const formName = "";
    const formUrl = "";


    const onFacebookClick = () => {

        const fbLink = `https://www.facebook.com/sharer/sharer.php?u=${formUrl}`;

        window.open(fbLink);
    };

    const onTwitterClick = () => {

        const twitterShareUrl = `http://twitter.com/share?text=${formName}&url=${formUrl}`;

        window.open(twitterShareUrl);
    };

    const onLinkedInClick = () => {

        const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${formUrl}`;

        window.open(linkedInUrl);
    };

    const onEmailClick = () => {

        const emailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${formName}&body='${formUrl}'&ui=2&tf=1&pli=1`;

        window.open(emailLink);
    };

    return (
        <div className={cn(`
            flex-col items-start justify-center p-4 px-5 bg-white rounded-lg
            border border-gray-300 shadow-sm space-y-2
        `)}>

            <div>

                <h2 className="text-lg font-semibold tracking-wide">
                    Share on socials
                </h2>

                <p className="text-sm font-normal text-gray-500 tracking-wide">
                    Share your form on social channels and messaging apps with one click.
                </p>

            </div>

            <div className="flex items-center space-x-4 pt-2">

                <Icon
                    icon={<FacebookIcon />}
                    onClick={() => onFacebookClick()}
                />

                <Icon
                    icon={<TwitterIcon />}
                    onClick={() => onTwitterClick()}
                />

                <Icon
                    icon={<LinkedInIcon />}
                    onClick={() => onLinkedInClick()}
                />

                <Icon
                    icon={<EmailIcon />}
                    onClick={() => onEmailClick()}
                />

            </div>

        </div >
    );
}

type IconProps = {
    icon: ReactNode,
    onClick: () => void;
};

const Icon: React.FC<IconProps> = ({ icon, onClick }) => {
    return (
        <div
            className={`
                bg-white rounded-lg p-1 cursor-pointer transform 
                flex items-center justify-center
                transition-all duration-300 ease-in-out
                border shadow-sm hover:shadow-md hover:scale-105

            `}
            onClick={() => onClick()}
        >
            <div className="h-[30px] w-[30px] flex items-center justify-center">
                {icon}
            </div>
        </div>
    );
};