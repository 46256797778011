import { ColorPicker } from "@components/colorPicker";
import { TextToggle } from "@components/toggle";
import { useSocialProofStore } from "@store";


export function ImageTemplateBackground() {

    const { color, transparent } = useSocialProofStore((store) => store.imageTemplate.background);

    return (
        <div className="space-y-4">

            <ColorPicker
                defaultColor={color ?? "#fff"}
                label="Background Color"
                onChange={(val) => useSocialProofStore.getState().setImageTemplateBackground({
                    color: val
                })}
            />

            <TextToggle
                isActive={transparent}
                name="Show Profile Picture"
                onClick={() => useSocialProofStore.getState().setImageTemplateBackground({
                    transparent: !transparent
                })}
            />

        </div>
    );
}