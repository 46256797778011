import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { WelcomeVideo } from "./introVideo";
import { QuillInput } from "@components/quillInput";
import { useFormStore } from "@store";


export function WelcomePage() {

    const { title, description, allowed, } = useFormStore((state) => ({
        title: state.welcome.title,
        description: state.welcome.description,
        allowed: state.welcome.allowedTestimonialTypes,
    }));

    return (
        <div className="space-y-2">

            <div className="space-y-1">

                <Text
                    variant="label"
                    text="Title"
                />

                <QuillInput
                    recall={true}
                    valueAsHTML={title}
                    placeHolder="Enter your title"
                    onChange={(e) => useFormStore.getState().setWelcomeTitle(e.html)}
                />

            </div>

            <div className="space-y-1">

                <Text
                    text="Message"
                    variant="label"
                />

                <QuillInput
                    recall={true}
                    valueAsHTML={description}
                    placeHolder="Enter your description"
                    onChange={(e) => useFormStore.getState().setWelcomeDescription(e.html)}
                />

            </div>

            <WelcomeVideo />

            <div className="space-y-2">

                <Text
                    text="Allowed Testimonial types"
                    variant="label"
                />

                <TextToggle
                    isActive={allowed.includes("video")}
                    name="Collect Video Testimonials"
                    onClick={() => useFormStore.getState().setAllowedTestimonialType("video")}
                />

                <TextToggle
                    isActive={allowed.includes("text")}
                    name="Collect written Testimonials"
                    onClick={() => useFormStore.getState().setAllowedTestimonialType("text")}
                />

                <TextToggle
                    isActive={allowed.includes("audio")}
                    name="Collect audio Testimonials"
                    onClick={() => useFormStore.getState().setAllowedTestimonialType("audio")}
                />

            </div>


        </div>
    );
}