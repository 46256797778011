import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { MdClose } from 'react-icons/md';
import { BiError } from 'react-icons/bi';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    error?: string;
    children?: ReactNode,
    showIcon?: boolean,
    showClose?: boolean,
    onClose?: () => void;
}

export function ErrorBadge({ children, error, className, showIcon = true, showClose = true, onClose, ...props }: Props) {
    return (
        <div
            {...props}
            className={cn(`
                bg-[#ffc3c3] py-2 px-4 rounded-md flex items-center 
                transition-all duration-300 ease-in-out
            `, className)}
        >

            {showIcon ? <BiError className="w-6 h-6 text-red-900" /> : null}

            <div className="w-full ml-3 text-base font-medium text-red-900">
                {error}
                {children}
            </div>

            {showClose ?
                <MdClose
                    className="w-5 h-5 text-red-900 cursor-pointer"
                    onClick={() => onClose?.()}
                /> : null
            }

        </div>
    );
}