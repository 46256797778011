
type Props = {
    classNames?: string,
    active?: boolean
}

export const SideTabIcon: React.FC<Props> = ({classNames, active}) => {
    return (
        <svg  
            className={`${classNames} ${active ? 'text-primary' : 'text-font_secondary'}`}
            width="106" 
            height="74" 
            viewBox="0 0 106 74" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="0.5" y="0.5" width="105" height="73" rx="5.5" fill="white" stroke="currentColor"/>
            <rect x="62" y="12" width="36" height="50" rx="4" fill="currentColor"/>
            <rect x="56" y="29" width="4" height="16" rx="2" fill="currentColor"/>
        </svg>
    );
};