import { useSocialProofApiClient } from "@hooks";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Loading } from "@components/loading";
import { Tabs } from "@components/tabs";
import { MdOutlineWidgets, MdSave } from "react-icons/md";
import { GoImage } from "react-icons/go";
import { LuLayout, LuVideo } from "react-icons/lu";
import { EmptyScreen } from "./empty";
import { ImageTemplates, VideoTemplates, WallOfLoveTemplates, WidgetTemplates } from "./data";
import { Fragment } from "react";
import { PreviewCard } from "./previewCard";

const tabsList = [
    {
        title: "Saved",
        icon: <MdSave className="w-5 h-5" />,
        value: "saved",
    },
    {
        title: "Images",
        icon: <GoImage className="w-5 h-5" />,
        value: "images",
    },
    {
        title: "Widgets",
        icon: <MdOutlineWidgets className="w-5 h-5" />,
        value: "widgets",
    },
    {
        title: "Videos",
        icon: <LuVideo className="w-5 h-5" />,
        value: "videos",
    },
    {
        title: "Wall of Love",
        icon: <LuLayout className="w-5 h-5" />,
        value: "wall-of-love",
    }
];

export function SocialProofPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get("tab") as TabsTypes ?? null;

    const { getAll } = useSocialProofApiClient({});

    const isLoading = getAll.isLoading || getAll.isFetching;

    const templates = getAll.data as Api.SocialProof.getAll;

    const onChange = (tab: TabsTypes) => {
        searchParams.set("tab", tab);
        setSearchParams(searchParams);
    };

    return (
        <div className="h-full w-full space-y-2 bg-gray-100">

            <div className="px-6 pt-6">

                <p className="text-xl font-semibold tracking-wide">
                    Social Proof Studio
                </p>

                <p className="text-sm font-normal text-gray-600 tracking-wide">
                    Turn your testimonials into shareable social proof.
                </p>

            </div>

            <div className="m-6 h-[82%] w-[95%]">

                <Tabs
                    className="mt-4 mx-0 h-full w-full"
                    defaultValue={searchParams.get("tab") || "saved"}
                >

                    <Tabs.List className="space-x-2 px-3 h-auto">

                        {tabsList.map((tab, idx) => (
                            <Tabs.Trigger
                                key={idx}
                                value={tab.value}
                                className="data-[state=active]:bg-primary data-[state=active]:text-white space-x-2 border"
                                onClick={() => onChange(tab.value as any)}
                            >

                                {tab.icon}

                                <p>
                                    {tab.title}
                                </p>

                            </Tabs.Trigger>
                        ))}

                    </Tabs.List>

                    {tabsList.map((tab, idx) => (
                        <Tabs.Content
                            key={idx}
                            value={tab.value}
                            className="w-full h-[95%] mt-2"
                        >

                            {isLoading ?
                                <Loading /> :
                                <Fragment>

                                    {activeTab === "saved" && !templates ?
                                        <EmptyScreen /> :
                                        <ListTemplates type={tab.value} />
                                    }

                                </Fragment>
                            }

                        </Tabs.Content>
                    ))}

                </Tabs>

            </div>

        </div>
    );
}

function ListTemplates({ type }: { type: string; }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex items-center flex-wrap gap-6 transition-all duration-300 ease-in-out"
        >

            {type === "images" ?
                <Fragment>

                    {ImageTemplates.map((item, idx) => (<PreviewCard key={idx} {...item} />))}

                </Fragment> : null
            }

            {type === "videos" ?
                <div className="space-x-4 flex items-start">

                    <div className="flex items-center space-x-4">

                        {VideoTemplates.slice(0, 1).map((item, idx) => (
                            <PreviewCard
                                key={idx}
                                {...item}
                                className="h-[380px]"
                            />
                        ))}

                    </div>

                    <div className="flex items-center space-x-4">

                        {VideoTemplates.slice(1, 2).map((item, idx) => (
                            <PreviewCard
                                key={idx}
                                {...item}
                            />
                        ))}

                    </div>


                </div> : null
            }

            {type === "widgets" ?
                <Fragment>

                    {WidgetTemplates.map((item, idx) => (<PreviewCard key={idx} {...item} />))}

                </Fragment> : null
            }

            {type === "wall-of-love" ?
                <Fragment>

                    {WallOfLoveTemplates.map((item, idx) => (<PreviewCard key={idx} {...item} />))}

                </Fragment> : null
            }

        </motion.div>
    );
}