import { useInvitesStore } from "@store";
import { UndefinedInitialDataOptions, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCustomQuery } from "../useCustomQuery";
import toast from "react-hot-toast";
import { ParseAxiosError } from "@lib/axios";
import { QueryKeys } from "./keys";
import { useActiveSpace } from "../useActiveSpace";

type Props = {
    getAll?: {
        apiParams: {
            formId: string,
            id?: string,
            next?: boolean,
            page?: number;
        },
        params: Partial<UndefinedInitialDataOptions>,
    },
};

export function useEmailInvitesApiClient({ getAll }: Props) {

    const { formId } = useActiveSpace();

    const getAllQuery = useQuery({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: [QueryKeys.emailInvites.getAll],
        queryFn: async () => {
            try {

                return (await axios.get<Api.EmailInvites.GetAll>("/api/v1/invites", {
                    params: {
                        ...getAll?.apiParams,
                        formId
                    }
                })).data;

            } catch (err) {
                throw err;
            }
        },
        ...getAll?.params
    });

    const sendInvite = useMutation({
        mutationKey: [QueryKeys.emailInvites.sendInvite],
        mutationFn: async (invites: { email: string, name: string; }[]) => {
            try {

                return (await axios.post<Api.EmailInvites.SendInvites>("/api/v1/invites", { formId, invites })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const deleteInvites = useMutation({
        mutationKey: [QueryKeys.emailInvites.deleteInvites],
        mutationFn: async () => {
            try {

                const ids = useInvitesStore.getState().selectedRowsToDelete;

                return (await axios.delete("/api/v1/invites", {
                    data: {
                        formId,
                        ids
                    }
                })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    /* email template */

    const getTemplateQuery = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.emailInvites.getTemplate],
        queryFn: async () => {
            try {

                return (await axios.get<Api.EmailInvites.getTemplate>("/api/v1/invites/template", {
                    params: {
                        id: formId
                    }
                })).data;

            } catch (err) {
                throw err;
            }
        },
        onError: (err: any) => toast.error(ParseAxiosError(err)),
        onSuccess: (data: Api.EmailInvites.getTemplate) => {

            useInvitesStore.getState().setTemplate(data.template);

        }
    });

    const saveTemplate = useMutation({
        mutationKey: [QueryKeys.emailInvites.updateTemplate],
        mutationFn: async () => {
            try {

                const template = useInvitesStore.getState().template;

                return (await axios.put("/api/v1/invites/template", { formId, ...template })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    return {
        getAll: getAllQuery,
        sendInvite,
        deleteInvites,
        getTemplate: getTemplateQuery,
        saveTemplate
    };

}