import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { ReactNode } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    isActive: boolean;
    icon: ReactNode,
    disable?: boolean,
    text: string,
}

export function NavItem({ isActive, className, icon, text, disable, ...props }: Props) {

    const { showFullNavbar } = useCommonStore();

    return (
        <div
            {...props}
            className={cn(`
                flex items-center rounded-lg cursor-pointer hover:text-primary overflow-hidden
                transition-all duration-300 p-3 select-none
            `, {
                "text-primary": isActive,
                "bg-blue-50": isActive && showFullNavbar,
                "hover:bg-blue-50": showFullNavbar
            })}
        >

            <div>
                {icon}
            </div>

            <span
                className={cn(`whitespace-nowrap text-base tracking-wide font-medium pl-2 transition-all duration-500 ease-in-out`, {
                    "pl-0 opacity-0 translate-x-1 overflow-hidden pointer-events-none": !showFullNavbar
                })}
            >
                {text}
            </span>

        </div>
    );
}