import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { HTMLMotionProps, motion } from "framer-motion";
import { Item } from "./item";
import { Head } from "./head";
import { Body } from "./body";

interface RootProps extends HTMLMotionProps<"div"> {
    children: ReactNode;
};

const Root: React.FC<RootProps> = ({ children, className, ...props }) => {
    return (
        <motion.div
            {...props}
            className={cn(className)}
            initial={{
                height: 0,
                opacity: 0
            }}
            animate={{
                height: "auto",
                opacity: 1
            }}
        >
            {children}
        </motion.div>
    );
};




export const Accordion = Object.assign(Root, { Item, Head, Body });