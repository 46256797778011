import { useCallback, useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import WebFont from 'webfontloader';
import Fonts from '@utils/constants/fonts.json';
import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useClickAway, useIsFirstRender } from "@uidotdev/usehooks";
import { IoSearch } from "react-icons/io5";
import { useDebouncedState } from "@hooks";
import { useFormStore } from "@store";

type Props = {
    defaultFontFamily?: string,
    onChange: (font: string) => void;
};

export function FontFamily({ defaultFontFamily, onChange }: Props) {

    const observer = useRef<any>();
    const isFirstRender = useIsFirstRender();

    const [show, setShow] = useState(false);
    const [fontFamilies, setFontFamilies] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [font, setFont] = useState(defaultFontFamily ?? "");
    const [search, setSearch, changed] = useDebouncedState<string>("", 200);

    let currIdx = 0;

    const ref = useClickAway<HTMLDivElement>(() => {
        setShow(false);
        setSearch("");
    });

    useEffect(() => {

        const defaultFontFamily = useFormStore.getState().design.fontFamily;

        setFont(defaultFontFamily);

        downloadFonts();

    }, []);

    useEffect(() => {

        if (isFirstRender === false && search) {

            search.toLowerCase();

            let result = Fonts.filter(font => new RegExp(search, "i").test(font.toLowerCase())).slice(0, 20);

            setLoading(!loading);

            WebFont.load({ google: { families: [...result] } });

            setFontFamilies([...result]);

            setLoading(!loading);

        }

        if (isFirstRender === false && !search) {
            setFontFamilies([]);
            downloadFonts();
        }

    }, [changed]);

    const handelObserver = useCallback((elements: any) => {

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver((elements) => {

            if (elements[0].isIntersecting && currIdx < 1100) {

                setTimeout(() => downloadFonts(), 0);
            }
        });

        if (elements) observer.current.observe(elements);

    }, []);

    const downloadFonts = async () => {
        try {

            let families = Fonts.slice(currIdx, currIdx + 50);

            setLoading(!loading);

            WebFont.load({ google: { families: [...families] } });

            currIdx += 50;

            setFontFamilies((prev) => [...prev, ...families]);

            setLoading(!loading);

        } catch (err) {
            console.log(err);
        }
    };

    const onClick = async (newFont: string): Promise<void> => {

        setShow(!show);

        setFont(newFont);

        onChange(newFont);

    };

    return (
        <div
            ref={ref}
            className="relative space-y-1"
        >

            <Text
                variant="label"
                text={"Font Family"}
            />

            {show ?
                <div
                    ref={ref}
                    className={cn(`border border-gray-400 rounded-lg shadow-md`)}
                >

                    <div className="flex items-center justify-between w-full border rounded-t-lg">

                        <IoSearch className="w-5 h-5 mx-2" />

                        <input
                            type="text"
                            className="p-2 outline-none w-full rounded-r-lg text-sm"
                            placeholder="Roboto"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />

                    </div>

                    <div className={cn(`
                        w-full h-auto max-h-[200px] text-sm overflow-y-auto rounded-b-lg p-2
                        leading-7 cursor-pointer bg-white border border-t-0
                    `)}>

                        <ul className={cn(``)}>

                            {fontFamilies.map((font, idx) => {

                                if (idx === fontFamilies.length - 20) {
                                    return (
                                        <li
                                            key={idx}
                                            style={{ fontFamily: font }}
                                            ref={handelObserver}
                                            onClick={() => onClick(font)}
                                            className={cn(`
                                                px-3 hover:bg-primary rounded-md
                                                transition-all duration-300 ease-in-out
                                            `)}
                                        >
                                            {font}
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            key={idx}
                                            style={{ fontFamily: font }}
                                            onClick={() => onClick(font)}
                                            className={cn(`
                                                px-3 py-1 hover:bg-gray-200 text-15px rounded-md
                                                transition-all duration-300 ease-in-out
                                            `)}
                                        >
                                            {font}
                                        </li>
                                    );
                                }
                            })}
                        </ul>

                    </div>

                </div> :
                <div
                    onClick={() => setShow(!show)}
                    style={{
                        fontFamily: font.length === 0 ? "" : font
                    }}
                    className={cn(`
                        relative flex items-center justify-between px-3 py-2 border border-gray-300 
                        rounded-lg cursor-pointer min-w-200px group
                    `)}
                >

                    <span
                        className="text-sm font-normal text-font_primary text-opacity-80"
                        style={{
                            fontFamily: font
                        }}
                    >
                        {font.length === 0 ? "DM Sans" : font}
                    </span>

                    <MdKeyboardArrowDown />

                </div>
            }

        </div>
    );
}