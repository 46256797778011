import { cn } from "@utils/cn";
import { useSearchParams } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { TestimonialSourceTypes } from "@components/icons";
import { useImportTestimonialStore } from "@store";


export function Providers() {

    const [searchParams, setSearchParams] = useSearchParams();

    const active = searchParams.get("import");

    useEffect(() => {

        if (!active) {
            searchParams.set("import", "text");
            setSearchParams(searchParams);
        }

    }, []);

    return (
        <div className="bg-white inline-block px-4 py-6 space-y-2 w-[26%] overflow-y-auto h-full max-h-full">

            <div className="space-y-1">

                {TestimonialSourceTypes.map((list, idx) => (
                    <List
                        key={idx}
                        {...list}
                        isActive={active === list.value}
                    />
                ))}

            </div>

        </div>
    );
}

type ListProps = {
    isActive: boolean,
    icon: ReactNode,
    name: string,
    value: string,
};


const List = ({ isActive, icon, name, value }: ListProps) => {

    const [searchParams, setSearchParams] = useSearchParams();

    const onClick = (provider: string) => {

        searchParams.set("import", provider);

        setSearchParams(searchParams);

        useImportTestimonialStore.getState().setSource(provider as any);

    };

    return (
        <div
            className={cn(`
                hover:bg-blue-100 cursor-pointer px-4 py-2 rounded-md flex items-center
                transition-all duration-300 ease-in-out
            `, {
                "bg-blue-100": isActive
            })}
            onClick={() => onClick(value)}
        >

            <div className="mr-3">

                {icon}

            </div>

            <p className="text-sm font-medium">
                {name}
            </p>

        </div>
    );
};