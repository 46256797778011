import { ColorPicker } from "@components/colorPicker";
import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";
import { useState } from "react";


export function GradientBackground() {

    const gradient = useFormStore((store) => store.design.background.gradient);

    const colors = gradient && gradient?.colors ? gradient?.colors : ["#eeeeee", "#eeeeee", "#eeeeee", "#eeeeee"];
    const animate = gradient && gradient?.animate ? gradient?.animate : false;

    const [isActive, setIsActive] = useState(gradient && gradient.colors ? true : false);

    const onChange = (idx: number, color: string) => {

        let newColors = [...colors];

        newColors[idx] = color;

        useFormStore.getState().setGradientBg({ colors: newColors });

    };

    return (
        <div className="mt-2">

            <TextToggle
                name="Use Gradient Background"
                isActive={isActive}
                onClick={() => setIsActive(!isActive)}
            />

            {isActive ?
                <div className="mt-1 space-y-4">

                    <div className="flex items-center space-x-4">

                        <ColorPicker
                            label="Color 1"
                            defaultColor={colors[0]}
                            onChange={(color1) => onChange(0, color1)}
                        />

                        <ColorPicker
                            label="Color 2"
                            defaultColor={colors[1]}
                            onChange={(color2) => onChange(1, color2)}
                        />

                    </div>

                    <div className="flex items-center space-x-4">

                        <ColorPicker
                            label="Color 3"
                            defaultColor={colors[3]}
                            onChange={(color3) => onChange(2, color3)}
                        />

                        <ColorPicker
                            label="Color 4"
                            defaultColor={colors[3]}
                            onChange={(color4) => onChange(3, color4)}
                        />

                    </div>

                    <TextToggle
                        name={"Animate gradient"}
                        isActive={animate}
                        onClick={() => { useFormStore.getState().setGradientBg({ animate: !animate }); }}
                    />

                </div> : null
            }

        </div>
    );
}