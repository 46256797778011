import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { Select } from "@components/select";
import { TextToggle } from "@components/toggle";
import { useEmbedStore } from "@store";

const options = [
    { label: "Left", value: "left" },
    { label: "Right", value: "right" }
];

export const SliderSettings: React.FC = () => {

    const { buttonColor, textColor, text, dir, removeBackground } = useEmbedStore((store) => store.slider);

    const activeDir = options.filter(item => item.value === dir)[0];

    return (
        <div className="space-y-2">

            <ColorPicker
                label="Button Color"
                defaultColor={buttonColor}
                onChange={(e) => useEmbedStore.getState().setSlider({ buttonColor: e })}
            />

            <ColorPicker
                label="Button Text Color"
                defaultColor={textColor}
                onChange={(e) => useEmbedStore.getState().setSlider({ textColor: e })}
            />

            <Input
                label="Button Text"
                value={text}
                onChange={(e) => useEmbedStore.getState().setSlider({ text: e.target.value })}
            />

            <Select
                options={options}
                defaultValue={activeDir}
                onChange={(e: any) => useEmbedStore.getState().setSlider({ dir: e })}
            />

            <TextToggle
                name="Remove Background"
                isActive={removeBackground}
                onClick={() => useEmbedStore.getState().setSlider({ removeBackground: !removeBackground })}
            />

        </div>
    );
};