import { useEffect } from "react";
import { useParams } from "react-router-dom";


export function OAuthPage() {

    const { app } = useParams<{ app: string; }>();

    useEffect(() => (window as any).top.close(), []);

    useEffect(() => {

        const code = new URLSearchParams(window.location.search).get("code") as string;

        if (code && code.length !== 0) {

            switch (app) {

                case "googleSheet":
                    localStorage.setItem("_c", code);
                    break;

                default:
                    break;
            }
        }

    }, [window.location]);

    return <></>;
}