import { cn } from "@utils/cn";
import { MdContentCopy } from "react-icons/md";
import { Button } from "@components/button";


export function CustomDomainCard() {

    const formUrl = "https://pp.testimonial.gozen.io";

    return (
        <div className={cn(`
            flex-col items-start justify-center p-4 px-5 bg-white rounded-lg
            border border-gray-200 shadow-sm space-y-2
        `)}
        >

            <div className="relative">

                <h2 className="text-lg font-semibold tracking-wide">
                    Custom Url
                </h2>

                <div className="blur-sm">

                    <p className="text-sm font-normal text-gray-500 tracking-wide">
                        Collect and share testimonials with your branded URL like love.nnmnm.com.
                    </p>

                    <div className="font-normal text-base inline-flex items-center w-full pt-1">

                        <p className={cn(`
                    w-full font-medium text-left bg-gray-100 border p-[6px] 
                    text-gray-600 rounded-l-lg px-2 tracking-wide 
                `)}>
                            {formUrl}
                        </p>

                        <div
                            className={cn(`
                        bg-primary text-white px-4 py-2.5 rounded-lg rounded-l-none 
                        inline-flex space-x-2 items-center text-sm h-10 cursor-pointer
                        transition-all duration-300 ease-in-out
                        hover:bg-primary/80
                    `)}
                            onClick={() => { }}
                        >
                            <MdContentCopy className="w-5 h-5" />
                        </div>

                    </div>

                </div>


                <Button
                    text="Upgrade"
                    className="absolute top-1/2 left-[45%] w-auto text-sm font-medium tracking-wide"
                />

            </div>

        </div>
    );
}