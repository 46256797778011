
type Props = {
    height?: string,
    width?: string;
};

export function GrowthLogo({ height, width }: Props) {
    return (
        <svg width={height ?? "400"} height={width ?? "400"} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M320.311 279.707C305.806 303.826 279.38 319.961 249.186 319.961H174.978C144.784 319.961 118.359 303.826 103.854 279.708C104.243 279.366 104.64 279.027 105.047 278.693L159.486 234.006L181.128 248.355C199.89 260.793 224.275 260.793 243.037 248.355L264.68 234.006L319.119 278.693C319.525 279.027 319.923 279.365 320.311 279.707Z" fill="#06152D" />
            <path d="M332.123 189.294V237.024C332.123 244.395 331.161 251.542 329.357 258.346L279.145 224.417L332.123 189.294Z" fill="#06152D" />
            <path d="M95.3911 260.442L146.879 225.651L92.0391 189.294V237.024C92.0391 245.157 93.2095 253.016 95.3911 260.442Z" fill="#06152D" />
            <path d="M332.123 166.31L232.45 232.391C220.104 240.576 204.058 240.576 191.712 232.391L92.0391 166.31V162.816C92.0391 117.01 129.172 79.8776 174.977 79.8776H249.185C294.99 79.8776 332.123 117.01 332.123 162.816V166.31Z" fill="#2563EB" />
            <path d="M169.709 43.4246H270.165C324.516 43.4246 368.575 87.4844 368.575 141.835V258.003C368.575 312.354 324.516 356.413 270.165 356.413H153.997C105.71 356.413 65.546 321.636 57.185 275.763L75.1212 273.493C79.9211 272.886 82.2634 267.31 79.3375 263.457L37.8619 208.84C34.936 204.987 28.9362 205.746 27.0623 210.206L0.499946 273.434C-1.37394 277.895 2.28352 282.711 7.08337 282.103L25.9692 279.714C36.3004 341.084 89.6887 387.838 153.997 387.838H270.165C341.871 387.838 400 329.709 400 258.003V141.835C400 70.1291 341.871 12 270.165 12H169.709C161.032 12 153.997 19.0346 153.997 27.7123C153.997 36.39 161.032 43.4246 169.709 43.4246Z" fill="#2563EB" />
        </svg>
    );
};