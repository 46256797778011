import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import { MdCheck } from "react-icons/md";

type Props = {
    defaultChecked?: boolean,
    onClick: () => void;
};

export function CheckBox({ defaultChecked, onClick }: Props) {

    const [checked, setChecked] = useState(false);

    useEffect(() => setChecked(defaultChecked ?? false), [defaultChecked]);

    const onClickHandler = () => {
        setChecked(!checked);
        onClick();
    };

    return (
        <div
            className={cn(`
                h-[18px] w-[18px] flex items-center justify-center rounded bg-white border border-gray-400
                transition-all duration-100 ease-in-out
            `, {
                "outline outline-2 outline-offset-1 outline-blue-400 bg-primary ": checked
            })}
            onClick={() => onClickHandler()}
        >

            {checked ? <MdCheck className="text-white" /> : null}

        </div>
    );
}