import { cn } from "@utils/cn";
import { ReactNode } from "react";

interface Props {
    active: boolean,
    icon: ReactNode,
    name: string,
    onClick: () => void;
}

export function AppCard({ active, icon, name, onClick }: Props) {
    return (
        <div
            onClick={() => onClick()}
            className={cn(`
                w-[100px] h-[95px] rounded-2xl p-2 cursor-pointer inline-flex flex-col items-center justify-center
                transition-all duration-200 ease-in-out
                hover:bg-blue-50 border-2 border-transparent
            `, {
                "border-2 border-blue-400 bg-blue-50": active
            })}
        >

            <div className={"max-w-[80px] max-h-[60px]"}>
                {icon}
            </div>

            <p className={"text-sm mt-2 font-medium"}>
                {name}
            </p>

        </div>
    );
}