import { useFormStore } from "@store";
import { TextToggle } from "@components/toggle";
import { useShallow } from 'zustand/react/shallow';
import { cn } from "@utils/cn";
import { useState } from "react";
import { VideoPage } from "./video";
import { TextPage } from "./text";
import { AudioPage } from "./audio";


type NavItems = Form.State["welcome"]["allowedTestimonialTypes"];

export function ResponsePage() {

    const { accept, collectRating } = useFormStore(useShallow((state) => ({
        accept: state.welcome.allowedTestimonialTypes,
        collectRating: state.response.collectRating
    })));

    const [active, setActive] = useState<NavItems[0]>(accept[0]);

    const onClick = (param: NavItems[0]) => {

        setActive(param);

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "responseTestimonialType",
                payload: {
                    type: param.toLowerCase()
                }
            } as Messages.Types, "*");
        }

    };

    return (
        <div>

            <ul className={cn(`
                text-gray-500 rounded-lg shadow inline-flex w-auto p-1.5 bg-gray-100 
                space-x-2 border border-gray-100
            `)}>

                {accept.map((item, idx) => (
                    <li
                        key={idx}
                        className={cn(`
                            inline-block px-4 py-2 text-gray-900 border-gray-200 rounded-lg tracking-wide
                            transition-all duration-300 ease-in-out border border-transparent text-sm font-medium
                        `, {
                            "bg-white shadow-md border ": active === item
                        })}
                        onClick={() => onClick(item)}
                    >
                        {item === "video" ? "Video" : null}
                        {item === "audio" ? "Audio" : null}
                        {item === "text" ? "Text" : null}
                    </li>
                ))}

            </ul>

            {active === "video" ? <VideoPage /> : null}

            {active === "audio" ? <AudioPage /> : null}

            {active === "text" ? <TextPage /> : null}

            {accept.length ?
                <div className="space-y-2 pt-3">

                    <TextToggle
                        isActive={collectRating}
                        name="Collect rating"
                        onClick={() => useFormStore.getState().setCollectRatings(!collectRating)}
                    />

                </div> : null
            }

        </div>
    );
}