import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useImportTestimonialStore } from "@store";

type Props = {
    label?: string,
    placeholder?: string;
};

export function TextTestimonialField({ label, placeholder }: Props) {

    const { content, error, } = useImportTestimonialStore((store) => ({
        content: store.testimonial.testimonial.text.content,
        error: store.error
    }));

    console.log({ content });

    return (
        <div className="space-y-2">

            <Text
                text={label ?? "Testimonial Content"}
                variant="label"
                required={true}
            />

            <QuillInput
                placeHolder={placeholder ?? ""}
                recall={false}
                valueAsHTML={content}
                onChange={(e) => {
                    console.log({ e });
                    useImportTestimonialStore.getState().setTextTestimonial({
                        content: e.text
                    });
                }}
                error={error["content"]}
            />

        </div>
    );
}