import { CallToAction } from "./cta";
import { CustomThankyouPage } from "./customThankyouPage";
import { Sharing } from "./sharing";
import { TitleAndMessage } from "./titleAndMessage";
import { ThankyouVideo } from "./video";


export function ThankyouPage() {
    return (
        <div
            id="thankyou"
            className="space-y-3"
        >

            <TitleAndMessage />

            <ThankyouVideo />

            <CustomThankyouPage />

            <CallToAction />

            <Sharing />


        </div>
    );
}