import { MdCode } from "react-icons/md";
import { EmbedTypes } from "./types";
import { useCommonStore, useEmbedStore } from "@store";
import { Button } from "@components/button";
import { StandardSettings } from "./standard/settings";
import { PopupSettings } from "./popup/settings";
import { SliderSettings } from "./slider/settings";
import { SideTabSettings } from "./sideTab/settings";
import { motion } from "framer-motion";

export const EmbedSettings: React.FC = () => {

    const embedType = useEmbedStore((store) => store.type);

    return (
        <motion.div
            className="w-[30%] ml-5 rounded-lg bg-white py-4 px-6 pb-6 border border-gray-200 shadow-sm"
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >

            <EmbedTypes />

            <div className="">

                {embedType === "standard" ? <StandardSettings /> : null}

                {embedType === "popup" ? <PopupSettings /> : null}

                {embedType === "slider" ? <SliderSettings /> : null}

                {embedType === "sideTab" ? <SideTabSettings /> : null}

            </div>

            <Button
                className="text-sm font-medium mt-4"
                onClick={() => useCommonStore.getState().setActivePopup("get-embed-code")}
            >

                <MdCode className='text-white w-6 h-6 mr-2' />

                <span> Get code </span>

            </Button>

            {/* <div className="text-sm font-normal mt-2 flex">

                or use our

                <p
                    className="text-primary underline ml-1 cursor-pointer"
                    onClick={() => window.open(Config.WordPressPlugineUrl, "_blank")}
                >
                    wordpress plugin
                </p>

            </div> */}

        </motion.div>
    );
};