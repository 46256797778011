import { Text } from "@components/text";
import { UploadPicture } from "@components/upload";
import { useImportTestimonialStore } from "@store";
import UserPlaceholder from "@assets/user.png";


type Props = {
    label?: string;
};

export function AvatarField({ label }: Props) {

    const avatar = useImportTestimonialStore((store) => store.testimonial.avatar);

    return (
        <div className="w-[50%]">

            <Text
                text={label ?? "Avatar"}
                variant="label"
            />

            <UploadPicture
                id="avatar"
                file={avatar}
                defaultImage={UserPlaceholder}
                buttonText="Pick an image"
                containerClassName="space-y-1"
                imgClassName="w-[50px] h-[50px] rounded-full border"
                onChange={(file) => useImportTestimonialStore.getState().setAvatar(file)}
            />

        </div>
    );
}