import Src from '@assets/integrations/googleSheets.png';

export const GoogleSheetIcon: React.FC = () => {
    return (
        <img
            height="60"
            width="60"
            src={Src}
            alt="GoogleSheet Logo"
        />
    );
}

