import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from 'zustand/middleware/immer';

const State: EmbedStore.State = {
    type: "standard",
    view: "desktop",
    standard: {
        height: 100,
        heightUnit: "%",
        width: 100,
        widthUnite: "%",
        removeBackground: false
    },
    popup: {
        size: "medium",
        text: "Launch",
        removeBackground: false
    },
    slider: {
        buttonColor: "#0063EB",
        textColor: "#EEEEEE",
        dir: "left",
        text: "Launch",
        removeBackground: false
    },
    slideTab: {
        buttonColor: "#0063EB",
        textColor: "#EEEEEE",
        text: "Launch",
        removeBackground: false
    }
};

export const useEmbedStore = create<EmbedStore.Type>()(devtools(immer((set => ({
    ...State,
    setType: (type) => set((state) => {

        state.type = type;

    },
        false,
        "useCommonStore/setType"
    ),
    setView: (val) => set((state) => {

        state.view = val;

    },
        false,
        "useCommonStore/setView"
    ),
    setStandard: (params) => set((state) => {

        state.standard = { ...state.standard, ...params };

    },
        false,
        "useCommonStore/setStandard"
    ),
    setPopup: (params) => set((state) => {

        state.popup = { ...state.popup, ...params };

    },
        false,
        "useCommonStore/setPopup"
    ),
    setSlider: (params) => set((state) => {

        state.slider = { ...state.slider, ...params };

    },
        false,
        "useCommonStore/setSlider"
    ),
    setSideTab: (params) => set((state) => {

        state.slideTab = { ...state.slideTab, ...params };

    },
        false,
        "useCommonStore/setSideTab"
    ),
}))), {
    name: "Embed-fe",
    enabled: import.meta.env.DEV,
    anonymousActionType: ""
}));