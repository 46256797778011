import { Rating } from "@components/rating";
import { useEffect, useRef, useState } from "react";
import { Text } from "@components/text";
import { MdOutlineFileDownload } from "react-icons/md";
import { SecondaryButton } from "@components/button";
import { QueryKeys, useActiveSpace, useGetQueryData, useInvalidateQuery, useTestimonialsApiClient } from "@hooks";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { ParseAxiosError } from "@lib/axios";
import { CgTranscript } from "react-icons/cg";
import ReactHlsPlayer from "react-hls-player";
import { FaPlay } from "react-icons/fa";

export function VideoTestimonial() {

    const [searchParams] = useSearchParams();

    const { formId } = useActiveSpace();

    const testimonialId = searchParams.get("id");

    const playerRef = useRef<any>(null);

    const [downloading, setDownloading] = useState(false);

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const { invalidate } = useInvalidateQuery();

    const item = testimonialsReq.testimonials.filter(item => item.id === testimonialId)[0];

    const source = item ? item.source : "";

    const transcribed = item && item.testimonial.video?.transcribed ? true : false;

    const transcribedContent = item && item.testimonial.video?.transcribedText ? item.testimonial.video?.transcribedText : "";

    const rated = item && item.testimonial.rating ? true : false;

    const rating = item && item.testimonial.rating ? item.testimonial.rating : 0;

    const videoUrl = item && item.testimonial.video ? item.testimonial.video?.url : "";

    const thumbnail = item && item.testimonial.video ? item.testimonial.video.thumbnail : "";

    const { downloadQuery } = useTestimonialsApiClient({
        downloadParams: {
            apiParams: {
                id: testimonialId as string,
                formId
            },
            params: {
                enabled: false,
                refetchOnMount: false,
                retry: 0,
                onError: (err) => toast.error(ParseAxiosError(err)),
                onSuccess: (data: Api.Testimonials.download) => onDownloadFileFromPresignedUrl(data.url)
            }
        }
    });

    const { isFetching, isLoading } = downloadQuery;

    useEffect(() => {

        if (playerRef.current && item.testimonial.video) {

            playerRef.current.play();

        }

    }, []);

    const onDownloadFileFromPresignedUrl = async (presignedUrl: string) => {
        try {

            setDownloading(true);

            const res = await fetch(presignedUrl);

            const blob = await res.blob();

            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');

            a.href = url;
            a.download = "testimonial.mp4";

            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);

            setDownloading(false);

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="relative">

            {rated ?
                <Rating
                    rated={rating}
                    readonly={true}
                /> : null
            }

            {source === "youtube" ?
                <div
                    onClick={() => {

                        const sourceUrl = item.customerDetails["sourceUrl"];

                        if (!sourceUrl) return;

                        window.open(sourceUrl.answer as string, "_blank");

                    }}
                >

                    <img
                        src={thumbnail}
                        className="w-[400px] h-[300px] rounded-md relative"
                    />

                    <div className="bg-gray-500/70 p-4 absolute top-[35%] left-[28%] rounded-full">

                        <FaPlay className="w-8 h-8 text-white" />

                    </div>

                </div>
                : null
            }

            {source === "gozenTestimonial" ?
                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={videoUrl}
                    autoPlay
                    controls
                    className="h-[320px] w-auto m-auto rounded-md mt-4 outline-none"
                /> : null
            }

            {source === "gozenTestimonial" ?
                <SecondaryButton
                    disabled={(isFetching || isLoading || downloading)}
                    loading={(isFetching || isLoading || downloading)}
                    className="w-auto px-2 py-1 rounded-md absolute top-0 right-0 m-0"
                    onClick={async (e) => {
                        e.preventDefault();
                        await invalidate([QueryKeys.testimonials.download]);
                        downloadQuery.refetch();
                    }}
                >

                    <MdOutlineFileDownload className="w-5 h-5" />

                </SecondaryButton> : null
            }

            {transcribed ?
                <div className="my-4 space-y-1">

                    <Text variant="primary" size="md" className="font-medium">
                        Transcript
                    </Text>

                    <Text variant="primary" size="sm" className="font-normal text-gray-700">
                        {transcribedContent}
                    </Text>

                </div> :
                <div className="flex items-center justify-start space-x-2 text-gray-600 p-2 my-2">

                    <CgTranscript className="w-6 h-6" />

                    <p className="text-sm font-normal"> No transcript found </p>

                </div>
            }


        </div>
    );
}