import { Button } from "@components/button";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function CollectCard() {

    const navigate = useNavigate();

    return (
        <motion.div
            className="w-[48%] h-[230px] border border-gray-300 shadow-md rounded-lg p-4 test-card"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <div className="flex items-center space-x-2">

                <MdAdd className="w-6 h-6 text-primary" />

                <p className="text-xl font-bold tracking-wide">
                    Collect new testimonials
                </p>

            </div>

            <p className="text-sm font-normal text-gray-700 mt-4 px-1">
                Share the link to your review collection page.
                Use social media, email marketing, SMS, QR codes and more.
            </p>

            <div className="flex items-center space-x-4 mt-4 px-2">

                {/* gmail */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/5968/5968534.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* qr */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/747/747470.png"}
                    alt=""
                    className="w-6 h-6"
                />


                {/* insta */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/2111/2111463.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* linkind */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/3536/3536505.png"}
                    alt=""
                    className="w-6 h-6"
                />


                {/* x */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/5968/5968830.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* fb */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/733/733547.png"}
                    alt=""
                    className="w-6 h-6"
                />


            </div>

            <div className="flex items-center space-x-4 my-2 mt-5">

                {/* <SecondaryButton
                    className="text-base font-medium tracking-wide p-2 hover:bg-gray-100 hover:ring-blue-300"
                    onClick={() => navigate("/form")}
                >
                    View Form
                </SecondaryButton> */}

                <Button
                    text="Collect Testimonials"
                    className="text-base font-medium tracking-wide p-2 w-[50%] ml-auto"
                    onClick={() => navigate("/form")}
                />

            </div>

        </motion.div>
    );
}