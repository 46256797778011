import { Select } from "@components/select";
import { useInvitesStore } from "@store";
import { useEffect, useState } from "react";

type Option = {
    label: string,
    value: string;
};

export function SelectColumns() {

    const columns = useInvitesStore((store) => store.parsedFile.columns);

    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {

        setOptions([
            ...columns
                .map(col => col.trim())
                .map(col => ({ label: col, value: col }))
        ]);

    }, [columns.length]);

    const onSelect = (val: any) => {

        const newOptions = options.filter(item => item.value !== val.value);

        setOptions([...newOptions]);

    };

    return (
        <div className="space-y-2 p-2">

            <div className="flex items-center justify-between space-y-1">

                <p className="text-gray-600">
                    Email <span className="text-red-500"> * </span>
                </p>

                <Select
                    className="w-[80%] mt-0"
                    options={options}
                    onChange={(val) => onSelect(val as string)}
                />

            </div>

            <div className="flex items-center justify-between space-y-1">

                <p className="text-gray-600">
                    Name
                </p>

                <Select
                    className="w-[80%] mt-1"
                    options={options}
                    onChange={(val) => onSelect(val as string)}
                />

            </div>

        </div>
    );
}