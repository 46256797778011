import { motion } from "framer-motion";

export function UploadingIcon() {
    return (
        <svg
            width="80"
            height="40"
            viewBox="0 0 60 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-primary"
        >

            <path
                d="M49.9906 16.055C47.2175 5.00797 36.0143 -1.69934 24.9674 1.07371C16.3344 3.24086 10.0659 10.7022 9.42003 19.5795C3.29027 20.5903 -0.859471 26.3789 0.151395 32.5086C1.05001 37.9581 5.77191 41.9489 11.2948 41.927H20.6688V38.1774H11.2948C7.15317 38.1774 3.79564 34.8199 3.79564 30.6783C3.79564 26.5366 7.15317 23.1791 11.2948 23.1791C12.3303 23.1791 13.1696 22.3397 13.1696 21.3043C13.1602 11.9855 20.7071 4.4235 30.0259 4.41425C38.0927 4.40616 45.0368 10.1091 46.5972 18.0234C46.7513 18.8136 47.3926 19.4173 48.1908 19.5232C53.3162 20.2531 56.8794 24.9997 56.1496 30.1251C55.4943 34.7275 51.5647 38.1542 46.9159 38.1774H39.4167V41.927H46.9159C54.1639 41.9051 60.0217 36.0117 59.9996 28.7637C59.9814 22.7304 55.8517 17.4867 49.9906 16.055Z"
                fill="currentColor"
            />

            <motion.path
                initial={{ opacity: 0, y: "6vh" }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                    ease: "easeInOut",
                    duration: 1.5,
                    repeat: Infinity
                }}
                d="M28.7116 23.7229L21.2124 31.2221L23.8559 33.8656L28.1679 29.5723V47.5516H31.9175V29.5723L36.2108 33.8656L38.8542 31.2221L31.355 23.7229C30.6238 22.996 29.4429 22.996 28.7116 23.7229Z"
                fill="currentColor"
            />

        </svg>
    );
}