import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { Config } from "@config";
import { useCommonStore, useFormStore } from "@store";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";


export function EmbedScriptPopup() {

    const [searchParams] = useSearchParams();

    const testimonialId = searchParams.get("id") ?? null;

    const show = useCommonStore((store) => store.activePopup === "get-embed-code");

    const [code, setCode] = useState("");

    useEffect(() => {

        if (testimonialId) setCode(onGetCode());

    }, [testimonialId]);

    const onGetCode = () => {

        const formId = useFormStore.getState().id;


        const scriptSrc = Config.EmbedScript("testimonial");

        if (!testimonialId) throw new Error("");

        return `
            <div
                id="gz-single-testimonial-widget"
                data-gz-id="${formId}"
                data-gz-tid="${testimonialId}"
                style="height: 100%; width: 100%;"
            ></div > \n
            <script src = "${scriptSrc}" ></script >
        `;

    };

    const onClose = () => useCommonStore.getState().setActivePopup("");

    const onCopy = () => {
        navigator.clipboard.writeText(code);
        toast.success("copied!...");
        onClose();
    };


    if (show === false) return null;

    return (
        <Popup
            name="Embed Script"
            width="35%"
            onClose={() => onClose()}
        >

            <div className="">

                <p className="text-sm font-normal mt-3 mb-3">
                    Paste this code in your html, for this testimonial to ampere in your website.
                </p>

                <div className="bg-black rounded-md p-4 text-white text-sm font-normal">

                    {code}

                </div>

                <div className="mt-5 flex items-center justify-end space-x-2">

                    <SecondaryButton
                        className="w-auto text-sm font-medium"
                        onClick={() => onClose()}
                        text="Cancel"
                    />

                    <Button
                        className="w-auto text-sm font-medium"
                        text={"Copy code"}
                        onClick={() => onCopy()}
                    />

                </div>

            </div>

        </Popup>
    );
};;