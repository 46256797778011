import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const State: CaseStudy.State = {
    settings: {
        prompt: ""
    },
    testimonialDetails: {
        customerName: "",
        testimonialContent: "",
        company: "",
        title: ""
    },
    myDetails: {
        businessDescription: "",
        companyName: ""
    }
};


export const useCaseStudyStore = create<CaseStudy.Type>()(devtools(immer((set => ({
    ...State,
    setBusinessDescription: (val: string) => set(state => {

        state.myDetails.businessDescription = val;

    },
        false,
        "caseStudy/setBusinessDescription"
    ),
    setTitle: (val: string) => set(state => {

        state.testimonialDetails.title = val;

    },
        false,
        "caseStudy/setTitle"
    ),
    setCompany: (val: string) => set(state => {

        state.testimonialDetails.company = val;

    },
        false,
        "caseStudy/setCompany"
    ),
    setTestimonialDetailsCompanyName: (val: string) => set(state => {

        state.testimonialDetails.company = val;

    },
        false,
        "caseStudy/setTestimonialDetailsCompanyName"
    ),
    setCompanyName: (val: string) => set(state => {

        state.testimonialDetails.customerName = val;

    },
        false,
        "caseStudy/setCompanyName"
    ),
    setCustomerName: (val: string) => set(state => {

        state.testimonialDetails.customerName = val;

    },
        false,
        "caseStudy/setCustomerName"
    ),
    setPrompt: (val: string) => set(state => {

        state.settings.prompt = val;

    },
        false,
        "caseStudy/setPrompt"
    ),
    setTestimonialContent: (val: string) => set(state => {

        state.testimonialDetails.testimonialContent = val;

    },
        false,
        "caseStudy/setTestimonialContent"
    )
}))), {
    name: "Case Study - FE",
    enabled: import.meta.env.DEV,
}));