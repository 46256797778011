import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";
import { cn } from "@utils/cn";
import { LuInfo } from "react-icons/lu";
import { Tooltip } from "react-tooltip";

export function Sharing() {

    const allowSharing = useFormStore((state) => state.thankYou.allowSharing);

    return (
        <TextToggle
            isActive={allowSharing}
            name="Allow Sharing"
            onClick={() => useFormStore.getState().setAllowSharing(!allowSharing)}
        >

            <LuInfo
                className={cn(`text-gray-600`)}
                id={`allow-sharing-tooltip`}
            />

            <Tooltip
                anchorSelect={`#allow-sharing-tooltip`}
                place="top"
                style={{
                    width: 340,
                    textAlign: "left"
                }}
            >
                Allow people to share their testimonials on social media. This will also allow people to share unapproved testimonials.
            </Tooltip>

        </TextToggle>
    );
}