import { FormSettings } from "./formSettings/formSettings.main";
import { Preview, } from "./preview/preview.main";
import { AutoSaveForm } from "./autoSave";
import { useFormApiClient } from "@hooks";

export function CreateForm() {

    const { get } = useFormApiClient({
        getParams: {
            params: {
                refetchOnWindowFocus: false,
                retry: 1,
            },
        }
    });

    const isLoading = get.isLoading || get.isFetching;

    return (
        <section className="w-full h-full overflow-hidden flex items-center justify-center relative">

            <AutoSaveForm isLoading={isLoading} />

            <Preview isLoading={isLoading} />

            <FormSettings isLoading={isLoading} />

        </section >
    );
}