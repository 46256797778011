import { ManuallyImport } from "../manuallyImport/main.import";
import { ImportCard } from "../manuallyImport/main.layout";
import { Fragment, useState } from "react";
import { Input } from "@components/input";
import { z } from "zod";
import { useActiveSpace, useImportTestimonialApiClient, QueryKeys } from "@hooks";
import { useImportTestimonialStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

const schema = z.object({
    name: z.string().min(1, "Name required."),
    content: z.string().min(1, "Testimonial cannot be empty.")
});


export function AirbnbImport() {

    const activeSpace = useActiveSpace();

    const { manualImport } = useImportTestimonialApiClient();

    const queryClient = useQueryClient();

    const [manuallyImport, setManuallyImport] = useState(false);

    /*
        - TODO append to testimonials preview section 
    */

    const onManualImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({
                        [key]: item.message
                    });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId: activeSpace.formId,
                source: "capterra"
            });

            queryClient.invalidateQueries({ queryKey: [QueryKeys.testimonials.getAll] });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    const onScrapeAndImport = async () => {
        try {

        } catch (err) {

        }
    };

    const onImport = async () => {

        if (manualImport) await onManualImport();
        else onScrapeAndImport();

    };

    return (
        <ImportCard
            isLoading={manualImport.isPending}
            title="Import from Airbnb"
            onImport={() => manuallyImport ? onManualImport() : onImport()}
        >

            {manuallyImport ?
                <ManuallyImport
                    fields={{
                        testimonial: {
                            text: { content: false, imageAttachments: false },
                            audio: { url: false, transcribedText: false },
                            video: { url: true, transcribedText: true }
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="Product Url"
                        placeholder="https://www.airbnb.com/rooms/123456789"
                        required={true}
                    />

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                        onClick={() => setManuallyImport(true)}
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>
            }

        </ImportCard>
    );
}