import { useNavigate, useParams } from "react-router-dom";
import { VideoTemplate1 } from "./template1/template1";
import { VideoTemplate2 } from "./template2/template2";
import { Button, SecondaryButton } from "@components/button";
import { MdArrowBackIos } from "react-icons/md";

export function VideoPreviewTemplates() {

    const navigate = useNavigate();

    const { id } = useParams<EditWidgetPageParams>();

    const onShare = () => { };

    return (
        <div className="h-full w-full flex items-center justify-center relative">

            <div className="absolute w-[96%] top-2 right-4">

                <div className="flex items-center justify-between w-full bo space-x-4 py-2 px-4">

                    <SecondaryButton
                        className="text-sm font-medium w-auto px-3"
                        onClick={() => navigate("/social-proof")}
                    >

                        <MdArrowBackIos className="w-4 h-4 mr-1" />

                        <p> Back </p>

                    </SecondaryButton>

                    <Button
                        className="mt-0 w-auto text-sm font-medium"
                        onClick={() => onShare()}
                    >
                        Share
                    </Button>

                </div>

            </div>

            {id === "v1" ? <VideoTemplate1 /> : null}

            {id === "v2" ? <VideoTemplate2 /> : null}

        </div>
    );
}