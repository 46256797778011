import { Search } from "@components/search";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { MdAdd } from "react-icons/md";
import { Fragment, useState } from "react";
import { QueryKeys, useClickOutside, useGetQueryData } from "@hooks";
import { SpaceItem } from "./spaceItem";

type Props = {
    show: boolean,
    onClose: () => void;
};


type SearchResult = {
    spaces: Api.SpacesClient.SpaceType[],
    sharedSpaces: Api.SpacesClient.SpaceType[];
};

export function ChangeSpace({ show, onClose }: Props) {

    const spacesQuery = useGetQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const [searchResult, setSearchResult] = useState<SearchResult>({ sharedSpaces: [], spaces: [] });

    const ref = useClickOutside(() => onClose());

    const spaces = searchResult.spaces.length ? searchResult.spaces : (spacesQuery?.spaces ?? []);
    const sharedSpaces = searchResult.sharedSpaces.length ? searchResult.sharedSpaces : (spacesQuery?.sharedSpaces ?? []);

    const onCreateNewSpace = () => {
        onClose();
        useCommonStore.getState().setActivePopup("create-space");
    };

    const onSearch = (search: string) => {

        if (!spacesQuery) return;

        if (!search) {
            setSearchResult({ sharedSpaces: [], spaces: [] });
            return;
        }

        const regx = new RegExp(search.toLowerCase(), "g");

        setSearchResult({
            sharedSpaces: spacesQuery.sharedSpaces.filter(space => space.name.toLowerCase().match(regx)),
            spaces: spacesQuery.spaces.filter(space => space.name.toLowerCase().match(regx))
        });

    };

    return (
        <AnimatePresence >

            {show ?
                <motion.div
                    ref={ref}
                    className={cn(`
                        w-[240px] h-auto max-h-[300px] border border-gray-300 rounded-lg absolute top-0 right-[-260px]
                        bg-white shadow-md cursor-pointer flex flex-col justify-between z-[100]
                    `)}
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    transition={{
                        ease: "easeInOut",
                        type: "tween"
                    }}
                >

                    {spaces.length || sharedSpaces ?
                        <Fragment>

                            <div className='border-b px-2 pt-2 pb-1'>

                                <Search
                                    iconDivClassName='pl-1'
                                    iconClassName='w-5 h-5'
                                    inputClassName='border-none pl-8 text-sm'
                                    placeHolder='Search Space'
                                    onSearch={onSearch}
                                />

                            </div>

                            {spaces.length ?
                                <div className='h-auto max-h-[240px] overflow-y-auto p-2 space-y-1 border-b'>

                                    {spaces.map((space, idx) => (
                                        <SpaceItem
                                            key={idx}
                                            space={space}
                                            onClose={() => onClose()}
                                        />
                                    ))}

                                </div> : null
                            }

                            {sharedSpaces.length ?
                                <div className='h-auto max-h-[240px] overflow-y-auto p-2'>

                                    <p className="text-xs font-medium text-gray-600 tracking-wide px-0.5">
                                        Shared Spaces
                                    </p>

                                    <div className="mt-2">

                                        {sharedSpaces.map((space, idx) => (
                                            <SpaceItem
                                                key={idx}
                                                space={space}
                                                onClose={() => onClose()}
                                            />
                                        ))}

                                    </div>

                                </div> : null
                            }

                        </Fragment> : null
                    }

                    <div
                        className={cn(`
                            flex items-center space-x-2 rounded-b-md px-3 py-3 
                            transition-all duration-300 ease-in-out border-t group
                            hover:bg-blue-50
                        `, {
                            "border-t-0": !spaces.length
                        })}
                        onClick={() => onCreateNewSpace()}
                    >

                        <div className='bg-gray-200 rounded-lg'>

                            <MdAdd className="text-primary w-5 h-5 m-[2px]" />

                        </div>

                        <p className='text-sm'> Create a new space </p>

                    </div>

                </motion.div> : null
            }

        </AnimatePresence>
    );
}

