import { Input } from "@components/input";
import { useFormStore } from "@store";


export function ClaimRewardButton() {

    const buttonText = useFormStore((store) => store.reward.buttonText);

    const onClick = () => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "currentScreen",
                payload: { screen: "reward-cta" }
            } as Messages.Types, "*");

            ele.scrollTop = ele.scrollHeight;

        }

    };

    return (
        <Input
            label="CTA Button Text"
            type="text"
            value={buttonText}
            onChange={(e) => useFormStore.getState().setRewardButtonTxt(e.target.value)}
            onClick={() => onClick()}
        />
    );
}