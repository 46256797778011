import { Loading } from "@components/loading";
import { useBillingApiClient } from "@hooks";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";


export function CheckOutStatusPage() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { subscriptionCreate } = useBillingApiClient({});

    const sessionId = searchParams.get("session_id") ?? null;
    const plan = searchParams.get("plan") ?? null;
    const interval = searchParams.get("interval") ?? null;
    const addon = searchParams.get("addon") ?? null;
    const type = searchParams.get("type") ?? null;
    const qty = searchParams.get("qty") ?? null;

    const [status, setStatus] = useState<"complete" | "open" | "expired">();

    const isLoading = subscriptionCreate.isPending;

    useEffect(() => {

        const valid = addon ? (sessionId && type && qty) : (sessionId && plan && interval);

        if (!valid) {
            navigate("/settings", { replace: true });
            return;
        }

        onCheckSubscription();

    }, []);

    const onCheckSubscription = async () => {
        try {

            // const res = addon ?
            //     await Billing.createAddon({
            //         qty: parseInt(qty as string),
            //         sessionId: sessionId as string,
            //         type: type as string
            //     }) :
            //     await Billing.checkSessionStatus({
            //         sessionId: sessionId as string,
            //         interval: interval as BillingInterval,
            //         plan: plan as string
            //     });

            const { status } = await subscriptionCreate.mutateAsync({
                interval: interval as any,
                plan: plan as any,
                sessionId: sessionId as any
            });

            if (subscriptionCreate.error) {
                setStatus("expired");
                toast.error("");
                return;
            }

            if (status !== "complete") {
                toast.error("Your last payment attempt failed no amount was debited. Connect support for help.");
                goBack();
                return;
            }

            setTimeout(() => goBack(), 3000);

        } catch (err) {
            setStatus("expired");
        }
    };

    const goBack = () => {
        navigate("/settings", { replace: true });
    };

    return (
        <div className="w-screen h-screen overflow-hidden">

            {isLoading ? <Loading text="Please, wait we are confirming your payment." /> : null}

            {status === "expired" || status === "open" ? <p> your payment failed. </p> : null}

            {status === "complete" ?
                <div className="w-screen h-screen flex items-center justify-center">

                    <div className={"w-[35%] h-full p-5 flex flex-col items-center justify-center"}>

                        <svg
                            width={"100px"}
                            height={"100px"}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.99996 0.666748C4.39996 0.666748 0.666626 4.40008 0.666626 9.00008C0.666626 13.6001 4.39996 17.3334 8.99996 17.3334C13.6 17.3334 17.3333 13.6001 17.3333 9.00008C17.3333 4.40008 13.6 0.666748 8.99996 0.666748ZM6.74163 12.5751L3.74996 9.58342C3.42496 9.25842 3.42496 8.73341 3.74996 8.40841C4.07496 8.08341 4.59996 8.08341 4.92496 8.40841L7.33329 10.8084L13.0666 5.07508C13.3916 4.75008 13.9166 4.75008 14.2416 5.07508C14.5666 5.40008 14.5666 5.92508 14.2416 6.25008L7.91663 12.5751C7.59996 12.9001 7.06663 12.9001 6.74163 12.5751Z"
                                fill={"#56f563"}
                            />
                        </svg>

                        <p className={"text-lg font-lg text-center"}>
                            Your payment is successfully
                        </p>

                        <p className={"text-base font-normal text-gray-700 text-center"}>
                            A payment to gozen.io will appear on your statement.
                        </p>

                        <button
                            className={`
                                text-white bg-primary px-5 py-2
                                rounded-lg border text-lg font-medium mt-5
                                cursor-pointer hover:opacity-80
                            `}
                            onClick={() => navigate({
                                pathname: "/settings",
                                search: createSearchParams({ screen: "billing" }).toString(),
                            }, {
                                replace: true
                            })}
                        >
                            Go to Billing
                        </button>

                    </div>

                </div> : null
            }

        </div>
    );
}