import { useQueryClient } from "@tanstack/react-query";


export function useInvalidateQuery(key?: string[]) {

    const client = useQueryClient();

    if (key && key.length) client.invalidateQueries({ queryKey: [key] });

    return {
        invalidate: (key: string[]) => client.invalidateQueries({ queryKey: [key] })
    };

}