import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { cn } from "@utils/cn";
import React from "react";

const Root = DropdownMenuPrimitive.Root;

const Trigger = DropdownMenuPrimitive.Trigger;

const Content = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal>

        <DropdownMenuPrimitive.Content
            ref={ref}
            align={align}
            sideOffset={sideOffset}
            side="right"
            className={cn("shadow-md border bg-white w-[220px] rounded-md p-2", className)}
            {...props}
        >

        </DropdownMenuPrimitive.Content>

    </DropdownMenuPrimitive.Portal>
));

const Item = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ children, className, ...props }, ref) => (
    <DropdownMenuPrimitive.Item
        ref={ref}
        className={cn(`
            p-2 text-sm font-medium outline-none hover:bg-gray-200 rounded-md 
            transition-all duration-300 ease-in-out cursor-pointer
            
        `, className)}
        {...props}
    >
        {children}
    </DropdownMenuPrimitive.Item>
));

export const DropdownMenu = Object.assign(Root, { Trigger, Content, Item });
