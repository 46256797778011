import { Text } from "@components/text";
import { cn } from "@utils/cn";

type Props = {
    active: "preview" | "edit",
    onClick: (type: string) => void;
};

export function ImportNavigationSection({ active, onClick }: Props) {
    return (
        <div className="flex items-center justify-center py-2 px-4">

            <div
                className={cn(`
                    p-[5px] rounded-md bg-gray-300 
                    flex items-center space-x-1 cursor-pointer 
                `)}
            >

                <div
                    className={cn(`
                        rounded-md transition-all duration-300 ease-in-out 
                        flex items-center p-2 px-4 
                    `, {
                        "bg-white": active === "preview"
                    })}
                    onClick={() => onClick("preview")}
                >

                    <Text
                        text="Preview"
                        className="text-sm font-medium"
                    />

                </div>

                <div
                    className={cn(`
                        rounded-md transition-all duration-300 ease-in-out 
                        flex items-center p-2 px-4
                    `, {
                        "bg-white": active === "edit"
                    })}
                    onClick={() => onClick("edit")}
                >

                    <Text
                        text="Edit"
                        className="text-sm font-medium"
                    />

                </div>

            </div>

            {/* <Button
                text="Import"
                className="w-auto"
            /> */}

        </div>
    );
}