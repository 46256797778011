import { Toggle } from "@components/toggle";
import { MdAlternateEmail, MdOutlineMapsHomeWork, MdOutlinePhotoCameraFront, MdOutlineLink, MdCardMembership, MdPhoto } from "react-icons/md";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { CheckBox } from "@components/checkbox";
import { ReactNode } from "react";
import { cn } from "@utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { useFormStore } from "@store";
import { LuInfo } from "react-icons/lu";
import { Tooltip } from "react-tooltip";

const fields: {
    id: keyof Form.State["customDetails"]["fields"],
    icon: ReactNode,
    title: string,
    tooltip: string,
}[] = [
        {
            id: "email",
            icon: <MdAlternateEmail className="w-5 h-5 text-blue-600" />,
            title: "Email",
            tooltip: "testest"
        },
        {
            id: "job",
            icon: <MdOutlineMapsHomeWork className="w-5 h-5 text-blue-600" />,
            title: "Job Title",
            tooltip: ""
        },
        {
            id: "photo",
            icon: <MdOutlinePhotoCameraFront className="w-5 h-5 text-blue-600" />,
            title: "Collect User Photo",
            tooltip: ""
        },
        {
            id: "website",
            icon: <MdOutlineLink className="w-5 h-5 text-blue-600" />,
            title: "Collect Website Url",
            tooltip: ""
        },
        {
            id: "company",
            icon: <HiOutlineBuildingOffice className="w-5 h-5 text-blue-600" />,
            title: "Collect Company",
            tooltip: ""
        },
        {
            id: "role",
            icon: <MdCardMembership className="w-5 h-5 text-blue-600" />,
            title: "Role",
            tooltip: ""
        },
        {
            id: "companyLogo",
            icon: <MdPhoto className="w-5 h-5 text-blue-600" />,
            title: "Company Logo",
            tooltip: ""
        }
    ];

export function DefaultFields() {

    const primaryFields = useFormStore((store) => store.customDetails.fields);

    return (
        <div className="space-y-2 pt-2">

            <div className="border border-gray-300 rounded-md p-2 space-y-2">

                {fields.map((field, idx) => (
                    <div
                        key={idx}
                        className={cn(`
                            flex items-start justify-between p-2 rounded-md space-x-2 hover:bg-gray-100
                            transition-all duration-200 ease-in-out 
                        `, {
                            "border-b": idx !== (fields.length - 1)
                        })}
                    >

                        <div className="flex items-center space-x-2">

                            <div>
                                {field.icon}
                            </div>

                            <p className="text-sm font-medium">
                                {field.title}
                            </p>

                            <LuInfo
                                className={cn(`text-gray-600`)}
                                id={`${field.id}-tooltip`}
                            />

                            <Tooltip anchorSelect={`#${field.id}-tooltip`} place="top">
                                Hello world!
                            </Tooltip>

                        </div>

                        <div className="flex items-center space-x-4">

                            <AnimatePresence>

                                {primaryFields[field.id].collect &&
                                    <motion.div
                                        className="flex items-center space-x-2"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                    >

                                        <CheckBox
                                            defaultChecked={primaryFields[field.id].required}
                                            onClick={() => {
                                                useFormStore.getState().setCustomerFields(field.id, {
                                                    ...primaryFields[field.id],
                                                    required: !primaryFields[field.id].required
                                                });
                                            }}
                                        />

                                        <p className="text-gray-800 text-xs select-none"> Required </p>

                                    </motion.div>
                                }

                            </AnimatePresence>

                            <Toggle
                                isActive={primaryFields[field.id].collect}
                                onClick={() => {
                                    useFormStore.getState().setCustomerFields(field.id, {
                                        ...primaryFields[field.id],
                                        collect: !primaryFields[field.id].collect
                                    });
                                }}
                            />


                        </div>


                    </div>
                ))}

            </div>

        </div>
    );
}