import { SecondaryButton } from "@components/button";
import { Loading } from "@components/loading";
import { Toggle } from "@components/toggle";
import { QueryKeys, useActiveSpace, useGetQueryData, useIntegrationsApiClient } from "@hooks";
import { useCommonStore } from "@store";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import { ReactNode, } from "react";
import toast from "react-hot-toast";
import { IoMdTrash } from "react-icons/io";
import { motion } from "framer-motion";

type Props = {
    icon: ReactNode,
    type: string;
    title: string,
    description: string,
};

export function IntegrationCard({ icon, title, type, description, }: Props) {

    const { createWebhook, toggle, deleteIntegration } = useIntegrationsApiClient();

    const activeSpace = useActiveSpace();

    const queryClient = useQueryClient();

    const getAllIntegrationsQuery = useGetQueryData<Api.Integrations.getAll>([QueryKeys.integrations.getAll]);

    const integrations = (getAllIntegrationsQuery?.integrations[type] ?? []);

    const activeIntegration = (integrations?.filter(item => item ? item?.formId === activeSpace.formId : null)[0] ?? null);

    const isActive = (activeIntegration?.active ?? false);

    const isLoading = createWebhook.isPending || deleteIntegration.isPending;

    const onToggle = async () => {
        try {

            await toggle.mutateAsync({
                formId: activeSpace.formId,
                integrationId: activeIntegration.id
            });

            const items = getAllIntegrationsQuery.integrations[type].map(item => item.id === activeIntegration.id ?
                { ...item, active: !activeIntegration.active } :
                item
            );

            const updatedIntegrations = { ...getAllIntegrationsQuery.integrations, [type]: [...items] };

            queryClient.setQueryData([QueryKeys.integrations.getAll], { integrations: updatedIntegrations });

            if (activeIntegration.active) toast.success("Successfully, paused your integration.");
            else toast.success("Successfully, enabled your integration.");

        } catch (err) {
            console.log(err);
        }
    };

    const onDelete = async () => {
        try {

            await deleteIntegration.mutateAsync(activeIntegration.id);

            const items = getAllIntegrationsQuery.integrations[type].filter(item => item.id !== activeIntegration.id);

            const updatedIntegrations = { ...getAllIntegrationsQuery.integrations, [type]: items };

            queryClient.setQueryData([QueryKeys.integrations.getAll], { integrations: updatedIntegrations });

            toast.success("Successfully, deleted your integration.");

        } catch (err) {
            console.log(err);
        }
    };

    const onConnect = () => {

        if (type === "googleSheet") useCommonStore.getState().setActivePopup("googleSheet");
        else if (type === "pabbly") useCommonStore.getState().setActivePopup("pabbly");
        else if (type === "webhook") useCommonStore.getState().setActivePopup("webhook");
        else if (type === "zapier") useCommonStore.getState().setActivePopup("zapier");
        else return;

    };

    return (
        <motion.div
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
            className={cn(`
                bg-white my-4 p-2 flex items-center justify-between shadow-sm
                rounded-2xl px-6 py-5 border integration-card-shadow fade-in
                hover:shadow-md transition-all duration-200 was
            `)}
        >

            <div className="flex flex-row items-center space-x-4">

                <div className="border border-gray-200 rounded-xl">
                    {icon}
                </div>

                <div className="w-full flex flex-col items-start break-words cursor-default">

                    <p className="text-base font-medium">
                        {title}
                    </p>

                    <p className="text-sm font-normal text-font_secondary mt-1 text-gray-500 break-words">
                        {description}
                    </p>

                </div>

            </div>

            {activeIntegration ?
                <div className="flex flex-row items-center space-x-4">

                    <Toggle
                        disable={isLoading}
                        isActive={isActive}
                        onClick={() => onToggle()}
                    />

                    <SecondaryButton
                        className={cn(`
                            border p-1.5 group transition-all duration-200 ease-in-out
                            hover:bg-red-200 hover:ring-red-200 hover:border-red-200
                        `)}
                        onClick={() => onDelete()}
                    >

                        {isLoading ?
                            <Loading text="Deleting..." /> :
                            <IoMdTrash className={cn(`
                                w-6 h-6 text-font_secondary group-hover:text-red-700
                                transition-all duration-200 ease-in-out
                            `)} />
                        }

                    </SecondaryButton>

                </div> :
                <SecondaryButton
                    disabled={isLoading}
                    text="Connect"
                    className="w-auto text-sm font-medium"
                    onClick={() => onConnect()}
                />
            }

        </motion.div>
    );
}