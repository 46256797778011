import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { Config } from "@config";
import { useActiveSpace } from "@hooks";
import { useCommonStore, useEmbedStore, useFormStore } from "@store";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";


export function GetEmbedCodePopup() {

    const { formId } = useActiveSpace();

    const show = useCommonStore((store) => store.activePopup === "get-embed-code");

    const { type, standard, slideTab, slider, popup } = useEmbedStore();

    const customFields = useFormStore((store) => store.customDetails.customFields);

    const [code, setCode] = useState("");

    useEffect(() => {

        setCode(onGetCode());

    }, []);

    const onGetCode = () => {

        const hiddenFields = customFields.filter(field => field.hidden);

        const scriptSrc = Config.EmbedScript("form");

        if (type === "standard") {

            const height = `${standard.height}${standard.heightUnit}`;
            const width = `${standard.width}${standard.widthUnite}`;

            return `
                <div 
                    id="gz-testimonial-widget" 
                    data-gz-id="${formId}"
                    data-gz-type="standard" ${hiddenFields ? `\ndata-gz-hidden="${hiddenFields}"\n` : ""}
                    style="height: ${height}; width: ${width};"
                ></div> \n
                <script src="${scriptSrc}"></script>
            `;

        } else if (type === "popup") {

            const { size, text } = popup;

            return `
                <button 
                    id="gz-testimonial-widget"
                    data-gz-id="${formId}"
                    data-gz-type="popup"
                    data-popup-size="${size}" ${hiddenFields ? `\ndata-gz-hidden="${hiddenFields}"\n` : ""}
                > 
                    ${text}
                </button> \n
                <script src="${scriptSrc}"></script>
            `;


        } else if (type === "sideTab") {

            const { buttonColor, text, textColor } = slideTab;

            return `
                <div 
                    id="gz-testimonial-widget"
                    data-gz-id="${formId}" 
                    data-gz-type="sideTab"
                    data-gz-btn-color="${buttonColor}"
                    data-gz-btn-text-color="${textColor}"
                    data-gz-btn-text="${text}" ${hiddenFields ? `\ndata-zf-hidden="${hiddenFields}"\n` : ""}
                ></div> 
                <script src="${scriptSrc}"></script>
            `;

        } else if (type === "slider") {

            const { dir, buttonColor, text, textColor } = slider;

            return `
                <div  
                    id="gz-testimonial-widget"
                    data-gz-id="${formId}" 
                    data-gz-type="slider"
                    data-gz-direction="${dir}" 
                    data-gz-btn-color="${buttonColor}"
                    data-gz-btn-text-color="${textColor}" ${hiddenFields ? `\ndata-zf-hidden="${hiddenFields}"\n` : ""}
                    style="padding: 5px; font-size: 18px; background: ${buttonColor}; color: ${textColor}; border-radius: 4px; display: inline-block; cursor: pointer;"
                >   
                    ${text}
                </div> 
                <script src="${scriptSrc}"></script>
            `;

        } else throw new Error("invalid type.");

    };

    const onClose = () => useCommonStore.getState().setActivePopup("");

    const onCopy = () => {
        navigator.clipboard.writeText(code);
        toast.success("copied!...");
        onClose();
    };

    if (show === false) return <></>;

    return (
        <Popup
            name="Embed Code"
            width="35%"
            onClose={() => onClose()}
        >

            <div className="">

                <p className="text-sm font-normal mt-3 mb-3">
                    Paste this code in your html, for this Gozen Testimonial to ampere in your website.
                </p>

                <div className="bg-black rounded-md p-4 text-white text-sm font-normal">

                    {code}

                </div>

                <div className="mt-5 flex items-center justify-end space-x-2">

                    <SecondaryButton
                        className="w-auto text-sm font-medium"
                        onClick={() => onClose()}
                        text="Cancel"
                    />

                    <Button
                        className="w-auto text-sm font-medium"
                        text={"Copy code"}
                        onClick={() => onCopy()}
                    />

                </div>

            </div>

        </Popup>
    );
}