import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useFormStore } from "@store";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import { useActiveSpace, useUsersApiClient } from "@hooks";
import { Config } from "@config";
import { MdFormatAlignCenter } from "react-icons/md";
import { MdOutlineFormatAlignLeft } from "react-icons/md";
import { MdOutlineFormatAlignRight } from "react-icons/md";


const positions: { icon: any, value: Form.State["design"]["alignment"]; }[] = [
    {
        icon: <MdOutlineFormatAlignLeft className="w-5 h-5 text-gray-700 group-hover:text-primary" />,
        value: "left"
    },
    {
        icon: <MdFormatAlignCenter className="w-5 h-5 text-gray-700 group-hover:text-primary" />,
        value: "center"
    },
    {
        icon: <MdOutlineFormatAlignRight className="w-5 h-5 text-gray-700 group-hover:text-primary" />,
        value: "right"
    }
];


export function Logo() {

    const activeSpace = useActiveSpace();

    const { activeLogo, alignment } = useFormStore(state => ({
        activeLogo: state.design.logo ? state.design.logo : activeSpace.logo ? activeSpace.logo : Config.misc.placeHolderLogo,
        alignment: state.design.alignment,
        formId: state.id
    }));

    const imgRef = useRef<HTMLImageElement>(null);

    const [logo, setLogo] = useState<File | null>(null);

    const { uploadAsserts } = useUsersApiClient({});

    const onInvalidImage = () => {

        if (imgRef.current) {
            imgRef.current.src = Config.misc.placeHolderLogo;
        }

    };

    const onChange = async (e: any) => {
        try {

            const newFile = (e.target.files as FileList)[0];

            const schema = z.object({
                size: z.number().min(0, "File required.").max(3 * 1024 * 1024, "Image too large. image should less 3mb."),
                fileType: z.string().min(1, "File required.").refine((type) => {

                    const types = ["image/png", "image/jpg", "image/jpeg"];

                    return types.includes(type);

                }, { message: "invalid file type." })
            });

            const valid = schema.safeParse({ size: newFile.size, fileType: newFile.type });

            if (!valid.success) {
                toast.error(valid.error.errors[0].message);
                return;
            }

            setLogo(newFile);

            const data = await uploadAsserts.mutateAsync({
                file: newFile,
                id: activeSpace.id,
                type: "formLogo"
            });

            console.log({ data });

            if (uploadAsserts.isError) {
                toast.error(uploadAsserts.error.message);
                return;
            }

            useFormStore.getState().setLogo(data?.url as string);

            toast.success("uploaded successfully.");

        } catch (err: any) {
            toast.error(err);
        }
    };

    return (
        <div className="space-x-8 flex items-start justify-start w-full h-full">

            <div className="">

                <Text
                    variant="label"
                    text="Logo (50x50)"

                />

                <input
                    id="change-space-logo"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    className="hidden"
                    onChange={(e) => onChange(e)}
                />

                <div className="flex items-center space-x-4 mt-2">

                    <label
                        htmlFor="change-space-logo"
                        className={cn(`
                        inline-block border-2 py-4 px-10 rounded-md hover:opacity-80 hover:border-gray-500
                        transition-all duration-200 ease-in-out cursor-pointer
                    `)}
                    >

                        {uploadAsserts.isPending ?
                            <div className="w-[60px] h-[60px] flex items-center justify-center">

                                <p className="text-gray-700 text-sm font-normal tracking-wide">
                                    uploading...
                                </p>

                            </div> :
                            <img
                                ref={imgRef}
                                onInvalid={onInvalidImage}
                                src={logo ? URL.createObjectURL(logo as File) : activeLogo}
                                className="w-[60px] h-[60px]"
                            />
                        }

                    </label>

                </div>

            </div>

            <div className="h-full">

                <Text
                    variant="label"
                    text="Alignment"
                />

                <div className="flex items-center bg-gray-100 border  rounded-lg space-x-1 p-1 mt-2">

                    {positions.map((item, idx) => (
                        <div
                            key={idx}
                            className={cn(`
                                p-1.5 rounded-md group border border-transparent 
                                hover:bg-white hover:border-gray-300
                                transition-all duration-200 ease-in-out
                            `, {
                                "bg-white border-gray-300 text-primary": item.value === alignment
                            })}
                            onClick={() => useFormStore.getState().setAlignment(item.value)}
                        >

                            {item.icon}

                        </div>
                    ))}

                </div>

            </div>

        </div>
    );
}