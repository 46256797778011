import { GenerateProfilePic } from "@utils/generateProfilePic";

type Props = {
    customer: Api.Testimonials.getAll["testimonials"][0]["customerDetails"];
};

export function ProfileSection({ customer }: Props) {

    if (!customer) return;

    const name = customer["name"]?.answer as string ?? null;
    const email = (customer["email"]?.answer as string)?.substring(0, 24) ?? "";
    const role = (customer["role"]?.answer as string)?.substring(0, 24) ?? "";

    const profilePicture = customer["photo"]?.answer ?
        customer["photo"]?.answer as string :
        GenerateProfilePic(name ? name[0] : email[0]);

    return (
        <div className="w-[30%] flex flex-col items-start justify-start space-x-3 cursor-default">

            <img
                src={profilePicture}
                className="w-16 h-16 rounded-full mx-1 border border-gray-300 bg-gray-300"
            />

            <div className="text-left space-y-2">

                <p className="text-sm font-medium tracking-wide">
                    {customer["name"]?.answer ?? ""}
                </p>

                <p className="text-xs font-medium text-gray-600 tracking-wide">

                    {role ? role : null}

                </p>


                <p className="text-xs font-medium text-gray-600 tracking-wide">

                    {email ? email : null}

                </p>


            </div>

        </div>
    );
}