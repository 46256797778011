import { UndefinedInitialDataOptions, UseMutationOptions, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { CustomQueryParams, useCustomQuery } from "../useCustomQuery";
import { ParseAxiosError } from "@lib/axios";

type Params = {
    verifyTokenParams?: {
        params: Partial<UndefinedInitialDataOptions>;
    },
    resendVerificationMailParams?: {
        apiParams?: { email: string; },
        params?: Partial<CustomQueryParams>,
    },
    logoutParams?: {
        params?: Partial<UseMutationOptions>;
    };
};

export function useAuthApiClient({ verifyTokenParams, resendVerificationMailParams, logoutParams }: Params) {

    const login = useMutation({
        mutationKey: [QueryKeys.auth.login],
        mutationFn: async (token: string) => {
            try {

                const lastActiveSpaceId = (localStorage.getItem("lastActiveSpaceId") as string) ?? null;

                return (await axios.post<Api.Auth.Login>("/api/v1/auth/login", {
                    token,
                    spaceId: lastActiveSpaceId
                })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const signUp = useMutation({
        mutationKey: [QueryKeys.auth.signUp],
        mutationFn: async (body: Api.Auth.signupParams) => {
            try {
                return (await axios.post("/api/v1/auth/signup", body)).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const resendVerificationMail = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.auth.resendVerificationMail],
        queryFn: async () => {
            try {
                return (await axios.get("/api/v1/email/verify/resend", {
                    params: {
                        email: resendVerificationMailParams?.apiParams?.email
                    }
                })).data;
            } catch (err) {
                throw err;
            }
        },
        ...resendVerificationMailParams?.params
    });

    const verifyToken = useQuery({
        enabled: false,
        queryKey: [QueryKeys.auth.verifyToken],
        queryFn: async () => {
            try {

                const res = await axios.get<Api.Auth.verifyToken>("/api/v1/auth/token");

                return { status: res.status };

            } catch (err) {
                throw err;
            }
        },
        ...verifyTokenParams?.params
    });

    const verifyEmail = useMutation({
        mutationKey: [QueryKeys.auth.verifyEmail],
        mutationFn: async (token: string) => {
            try {
                return (await axios.post("/api/v1/auth/verify", token)).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const logout = useMutation({
        mutationKey: [QueryKeys.auth.logout],
        mutationFn: async () => {
            try {
                return (await axios.post("/api/v1/auth/logout")).data;
            } catch (err) {
                throw err;
            }
        },
        ...logoutParams?.params
    });

    const changeEmail = useMutation({
        mutationKey: [QueryKeys.auth.changeEmail],
        mutationFn: async (email: string) => {
            try {
                return (await axios.post("/api/v1/auth/email/change", { email })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });


    const changePassword = useMutation({
        mutationKey: [QueryKeys.auth.changePassword],
        mutationFn: async (password: string) => {
            try {
                return (await axios.post("/api/v1/auth/password/change", { password })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        login,
        signUp,
        resendVerificationMail,
        verifyToken,
        logout,
        changeEmail,
        changePassword,
        verifyEmail
    };

}