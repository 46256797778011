import { useState } from "react";
import { AppDrawerIcon } from "./appDrawer.icon";
import { Config } from "@config";
import { apps } from "./apps";
import { AppCard } from "./card";
import { useClickOutside } from "@hooks";
import { motion, AnimatePresence } from 'framer-motion';

export function AppDrawer() {

    const [showAppsPopup, setShowAppsPopup] = useState(false);

    const ref = useClickOutside(() => { showAppsPopup && setShowAppsPopup(false); });


    const onGoto = (appId: string) => {

        const app = apps.filter((app: any) => app.id === appId)[0];

        if (!app) return;

        let redirectUrl: any = {};

        if (app.id === "gozen") redirectUrl = app.redirectUrl;

        if (app.id === "gz_content_ai") redirectUrl = app.redirectUrl;

        if (app.id === "gz_forms") redirectUrl = app.redirectUrl;

        if (app.id === "gz_growth") redirectUrl = app.redirectUrl;

        if (app.id === "optinly") redirectUrl = app.redirectUrl;

        if (app.id === "gz_testimonial") return;

        const url = Config.AppEnv === "prod" ? redirectUrl.prod : redirectUrl.dev;

        window.location.replace(url);

    };

    const onOpen = () => {

        if (!showAppsPopup) setShowAppsPopup(true);

    };

    return (
        <div ref={ref}>

            <AppDrawerIcon onClick={() => onOpen()} />

            <AnimatePresence>

                {showAppsPopup ?

                    <motion.div
                        className={`
                            h-auto absolute top-[60px] right-[130px] z-[100]  bg-white rounded-lg shadow-xl
                            select-none border p-3
                            grid grid-cols-3 grid-rows-2 gap-2
                        `}
                        initial={{
                            transformOrigin: "top right",
                            scale: 0.5,
                            opacity: 0,
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1,
                            transition: {
                                duration: 0.1,
                                stiffness: 100,
                                ease: "easeInOut"
                            }
                        }}
                        exit={{
                            opacity: 0,
                            transition: {
                                duration: 0.1
                            }
                        }}
                    >

                        {apps.map((app: any) => (
                            <AppCard
                                key={app.id}
                                active={app.active}
                                icon={app.icon}
                                name={app.name}
                                onClick={() => onGoto(app.id)}
                            />
                        ))}

                    </motion.div> : null

                }

            </AnimatePresence>

        </div>
    );
}