import { Popup } from "@components/popup";
import { useCommonStore, useIntegrationsStore } from "@store";
import { AuthScreen } from "./auth";
import { ConnectScreen } from "./connect";
import { Fragment, useEffect } from "react";

export function GoogleSheetIntegrationPopup() {

    const activeScreen = useIntegrationsStore((store) => store.googleSheet.activeScreen);

    useEffect(() => {

        window.addEventListener("storage", () => onStorageSync());

        return () => window.removeEventListener("storage", () => onStorageSync());

    }, []);

    const onStorageSync = () => {

        if (localStorage.getItem("_c")?.length !== 0) {

            useIntegrationsStore.getState().setGoogleSheetActiveScreen("connect");

            return;

        }

    };

    return (
        <Popup
            width="35%"
            name="GoogleSheet"
            onClose={() => {
                useCommonStore.getState().setActivePopup("");
                useIntegrationsStore.getState().setGoogleSheetActiveScreen("auth");
            }}
        >

            <Fragment>

                {activeScreen === "auth" ? <AuthScreen /> : null}

                {activeScreen === "connect" ? <ConnectScreen /> : null}

            </Fragment>

        </Popup>
    );
}