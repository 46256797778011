import { MdStar } from "react-icons/md";
import CeoImage from "@assets/ceo-logo.png";
import { motion } from "framer-motion";

export function DummyTestimonials() {

    const arr = [1, 2, 3, 4, 5];

    return (
        <motion.div
            className="h-[50%] px-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <p className="text-lg font-medium px-1"> Recent Testimonials  </p>

            <div className="bg-white rounded-lg border border-gray-300 w-full shadow-md h-auto px-4 py-6 mt-2">

                <div className="flex items-center justify-between">

                    <div className="flex items-center space-x-2 px-2">

                        <img
                            src={CeoImage}
                            className="w-10 h-10 rounded-full"
                        />

                        <div>

                            <p className="text-sm font-normal tracking-wide"> Richard Hendricks  </p>

                            <span className="text-xs font-medium text-gray-600"> CEO of Pied Pier </span>

                        </div>


                    </div>


                    <div className="flex items-center space-x-1 px-2">

                        {arr.map((_, idx) => <MdStar key={idx} className="w-5 h-5 text-yellow-400" />)}

                    </div>

                </div>

                <p className="text-sm font-medium py-4 px-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur culpa,
                    magnam quam doloremque unde ducimus magni ad possimus quasi placeat
                    recusandae esse error illo atque minima aperiam, natus, aut doloribus!
                </p>

                <div className="flex items-center justify-between">

                    <p className="text-sm font-normal text-gray-600 px-2"> Just now </p>

                    <div className="text-red-600 bg-red-100 text-xs font-medium p-2 rounded-md inline-block cursor-default">
                        Unapproved
                    </div>

                </div>


            </div>

        </motion.div>
    );
}