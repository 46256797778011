import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { InputHTMLAttributes, forwardRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { AnimatePresence, motion } from "framer-motion";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    required?: boolean,
    tooltip?: { id: string, tip: string, width?: number; },
    label?: string,
    error?: string | null,
    register?: UseFormRegisterReturn<any>,
    mainClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, Props>(({
    required, className, register,
    error = "", mainClassName = "", tooltip, label = "",
    ...props
}, ref) => {
    return (
        <AnimatePresence initial={false}>

            <motion.div
                className={cn("flex flex-col items-start justify-center", mainClassName)}
                animate={{ height: "auto" }}
            >

                {label ?
                    <Text variant="label" tooltip={tooltip}>

                        {label}

                        {required ? <span className="text-red-500 font-medium"> * </span> : null}

                    </Text> : null
                }

                <input
                    {...props}
                    {...register}
                    ref={ref}
                    className={cn(`
                        flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 text-sm py-2
                        placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:ring-offset-1
                        disabled:cursor-not-allowed disabled:opacity-50 bg-white
                    `, {
                        "focus:ring-red-400 right-1 ring-red-400 ring-offset-1 border-red-300": error ? true : false,
                        "cursor-not-allowed select-none focus:ring-0": props.readOnly
                    }, className)}
                />


                {error &&
                    <motion.span
                        className="text-red-500 flex flex-row items-center space-x-1 mt-1 px-1 text-sm font-normal text-left"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                    >
                        * {error}
                    </motion.span>
                }

            </motion.div>

        </AnimatePresence>
    );
});
