import { GetEmbedCodePopup } from "./getCode.popup";
import { Preview } from "./preview";
import { EmbedSettings } from "./settings";

export function EmbedSection() {
    return (
        <div className="flex items-start justify-center">

            <div className="w-[70%] h-full border-r">

                <Preview />

            </div>

            <EmbedSettings />

            <GetEmbedCodePopup />

        </div>
    );
}