import { Button, SecondaryButton } from "@components/button";
import { QuillInput } from "@components/quillInput";
import { QueryKeys } from "@hooks";
import { useMutation } from "@tanstack/react-query";

type Props = {
    edit: boolean,
    text: string,
    onSaved: () => void;
};

export function Content({ edit, text }: Props) {

    // const [saveChanges, setSaveChanges] = useState(false);

    const { } = useMutation({
        mutationKey: [QueryKeys.testimonials.saveContent],
        // mutationFn: () => { }
    });

    // const onChange = (val: string) => {

    //     if (saveChanges === false) setSaveChanges(true);



    // };

    /*
    const onSave = () => {

         update testimonial query state

         call testimonial - mutate
         
    };
        */

    return (
        <div>

            {edit ?
                <div className="relative">

                    <QuillInput
                        valueAsHTML={text}
                        placeHolder=""
                        recall={false}
                        toolBar={["bold", "italic"]}
                        formats={["italic", "bold"]}
                        className="text-gray-700"
                        style={{
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                            letterSpacing: "0.025em"
                        }}
                        onChange={(txt) => { console.log(txt); }}
                    />

                    <div className="flex items-center justify-end space-x-2 absolute w-full">

                        <SecondaryButton
                            className="text-sm font-normal w-auto"
                            onClick={() => { }}
                        >
                            cancel
                        </SecondaryButton>

                        <Button
                            className="text-sm font-normal w-auto"
                            onClick={() => { }}
                        >

                            save
                        </Button>

                    </div>

                </div> :
                <p className="text-base font-normal text-gray-700 tracking-wide cursor-default">
                    {text}
                </p>
            }


        </div>
    );
}