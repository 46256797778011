import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { useEmailInvitesApiClient } from "@hooks";
import { useCommonStore, useInvitesStore } from "@store";
import { cn } from "@utils/cn";
import { Fragment } from "react";
import { MdDelete } from "react-icons/md";


export function DeleteInvites() {

    const showPopup = useCommonStore((store) => store.activePopup === "delete-invites");
    const selectedRows = useInvitesStore((store) => store.selectedRowsToDelete);

    const { deleteInvites } = useEmailInvitesApiClient({});

    if (selectedRows.length === 0) return null;

    return (
        <Fragment>

            <button
                className={cn(`
                    py-1.5 px-3 bg-red-200 rounded-md w-auto relative cursor-pointer 
                    inline-flex items-center space-x-2 select-none transition-all 
                `)}
                onClick={() => useCommonStore.getState().setActivePopup("delete-invites")}
            >

                <MdDelete className="w-5 h-5 text-red-800" />

                <p className="text-red-800 font-medium"> Delete </p>

            </button>

            {showPopup ?
                <Popup
                    width="30%"
                    name="Delete Invites"
                    onClose={() => useCommonStore.getState().setActivePopup("")}
                >

                    <div className="my-3 space-y-4">

                        <p className="text-sm font-normal">
                            You're about to delete. It'll be gone forever and we won't be able to recover it.
                        </p>

                        <p className="text-sm font-normal">
                            Are you sure? you want to delete?
                        </p>

                    </div>

                    <div className="flex items-center justify-end space-x-3 mt-0 pt-2">

                        <SecondaryButton
                            disabled={deleteInvites.isPending}
                            loading={deleteInvites.isPending}
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => useCommonStore.getState().setActivePopup("")}
                        >
                            Cancel
                        </SecondaryButton>

                        <Button
                            disabled={deleteInvites.isPending}
                            loading={deleteInvites.isPending}
                            variant="danger"
                            className="w-auto text-sm font-medium mt-0"
                            onClick={() => deleteInvites.mutate()}
                        >
                            Delete
                        </Button>

                    </div>

                </Popup> : null
            }


        </Fragment>
    );
}