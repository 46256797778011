import { Button, SecondaryButton } from "@components/button";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";


export function WallOfLovePreviewHeader() {

    const navigate = useNavigate();

    const onShare = () => {

        /* generate embed code  */

    };

    const onSave = () => {

        /* save template */

    };

    return (
        <div className="flex items-center justify-between w-full bo space-x-4 py-2 px-4">

            <SecondaryButton
                className="text-sm font-medium w-auto px-3"
                onClick={() => navigate("/social-proof")}
            >

                <MdArrowBackIos className="w-4 h-4 mr-1" />

                <p> Back </p>

            </SecondaryButton>

            <div className="flex items-center space-x-4">

                <SecondaryButton
                    className="mt-0 w-auto text-sm font-medium"
                    onClick={() => onShare()}
                >
                    Share
                </SecondaryButton>

                <Button
                    className="mt-0 w-auto text-sm font-medium"
                    onClick={() => onSave()}
                >
                    Save Changes
                </Button>

            </div>

        </div>
    );
}