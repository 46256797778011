import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const State: Testimonials.State = {
    filter: {
        range: { from: null, to: null },
        sources: [],
        type: [],
        approved: null,
        rating: null
    },
    pagination: {
        next: false,
        page: 1
    },
    collapsedRow: true,
    showColumns: {
        customerDetails: true,
        status: true,
        submittedAt: true,
        testimonial: true,
    },
    showDetailsPopup: false,
    customerDetails: {},
    selectedRows: []
};

export const useTestimonialStore = create<Testimonials.Type>()(devtools(immer(set => ({
    ...State,
    setFilter: (filter) => set((state) => { state.filter = { ...state.filter, ...filter }; },
        false,
        "testimonials/setFilter"
    ),

    setCollapsedRow: () => set((state) => { state.collapsedRow = !state.collapsedRow; },
        false,
        "testimonials/setCollapsedRow"
    ),
    setTableColumns: (col) => set((state) => { state.showColumns[col] = !state.showColumns[col]; },
        false,
        "testimonial/setTableColumns"
    ),
    setShowDetailsPopup: (show) => set((state) => { state.showDetailsPopup = show; },
        false,
        "testimonials/setShowDetailsPopup"
    ),
    setUpdatedCustomerDetails: (id, value) => set((state) => {

        state.customerDetails[id] = { ...state.customerDetails[id], ...value };

    },
        false,
        "testimonials/setUpdatedCustomerDetails"
    ),
    setSelectedRows: (ids: string[]) => set((state) => { state.selectedRows = [...ids]; },
        false,
        "testimonials/setSelectedRows"
    ),
    setPagination: (pagination) => set((state) => { state.pagination = { ...state.pagination, ...pagination }; },
        false,
        "testimonials/setPagination"
    )
})), {
    name: "testimonials",
    enabled: import.meta.env.DEV
}));