import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from 'zustand/middleware/immer';

const State: Common.State = {
    activePopup: "published-case-study",
    showFullNavbar: false
};

export const useCommonStore = create<Common.Type>()(devtools(immer((set => ({
    ...State,
    setActivePopup: (popup) => set((state) => {

        state.activePopup = popup;

    },
        false,
        "common/setActivePopup"
    ),
    setShowFullNavBar: (show) => set((state) => {

        state.showFullNavbar = show;

    },
        false,
        "common/setShowFullNavBar"
    )
}))), {
    name: "Popup",
    enabled: import.meta.env.DEV,
    anonymousActionType: ""
}));