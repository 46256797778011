import { ImportSection } from "./import/main.import";
import { Providers } from "./providers";


export function ImportTestimonialsPage() {

    return (
        <div className="flex items-start h-full max-h-full overflow-y-auto">

            <Providers />

            <ImportSection />

        </div>
    );
}