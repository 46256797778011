import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "./apiClient";
import { useEffect } from "react";


export function useActiveSpace(): Api.SpacesClient.SpaceType {

    const queryClient = useQueryClient();

    const query = queryClient.getQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    let activeSpace: Api.SpacesClient.SpaceType = {
        createdAt: "",
        formId: "",
        primarySpace: false,
        id: "",
        name: "",
        site: "",
        slug: "",
        logo: ""
    };

    useEffect(() => {

        if (query && query?.activeSpace) {

            activeSpace = { ...query?.activeSpace };
        }

    }, [JSON.stringify(query?.activeSpace ?? "{}")]);

    if (query) activeSpace = { ...query.activeSpace };

    return activeSpace;

}