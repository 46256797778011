import { cn } from "@utils/cn";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { ReactNode } from "react";

interface BodyProps extends HTMLMotionProps<"div"> {
    show: boolean,
    children: ReactNode;
}

export const Body: React.FC<BodyProps> = ({ show, children, ...props }) => {
    return (
        <AnimatePresence initial={false}>

            {show &&
                <motion.div
                    {...props}
                    className={cn(` `)}
                    initial={{
                        opacity: 0,
                        height: 0
                    }}
                    animate={{
                        opacity: 1,
                        height: "auto"
                    }}
                    exit={{
                        opacity: 0,
                        height: 0
                    }}
                    transition={{
                        duration: 0.8,
                        ease: [0.04, 0.62, 0.23, 0.98]
                    }}
                >

                    <motion.div
                        initial={{
                            opacity: 0,
                            scale: 0
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0,
                            transition: {
                                duration: 0.2,
                                type: "spring",
                                bounce: 0
                            }
                        }}
                        transition={{
                            duration: 0.4,
                            delayChildren: 0.3
                        }}
                        className="p-4"
                    >

                        {children}

                    </motion.div>

                </motion.div>
            }

        </AnimatePresence>
    );
};