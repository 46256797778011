import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import { IconBaseProps } from "react-icons/lib";
import { PiStarFill } from "react-icons/pi";

type Props = {
    showLabel?: boolean;
    rated: number,
    readonly?: boolean,
    className?: string,
    iconProps?: IconBaseProps,
    onChange?: (val: number) => void;
};

export function Rating({ showLabel, rated, readonly = false, iconProps, onChange }: Props) {

    const arr = [1, 2, 3, 4, 5];

    const [activeIdx, setActiveIdx] = useState(-1);
    const [hoveredIdx, setHoveredIdx] = useState(-1);

    useEffect(() => { setActiveIdx(rated); }, [rated]);

    return (
        <div className="space-y-2 w-1/2">

            {showLabel ?
                <Text variant="label">
                    Rating
                </Text> : null
            }

            <div className="flex items-center space-x-1">

                {arr.map((_, idx) => {

                    idx += 1;

                    return (
                        <div
                            key={idx}
                            className={cn(`cursor-pointer transition-all duration-200 ease-in-out`, {
                                "cursor-default": readonly
                            })}
                            onMouseEnter={() => {
                                if (readonly) return;
                                setHoveredIdx(idx);
                            }}
                            onMouseLeave={() => {
                                if (readonly) return;
                                setHoveredIdx(-1);
                            }}
                            onClick={() => {
                                if (readonly) return;
                                setActiveIdx(idx);
                                onChange?.(idx);
                            }}
                        >

                            <PiStarFill
                                key={idx}
                                className={cn(`
                                    ${(idx <= hoveredIdx) || (idx <= activeIdx) ? "text-yellow-400 scale-105" : "text-gray-400"}
                                    w-6 h-6   
                                `)}
                                {...iconProps}
                            />

                        </div>
                    );
                })}

            </div>

        </div>
    );
}