import { Logo } from "@components/icons/logo";
import { cn } from "@utils/cn";

type Props = {
    className?: string;
};

export function PoweredBy({ className }: Props) {
    return (
        <div
            className={cn(`
                bg-white rounded-xl shadow-lg space-x-2 px-4 py-2 absolute top-[10px] left-[20px]
                flex items-center justify-center w-[390px] text-center
            `, className)}
        >

            <span className="text-xl text-center font-semibold tracking-wider">
                Made with
            </span>

            <Logo className="w-8 h-8" />

            <span className="text-xl text-center font-semibold tracking-wider">
                Gozen Testimonial
            </span>

        </div>
    );
}