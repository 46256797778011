import { DateTime } from "luxon";
import { TextTestimonial } from "./text";
import { VideoTestimonial } from "./video";
import { AudioTestimonial } from "./audio";

type Props = {
    response: Api.Testimonials.getAll["testimonials"][0];
};

export function TestimonialSection({ response }: Props) {

    const type = response.type;

    const submittedAt = response.submittedAt;
    const dateTime = DateTime.fromISO(submittedAt);

    const relativeTime = dateTime.toRelative();

    const testimonial = response.testimonial;

    return (
        <div className="w-[70%]">

            {type === "text" && testimonial.text ?
                <TextTestimonial
                    content={testimonial.text.content}
                    rating={testimonial.rating}
                    relativeTime={relativeTime as string}
                /> : null
            }

            {type === "video" && testimonial.video ?
                <VideoTestimonial
                    rating={testimonial.rating}
                    thumbnailKey={testimonial.video.thumbnail}
                    relativeTime={relativeTime as string}
                /> : null
            }

            {type === "audio" && testimonial.audio ?
                < AudioTestimonial
                    rating={testimonial.rating}
                    relativeTime={relativeTime as string}
                    submittedAt={submittedAt}
                /> : null
            }

        </div>
    );

}