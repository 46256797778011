import { Button } from "@components/button";
import { Select } from "@components/select";
import { Text } from "@components/text";
import { useState } from "react";

type Props = {
    type: "pabbly" | "webhook";
    options: { value: string, label: string; }[];
    onCreateNew: () => void,
    onClick: (id: string) => void;
};

export function ListExistingPabblyIntegration({ type, options, onCreateNew, onClick }: Props) {

    const [selectedIntegrationId, setSelectedIntegrationId] = useState("");

    return (
        <div>

            <div className="space-y-2 border-y py-2">

                <Text
                    text={type === "pabbly" ?
                        "Integration for pabbly already exist for this account." :
                        "Your have already added a custom webhook for this account."
                    }
                />

                <div className="mt-4">

                    <Text
                        text={
                            type === "pabbly" ?
                                "Would you like to connect your existing account?" :
                                "Your you like to use existing webhook"
                        }
                        className="text-sm font-normal"
                    />

                    <Select
                        className="mt-1"
                        defaultInputValue={selectedIntegrationId}
                        options={options}
                        onChange={(option: any) => setSelectedIntegrationId(option.value)}
                    />

                    <p className="my-1 text-sm text-gray-600 mt-1 px-1">

                        Or connect new account

                        <u
                            onClick={() => onCreateNew()}
                            className="text-primary cursor-pointer pl-1"
                        >
                            click
                        </u>

                    </p>

                </div>

            </div>

            <div className="flex items-center justify-end my-2">

                <Button
                    text="Create"
                    className="w-auto text-sm"
                    onClick={() => onClick(selectedIntegrationId)}
                />

            </div>

        </div >
    );
}