import { CheckBoxInput } from "@components/input";
import { Text } from "@components/text";
import { useTestimonialStore } from "@store";


export function TestimonialTypesFilter() {

    const types = useTestimonialStore((store) => store.filter.type);

    const onChangeType = (type: string) => {

        useTestimonialStore.getState().setFilter({
            type: types.includes(type) ?
                types.filter((t) => t !== type) :
                [...types, type]
        });

    };
    return (
        <div className="">

            <Text
                variant="label"
                size="sm"
                className="font-medium tracking-wide text-gray-900"
                text={"Testimonial Type"}
            />

            <div className="space-y-1 px-1 my-1">

                <CheckBoxInput
                    label="video"
                    value={"video"}
                    defaultChecked={types.includes("video")}
                    onClick={() => onChangeType("video")}
                />

                <CheckBoxInput
                    label="audio"
                    value={"audio"}
                    defaultChecked={types.includes("audio")}
                    onClick={() => onChangeType("audio")}
                />

                <CheckBoxInput
                    label="text"
                    value={"text"}
                    defaultChecked={types.includes("text")}
                    onClick={() => onChangeType("text")}
                />

            </div>

        </div>
    );
}