import { cn } from "@utils/cn";
import { useFormStore } from "@store";
import { MediaRecodingState } from "./main";
import { AudioVisualizer } from "./audioVisualizer";

type Props = {
    recordingState: MediaRecodingState,
    formattedTimer: string,
    mediaSteam: React.MutableRefObject<MediaStream | null>,
    onStart: () => void;
};

export function Controls({ recordingState, formattedTimer, mediaSteam, onStart }: Props) {

    const fontFamily = useFormStore((store) => store.design.fontFamily);

    return (
        <div className="flex items-center justify-between px-4 py-3 bg-black rounded-b-xl">

            <p
                className="text-white cursor-default"
                style={{ fontFamily }}
            >
                {formattedTimer}
            </p>

            <div
                className={cn(`
                    rounded-full bg-red-600 w-[40px] h-[40px] cursor-pointer border-[4px] border-white
                    transition-all duration-200 
                `, {
                    "hover:shadow-lg hover:shadow-red-700 hover:border-[3px]": recordingState !== "starting",
                    "bg-white flex items-center justify-center": recordingState === "recoding",
                })}
                onClick={() => onStart()}
            >
                {recordingState === "recoding" ?
                    <div
                        className="bg-red-600 rounded-md w-[22px] h-[22px]"
                    /> : null
                }
            </div>

            <AudioVisualizer stream={mediaSteam.current} />

        </div>
    );
}