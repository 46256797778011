
type Props = {
    onClick: () => void;
};

export function AppDrawerIcon({ onClick }: Props) {
    return (
        <div
            className={`
                hover:bg-[#eeeeee] hover:border border 
                border-white p-[12px] rounded-full cursor-pointer
            `}
            onClick={() => onClick()}
        >

            <svg
                className={"w-[18px] h-[18px]"}
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.5 4.75C0.5 5.30228 0.947715 5.75 1.5 5.75H4.75C5.30228 5.75 5.75 5.30228 5.75 4.75V1.5C5.75 0.947715 5.30228 0.5 4.75 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V4.75ZM8.375 20.5C8.375 21.0523 8.82272 21.5 9.375 21.5H12.625C13.1773 21.5 13.625 21.0523 13.625 20.5V17.25C13.625 16.6977 13.1773 16.25 12.625 16.25H9.375C8.82271 16.25 8.375 16.6977 8.375 17.25V20.5ZM0.5 20.5C0.5 21.0523 0.947715 21.5 1.5 21.5H4.75C5.30228 21.5 5.75 21.0523 5.75 20.5V17.25C5.75 16.6977 5.30228 16.25 4.75 16.25H1.5C0.947715 16.25 0.5 16.6977 0.5 17.25V20.5ZM0.5 12.625C0.5 13.1773 0.947715 13.625 1.5 13.625H4.75C5.30228 13.625 5.75 13.1773 5.75 12.625V9.375C5.75 8.82271 5.30228 8.375 4.75 8.375H1.5C0.947715 8.375 0.5 8.82272 0.5 9.375V12.625ZM8.375 12.625C8.375 13.1773 8.82272 13.625 9.375 13.625H12.625C13.1773 13.625 13.625 13.1773 13.625 12.625V9.375C13.625 8.82271 13.1773 8.375 12.625 8.375H9.375C8.82271 8.375 8.375 8.82272 8.375 9.375V12.625ZM17.25 0.5C16.6977 0.5 16.25 0.947715 16.25 1.5V4.75C16.25 5.30228 16.6977 5.75 17.25 5.75H20.5C21.0523 5.75 21.5 5.30228 21.5 4.75V1.5C21.5 0.947715 21.0523 0.5 20.5 0.5H17.25ZM8.375 4.75C8.375 5.30228 8.82272 5.75 9.375 5.75H12.625C13.1773 5.75 13.625 5.30228 13.625 4.75V1.5C13.625 0.947715 13.1773 0.5 12.625 0.5H9.375C8.82271 0.5 8.375 0.947715 8.375 1.5V4.75ZM16.25 12.625C16.25 13.1773 16.6977 13.625 17.25 13.625H20.5C21.0523 13.625 21.5 13.1773 21.5 12.625V9.375C21.5 8.82271 21.0523 8.375 20.5 8.375H17.25C16.6977 8.375 16.25 8.82272 16.25 9.375V12.625ZM16.25 20.5C16.25 21.0523 16.6977 21.5 17.25 21.5H20.5C21.0523 21.5 21.5 21.0523 21.5 20.5V17.25C21.5 16.6977 21.0523 16.25 20.5 16.25H17.25C16.6977 16.25 16.25 16.6977 16.25 17.25V20.5Z"
                    fill="#06152D"
                />

            </svg>

        </div >
    );
}