import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useEmbedStore } from "@store";


export const StandardSettings: React.FC = () => {

    const { height, heightUnit, width, widthUnite, removeBackground } = useEmbedStore((store) => store.standard);

    return (
        <div className="space-y-4">

            <div className="font-normal text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">

                <span className="text-sm font-normal text-gray-600 tracking-wide">
                    Width
                </span>

                <div className="inline-flex justify-end">

                    <Input
                        type="number"
                        value={width}
                        className="rounded-l-lg rounded-r-none border-r-0"
                        onChange={(e) => useEmbedStore.getState().setStandard({
                            width: parseInt(e.target?.value ?? 0)
                        })}
                    />

                    <select
                        value={widthUnite}
                        onChange={e => useEmbedStore.getState().setStandard({ widthUnite: e.target.value as any })}
                        className="focus:outline-none border px-2 py-1 rounded-lg rounded-l-none font-medium"
                    >
                        <option value="%" > % </option>
                        <option value="px"> px </option>
                    </select>

                </div>
            </div>

            <div className="font-normal text-xs 2xl:text-sm flex flex-row justify-between items-center w-full">

                <span className="text-sm font-normal text-gray-600 tracking-wide">
                    Height
                </span>
                <div className="inline-flex justify-end">

                    <Input
                        type="number"
                        value={height}
                        className="rounded-l-lg rounded-r-none border-r-0"
                        onChange={(e) => useEmbedStore.getState().setStandard({
                            height: parseInt(e.target?.value ?? 0)
                        })}
                    />

                    <select
                        value={heightUnit}
                        onChange={e => useEmbedStore.getState().setStandard({ heightUnit: e.target.value as any })}
                        className="focus:outline-none border px-2 py-1 rounded-lg rounded-l-none text-font_secondary"
                    >
                        <option value="%"> %</option>
                        <option value="px"> px </option>
                    </select>

                </div>

            </div>

            <TextToggle
                name="Remove Background"
                isActive={removeBackground}
                onClick={() => useEmbedStore.getState().setStandard({ removeBackground: !removeBackground })}
            />

        </div>
    );
};