import { MigrationSourceTypes } from "@components/icons";
import { cn } from "@utils/cn";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";


export function MigratorList() {

    const [searchParams, setSearchParams] = useSearchParams();

    const active = searchParams.get("tab");

    useEffect(() => {

        if (!active) {
            searchParams.set("tab", "testimonial.to");
            setSearchParams(searchParams);
        }

    }, []);

    const onClick = (provider: string) => {

        searchParams.set("tab", provider);

        setSearchParams(searchParams);

    };

    return (
        <div className="bg-white inline-block px-4 py-6 space-y-2 w-[26%] overflow-y-auto h-full max-h-full">

            <div className="space-y-2">

                {MigrationSourceTypes.map((item, idx) => (
                    <div
                        key={idx}
                        className={cn(`
                                hover:bg-blue-100 cursor-pointer px-4 py-2 rounded-md flex items-center
                                transition-all duration-300 ease-in-out
                            `, {
                            "bg-blue-100": active === item.value
                        })}
                        onClick={() => onClick(item.value)}
                    >

                        <div className="mr-3">

                            {item.icon}

                        </div>

                        <p className="text-sm font-medium">
                            {item.name}
                        </p>

                    </div>
                ))}

            </div>

        </div>
    );
}