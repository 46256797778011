import Src from '@assets/integrations/webhook.png';

export const WebHookIcon: React.FC = () => {
    return (
        <img
            height="60"
            width="60"
            src={Src}
            alt="custom webhook"
        />
    );
}

