import { Auth0 } from "@lib/auth0";
import { Fragment, useEffect, useState, } from "react";
import { ResendVerificationMail } from "./resendVerificationMail";
import { ErrorBadge } from "@components/badge";
import { Button } from "@components/button";
import { Loading } from "@components/loading";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "@store";
import { useAuthApiClient } from "@hooks";
import toast from "react-hot-toast";
import { LocalStorageUtil } from "@utils/localStorage";


export function LoginSuccess() {

    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [resendVerificationMail, setResendVerificationMail] = useState(false);
    const [loading, setLoading] = useState(true);

    const { setUser } = useUserStore();

    const { login } = useAuthApiClient({});

    useEffect(() => { onGetAccessToken(); }, []);

    const onGetAccessToken = async () => {
        try {

            setLoading(true);

            const result = await Auth0.getAccessToken();

            if (result.error) {
                setError(result.error);
                return;
            }

            if (result.accessToken) {

                const data = await login.mutateAsync(result.accessToken);

                if (login.isError) {
                    toast.error(login.error.message);
                    return;
                }

                if (!data) return;

                setUser({ ...data.user });

                let redirectTo = "/";

                if (!data.user.onboarding) {
                    redirectTo = "/onboarding?screen=website";
                    LocalStorageUtil.set("showOnboarding", "true");
                }

                navigate(redirectTo, { replace: true, });

                localStorage.setItem("showOnboarding", data.user.onboarding ? "false" : "true");

                return;
            }

        } catch (err: any) {
            Auth0.logout();
        }

    };

    const onRedirectToLogin = () => Auth0.logout();

    return (
        <div className="w-[60%] max-w-[60%] flex items-center justify-center">

            <div className="w-[60%] max-w-[60%] flex items-center justify-center">

                {loading && !error ? <Loading text="Verifying your account..." /> :
                    <Fragment>

                        {resendVerificationMail ?
                            <ResendVerificationMail /> :
                            <div className="flex flex-col items-center justify-center h-full p-10 space-y-4">

                                <ErrorBadge
                                    error={error}
                                    showClose={false}
                                />

                                {error === "Please verify your email before logging in." ?
                                    <Button onClick={() => setResendVerificationMail(true)}>

                                        Resend Verification Email

                                    </Button> : null
                                }

                                <p className="mt-5 font-normal cursor-default text-md">

                                    Go back to

                                    <u
                                        className="ml-1 cursor-pointer text-primary"
                                        onClick={() => onRedirectToLogin()}
                                    >
                                        Login
                                    </u>
                                </p>

                            </div>
                        }

                    </Fragment>
                }

            </div>

        </div>
    );
}
