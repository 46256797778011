import { useIsFirstRender } from "@uidotdev/usehooks";
import { cn } from "@utils/cn";
import { HTMLMotionProps, motion } from "framer-motion";
import { ReactNode } from "react";

interface ItemProps extends HTMLMotionProps<"div"> {
    children: ReactNode;
}

export const Item: React.FC<ItemProps> = ({ children, className, ...props }) => {

    const isFirstRender = useIsFirstRender();

    return (
        <motion.div
            {...props}
            className={cn(`bg-white cursor-pointer`, className)}
            initial={isFirstRender ? false : {
                height: 0
            }}
            animate={{
                height: "auto"
            }}
            exit={{
                height: 0
            }}
            transition={{
                when: "beforeChildren"
            }}
        >
            {children}
        </motion.div>
    );
};