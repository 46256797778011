import { cn } from "@utils/cn";
import { useEffect, useState } from "react";
import { MdSearch } from "react-icons/md";
import { useDebounce } from "@uidotdev/usehooks";

type Props = {
    className?: string,
    iconClassName?: string,
    iconDivClassName?: string,
    inputClassName?: string,
    placeHolder?: string,
    onSearch: (search: string) => void;
};

export function Search({ className, iconClassName, iconDivClassName, inputClassName, placeHolder, onSearch }: Props) {

    const [search, setSearch] = useState("");

    const debouncedSearch = useDebounce(search, 300);

    useEffect(() => onSearch(debouncedSearch), [debouncedSearch]);

    return (
        <div className={cn("relative bg-white", className)}>

            <div className={cn(`absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none`, iconDivClassName)}>
                <MdSearch className={cn(`w-6 h-6 text-gray-600`, iconClassName)} />
            </div>

            <input
                type="text"
                value={search}
                className={cn(`
                    block w-full px-4 py-2 pl-10 text-gray-900 border border-gray-300
                    outline-none text-lg font-normal rounded-lg 
                `, inputClassName)}
                placeholder={placeHolder ?? "Search"}
                onChange={(e) => setSearch(e.target.value)}
            />

            { }

        </div>
    );
}