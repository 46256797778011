import { CheckBox } from "@components/checkbox";
import { Input } from "@components/input";
import { Select } from "@components/select";
import { Text } from "@components/text";
import { LuHelpCircle } from "react-icons/lu";
import { MdOutlineAdd } from "react-icons/md";
import { CustomFieldTypes, useFormStore } from "@store";
import { Tooltip } from "react-tooltip";
import { MdDelete } from "react-icons/md";
import { cn } from "@utils/cn";
import { v4 as uuid } from "uuid";

const options: { value: (keyof (typeof CustomFieldTypes)), label: string; }[] = [
    { value: "email", label: "Email" },
    { value: "url", label: "Url" },
    { value: "text", label: "Text" },
    { value: "longText", label: "Long Text" },
    { value: "checkBox", label: "CheckBox" },
];

export function CustomFields() {

    const fields = useFormStore(state => state.customDetails.customFields);

    return (
        <div className="pt-2">

            <div className="flex items-center justify-between px-1">

                <Text
                    text="Custom Fields"
                    variant="label"
                    className="text-gray-900"
                />

                <div className="bg-gray-200 rounded-md p-1 hover:bg-gray-300 transition-all duration-300 ease-in-out">

                    <MdOutlineAdd
                        className="w-5 h-5"
                        onClick={() => useFormStore.getState().setAddCustomField(uuid())}
                    />

                </div>

            </div>

            <div className="space-y-4">

                {fields.map((field, idx) => <Field key={idx} id={field.id} idx={idx} />)}

            </div>

        </div>
    );
}

type Props = {
    idx: number,
    id: string;
};

function Field({ id, idx, }: Props) {

    const fields = useFormStore((state) => state.customDetails.customFields);

    const field = fields[idx];

    const selectedOption = options.filter(item => item.value === field.type)[0];

    return (
        <div className="border border-gray-300 rounded-md p-3 space-y-1 my-2 bg-gray-50">

            <div>

                <Text
                    text="Type"
                    variant="label"
                />

                <Select
                    className="mt-1"
                    defaultValue={selectedOption}
                    options={options}
                    onChange={(e: any) => useFormStore.getState().setCustomField(idx, { type: e.value })}
                />

            </div>

            <Input
                type="text"
                label="Label"
                value={field.label}
                onChange={(e) => useFormStore.getState().setCustomField(idx, { label: e.target.value })}
            />

            <div className="">

                <Text variant="label">
                    Other Options
                </Text>

                <div className="px-1 flex justify-between">

                    <div className="flex items-center space-x-3 mt-0">

                        <div className="flex items-center space-x-1.5">

                            <CheckBox
                                defaultChecked={field.required}
                                onClick={() => useFormStore.getState().setCustomField(idx, { required: !field.required })}
                            />

                            <Text className="text-gray-800">
                                Required
                            </Text>

                        </div>

                        <div className="flex items-center space-x-1.5">

                            <CheckBox
                                defaultChecked={field.hidden}
                                onClick={() => useFormStore.getState().setCustomField(idx, { hidden: !field.hidden })}
                            />

                            <Text className="text-gray-800">
                                Hidden
                            </Text>

                            <LuHelpCircle
                                id="hidden-field-tooltip"
                                className="text-gray-500"
                            />

                            <Tooltip anchorSelect="#hidden-field-tooltip">
                                Hidden field
                            </Tooltip>

                        </div>

                    </div>

                    <div
                        className={cn(`
                            bg-gray-200 p-1 rounded-md flex items-center hover:bg-red-300 group 
                            transition-all duration-300 ease-in-out
                        `)}
                        onClick={() => useFormStore.getState().deleteCustomField(id)}
                    >

                        <MdDelete className="w-5 h-5 group-hover:text-red-900" />

                    </div>

                </div>

            </div>

        </div>
    );
}