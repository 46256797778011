import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";
import { SocialProof } from "./socialProof.type";
import { SocialProofState } from "./state";

export const useSocialProofStore = create<SocialProof.Type>()(devtools(persist(immer(set => ({
    ...SocialProofState,
    clear: () => set((state) => {

        state.selectedTestimonials = [];

    },
        false,
        "socialProof/clear"
    ),
    setActiveImageTemplateTab: (tab) => set((state) => {

        state.activeTab = tab;

    },
        false,
        "socialProof/setActiveImageTemplateTab"
    ),
    setFilter: (filter) => set((state) => {

        state.testimonialFilter = { ...state.testimonialFilter, ...filter };

    },
        false,
        "testimonials/setFilter"
    ),
    setSelectedTestimonial: (testimonial) => set((state) => {

        state.selectedTestimonials = [...state.selectedTestimonials, testimonial];

    },
        false,
        "testimonials/setSelectedTestimonial"
    ),
    removeSelectedTestimonial: (id) => set((state) => {

        state.selectedTestimonials = state.selectedTestimonials.filter(t => t.id !== id);

    },
        false,
        "testimonials/removeSelectedTestimonial"
    ),

    /* image template */
    setImageTemplateContent: (content) => set((state) => {

        state.imageTemplate.content = { ...state.imageTemplate.content, ...content };

    },
        false,
        "testimonials/setImageTemplateContent"
    ),
    setImageTemplateSettings: (settings) => set((state) => {

        state.imageTemplate.settings = { ...state.imageTemplate.settings, ...settings };

    },
        false,
        "testimonials/setImageTemplateSettings"
    ),
    setImageTemplateBackground: (background) => set((state) => {

        state.imageTemplate.background = { ...state.imageTemplate.background, ...background };

    },
        false,
        "testimonials/setImageTemplateBackground"
    ),
    setImageTemplateBranding: (branding) => set((state) => {

        state.imageTemplate.branding = { ...state.imageTemplate.branding, ...branding };

    },
        false,
        "testimonials/setImageTemplateBranding"
    ),

    /* wall of love */
    setWolTemplateBranding: (branding) => set((state) => {

        state.wallOfLoveTemplate.branding = { ...state.wallOfLoveTemplate.branding, ...branding };

    },
        false,
        "testimonials/setWolTemplateBranding"
    ),
    setWolTemplateFilter: (filter) => set((state) => {

        state.wallOfLoveTemplate.filter = { ...state.wallOfLoveTemplate.filter, ...filter };

    },
        false,
        "testimonials/setWolTemplateFilter"
    ),
    setWolTemplateContent: (content) => set((state) => {

        state.wallOfLoveTemplate.content = { ...state.wallOfLoveTemplate.content, ...content };

    },
        false,
        "testimonials/setWolTemplateContent"
    ),
    setWolTemplateHero: (hero) => set((state) => {

        state.wallOfLoveTemplate.hero = { ...state.wallOfLoveTemplate.hero, ...hero };

    },
        false,
        "testimonials/setWolTemplateHero"
    ),
    setWolTemplateNavigation: (navigation) => set((state) => {

        state.wallOfLoveTemplate.navigation = { ...state.wallOfLoveTemplate.navigation, ...navigation };

    },
        false,
        "testimonials/setWolTemplateNavigation"
    ),

    /* video template */
    setVideoTemplate: (params) => set((state) => {

        state.videoTemplate = { ...state.videoTemplate, ...params };

    },
        false,
        "testimonials/setVideoTemplate"
    ),
    setVideoTemplateBackground: (background) => set((state) => {

        state.videoTemplate.background = { ...state.videoTemplate.background, ...background };

    },
        false,
        "testimonials/setVideoTemplateBackground"
    )
})), {
    name: StoreKeys.socialProof
}), {
    name: StoreKeys.socialProof,
    enabled: import.meta.env.DEV
}));