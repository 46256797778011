import { Input } from "@components/input";
import { motion } from "framer-motion";
import { ProfileField } from "./profile/profile";
import { TextTestimonialField } from "./profile/text";
import { AttachmentsField } from "./profile/attachment";
import { Button, SecondaryButton } from "@components/button";

export function EditSection() {

    const customerDetails: Api.Testimonials.Type["customerDetails"] = {
        "name": {
            answer: "Alvaro Cintas",
            type: "string",
            varType: "string"
        },
        "handle": {
            answer: "@dr_cintas",
            type: "string",
            varType: "string"
        },
        "photo": {
            answer: "https://pbs.twimg.com/profile_images/1615753720691556375/3IlAzsa0_x96.jpg",
            type: "upload",
            varType: "string"
        }
    };

    // const source = "twitter";

    // const testimonial: Api.Testimonials.Type["testimonial"] = {
    //     text: {
    //         content: "Cursor with Claude 3.5 Sonnet is a major shift.\n\nPeople keep building and sharing crazy use cases with it.\n\n10 wild examples",
    //         imageAttachments: ["https://pbs.twimg.com/media/GWjwdEAWQAA854L?format=jpg&name=medium"]
    //     },
    //     audio: null,
    //     rating: null,
    //     video: null
    // };

    return (
        <motion.div
            className="h-[82%] w-full mt-2 p-4 space-y-3 bg-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <Input
                label="Name"
                value={customerDetails['name'].answer}
                onChange={() => { }}
            />

            <Input
                label="Secondary Name"
                value={customerDetails['name'].answer}
                onChange={() => { }}
            />

            <ProfileField />

            <TextTestimonialField />

            <AttachmentsField />

            <div className="flex items-center justify-end space-x-4 py-3">

                <SecondaryButton
                    className="w-auto text-sm font-medium"
                    onClick={() => { }}
                    text="Undo"
                />

                <Button
                    className="w-auto text-sm font-medium"
                    text={"Import"}
                    onClick={() => { }}
                />

            </div>

        </motion.div>
    );
}