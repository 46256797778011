import { NavigateOptions, useNavigate } from "react-router-dom";

type Props = {
    path: string,
    params?: {
        [key: string]: string;
    },
    options?: NavigateOptions;
};

export function useCustomNavigation() {

    const navigate = useNavigate();

    return ({ path, params, options }: Props) => {

        const searchParams = new URLSearchParams(params).toString();

        navigate(`${path}?${searchParams}`, options);
    };

}