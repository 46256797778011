import { useEffect, useState } from "react";
import { Card } from "./card";
import { useUserStore } from "@store";
import toast from "react-hot-toast";
import { QueryKeys, useActiveSpace, useFormApiClient, useGetQueryData, useGetQueryStatus } from "@hooks";

type Props = {
    loading: boolean,
};

export function NotifyMeMail({ loading }: Props) {

    const { name, formId } = useActiveSpace();
    const emailId = useUserStore((store) => store.email);

    const query = useGetQueryData<Form.State["emailNotification"]>([QueryKeys.form.getEmailTemplates]);
    const queryStatus = useGetQueryStatus([QueryKeys.form.getEmailTemplates]);

    const [show, setShow] = useState(false);
    const [sendTo, setSendTo] = useState(emailId);
    const [subject, setSubject] = useState(`GoZen Testimonial: New testimonial received`);
    const [message, setMessage] = useState(`
        <div> Your ${name} form has a new response </div>
            <div><br/></div>
            <div>Log in to view or download your responses at 
                <a href="https://app.testimonial.gozen.io/testimonials" target="_blank"> 
                    here
                </a>  
            </div>
            <div><br></div>
        <div>Team GoZen </div>
    `);

    useEffect(() => {

        if (query && query.me) {

            if (query.me?.message) setMessage(query.me?.message);

            if (query.me?.subject) setSubject(query.me?.subject);

            setShow(true);

        } else setShow(false);

    }, [queryStatus?.status === "pending"]);

    const { saveEmailTemplate } = useFormApiClient({});

    const { isPending } = saveEmailTemplate;

    const onSave = async (disable: boolean) => {

        await saveEmailTemplate.mutateAsync({
            id: formId,
            emailNotification: {
                me: disable === false ? { message, sendTo, subject } : null,
            },
            onError: (err: any) => toast.error(err),
        });

    };

    const onShowToggle = () => {

        const previouslyEnabled = query.me?.message !== null && query.me?.subject !== null;

        console.log({ previouslyEnabled });

        if (!show === false && previouslyEnabled) onSave(true);

        setShow(!show);

    };

    return (
        <Card
            type="me"
            loading={loading}
            active={show}
            saving={isPending}
            title="Notify Me"
            description="Get notified when someone submits response to your form"
            message={message}
            subject={subject}
            sendTo={sendTo}
            onSave={() => onSave(false)}
            onSendToChange={(val) => setSendTo(val)}
            onSubjectChange={(val) => setSubject(val)}
            onMessageChange={(val) => setMessage(val)}
            onToggle={() => onShowToggle()}
        />
    );
}