import { RadioInput } from "@components/input";
import { Text } from "@components/text";
import { useTestimonialStore } from "@store";


export function StatusFilter() {

    const approved = useTestimonialStore((store) => store.filter.approved);

    return (
        <div className="">

            <Text
                variant="label"
                text={"Approval Status"}
                size="sm"
                className="font-medium tracking-wide text-gray-900"
            />

            <div className="space-y-2 mt-2 px-1">

                <RadioInput
                    name="approved"
                    label="Approved"
                    defaultChecked={approved ? true : false}
                    value="approved"
                    labelClassName="text-normal text-black"
                    onClick={() => useTestimonialStore.getState().setFilter({ approved: true })}
                />

                <RadioInput
                    name="approved"
                    label="Unapproved"
                    defaultChecked={!approved ? true : false}
                    value="unapproved"
                    labelClassName="text-normal text-black"

                    onClick={() => useTestimonialStore.getState().setFilter({ approved: false })}
                />

            </div>

        </div>
    );
}