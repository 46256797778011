import { SecondaryButton } from "@components/button";
import { useTestimonialStore } from "@store";
import { cn } from "@utils/cn";
import { MdOutlineCompress } from "react-icons/md";
import { MdExpand } from "react-icons/md";


export function ExpandAndCollapse() {

    const collapsed = useTestimonialStore((store) => store.collapsedRow);

    return (
        <SecondaryButton
            className={cn(`py-2 px-3 bg-gray-200 hover:bg-gray-200/80 w-auto`)}
            onClick={() => useTestimonialStore.getState().setCollapsedRow()}
        >

            {!collapsed ?
                <MdExpand className="h-5 w-5" /> :
                <MdOutlineCompress className="h-5 w-5" />
            }

        </SecondaryButton>
    );
}