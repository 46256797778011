import { Input } from "@components/input";
import { Text } from "@components/text";
import { useCaseStudyStore } from "@store";


export function YourDetails() {

    const { companyName, businessDescription } = useCaseStudyStore((store) => ({
        companyName: store.myDetails.companyName,
        businessDescription: store.myDetails.businessDescription
    }));

    return (
        <div>

            <Text
                text="You can give your business details here, To improve the output result."
                variant="label"
            />

            <div className="mt-2 space-y-2">

                <Input
                    type="text"
                    label="Company Name"
                    placeholder="Pied Piper"
                    value={companyName}
                />

                <Input
                    type="text"
                    label="Business Description"
                    placeholder=""
                    value={businessDescription}
                />

            </div>

        </div>
    );
}