import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";


export function CompanyField() {

    const company = useImportTestimonialStore((store) => store.testimonial.company);

    return (
        <Input
            type="text"
            placeholder="Pied Piper"
            label="Company"
            mainClassName="w-full"
            value={company}
            onChange={(e) => useImportTestimonialStore.getState().setCompany(e.target.value)}
        />
    );
}