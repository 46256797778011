import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";
import axios from "axios";
import { CustomQueryParams, useCustomQuery } from "../useCustomQuery";

type Props = {
    verifyParams?: {
        apiParams?: {
            id: string;
        },
        params: Partial<CustomQueryParams>;
    };
};

export function useDomainsApiClient({ verifyParams }: Props) {

    const addQuery = useMutation({
        mutationKey: [QueryKeys.domains.get],
        mutationFn: async (body: { type: "sub" | "custom", domain: string; }) => {
            try {
                return (await axios.post<Api.Domains.add>("/api/v1/domains", body)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const removeQuery = useMutation({
        mutationKey: [QueryKeys.domains.remove],
        mutationFn: async (id: string) => {
            try {
                return (await axios.delete("/api/v1/domains", { data: { id } })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const getAllQuery = useQuery({
        enabled: false,
        queryKey: [QueryKeys.domains.get],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Domains.get>("/api/v1/domains")).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const verifyQuery = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.domains.verify, verifyParams?.apiParams?.id],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Domains.verify>("/api/v1/domains/verify", {
                    params: {
                        id: verifyParams?.apiParams?.id ?? ""
                    }
                })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...verifyParams?.params
    });

    return {
        addQuery,
        removeQuery,
        getAllQuery,
        verifyQuery
    };

}