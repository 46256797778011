import { Rating } from "@components/rating";
import { useRef } from "react";
import TemplateBg from "./t4.png";
import { useSocialProofStore } from "@store";
import { PurifyHTML } from "@utils/purifyHTML";


export function Template4() {

    const ref = useRef<null>(null);

    const { name, role, company, rating, text } = useSocialProofStore((store) => ({
        name: store.imageTemplate.content.customerName,
        role: store.imageTemplate.content.role,
        company: store.imageTemplate.content.company,
        rating: store.imageTemplate.content.rating,
        text: store.imageTemplate.content.text
    }));

    return (
        <div
            ref={ref}
            id="preview-template"
            className="border border-gray-600 rounded-lg bg-gray-300 relative h-[1080px] w-[1080px] scale-[0.4]"
        >

            <img
                src={TemplateBg}
                className="w-full h-full rounded-md"
            />

            <div className="absolute top-[0px] left-[70px] w-[84%] h-full p-4 flex items-center">

                <div className="h-[90%] py-2 flex flex-col items-center justify-between">

                    {rating ?
                        <Rating
                            rated={rating}
                            className="mt-4 w-[70px] h-[70px] mx-2"
                            iconProps={{
                                stroke: "black",
                                strokeWidth: 8,
                                className: "mt-4 w-[60px] h-[60px] mx-2"
                            }}
                        /> : null
                    }

                    <p
                        className="text-black text-4xl leading-normal font-medium tracking-wide break-words mt-2 p-4"
                        dangerouslySetInnerHTML={{ __html: PurifyHTML(text ?? "") }}
                    />

                    <div className="flex items-center justify-center space-x-6 w-full h-full mt-4">

                        <div className="space-y-3 text-center">

                            <p className="text-4xl font-semibold tracking-wider text-black">
                                {name}
                            </p>

                            {role && company ?
                                <p className="text-3xl font-normal text-gray-100">

                                    {role && company ? <> {role} of {company} </> : null}

                                    {role ? role : null}

                                    {company ? company : null}

                                </p> : null
                            }

                        </div>

                    </div>

                </div>

            </div>

        </div>
    );

}
