import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { useRef } from "react";


export function UploadAudioTestimonialField() {

    // const avatar = useImportTestimonialStore((store) => store.avatar);

    // const [file, setFile] = useState(null);

    const ref = useRef(null);

    return (
        <div className={cn("space-y-3")}>

            <Text
                text="Pick a Audio file"
                variant="label"
            />

            <input
                id="change-space-logo"
                type="file"
                ref={ref.current}
                accept="image/png, image/jpg, image/jpeg"
                className="hidden"
                onChange={() => { }}
            />

            <div className="flex items-center space-x-3">

                <img
                    onInvalid={() => { }}
                    src={""}
                    className={cn("w-[40px] h-[40px]")}
                />

                <label
                    htmlFor="change-space-logo"
                    className={cn(`
                        inline-block border-2 border-gray-300 py-1 px-4 rounded-md font-medium tracking-wide
                        transition-all duration-200 ease-in-out cursor-pointer text-sm 
                        hover:bg-blue-100 hover:text-primary hover:border-blue-500
                    `)}
                >

                    Change

                </label>

            </div>

        </div>
    );
}