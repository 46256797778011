import { Button } from "@components/button";
import { Input } from "@components/input";
import { useActiveSpace, useIntegrationsApiClient } from "@hooks";
import { useCommonStore, useIntegrationsStore } from "@store";
import { useState } from "react";


export function ConnectScreen() {

    const activeSpace = useActiveSpace();

    const { getAll } = useIntegrationsApiClient();

    const useExistingIntegrationId = useIntegrationsStore((store) => store.googleSheet.useExistingIntegrationId);

    const [sheetName, setSheetName] = useState("");

    const { googleSheet } = useIntegrationsApiClient();

    const onCreate = async () => {
        try {

            if (!useExistingIntegrationId) {

                const code = localStorage.getItem("_c");

                if (!code) {
                    useIntegrationsStore.getState().setGoogleSheetActiveScreen("auth");
                    return;
                }

                await googleSheet.create.mutateAsync({
                    code,
                    formId: activeSpace.formId,
                    sheetName
                });

            } else {

                await googleSheet.create.mutateAsync({
                    code: "",
                    sheetName,
                    integrationId: useExistingIntegrationId,
                    formId: activeSpace.formId,
                });

            }

            getAll.refetch();

            useCommonStore.getState().setActivePopup("");

        } catch (err) {
            console.log(err);
        } finally {
            localStorage.removeItem("_c");
        }
    };

    return (
        <div>

            <div className="my-2 border-y py-3">

                <Input
                    type="text"
                    label="Enter your new sheet name"
                    mainClassName="space-y-1"
                    placeholder="Pied Piper Testimonials"
                    onChange={(e) => setSheetName(e.target.value)}
                />

            </div>

            <div className="flex items-center justify-end">

                <Button
                    disabled={googleSheet.create.isPending}
                    loading={googleSheet.create.isPending}
                    text="Connect"
                    className="text-sm w-auto font-medium"
                    onClick={() => onCreate()}
                />

            </div>

        </div>
    );
}