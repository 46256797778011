import { VideoTestimonial } from "./testimonial/video/main.video";
import { AudioTestimonial } from "./testimonial/audio/main.audio";
import { useSearchParams } from "react-router-dom";
import { QueryKeys, useGetQueryData } from "@hooks";
import { useEffect, useState } from "react";
import { TextTestimonial } from "./testimonial/text/main.text";
import { DateTime } from "luxon";
import { RenderSourceLogo } from "@components/icons";

export function TestimonialCard() {

    const [type, setType] = useState<Api.Testimonials.Type['type'] | "">("");

    const [searchParams] = useSearchParams();

    const testimonialId = searchParams.get("id") ?? "";

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    const item = testimonialsReq.testimonials.filter(item => item.id === testimonialId)[0];

    const dateTime = DateTime.fromISO(item ? item.submittedAt : new Date().toISOString());

    const relativeTime = dateTime.toRelative();

    useEffect(() => {

        const testimonials = testimonialsReq.testimonials;

        const testimonial = testimonials.filter(item => item.id === testimonialId);

        if (testimonial.length) setType(testimonial[0].type);

    }, [testimonialId]);

    return (
        <div className="border border-gray-200 shadow-sm p-4 rounded-lg bg-white space-y-3">

            {type === "text" ? <TextTestimonial /> : null}

            {type === "video" ? <VideoTestimonial /> : null}

            {type === "audio" ? <AudioTestimonial /> : null}

            {item && item?.source !== "gozenTestimonial" ?
                <div className="flex items-center justify-end space-x-2">

                    <p className="text-sm font-normal tracking-wide text-gray-400">
                        Imported from
                    </p>

                    <RenderSourceLogo source={item.source} />

                    <p className="text-sm font-normal tracking-wide text-gray-400">
                        {relativeTime}
                    </p>

                </div> : null
            }

        </div>
    );
}