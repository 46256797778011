import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";

const State: Invites.State = {
    selectedFile: null,
    activeUploadTab: "upload",
    activeTab: "upload",
    selectedColumns: {},
    selectedRowsToDelete: [],
    parsedFile: {
        columns: [],
        rows: []
    },
    template: {
        from: "no-reply@testimonial-gozen.io",
        design: {
            logo: "https://asserts.dev.testimonial.gozen.io/misc/logo.png",
            buttonColor: "#2563EB",
            fontFamily: "Roboto"
        },
        message: `<p> Hey test1 👋 </p> <br/> 

        <p>
            Got a quick minute? We'd love to hear your thoughts! Your feedback helps us make sure we're 
            delivering the best experience possible. 
        </p>   <br/> 
        
        <p>
            Click the link below to share a testimonial with us! 
        </p> <br/>


        <p> Thanks, </p>
        <p> mnm </p>`,
        subject: "Leave a Testimonial",
        to: "",
        fromName: "",
        replyEmail: "",
        buttonText: "Leave a Testimonial",
        templateId: "",
        showBranding: true,
        version: 0
    }
};

export const useInvitesStore = create<Invites.Type>()(devtools(immer(set => ({
    ...State,
    setActiveTab: (tab) => set((state) => { state.activeTab = tab; },
        false,
        "invites/setActiveTab"
    ),
    setActiveUploadTab: (tab) => set((state) => { state.activeUploadTab = tab; },
        false,
        "invites/setActiveUploadTab"
    ),
    setSelectedFile: (file) => set((state) => { state.selectedFile = file; },
        false,
        "invites/setSelectedFile"
    ),
    setSelectedColumn: (col, val) => set((state) => { state.selectedColumns[col] = val; },
        false,
        "invites/setAction"
    ),
    setParsedFile: (file) => set((state) => {

        state.parsedFile.rows = file.rows;
        state.parsedFile.columns = file.columns;

    },
        false,
        "invites/setParsedFile"
    ),
    setSelectedRowsToDelete: (ids: string[]) => set((state) => { state.selectedRowsToDelete = ids; },
        false,
        "invites/setSelectedRows"
    ),
    /* template */
    setTemplate: (template) => set((state) => { state.template = template; },
        false,
        "invite/setTemplate"
    ),
    setReplyToEmail: (email) => set((state) => { state.template.replyEmail = email; },
        false,
        "invites/setReplyToEmail"
    ),
    setFromName: (fromName) => set((state) => { state.template.fromName = fromName; },
        false,
        "invites/setFromName"
    ),
    setLogo: (logo) => set((state) => { state.template.design.logo = logo; },
        false,
        "invites/setLogo"
    ),
    setSubject: (subject) => set((state) => { state.template.subject = subject; },
        false,
        "invites/setSubject"
    ),
    setMessage: (message) => set((state) => { state.template.message = message; },
        false,
        "invites/setMessage"
    ),
    setButtonColor: (color) => set((state) => { state.template.design.buttonColor = color; },
        false,
        "invites/setButtonColor"
    ),
    setButtonText: (text) => set((state) => { state.template.buttonText = text; },
        false,
        "invites/setButtonText"
    ),
    clear: () => set((state) => {

        state.activeTab = "upload";
        state.parsedFile = { columns: [], rows: [] };
        state.selectedColumns = {};
        state.selectedFile = null;

    },
        false,
        "invites/clear"
    )
})), {
    name: StoreKeys.invites,
    enabled: import.meta.env.DEV
}));