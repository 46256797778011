import { Button, SecondaryButton } from "@components/button";
import { Text } from "@components/text";
import { QueryKeys, useEmailInvitesApiClient, useInvalidateQuery } from "@hooks";
import { TemplateSettings } from "./template/settings/main.settings";
import { TemplatePreview } from "./template/preview/main.preview";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { Loading } from "@components/loading";
import { MdArrowBack } from "react-icons/md";


export function InviteTemplatePage() {

    const navigate = useNavigate();

    const { getTemplate, saveTemplate } = useEmailInvitesApiClient({});

    const { invalidate } = useInvalidateQuery();

    useEffect(() => {

        if (!getTemplate.data) getTemplate.refetch();

    }, []);

    const onSendInvite = async () => {

        await saveTemplate.mutateAsync();

        navigate("/invites", { replace: true });

        invalidate([QueryKeys.emailInvites.getAll]);

    };

    return (
        <div className="h-full max-h-full overflow-y-hidden">

            <div className="flex items-center justify-between pt-4 px-8">

                <div className="flex items-center space-x-4">

                    <SecondaryButton
                        className="w-auto text-sm font-medium px-3"
                        onClick={() => navigate("/invites", { replace: true })}
                    >

                        <MdArrowBack className="w-4 h-4" />

                        <p className="ml-1.5"> Back </p>

                    </SecondaryButton>

                    <Text className="text-lg font-semibold tracking-wide">
                        Invite Template
                    </Text>

                </div>


                <Button
                    disabled={saveTemplate.isPending}
                    loading={saveTemplate.isPending}
                    className="w-auto text-sm font-medium"
                    onClick={() => onSendInvite()}
                >
                    Send Invite
                </Button>

            </div>

            <div className="h-full flex items-start justify-between bg-gray-50">

                {getTemplate.isLoading || getTemplate.isFetching ?
                    <Loading /> :
                    <Fragment>

                        <TemplatePreview />

                        <TemplateSettings />

                    </Fragment>
                }


            </div>

        </div>
    );
}