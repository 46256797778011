import { Toggle } from "@components/toggle";
import { cn } from "@utils/cn";
import { Fragment, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@components/button";
import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import Skeleton from "react-loading-skeleton";
import { z } from "zod";
import { Input } from "@components/input";
import { Select } from "@components/select";

type Props = {
    loading: boolean,
    enable?: boolean,
    type: "me" | "respondents",
    sendTo: string,
    options?: { value: string, label: string; }[];
    active: boolean,
    saving: boolean,
    title: string,
    description: string,
    subject: string,
    message: string,
    onSendToChange: (val: string) => void,
    onSubjectChange: (val: string) => void;
    onMessageChange: (val: string) => void;
    onSave: () => void;
    onToggle: () => void;
};

const schema = z.object({
    subject: z.string().min(1, "Subject cannot be empty."),
    message: z.string().min(1, "Message cannot be empty.")
});

export function Card({
    title, description, saving, sendTo, active, subject, message, loading, type, options, enable = true,
    onToggle, onSave, onMessageChange, onSubjectChange, onSendToChange,
}: Props) {

    const [error, setError] = useState({
        subject: "",
        message: ""
    });

    const onHandleSave = () => {

        const valid = schema.safeParse({
            subject: new DOMParser().parseFromString(subject, "text/html").body.innerText.trim(),
            message: new DOMParser().parseFromString(message, "text/html").body.innerText.trim()
        });

        if (valid.success === false) {

            setError({
                subject: valid.error.errors.filter(err => err.path.includes("subject"))[0]?.message ?? "",
                message: valid.error.errors.filter(err => err.path.includes("message"))[0]?.message ?? ""
            });

            return;
        }

        setError({ message: "", subject: "" });

        onSave();

    };

    console.log({ enable });

    return (
        <AnimatePresence initial={false}>

            <motion.div
                className={cn(`
                    flex-col bg-white rounded-xl px-4 py-3 border  
                    my-4 p-4 integration-card-shadow fade-in shadow-md
                `)}
                initial={{
                    height: "auto"
                }}
                animate={{
                    height: "auto"
                }}
                exit={{
                    height: 0
                }}
            >

                {loading ?
                    <Skeleton className="h-[50px]" /> :
                    <Fragment>

                        <div className={cn(`flex flex-row items-center justify-between`)}>

                            <div className="flex flex-row items-center space-x-4">

                                <div className="w-full flex flex-col items-start break-words">

                                    <p className="text-lg font-medium"> {title} </p>

                                    <p className="text-xs text-gray-600 capitalize mt-1">
                                        {description}
                                    </p>

                                </div>
                            </div>

                            <div className="flex flex-row items-center">
                                <Toggle
                                    disable={type === "me" ? false : enable}
                                    isActive={active}
                                    onClick={() => onToggle()}
                                />
                            </div>

                        </div>

                        {active ?
                            <motion.div
                                className="my-2 border-t space-y-2 py-2"
                                initial={{
                                    height: 0,
                                    opacity: 0
                                }}
                                animate={{
                                    height: "auto",
                                    opacity: 1
                                }}
                                exit={{
                                    height: 0,
                                    opacity: 0
                                }}
                            >

                                {type === "me" ?
                                    <Input
                                        disabled={true}
                                        type="email"
                                        label="Send notification to"
                                        value={sendTo}
                                        onChange={(e) => onSendToChange(e.target.value)}
                                    /> :
                                    <div>

                                        <Text text="Send notification to" variant="label" />

                                        <Select
                                            defaultValue={sendTo}
                                            className="mt-[1px]"
                                            options={options}
                                            onChange={(val: any) => onSendToChange(val)}
                                        />

                                    </div>
                                }

                                <div>

                                    <Text text="Subject" variant="label" />

                                    <QuillInput
                                        recall={true}
                                        valueAsHTML={subject}
                                        placeHolder="Enter your subject"
                                        onChange={(params) => onSubjectChange(params.html)}
                                        error={error.subject}
                                    />

                                </div>

                                <div>

                                    <Text text="Message" variant="label" />

                                    <QuillInput
                                        recall={true}
                                        valueAsHTML={message}
                                        placeHolder="Enter your message body"
                                        onChange={(params) => onMessageChange(params.html)}
                                        error={error.message}
                                    />

                                </div>

                                <div className="flex items-center justify-end">

                                    <Button
                                        disabled={saving}
                                        loading={saving}
                                        text="Save"
                                        className="w-auto text-sm font-medium py-2 tracking-wide"
                                        onClick={() => onHandleSave()}
                                    />

                                </div>

                            </motion.div> : null
                        }

                    </Fragment>
                }

            </motion.div>

        </AnimatePresence>
    );
}
