import { Fragment, useState } from "react";
import { cn } from "@utils/cn";
import { InputSource } from "./inputSource";
import { MediaRecodingState } from "../main";

type MediaInput = "video" | "audio";

type Props = {
    isRecordingVideo: boolean,
    recordingState: MediaRecodingState,
    activeSourceId: { audioId: string, videoId: string; },
    onSetActiveSourceId: (params: { audioId: string, videoId: string; }) => void,
    onSetup: () => void,
};

export function ControlSettings({
    isRecordingVideo, recordingState, activeSourceId,
    onSetActiveSourceId, onSetup,
}: Props) {

    const [popup, setPopup] = useState<MediaInput | "">("");
    const [sources, setSources] = useState<MediaDeviceInfo[]>([]);

    const onChangeInput = async (input: MediaInput) => {

        const devices = await navigator.mediaDevices.enumerateDevices();

        const newSources = isRecordingVideo ?
            devices.filter(device => {

                if (input === "video" && device.kind === "videoinput") return device;

                if (input === "audio" && device.kind === "audioinput") return device;

            }) :
            devices.filter(device => input === "audio" && device.kind === "audioinput");

        setSources(newSources);

        setPopup(input);

    };

    const onChangeSource = async (input: MediaInput, deviceId: string) => {

        const devices = await navigator.mediaDevices.enumerateDevices();

        const source = devices.filter(device => device.deviceId === deviceId)[0];

        if (!source) {

            alert("Sorry the source cannot be used.");
            return;

        } else {

            onSetActiveSourceId({
                audioId: input === "audio" ? deviceId : activeSourceId.audioId,
                videoId: input === "video" ? deviceId : activeSourceId.videoId
            });

        }

        onSetup();

    };

    return (
        <Fragment>

            {recordingState === "not-recoding" ?
                <div className={cn(`
                    flex items-center justify-end space-x-2 absolute top-3 right-4 z-30
                `)}>

                    {isRecordingVideo ?
                        <InputSource
                            type="video"
                            activeSourceId={activeSourceId.videoId}
                            popup={popup}
                            sources={sources}
                            onClose={() => setPopup("")}
                            onChangeInput={onChangeInput}
                            onChangeSource={(deviceId: string) => onChangeSource("video", deviceId)}
                        /> : null
                    }

                    <InputSource
                        type="audio"
                        activeSourceId={activeSourceId.audioId}
                        popup={popup}
                        sources={sources}
                        onClose={() => setPopup("")}
                        onChangeInput={onChangeInput}
                        onChangeSource={(deviceId: string) => onChangeSource("audio", deviceId)}
                    />

                </div> : null
            }

        </Fragment>
    );
}