import { Config } from "@config";
import { useActiveSpace } from "@hooks";
import { useRef } from "react";

type Props = {
    className?: string,
    height?: string,
    width?: string;
};

export const EmbeddedPreview: React.FC<Props> = ({
    className = '',
    height = "100%",
    width = "100%"
}) => {

    const { formId } = useActiveSpace();

    const ref = useRef<HTMLIFrameElement | null>(null);

    const url = `${Config.RenderEngineUrl()}/form/embed/${formId}`;

    const sendToken = () => {

        const token = localStorage.getItem("token") as string;

        const targetOrigin = new URL(Config.RenderEngineUrl()).origin;

        if (ref.current) {

            ref.current.contentWindow?.postMessage({ token }, targetOrigin);

        }
    };

    return (
        <iframe
            ref={ref}
            src={url}
            style={{
                height,
                width
            }}
            className={className ? className : `h-full w-full bg-white rounded-l-md`}
            onLoad={() => sendToken()}
        />
    );

};