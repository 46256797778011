import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";
import { ImportTestimonialState } from "./state";

export const useImportTestimonialStore = create<ImportTestimonial.Type>()(devtools(immer(set => ({
    ...ImportTestimonialState,
    setError: (error) => set((state) => {

        state.error = { ...state.error, ...error };

    },
        false,
        "importTestimonial/setErrors"
    ),
    setSource: (source) => set((state) => {

        state.source = source;
        state.error = {};
        state.testimonial = {
            name: "",
            email: "",
            avatar: null,
            company: "",
            companyLogo: null,
            companyUrl: "",
            tagline: "",
            sourceUrl: "",
            date: new Date().toISOString(),
            rating: 0,
            role: "",
            testimonial: {
                audio: { transcribedText: "", file: null },
                text: { content: "", imageAttachments: [] },
                video: { transcribedText: "", file: null }
            },
            type: "text"
        };

    },
        false,
        "importTestimonial/setSource"
    ),
    setName: (name) => set((state) => {

        if (state.error["name"]) state.error["name"] = "";

        state.testimonial.name = name;

    },
        false,
        "importTestimonial/setName"
    ),
    setEmail: (email) => set((state) => {

        if (state.error["email"]) state.error["email"] = "";

        state.testimonial.email = email;

    },
        false,
        "importTestimonial/setEmail"
    ),
    setAvatar: (avatar) => set((state) => {

        state.testimonial.avatar = avatar;

    },
        false,
        "importTestimonial/setAvatar"
    ),
    setCompany: (company) => set((state) => {

        state.testimonial.company = company;

    },
        false,
        "importTestimonial/setCompany"
    ),
    setCompanyUrl: (url) => set((state) => {

        state.testimonial.companyUrl = url;

    },
        false,
        "importTestimonial/setCompanyUrl"
    ),
    setCompanyLogo: (logo) => set((state) => {

        state.testimonial.companyLogo = logo;

    },
        false,
        "importTestimonial/setCompanyLogo"
    ),
    setRole: (role) => set((state) => {

        state.testimonial.role = role;

    },
        false,
        "importTestimonial/setRole"
    ),
    setRating: (rating) => set((state) => {

        state.testimonial.rating = rating;

    },
        false,
        "importTestimonial/setRating"
    ),
    setType: (type) => set((state) => {

        state.testimonial.type = type;

    },
        false,
        "importTestimonial/setType"
    ),
    setSourceUrl: (url) => set((state) => {

        state.testimonial.sourceUrl = url;

    },
        false,
        "importTestimonial/setSourceUrl"
    ),
    setTagline: (tagline) => set((state) => {

        state.testimonial.tagline = tagline;

    },
        false,
        "importTestimonial/setTagline"
    ),
    setDate: (date) => set((state) => {

        state.testimonial.date = date;

    },
        false,
        "importTestimonial/setDate"
    ),
    setAudioTestimonial: (field) => set((state) => {

        state.testimonial.testimonial.audio = {
            file: field.file ?? state.testimonial.testimonial.audio.file,
            transcribedText: field.transcribedText ?? state.testimonial.testimonial.audio.transcribedText
        };

    },
        false,
        "importTestimonial/setAudioTestimonial"
    ),
    setTextTestimonial: (field) => set((state) => {

        state.testimonial.testimonial.text = {
            content: field.content ?? state.testimonial.testimonial.text.content,
            imageAttachments: field.imageAttachments ?? state.testimonial.testimonial.text.imageAttachments
        };

    },
        false,
        "importTestimonial/setTextTestimonial"
    ),
    setVideoTestimonial: (field) => set((state) => {

        state.testimonial.testimonial.video = {
            file: field.file ?? state.testimonial.testimonial.video.file,
            transcribedText: field.transcribedText ?? state.testimonial.testimonial.video.transcribedText
        };

    },
        false,
        "importTestimonial/setVideoTestimonial"
    ),
    setClear: () => set((state) => {

        state.testimonial.name = "";
        state.testimonial.email = "";
        state.testimonial.avatar = null;
        state.testimonial.company = "";
        state.testimonial.companyUrl = "";
        state.testimonial.companyLogo = null;
        state.testimonial.tagline = "";
        state.testimonial.sourceUrl = "";
        state.testimonial.role = "";
        state.testimonial.rating = 0;
        state.testimonial.type = "";
        state.testimonial.date = new Date().toISOString();
        state.testimonial.testimonial = {
            audio: {
                transcribedText: "",
                file: null,
            },
            text: {
                content: "",
                imageAttachments: []
            },
            video: {
                transcribedText: "",
                file: null
            }
        };

    },
        false,
        "importTestimonial/setClear"

    )
})), {
    name: StoreKeys.importTestimonial,
    enabled: import.meta.env.DEV
}));