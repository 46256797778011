import { Text } from "@components/text";
import Template1 from "@assets/socialProofPreview/video/v1.png";
import Template2 from "@assets/socialProofPreview/video/v2.png";
import { cn } from "@utils/cn";
import { useNavigate } from "react-router-dom";

const templates = [
    { image: Template1, id: "v1" },
    { image: Template2, id: "v2" }
];

export function VideoTemplates() {

    const navigate = useNavigate();

    return (
        <div className="pb-10">

            <Text variant="primary" size="md" className="font-medium">
                Create an animated video
            </Text>

            <div className="flex flex-wrap items-start gap-2 mt-2">

                {templates.map((template, index) => (
                    <img
                        key={index}
                        src={template.image}
                        className={cn(`
                            w-36 rounded-md cursor-pointer border-2 hover:border-primary
                            transition-all duration-300 ease-in-out
                        `)}
                        onClick={() => navigate(`/social-proof/edit/video/${template.id}`)}
                    />
                ))}

            </div>

        </div>
    );
}