
export const TimeLine = [
    {
        "title": "Add Custom Domains and Sub Domains",
        "description": "Now add custom/sub to your forms with ease",
        "released": "Sep 11th, 2023",
        "latest": true,
        "readMore": null
    },
    {
        "title": "Add Others option for multiple choice field",
        "description": "Now add others option in your multiple choice field - it's fun and easy!",
        "released": "Aug 14th, 2023",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Collect Payments In Your Form",
        "description": "Experience the thrill of instant transactions with Gozen Forms! Now you can seamlessly collect payments right within your form.",
        "released": "Jul 7th, 2023",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/payment-integrations/stripe"
    },
    {
        "title": "Edit Form After Publishing",
        "description": "Now you can edit your online forms even after publishing without unpublishing to make changes.",
        "released": "Jun 1st, 2023",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/edit-form-after-publishing"
    },
    {
        "title": "Generate Form Using AI 🪄",
        "description": "Easily create forms with just a few lines of description.",
        "released": "May 11th, 2023",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/create-forms-using-ai"
    },
    {
        "title": "Teams (2.0)",
        "description": "Invite your team members to join your account to work together on forms and keep everyone on the same page.",
        "released": "April 17th, 2023",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/add-teammates"
    },
    {
        "title": "UTM Tracking code",
        "description": "This helps you create UTM(Urchin Tracking Module) tracking code, Paste it into your form's URL and start tracking your campaign's performance.",
        "released": "January 22th, 2023",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Custom form themes",
        "description": "This helps you edit your forms to your brand's feel and personality. Also, Save and reuse them later.",
        "released": "December 28th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Scoring",
        "description": "This helps you give scores to your audience based on their response. You can assign different scores to different answers.",
        "released": "December 25th, 2022",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/scoring"
    },
    {
        "title": "Email Notification",
        "description": "This helps you notify your audience whenever the forms are submitted. ",
        "released": "December 22th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Custom Variable",
        "description": "This helps you display perfect scores for each section your audience has responded to in tests and surveys. Allows you to calculate flawless financial details of your customer. You can even send personalized coupon codes/offers to your leads and customers.",
        "released": "December 14th, 2022",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/variables"
    },
    {
        "title": "Hidden Fields",
        "description": "This helps you to show your respondents' names in the form. And track where your audience is coming from to fill out your forms.",
        "released": "December 7th, 2022",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/hidden-fields"
    },
    {
        "title": "Added Template and New themes",
        "description": "New form templates and themes were added to the GoZen forms.",
        "released": "November 30th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Custom form layout",
        "description": "New form templates and themes were added to the GoZen forms.This helps you shape forms in your way. Display one question at a time. Show all of them as a list. Or, Mix them both.",
        "released": "November 26th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "E-Sign",
        "description": "This will help you collect E-signature inside the form.",
        "released": "November 22th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Limit Responses",
        "description": "This helps you limit the number of responses your forms can collect. ",
        "released": "November 10th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Net Promoter Score",
        "description": "This helps you do the Net promoter score survey to find people's emotions towards your business and brand.",
        "released": "September 25th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Custom Message",
        "description": "This helps you to customize the form messages to your brand's voice and tone. You can customize the button text, error message, loading message and more. ",
        "released": "September 18th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Picture Choice",
        "description": "This helps you to keep images as the options in the multiple-choice questions and more. ",
        "released": "September 11th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Statement",
        "description": "This helps you to display messages in forms other than questions and answers, E.g., Thank you pages or If you want to say any information before your audience starts filling up forms.",
        "released": "September 2nd, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Logic Jump",
        "description": "This helps you ask relevant questions based on the audience's response. ",
        "released": "August 30th, 2022",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/guides/how-to-use-logic-jump"
    },
    {
        "title": "Integration",
        "description": "This helps you to connect GoZen forms to external apps. Allowing you to send the responses to your desired app-Like Hubspot CRM-and power up your marketing and sales process. ",
        "released": "August 25th, 2022",
        "latest": false,
        "readMore": "https://docs.gozen.io/zenforms-knowledge-base/integration/acumbamail"
    },
    {
        "title": "Responsive forms",
        "description": "This helps your online forms and surveys look good on any device. That means you won't see beautiful forms on your Laptop screen and crappy forms on your phone screen.",
        "released": "August 18th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Share forms",
        "description": "Shareability made easy-peasy. Quickly generate a QR code, Copy the link, Get the embed code, and share to the platforms your audience spends time within a single place.",
        "released": "August 10th, 2022",
        "latest": false,
        "readMore": null
    },
    {
        "title": "Insights",
        "description": "Analyzing the Insights can't be this easy. Get to see your audience's complete activity inside your forms. Know where they come from, Which device they use, how many questions were answered or skipped, and the responses they've shared with your business. ",
        "released": "August 3rd, 2022",
        "latest": false,
        "readMore": null
    },
];