
export const REGX = {
    stringWithNumbers: /[^a-zA-Z0-9]/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    number: /[0-9]/,
    phoneNumber: /^[0-9()-+]/g,
    url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    specialCharacters: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    upperCase: /[A-Z]/,
    lowerCase: /[a-z]/,
    letters: /[a-zA-Z]/g,
    string: /[a-zA-Z][^#&<>\"~;$^%{}?]{0,400}$/,

    urlWithoutProtocol: /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/

};