import { Button, SecondaryButton } from "@components/button";
import { Popup } from "@components/popup";
import { useActiveSpace, useFormApiClient } from "@hooks";
import { useCommonStore, useFormStore } from "@store";
import { cn } from "@utils/cn";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import { FileUploadingGIF } from "../uploadingGif/main.uploading";

type Props = {
    type: "welcome" | "thankyou";
};

export function UploadVideoPopup({ type }: Props) {

    const uploadVideoRef = useRef<any>(null);

    const [file, setFile] = useState<File | null>(null);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const showPopup = useCommonStore((store) => store.activePopup === "upload-video");

    const activeSpace = useActiveSpace();

    const { uploadVideo } = useFormApiClient({});

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {

            const selectedFile = (e.target.files as FileList)[0];

            const schema = z.object({
                size: z.number().min(0, "intro file not selected").max(10 * 1024 * 1024),
                type: z.string().min(1, "intro file not selected").refine((type) => {

                    const allowed = ["video/mp4"];

                    return allowed.includes(type);

                }, "Please choose a valid video file.")
            });

            const valid = schema.safeParse({
                size: selectedFile.size,
                type: selectedFile.type
            });

            if (!valid.success) {
                toast.error(valid.error.errors[0].message);
                return;
            }

            setFile(selectedFile);

        } catch (err) {
            console.log(err);
        }
    };

    const onUpload = async () => {
        try {

            if (!file) return;

            setUploading(true);

            const data = await uploadVideo(
                {
                    fid: useFormStore.getState().id,
                    file,
                    sid: activeSpace.id,
                    type
                },
                (val) => setProgress(val)
            );

            if (type === "welcome") {
                useFormStore.getState().setWelcomeIntroVideo({
                    url: data.url,
                    autoPlay: true
                });
            }

            if (type === "thankyou") {
                useFormStore.getState().setThankyouVideo({
                    url: data.url,
                    autoPlay: true
                });
            }

            useCommonStore.getState().setActivePopup("");

        } catch (err) {
            console.log(err);
        } finally {
            setUploading(false);
        }
    };

    const onClose = () => useCommonStore.getState().setActivePopup("");

    if (!showPopup) return <></>;

    return (
        <Popup
            width="30%"
            name="Upload Video"
            onClose={() => onClose()}
        >

            <div className={cn(`
                border border-dashed border-gray-400 bg-gray-100 h-[140px] rounded-lg mt-2
                flex items-center justify-center    
            `)}>

                {uploading ?
                    <div className="py w-full h-full">

                        <FileUploadingGIF
                            progress={progress}
                            show={uploading}
                        />

                    </div> :
                    <div className="flex flex-col items-center justify-center text-center">

                        <input
                            ref={uploadVideoRef}
                            id="upload-video"
                            type="file"
                            accept="image/png, image/jpg, image/jpeg"
                            className="block text-center text-sm"
                            onChange={(e) => onFileChange(e)}
                        />

                        <p className="text-gray-600 text-sm font-normal mt-2 cursor-default">
                            Please upload video with (280 x 360) ratio
                        </p>

                    </div>
                }

            </div>

            <div className="flex items-center justify-end space-x-2 mt-4">

                <SecondaryButton
                    disabled={uploading}
                    text="Cancel"
                    className="text-sm font-medium w-auto"
                    onClick={() => onClose()}
                />

                <Button
                    disabled={uploading}
                    text="Upload"
                    className="text-sm font-medium w-auto"
                    onClick={() => onUpload()}
                />

            </div>

        </Popup>
    );
}