import { Popup } from "@components/popup";
import { useCommonStore, useInvitesStore } from "@store";
import { cn } from "@utils/cn";
import { UploadFile } from "./uploadSection/main.upload";
import { InputEmails } from "./inputEmails/main.input";

const tabs = [
    {
        value: "upload",
        label: "Upload"
    },
    {
        value: "input",
        label: "Input"
    }
];


export function UploadFilePopup() {

    const popup = useCommonStore((store) => store.activePopup);
    const activeTab = useInvitesStore((store) => store.activeTab);

    if (popup !== "invite-emails") return null;

    return (
        <Popup
            width="40%"
            name="Email Invites"
            onClose={() => {
                useCommonStore.getState().setActivePopup("");
                useInvitesStore.getState().setActiveTab("upload");
                useInvitesStore.getState().setSelectedFile(null);
                useInvitesStore.getState().setActiveUploadTab("upload");
            }}
        >

            <div className="text-sm font-medium text-center text-gray-500">

                <ul className="flex flex-wrap -mb-px border-b">

                    {tabs.map((tab, idx) => (
                        <li
                            key={idx}
                            className={cn("me-2")}
                            onClick={() => useInvitesStore.getState().setActiveTab(tab.value as any)}
                        >

                            <a
                                href="#"
                                className={cn(`
                                    inline-block p-2 border-b-2 border-transparent tracking-wide
                                    rounded-t-lg hover:text-gray-600 hover:border-gray-300 
                                `, {
                                    "text-blue-600 border-blue-600 active": tab.value === activeTab,
                                })}
                            >
                                {tab.label}
                            </a>

                        </li>
                    ))}

                </ul>

                <div className="h-auto">

                    {activeTab === "upload" ? <UploadFile /> : null}

                    {activeTab === "input" ? <InputEmails /> : null}

                </div>

            </div>

        </Popup >
    );
}