import { Button } from "@components/button";
import { BsDownload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export function ImportCard() {

    const navigate = useNavigate();

    return (
        <motion.div
            className="w-[48%] h-[230px] border border-gray-300 shadow-md rounded-lg p-4 test-card"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <div className="flex items-center space-x-2">

                <BsDownload className="w-6 h-6 text-primary" />

                <p className="text-xl font-bold tracking-wide">
                    Import Existing Reviews
                </p>

            </div>

            <p className="text-sm font-normal text-gray-700 mt-4 px-1">
                Start importing all your existing online reviews today to get a
                significant head start and boost your credibility!
            </p>

            <div className="flex items-center space-x-4 mt-4 px-2">

                {/* google */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/300/300221.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* qr */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/2875/2875433.png"}
                    alt=""
                    className="w-6 h-6"
                />


                {/* insta */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/2111/2111463.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* linkind */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/3536/3536505.png"}
                    alt=""
                    className="w-6 h-6"
                />


                {/* x */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/5968/5968830.png"}
                    alt=""
                    className="w-6 h-6"
                />

                {/* fb */}
                <img
                    src={"https://cdn-icons-png.flaticon.com/512/733/733547.png"}
                    alt=""
                    className="w-6 h-6"

                />


            </div>

            <div className="flex items-center justify-end space-x-4 my-2 mt-5">

                <Button
                    text="Import Reviews"
                    className="w-1/2 text-base font-medium p-2 tracking-wide"
                    onClick={() => navigate("/import-testimonials")}
                />

            </div>


        </motion.div>
    );
}