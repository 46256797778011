import { FullLogo, } from "@components/icons/logo";
import { Loading } from "@components/loading";
import { Config } from "@config";
import { QueryKeys, useAuthApiClient, useGetQueryData, useUsersApiClient } from "@hooks";
import { cn } from "@utils/cn";
import { Suspense, useEffect } from "react";


export function ChromeExtensionAuthPage() {

    const { verifyToken } = useAuthApiClient({});
    const { getKey } = useUsersApiClient({});

    const query = useGetQueryData<Api.Auth.verifyToken>([QueryKeys.auth.verifyToken]);

    useEffect(() => {

        verifyToken.refetch();

        window.addEventListener("message", onHandleMessage);

        return () => window.removeEventListener("message", onHandleMessage);

    }, []);

    useEffect(() => { onCheckAuth(); }, [verifyToken.isLoading]);

    const onHandleMessage = (e: MessageEvent) => {

        const data = e.data as { type: "gzTestimonialLoaded", payload: {}; };

        if (data.type !== "gzTestimonialLoaded") return;

    };

    const onCheckAuth = async () => {

        if (query && query?.status === 200) {

            await onSendKey();

            return;

        }

        if (query && query?.status !== 200) {

            onRedirectToLoginPage();

            return;

        }

    };

    const onRedirectToLoginPage = () => {

        const frontEndUrl = `${Config.FrontEndUrl()}/login`;

        window.parent.postMessage({
            type: "gzTestimonialRedirect",
            payload: {
                url: frontEndUrl
            }
        }, "*");

    };

    const onSendKey = async () => {
        try {

            const { data } = await getKey.mutateAsync();

            if (data.key) {

                window.parent.postMessage({
                    type: "gzTestimonialApiKey",
                    payload: {
                        key: data.key
                    }
                }, "*");

            }

        } catch (err) {
            console.log(err);
        }
    };

    return (
        <Suspense fallback={
            <div className="h-full w-full">
                <Loading text="" />
            </div>
        }>

            <div className={cn(`h-screen w-full`)}>

                <div
                    className={cn(`
                    w-full h-full bg-gray-800/35 p-6 space-y-6
                    flex flex-col items-center justify-center z-30
                `)}
                >

                    <FullLogo />

                    <div className="w-full">

                        <Loading text="Connecting your Account" />

                    </div>

                </div>

            </div>

        </Suspense>
    );
}