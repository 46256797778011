import { Fragment, useState } from "react";
import { ImportCard } from "../manuallyImport/main.layout";
import { FacebookEmbed } from "react-social-media-embed";
import { ManuallyImport } from "../manuallyImport/main.import";
import { Input } from "@components/input";
import { useActiveSpace, useImportTestimonialApiClient } from "@hooks";
import { z } from "zod";
import { useImportTestimonialStore } from "@store";
import toast from "react-hot-toast";

const schema = z.object({
    url: z.string().url().refine((val) => {
        try {
            return new URL(val).host === "www.facebook.com";
        } catch (err) {
            return false;
        }
    }, "Enter a valid post url.")
});

export function FaceBookImport() {

    const { formId } = useActiveSpace();

    const { manualImport, facebookImporter } = useImportTestimonialApiClient();

    const [showManuallyImport, setShowManuallyImport] = useState(false);
    const [postUrl, setPostUrl] = useState("");
    const [error, setError] = useState("");

    const onChange = (val: string) => {

        setPostUrl(val);

        if (!val && error) setError("");

        const result = schema.safeParse({ url: val });

        if (result.success === false) setError(result.error.issues[0].message);
        else setError("");

    };

    const onManualImport = async () => {
        try {

            const testimonial = useImportTestimonialStore.getState().testimonial;

            const result = schema.safeParse({
                name: testimonial.name,
                content: testimonial.testimonial.text.content
            });

            if (result.success === false) {

                result.error.errors.forEach(item => {

                    let key = item.path[0];

                    useImportTestimonialStore.getState().setError({ [key]: item.message });

                });

            }

            await manualImport.mutateAsync({
                testimonial,
                formId,
                source: "facebook"
            });

            useImportTestimonialStore.getState().setClear();

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }
    };

    const onImport = async () => {
        try {

            const doc = document.querySelectorAll('iframe[data-testid="fb:post Facebook Social Plugin"]');

            const src = (doc[0] as any).src as string;

            console.log({ src });

            const result = schema.safeParse({ url: src });

            if (result.success === false) {
                toast.error(result.error.message);
                return;
            }

            await facebookImporter.mutateAsync({
                formId,
                url: postUrl
            });

            setPostUrl("");

            toast.success("Successfully, Imported your testimonial.");

        } catch (err) {
            console.log(err);
        }

    };

    return (
        <ImportCard
            title="Import from Facebook"
            isLoading={manualImport.isPending || facebookImporter.isPending}
            onImport={() => showManuallyImport ? onManualImport() : onImport()}
        >

            {showManuallyImport ?
                <ManuallyImport
                    fields={{
                        email: null,
                        company: false,
                        role: false,
                        companyLogo: false,
                        testimonial: {
                            text: { content: true, imageAttachments: true },
                            audio: { url: false, transcribedText: false },
                            video: { url: false, transcribedText: false }
                        },
                        sourceUrl: {
                            label: "Post Url",
                            placeholder: "https://facebook.com/1831341857714119024"
                        }
                    }}
                /> :
                <Fragment>

                    <Input
                        type="text"
                        label="Tweet Url"
                        placeholder="https://facebook.com/1831341857714119024"
                        required={true}
                        value={postUrl}
                        error={error}
                        onChange={(e) => onChange(e.target.value)}
                    />

                    {postUrl ?
                        <FacebookEmbed
                            url={postUrl}
                            height={400}
                            width={600}
                            className="w-full h-full hidden"
                        /> : null
                    }

                    <p
                        className="text-sm font-medium text-gray-600 underline mt-4 cursor-pointer"
                        onClick={() => setShowManuallyImport(true)}
                    >
                        Or Add a testimonial manually
                    </p>

                </Fragment>


            }

        </ImportCard>
    );
}