import { cn } from "@utils/cn";
import { motion } from "framer-motion";
import { UploadingIcon } from "./upload.icon";

type Props = {
    show: boolean,
    progress: number;
};

export function FileUploadingGIF({ show, progress }: Props) {
    return (
        <div
            className={cn(`
                w-full h-full space-y-4
                flex flex-col items-center justify-center    
            `, {
                "hidden": show === false
            })}
        >

            <UploadingIcon />

            <div className="w-[70%] h-[10px] border border-gray-300 rounded-full">

                <motion.div
                    className="w-full h-full bg-primary rounded-full"
                    initial={{
                        width: 0
                    }}
                    animate={{
                        width: `${progress}%`
                    }}
                />

            </div>

            {progress}

        </div>

    );
}