import { Fragment, useState } from "react";
import { MdNotifications } from "react-icons/md";
import { WhatsNew } from "./watsNew";
import { cn } from "@utils/cn";
import { AnimatePresence } from "framer-motion";
import { useCommonStore } from "@store";

export function Notifications() {

    const { activePopup, setActivePopup } = useCommonStore();

    const [showBadge, setShowBadge] = useState(false);


    const onShowWhatsNew = () => {

        setActivePopup("wts-new");

        setShowBadge(false);

        // localStorage.setItem(LocalStorage.keys.showNotificationBadge, "ai");
    };

    return (
        <Fragment>

            <div
                className="bg-gray-100 p-2 rounded-md group hover:bg-gray-200 cursor-pointer"
                onClick={() => onShowWhatsNew()}
            >

                <MdNotifications className="w-5 h-5 text-gray-600 group-hover:text-gray-800" />

                {showBadge ?
                    <div className={cn(`
                    absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white 
                    bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900
                `)}>
                        1
                    </div> : null
                }

            </div>

            <AnimatePresence>

                {activePopup === "wts-new" ? <WhatsNew /> : null}

            </AnimatePresence>

        </Fragment>
    );

}
