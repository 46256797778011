import { ColorPicker } from "@components/colorPicker";
import { TextToggle } from "@components/toggle";
import { UploadPicture } from "@components/upload";
import { useSocialProofStore } from "@store";
import { useState } from "react";


export function VideTemplateBranding() {

    const { color, showBranding } = useSocialProofStore((store) => ({
        color: store.videoTemplate.background.color,
        showBranding: store.videoTemplate.showBranding
    }));

    const [file, setFile] = useState(null);

    return (
        <div className="space-y-4">

            <UploadPicture
                id="branding"
                file={file}
                title="Logo"
                titleClassName="text-sm font-medium text-gray-700"
                imgClassName="w-[50px] h-[50px]"
                onChange={() => setFile(null)}
            />

            <ColorPicker
                defaultColor={color ?? "#fff"}
                label="Background Color"
                onChange={(val) => useSocialProofStore.getState().setImageTemplateBackground({
                    color: val
                })}
            />

            <TextToggle
                isActive={showBranding}
                name="Remove Branding"
                onClick={() => useSocialProofStore.getState().setVideoTemplate({ showBranding: !showBranding })}
            />

        </div>
    );
}