import { CustomFields } from "./customFields";
import { DefaultFields } from "./defaultFields";
import { CustomPageTitle } from "./title";


export function CustomDetails() {
    return (
        <div className="space-y-2">

            <CustomPageTitle />

            <DefaultFields />

            <CustomFields />

        </div>
    );
}