import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";

export function useDebouncedState<T>(defaultValue: T, daley: number): [T, React.Dispatch<React.SetStateAction<T>>, T] {

    const [state, setState] = useState<T>(defaultValue);

    const changed = useDebounce<T>(state, daley);

    return [state, setState, changed];

}