import { Rating } from "@components/rating";
import { cn } from "@utils/cn";

type Props = {
    rating: number | null,
    content: string,
    relativeTime: string,
};

export function TextTestimonial({ content, rating, relativeTime }: Props) {
    return (
        <div className={cn("space-y-3 relative w-full text-start cursor-default")}>

            {rating ?
                <Rating
                    rated={rating ?? 0}
                    readonly={true}
                /> : null
            }

            <p className="text-sm font-normal text-gray-800 text-left cursor-default break-words px-1 min-h-[32px]">
                {content.slice(0, 160) + "..."}
            </p>

            <p
                data-tooltip-id="testimonial-submitted-at"
                className={cn(`
                    text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                    border-b border-dashed border-gray-300 inline-block 
                `)}
            >
                {relativeTime}
            </p>

        </div>
    );
}