import { useEmbedStore } from "@store";
import { StandardPreview } from "./standard/preview";
import { PopupPreview } from "./popup/preview";
import { SideTabPreview } from "./sideTab/preview";
import { SliderPreview } from "./slider/preview";
import { cn } from "@utils/cn";
import { TbDeviceLaptop } from "react-icons/tb";
import { LiaMobileSolid } from "react-icons/lia";
import { motion } from "framer-motion";

export const Preview: React.FC = () => {

    const { type, view } = useEmbedStore((store) => ({
        type: store.type,
        view: store.view
    }));

    return (
        <motion.div
            className={`flex flex-col justify-center items-center space-y-4 ml-0 mr-4`}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1
            }}
            exit={{
                opacity: 0
            }}
        >

            {type === 'standard' ? <StandardPreview /> : null}

            {type === "popup" ? <PopupPreview /> : null}

            {type === "sideTab" ? <SideTabPreview /> : null}

            {type === "slider" ? <SliderPreview /> : null}

            <div className="flex items-center justify-center">

                <div className={cn(`
                    p-[5px] rounded-md bg-gray-300 inline-flex items-center space-x-1 cursor-pointer 
                `)}>

                    <div
                        className={cn(`rounded-md transition-all duration-300 ease-in-out`, {
                            "bg-white": view === "desktop"
                        })}
                        onClick={() => useEmbedStore.getState().setView("desktop")}
                    >

                        <TbDeviceLaptop className="w-6 h-6 m-1" />

                    </div>

                    <div
                        className={cn(`rounded-md transition-all duration-300 ease-in-out`, {
                            "bg-white": view === "mobile"
                        })}
                        onClick={() => useEmbedStore.getState().setView("mobile")}
                    >

                        <LiaMobileSolid className="w-6 h-6 m-1" />

                    </div>

                </div>

            </div>

        </motion.div>
    );
};