import { Player } from "@remotion/player";
import { useEffect, useRef } from "react";
import Bg from "./t2.png";
import { cn } from "@utils/cn";
import { PoweredBy } from "@components/branding";
import ReactHlsPlayer from "react-hls-player";

function Test() {

    const src = "https://video.dev.testimonial.gozen.io/ee7d76b5-5c24-4b4c-847f-0e9c383b5038/dfc8cad2-68fb-4d7f-94ce-c9cc96c4a545/main.m3u8";

    const ref = useRef<HTMLVideoElement>(null);

    useEffect(() => {



    }, []);

    return (
        <div
            className={cn(`
                h-[530px] w-full relative
            `)}
        >

            <img
                src={Bg}
                className="h-full w-full"
            />

            <div className="w-full overflow-hidden rounded-2xl border border-red-500">

                <ReactHlsPlayer
                    playerRef={ref}
                    src={src}
                    style={{
                        height: 360,
                        width: 600,
                        borderRadius: 40
                    }}
                    className="absolute top-0 left-0 h-[80%] w-full object-cover p-4"
                />

            </div>

            <PoweredBy className={`scale-[0.5] border left-[210px]`} />

        </div>
    );
}

export function VideoTemplate2() {

    return (
        <Player
            component={Test}
            durationInFrames={120}
            compositionWidth={520}
            compositionHeight={520}
            fps={30}
            style={{
                width: 520,
                height: 520,
            }}
            controls
        />
    );
}