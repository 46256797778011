import { Text } from "@components/text";
import { UploadPicture } from "@components/upload";



export function ProfileField() {

    return (
        <div>

            <Text
                text="Profile"
                variant="label"
            />

            <UploadPicture
                id="profile-picture"
                file={null}
                onChange={() => { }}
            />

        </div>
    );
}