import { ReactNode } from "react";
import { Toggle } from ".";
import { Text } from "@components/text";
import { LuInfo } from "react-icons/lu";
import { Tooltip } from "react-tooltip";
import { cn } from "@utils/cn";

type Props = {
    name: string,
    isActive: boolean,
    children?: ReactNode,
    tooltip?: {
        id: string,
        tip: string,
        width?: number;
    },
    onClick: () => void;
};

export function TextToggle({ name, isActive, tooltip, children, onClick }: Props) {
    return (
        <div className="flex items-center justify-between">

            <div className="flex items-center space-x-2">

                <Text
                    variant="label"
                    className="text-gray-900 font-normal tracking-wide"
                >
                    {name}
                </Text>

                {tooltip ?
                    <div className="mx-1">

                        <LuInfo
                            className={cn(`text-gray-600 mx-1`)}
                            id={tooltip.id}
                        />

                        <Tooltip
                            anchorSelect={`#${tooltip.id}`}
                            place="top"
                            style={{
                                width: tooltip.width ?? 300,
                                textAlign: "left"
                            }}
                        >
                            {tooltip.tip}
                        </Tooltip>

                    </div> : null
                }

                {children}

            </div>

            <Toggle
                isActive={isActive}
                onClick={onClick}
            />

        </div>
    );
}