import { Button } from "@components/button";
import { Input } from "@components/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

type Props = {
    type: "pabbly" | "webhook";
    onClick: (params: { name: string, hookUrl: string; }) => void;
};

export function NewIntegration({ type, onClick }: Props) {

    const Schema = z.object({
        name: z.string().min(3).max(100),
        hookUrl: z.string().refine((val) => {
            try {

                const url = new window.URL(val);

                if (type === "pabbly") return url.host === "connect.pabbly.com";
                else return true;

            } catch {
                return false;
            }
        }, {
            message: type === "pabbly" ? "Enter a valid pabbly hook url." : "Enter a valid hook url."
        })
    });

    type SchemaType = z.infer<typeof Schema>;

    const { formState, register, handleSubmit, clearErrors } = useForm<SchemaType>({
        resolver: zodResolver(Schema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            name: "",
            hookUrl: ""
        }
    });

    const onSubmit: SubmitHandler<SchemaType> = (data) => {

        onClick({ hookUrl: data.hookUrl, name: data.name });

    };

    return (
        <div>

            <form
                className="my-2"
                onSubmit={handleSubmit(onSubmit)}
            >

                <div className="border-y py-4 space-y-2 ">

                    <Input
                        {...register("name")}
                        required={true}
                        error={formState.errors.name?.message}
                        type="text"
                        label="Name your integration"
                        placeholder="Pied Piper Testimonial"
                        mainClassName="space-y-1"
                        onChange={() => clearErrors("name")}
                    />

                    <Input
                        {...register("hookUrl")}
                        required={true}
                        error={formState.errors.hookUrl?.message}
                        type="text"
                        label="Webhook Url"
                        mainClassName="space-y-1"
                        placeholder={
                            type === "pabbly" ?
                                "https://connect.pabbly.com/workflow/sendwebhookdata/XXXX" :
                                "https://yourhook.com/XXXX"
                        }
                        onChange={() => clearErrors("hookUrl")}
                    />

                </div>


                <div className="flex items-center justify-end mt-2">

                    <Button
                        type="submit"
                        text="Connect"
                        className="w-auto text-sm"
                    />

                </div>

            </form>



        </div>
    );
}