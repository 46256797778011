import { Logo } from "@components/icons/logo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebsiteInfo } from "./screens/website";
import { useEffect, useState } from "react";
import { BusinessAndRoleInfo } from "./screens/businessAndRole";
import { Action } from "./screens/action";
import { useUserStore } from "@store";
import { motion, AnimatePresence } from "framer-motion";
import { cn } from "@utils/cn";


export function OnBoardingPage() {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const screen = searchParams.get("screen") ?? "website";

    const [dir, setDir] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(false);

    const { onboarding } = useUserStore();

    useEffect(() => {

        setShowOnboarding(JSON.parse(localStorage.getItem("showOnboarding") ?? "false"));

        if (onboarding && !screen) {
            searchParams.append("screen", "website");
            setSearchParams(searchParams);
        }

    }, []);

    useEffect(() => setDir(!dir), [screen]);

    if (showOnboarding === false) {
        navigate("/", { replace: true });
        return null;
    }

    return (
        <div className="h-screen w-screen bg-secondary flex items-center justify-center dotted-bg">

            <motion.div
                className={cn(`
                    xl:w[25%] md:w-[40%] bg-white shadow-lg p-8 rounded-xl border border-gray-200
                    transition-all duration-300 ease-in-out
                `)}
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
            >

                <Logo className="" />

                <AnimatePresence initial={false} custom={dir}>

                    {screen === "website" ? <WebsiteInfo /> : null}

                    {screen === "role" ? <BusinessAndRoleInfo /> : null}

                    {screen === "action" ? <Action /> : null}

                </AnimatePresence>

            </motion.div>

        </div>
    );
}