import { Drawer } from "@components/drawer";
import { useState } from "react";
import { WelcomePageLabels } from "./welcome";
import { ResponsePageLabels } from "./response";
import { CustomDetailsPageLabels } from "./customDetails";
import { Text } from "@components/text";
import { FaChevronRight } from "react-icons/fa";


export function CustomLabels() {

    const [show, setShow] = useState(false);

    return (
        <div className="space-y-2">

            <div
                className="flex items-center justify-between"
                onClick={() => setShow(true)}
            >

                <Text text="Custom Labels" />

                <FaChevronRight className="w-4 h-4 text-gray-600" />

            </div>


            {show ?
                <Drawer
                    show={show}
                    title="Custom Labels"
                    onClose={() => setShow(false)}
                    className="space-y-2"
                >

                    <WelcomePageLabels />

                    <ResponsePageLabels />

                    <CustomDetailsPageLabels />

                </Drawer> : null
            }

        </div>
    );
}
