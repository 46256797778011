import { Drawer } from "@components/drawer";
import { Text } from "@components/text";
import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { NotifyMeMail } from "./notifyMe";
import { NotifyRespondentMail } from "./respondentMail";
import { useQueryClient } from "@tanstack/react-query";
import { useFormStore } from "@store";
import { QueryKeys, useFormApiClient } from "@hooks";


export function EmailNotification() {

    const queryClient = useQueryClient();

    const formId = useFormStore((store) => store.id);

    const [show, setShow] = useState(false);

    const { getEmailTemplate } = useFormApiClient({
        getEmailTemplateParams: {
            params: {
                enabled: false,
                refetchOnMount: false,
                retry: false
            },
            apiParams: {
                formId
            }
        }
    });

    const { isLoading, isFetching } = getEmailTemplate;

    useEffect(() => {

        if (show) {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.form.getEmailTemplates] });
            getEmailTemplate.refetch();
        }

    }, [show]);

    const onClose = () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.form.getEmailTemplates] });
        setShow(!show);
    };

    return (
        <div className="space-y-2">

            <div
                className="flex items-center justify-between"
                onClick={() => setShow(true)}
            >

                <Text text="Email Notification" />

                <FaChevronRight className="w-4 h-4 text-gray-600" />

            </div>

            {show ?
                <Drawer
                    title="Email Notification"
                    show={show}
                    onClose={() => onClose()}
                >

                    <NotifyMeMail loading={isLoading || isFetching} />

                    <NotifyRespondentMail loading={isLoading || isFetching} />

                </Drawer> : null
            }

        </div>
    );
}