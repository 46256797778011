import { FiVideo } from "react-icons/fi";
import { LuMic } from "react-icons/lu";
import { MdOutlineDone } from "react-icons/md";
import { cn } from "@utils/cn";
import { IoCloseOutline } from "react-icons/io5";
import { useClickOutside } from "@hooks";
import { useFormStore } from "@store";

type MediaInput = "video" | "audio";

type Props = {
    type: MediaInput,
    activeSourceId: string,
    popup: MediaInput | "",
    sources: MediaDeviceInfo[],
    onClose: () => void,
    onChangeInput: (input: MediaInput) => void,
    onChangeSource: (deviceId: string) => void;
};

export function InputSource({
    type, popup, activeSourceId, sources,
    onChangeInput, onChangeSource, onClose
}: Props) {

    const ref = useClickOutside(() => { onClose(); });

    const fontFamily = useFormStore((store) => store.design.fontFamily);

    return (
        <div
            className="relative"
        >

            <div
                className={cn(`
                    bg-black hover:bg-gray-900 rounded-full p-2 cursor-pointer
                    transition-all duration-300 ease-in-out 
                `)}
                onClick={() => { onChangeInput(type); }}
            >

                {type === "video" ?
                    <FiVideo className="w-4 h-4 text-white" /> :
                    <LuMic className="w-4 h-4 text-white" />
                }

            </div>

            {popup && popup === type ?
                <div ref={ref} className="bg-black absolute top-0 right-0 w-[280px] rounded-lg">

                    <div className="flex items-center justify-between border-b border-gray-800 px-3 pt-2 pb-1">

                        <p
                            className="text-white text-sm font-normal ml-1 tracking-wide cursor-default"
                            style={{
                                fontFamily
                            }}
                        >
                            {popup === "video" ? "Select Camera" : "Select Microphone"}
                        </p>

                        <IoCloseOutline
                            className="w-6 h-6 text-white cursor-pointer"
                            onClick={() => onClose()}
                        />

                    </div>


                    <div className="p-2">

                        {sources.map(source => (
                            <div
                                key={source.deviceId}
                                onClick={() => onChangeSource(source.deviceId)}
                                className={cn(`
                                    text-white font-normal text-sm cursor-pointer flex items-center space-x-2 rounded-md
                                    hover:bg-white/10 px-2 py-1 transition-all duration-300 ease-in-out tracking-wide
                                `)}
                                style={{
                                    fontFamily
                                }}
                            >

                                {source.label}

                                {source.deviceId === activeSourceId ?
                                    <MdOutlineDone className="w-5 h-5 text-white ml-2" /> : null
                                }
                            </div>
                        ))}

                    </div>

                </div> : null
            }

        </div>
    );
}