import { ColorPicker } from "@components/colorPicker";
import { useFormStore } from "@store";


export function ButtonProps() {

    const buttonFontColor = useFormStore((store) => store.design.buttonFontColor);

    return (
        <div className="w-[48%]">

            <ColorPicker
                label='Button Font Color'
                defaultColor={buttonFontColor}
                onChange={(color) => useFormStore.getState().setBtnFontColor(color)}
            />

        </div>
    );
}