import { Button } from "@components/button";
import { QueryKeys, useGetQueryData, useTestimonialsApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@utils/cn";
import toast from "react-hot-toast";

type Props = {
    id: string,
    approved: boolean,
};

export function TestimonialStatus({ id, approved, }: Props) {

    const queryClient = useQueryClient();

    const spacesQuery = useGetQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const { approve } = useTestimonialsApiClient({});

    const { isPending } = approve;

    const onMutate = async () => {

        await approve.mutateAsync({
            fid: spacesQuery.activeSpace.formId,
            tid: id
        });

        if (approve.isError) {
            toast.error(approve.error.message);
            return;
        }

        queryClient.setQueriesData<Api.Testimonials.getAll>({ queryKey: [QueryKeys.testimonials.getAll] },
            (oldData) => ({
                ...oldData,
                testimonials: oldData?.testimonials.map(item => item.id === id ?
                    { ...item, approved: !item.approved } :
                    item
                )
            } as Api.Testimonials.getAll),
            {
                updatedAt: Date.now()
            }
        );

        if (approved) toast.success("Successfully, testimonial approved");
        else toast.success("Successfully, testimonial disapproved");

    };

    if (approved) {
        return (
            <div className="flex items-center justify-center">

                <Button
                    disabled={isPending}
                    loading={isPending}
                    className={cn(`
                    border border-red-200 text-red-700 bg-red-100 hover:bg-red-200 hover:ring-red-300 
                    text-sm font-semibold py-2 px-4 tracking-wide w-auto
                `)}
                    onClick={() => onMutate()}
                >
                    Unapproved
                </Button>

            </div>
        );
    }

    return (
        <div className="flex items-center justify-center">

            <Button
                disabled={isPending}
                loading={isPending}
                className={cn(`
                border border-green-200 text-green-600 bg-green-100 hover:bg-green-200 hover:ring-green-300 
                text-sm font-semibold py-2 px-4 tracking-wide w-auto
            `)}
                onClick={() => onMutate()}
            >
                Approve
            </Button>

        </div>
    );

}