import { Button } from "@components/button";
import { TestimonialSourceTypes } from "@components/icons";
import { Text } from "@components/text";
import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

type Props = {
    title: string,
    isLoading?: boolean,
    children: ReactNode,
    onImport: () => void;
};

export function ImportCard({ title, isLoading, children, onImport }: Props) {

    const [searchParams] = useSearchParams();

    const value = searchParams.get("import") ?? "text";

    const icon = TestimonialSourceTypes.filter(item => item.value === value)[0].icon;

    // const { importQuery } = useImportTestimonialApiClient();

    // const onImport = async () => {
    //     try {

    //         const store = useImportTestimonialStore.getState();

    //         await importQuery.mutateAsync({
    //             formId,
    //             source: value,
    //             email: store.email,
    //             name: store.name,
    //             type: store.type,
    //             avatar: store.avatar,
    //             company: store.company,
    //             companyLogo: store.companyLogo,
    //             companyUrl: store.companyUrl,
    //             date: store.date,
    //             rating: store.rating,
    //             testimonial: {
    //                 audio: store.testimonial.audio ?? null,
    //                 text: store.testimonial.text ?? null,
    //                 video: store.testimonial.video ?? null,
    //             },
    //         });

    //         useImportTestimonialStore.getState().setClear();

    //         toast.success("Testimonial imported successfully");

    //     } catch (err: any) {
    //         console.log(err);
    //         toast.error(err);
    //     }
    // };

    return (
        <div className="bg-gray-100 h-full w-full max-h-full overflow-hidden flex p-4 ">

            <div className="bg-white shadow-lg w-[80%] h-full rounded-lg border border-gray-200">

                <div className="border-b py-4 px-6 flex items-center justify-between">

                    <div className="flex items-center space-x-2">

                        {icon}

                        <Text
                            text={title}
                            size="lg"
                            className="font-semibold tracking-wide cursor-default"
                        />

                    </div>

                    <Button
                        loading={isLoading}
                        disabled={isLoading}
                        text="Import"
                        className="w-auto text-sm font-medium"
                        onClick={() => onImport()}
                    />

                </div>

                <div className="px-5 py-4 space-y-4 h-[86%] overflow-y-scroll">
                    {children}
                </div>

            </div>

        </div>
    );
}