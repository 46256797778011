import { Loading } from "@components/loading";
import { Auth0 } from "@lib/auth0";
import { useEffect } from "react";
import { LocalStorageUtil } from "@utils/localStorage";
import { useAuthApiClient } from "@hooks";
import Cookie from "js-cookie";

export function Logout() {

    const { logout } = useAuthApiClient({
        logoutParams: {
            params: {
                onSuccess: () => {

                    Auth0.logout();

                    LocalStorageUtil.clear(["formSnapshot", "onboarding", "user"]);

                }
            }
        }
    });

    useEffect(() => {

        logout.mutate();

        Cookie.remove("_s_s");

    }, []);

    return (
        <div className="w-[60%] h-full flex items-center justify-between ">

            <Loading text="Please wait your being logout." />


        </div>
    );
}