import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useImportTestimonialStore } from "@store";


export function AudioTestimonialTranscriptField() {

    const content = useImportTestimonialStore((store) => store.testimonial.testimonial.video.transcribedText);

    return (
        <div className="space-y-2">

            <Text
                text="Video Transcript"
                variant="label"
            />

            <QuillInput
                placeHolder=""
                recall={false}
                valueAsHTML={content}
                onChange={(e) => {
                    useImportTestimonialStore.getState().setAudioTestimonial({
                        transcribedText: e.text
                    });
                }}
            />

        </div>
    );
}