import { Text } from "@components/text";
import * as Accordion from '@radix-ui/react-accordion';
import { useSocialProofStore } from "@store";
import { cn } from "@utils/cn";
import { FaAngleDown } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { LuImage } from "react-icons/lu";
import { MdOutlineDesignServices } from "react-icons/md";
import { EditTestimonialContent } from "./content";
import { ImageTemplateBackground } from "./background";
import { ImageTemplateBranding } from "./branding";



const Tabs = [
    {
        icon: <FiEdit3 className="w-5 h-5 text-green-600" />,
        title: "Content",
        value: "content",
        content: <EditTestimonialContent />
    },
    {
        icon: <LuImage className="w-5 h-5 text-red-600" />,
        title: "Background",
        value: "background",
        content: <ImageTemplateBackground />
    },
    {
        icon: <MdOutlineDesignServices className="w-5 h-5 text-yellow-600" />,
        title: "Branding",
        value: "branding",
        content: <ImageTemplateBranding />
    }
];

export function ImageSettings() {

    const selectedTestimonials = useSocialProofStore((state) => state.selectedTestimonials);

    return (
        <div className="p-4 border-r">

            <div className="px-4">

                <Text
                    text="Customize"
                    size="lg"
                    className="font-semibold tracking-wide"
                />

            </div>

            <div className="mt-2 border-y">

                <Accordion.Root
                    type="single"
                    defaultValue="item-1"
                    collapsible
                >

                    <div
                        className="flex items-center justify-between w-full p-4 cursor-pointer"
                        onClick={() => useSocialProofStore.getState().setActiveImageTemplateTab("selectTestimonials")}
                    >

                        <div className="flex items-center space-x-2">

                            <FaRegHeart className="w-4 h-4 text-primary" />

                            <p className="font-medium text-sm tracking-wide">
                                Select Testimonials
                            </p>

                        </div>

                        <p className="px-1">
                            {selectedTestimonials.length}
                        </p>

                    </div>

                    {Tabs.map((tab, idx) => {
                        return (
                            <Accordion.Item
                                key={idx}
                                value={tab.title}
                                className={cn({
                                    "border-t": idx === 0,
                                    "border-y": idx > 0 && idx < 2,
                                })}
                            >

                                <Accordion.Header className={cn(`p-4 `)}>

                                    <Accordion.Trigger
                                        className="flex items-center justify-between w-full"
                                        onClick={() => useSocialProofStore.getState().setActiveImageTemplateTab("template")}
                                    >

                                        <div className="flex items-center space-x-2">

                                            {tab.icon}

                                            <p className="font-medium text-sm tracking-wide">
                                                {tab.title}
                                            </p>

                                        </div>


                                        <FaAngleDown
                                            className={cn(`
                                                ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform 
                                                duration-300 group-data-[state=open]:rotate-180 text-gray-700
                                            `)}
                                        />

                                    </Accordion.Trigger>

                                </Accordion.Header>

                                <Accordion.Content
                                    className={cn(`
                                        data-[state=open]:border-t
                                        data-[state=open]:animate-slideDown 
                                        data-[state=closed]:animate-slideUp 
                                        overflow-hidden p-2
                                    `)}
                                >

                                    {tab.content}

                                </Accordion.Content>

                            </Accordion.Item>
                        );
                    })}

                </Accordion.Root>


            </div>

        </div>
    );
};