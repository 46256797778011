import { Input } from "@components/input";
import { useCaseStudyStore } from "@store";


export function CaseStudySettings() {

    const prompt = useCaseStudyStore((store) => store.settings.prompt);

    return (
        <div>

            <Input
                label="Prompt used for case study"
                value={prompt}

            />

        </div>
    );
}