import { Rating } from "@components/rating";
import { cn } from "@utils/cn";
import { CgTranscript } from "react-icons/cg";
import { IoMusicalNotes } from "react-icons/io5";
import { Tooltip } from "react-tooltip";

type Props = {
    rating: number | null,
    relativeTime: string,
    submittedAt: string,
};

export function AudioTestimonial({ rating, relativeTime, submittedAt }: Props) {
    return (
        <div className={cn("relative w-full text-start cursor-default")}>

            <div className="flex items-start justify-between">

                <div className="w-[80%] space-y-3 h-[80px]">

                    {rating ?
                        <Rating
                            rated={rating ?? 0}
                            readonly={true}
                        /> : null
                    }

                    <div className="flex items-center justify-start space-x-2 text-gray-600 p-2">

                        <CgTranscript className="w-6 h-6" />

                        <p className="text-sm font-normal"> No transcript </p>

                    </div>

                </div>

                <div className="w-[20%] h-[80px] flex items-center justify-center">

                    <div className="border flex items-center justify-center h-full w-[64%] rounded-md">

                        <IoMusicalNotes className="w-10 h-10 text-blue-600" />

                    </div>

                </div>

            </div>

            <p
                data-tooltip-id="testimonial-submitted-at"
                className={cn(`
                    text-left text-gray-600 text-xs font-normal mt-2 mx-2 cursor-pointer 
                    border-b border-dashed border-gray-300 inline-block
                `)}
            >
                {relativeTime}
            </p>

            <Tooltip id="testimonial-submitted-at">
                {new Date(submittedAt).toLocaleDateString("EN", { dateStyle: "medium" })}
            </Tooltip>

        </div>
    );
}