import { Rating } from "@components/rating";
import { Text } from "@components/text";
import { useTestimonialStore } from "@store";


export function RatingFilter() {

    const rating = useTestimonialStore((store) => store.filter.rating);

    return (
        <div className="space-y-2">

            <Text
                variant="label"
                size="sm"
                className="font-medium tracking-wide text-gray-900"
                text={"Rating"}
            />

            <Rating
                rated={(rating ?? 0)}
                onChange={(val) => useTestimonialStore.getState().setFilter({ rating: val })}
            />

        </div>
    );
}