import { Text } from "@components/text";
import { cn } from "@utils/cn";
import { motion, AnimatePresence } from "framer-motion";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";

type Props = {
    show: boolean,
    width?: string,
    dir?: "left" | "right";
    titleIcon?: ReactNode,
    title: string,
    children: ReactNode,
    className?: string,
    onClose: () => void;
};

export function Drawer({ show, title, dir = "left", width = '40%', titleIcon, className, children, onClose }: Props) {
    return createPortal(
        <AnimatePresence initial={false}>

            {show ?
                <motion.div
                    className={cn(`
                    absolute top-[0] right-0 z-[100] h-full w-full overflow-y-hidden bg-gray-500/40
                `)}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        x: 0,
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        when: "beforeChildren",
                        staggerChildren: 1
                    }}
                >

                    <motion.div
                        className={cn(`bg-white h-full z-[110] w-[${width}]`)}
                        initial={{
                            translateX: 300,
                            opacity: 0
                        }}
                        animate={{
                            translateX: 0,
                            opacity: 1
                        }}
                        exit={{
                            translateX: 300,
                            opacity: 0
                        }}
                    >

                        <div className={cn(`
                            inline-flex items-center justify-between w-full text-base font-semibold text-gray-800
                            px-4 py-4 mt-2    
                        `, {
                            "": dir === "right"
                        })}>

                            <div className="">

                                {titleIcon ? titleIcon : null}

                                <Text text={title} variant="primary" />

                            </div>

                            <div
                                className={cn(`
                                cursor-pointer rounded-md p-1 transition-colors duration-200 hover:bg-gray-200
                                border border-gray-200
                            `)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClose();
                                }}
                            >

                                <MdClose className="w-6 h-6" />

                            </div>

                        </div>

                        <div className={cn(`h-[90%] overflow-y-scroll px-4 pb-4`, className)}>
                            {children}
                        </div>

                    </motion.div>

                </motion.div> : null
            }

        </AnimatePresence>,
        document.getElementById("popup") as HTMLDivElement
    );
}