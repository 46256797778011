import { Button } from "@components/button";
import { Input } from "@components/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useOnboardingStore, useUserStore } from "@store";
import { cn } from "@utils/cn";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { motion } from "framer-motion";

const Schema = z.object({
    website: z.string().refine((val) => {

        const regx = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/.*)?$/;

        return regx.test(val);

    }, "Enter a valid url.")
});

export function WebsiteInfo() {

    const { website } = useOnboardingStore();
    const { name } = useUserStore();

    const [searchParams, setSearchParams] = useSearchParams();

    const { setWebsite } = useOnboardingStore();

    const { formState, clearErrors, register, handleSubmit } = useForm({
        resolver: zodResolver(Schema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            website
        }
    });

    const onSubmit: SubmitHandler<z.infer<typeof Schema>> = (data) => {
        setWebsite(data.website);
        searchParams.set("screen", "role");
        setSearchParams(searchParams);
    };

    return (
        <motion.form
            onSubmit={handleSubmit(onSubmit)}
            initial={false}
            animate={{ opacity: 1, }}
            exit={{ opacity: 0, }}
            transition={{ duration: 0.3, type: "spring" }}
        >

            <p className="text-xl font-semibold text-left tracking-wide  mt-4">

                Hi {name}! What website, product or service do you want to collect testimonials for?

            </p>

            <div className="my-4 mb-5">

                <Input
                    {...register("website", { required: true })}
                    error={formState.errors.website?.message}
                    className={cn(`
                        border-gray-300 placeholder:text-gray-500 text-base font-normal 
                        border-2 focus:border-blue-300
                    `, {
                        "focus:border-red-300": formState.errors.website?.message?.length ? true : false
                    })}
                    placeholder="goodvibs.io"
                    onChange={() => clearErrors("website")}
                />

            </div>

            <Button
                type="submit"
                className="mt-3 text-lg"
            >
                Continue
            </Button>

        </motion.form>
    );
}