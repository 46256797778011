import { MigratorList } from "./provider";
import { MigrateSection } from "./section/main.section";


export function MigratePage() {

    return (
        <div className="flex items-start h-full max-h-full overflow-y-auto">

            <MigratorList />

            <MigrateSection />

        </div>
    );
}