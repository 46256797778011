import { Config } from "@config";
import { cn } from "@utils/cn";
import { useEffect, useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player";

type Props = {
    testimonial: Api.Testimonials.Type;
};

export function ShowCaseTestimonial({ testimonial }: Props) {

    const playerRef = useRef<any>(null);

    const [paused, setPaused] = useState(false);
    const [url, setUrl] = useState("");

    const type = testimonial?.type ?? "video";

    useEffect(() => {

        if (playerRef.current && testimonial) {

            const key = type === "video" ? testimonial.testimonial.video?.url : testimonial.testimonial.audio?.url;

            const url = Config.cdn.video() + key;

            playerRef.current.play();

            setUrl(url);

        }

    }, []);

    return (
        <div>

            {type === "video" ?
                <ReactHlsPlayer
                    playerRef={playerRef}
                    src={url}
                    autoPlay
                    controls
                    className="h-[320px] w-full m-auto rounded-md mt-4 outline-none border"
                /> : null
            }

            {type === "audio" ?
                <div className={cn(`
                    flex flex-col items-center justify-center border border-gray-300 
                    rounded-lg p-3 mt-4 bg-gray-100
                `)}>

                    <div className="boxContainer w-auto my-3 space-x-2">

                        <div className={cn("box", { "box1": paused === false })} />

                        <div className={cn("box", { "box2": paused === false })} />

                        <div className={cn("box", { "box3": paused === false })} />

                        <div className={cn("box", { "box4": paused === false })} />

                        <div className={cn("box", { "box5": paused === false })} />

                    </div>

                    <ReactHlsPlayer
                        playerRef={playerRef}
                        src={url}
                        autoPlay
                        controls
                        onPlay={() => setPaused(false)}
                        onPause={() => setPaused(true)}
                        className="w-full m-auto rounded-md outline-none border border-gray-200 shadow-sm"
                    />

                </div> : null
            }

        </div>
    );
}