import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";

type Props = {
    placeholder?: string,
    label?: string;
};

export function NameField({ label, placeholder }: Props) {

    const { name, error } = useImportTestimonialStore((store) => ({
        name: store.testimonial.name,
        error: store.error
    }));

    return (
        <Input
            type="text"
            placeholder={placeholder ?? "Richard"}
            label={label ?? "Name"}
            required={true}
            mainClassName="w-full"
            value={name}
            error={error["name"]}
            onChange={(e) => useImportTestimonialStore.getState().setName(e.target.value)}
        />
    );
}