import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Header } from "./header/header.main";
import { NavBar } from "./navbar/navbar.main";
import { Toaster } from "react-hot-toast";
import {
    useAuthApiClient, useDetectPageReload, useFormApiClient,
    useSpacesApiClient, useUsersApiClient
} from "@hooks";
import { useUserStore } from "@store";
import { Fragment, useEffect, useState } from "react";
import { Loading } from "@components/loading";
import { useIsFirstRender } from "@uidotdev/usehooks";
import { LocalStorageUtil } from "@utils/localStorage";

export function DashboardLayout() {

    const { onboarding } = useUserStore();
    const navigate = useNavigate();

    const location = useLocation();
    const isFirstRender = useIsFirstRender();

    const formApi = useFormApiClient({});
    const { getProfile } = useUsersApiClient({});
    const { verifyToken } = useAuthApiClient({});
    const { getDetails, getAll } = useSpacesApiClient({});

    const { hasReloaded } = useDetectPageReload();

    const [loading, setLoading] = useState(true);

    useEffect(() => { onLoad(); }, []);

    useEffect(() => setLoading(getDetails.isFetching), [getDetails.isFetching]);

    useEffect(() => {

        if (isFirstRender === false) verifyToken.refetch();

    }, [hasReloaded]);

    const onLoad = async () => {

        const isNotOnboardingScreen = location.pathname !== "/onboarding";

        const completedOnboarding = (LocalStorageUtil.get<string>("showOnboarding") ?? "false") == "true" ? true : false;

        if (isNotOnboardingScreen && completedOnboarding === true) {
            setLoading(false);
            navigate("/onboarding?screen=website", { replace: true });
            return;
        }

        const notFetched = getDetails.isFetched === false && getAll.isFetched === false && formApi.get.isFetched === false;

        if (notFetched && isNotOnboardingScreen) {

            await Promise.all([
                getProfile.mutateAsync(),
                getDetails.refetch(),
                getAll.refetch(),
                formApi.get.refetch()
            ]);

            setLoading(false);

        }

    };

    const showNavbar = location.pathname !== "/spaces";

    if (!onboarding) {
        <Navigate to={"/onboarding?screen=website"} />;
    }

    if (location.pathname === "/onboarding") return <Outlet />;

    return (
        <section className="w-screen h-screen overflow-hidden ">

            <Toaster />

            <Header />

            <section className="xl:h-[95%] md:h-[92%] w-full max-w-full bg-gray-50 flex items-center">

                {loading ? <Loading /> :
                    <Fragment>

                        {showNavbar ? <NavBar isLoading={loading} /> : null}

                        <main className="h-full flex-1 max-h-full bg-transparent overflow-auto ">

                            <Outlet />

                        </main>

                    </Fragment>
                }

            </section>

        </section>
    );
}