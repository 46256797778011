import { ColorPicker } from "@components/colorPicker";
import { Input } from "@components/input";
import { TextToggle } from "@components/toggle";
import { useEmbedStore } from "@store";


export const SideTabSettings: React.FC = () => {

    const { buttonColor, textColor, text, removeBackground } = useEmbedStore((store) => store.slideTab);

    return (
        <div className="space-y-2">

            <ColorPicker
                label="Button Color"
                defaultColor={buttonColor}
                onChange={(e) => useEmbedStore.getState().setSideTab({ buttonColor: e })}
            />

            <ColorPicker
                label="Button Text Color"
                defaultColor={textColor}
                onChange={(e) => useEmbedStore.getState().setSideTab({ textColor: e })}
            />

            <Input
                label="Button Text"
                value={text}
                onChange={(e) => useEmbedStore.getState().setSideTab({ text: e.target.value })}
            />

            <TextToggle
                name="Remove Background"
                isActive={removeBackground}
                onClick={() => useEmbedStore.getState().setSideTab({ removeBackground: !removeBackground })}
            />

        </div>
    );
};