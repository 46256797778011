import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Loading } from "@components/loading";

const NewSubscriptionComponent = lazy(() => import("./new"));
const UpdateSubscriptionComponent = lazy(() => import("./update"));

export function CheckOutPage() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const action = searchParams.get("action") ?? null;

    if (!action) {
        navigate({
            pathname: "/settings",
            search: createSearchParams({
                screen: "billing"
            }).toString()
        }, {
            replace: true
        });
        return null;
    }

    return (
        <div className="w-full h-full overflow-hidden">

            <Suspense fallback={<Loading />}>

                {action === "new" ? <NewSubscriptionComponent /> : <UpdateSubscriptionComponent />}

            </Suspense>

        </div>
    );
}