import { CustomQueryParams, useCustomQuery } from "../useCustomQuery";
import axios from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTestimonialStore } from "@store";
import { QueryKeys } from "./keys";
import { useActiveSpace } from "../useActiveSpace";

type Props = {
    exportParams?: {
        params: Partial<CustomQueryParams>,
        apiParams?: { id: string; };
    },
    downloadParams?: {
        params: Partial<CustomQueryParams>,
        apiParams?: Api.Testimonials.downloadParams;
    };
};

export function useTestimonialsApiClient({ exportParams, downloadParams }: Props) {

    const { formId } = useActiveSpace();

    const getAllQuery = useQuery({
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: [QueryKeys.testimonials.getAll],
        queryFn: async () => {
            try {

                const params = {
                    fid: formId,
                    next: false,
                    page: 1
                };

                const parsedFilter: any = {};

                const filter = useTestimonialStore.getState().filter;

                if (filter.range && filter.range.from) parsedFilter["starting"] = filter.range.from;

                if (filter.range && filter.range.to) parsedFilter["ending"] = filter.range.to;

                if (filter.rating) parsedFilter["rating"] = filter.rating;

                if (filter.approved !== null) parsedFilter["approved"] = filter.approved;

                if (filter.sources.length) parsedFilter["source"] = filter.sources;

                if (filter.type.length) parsedFilter["type"] = filter.type;

                return (await axios.post<Api.Testimonials.getAll>("/api/v1/testimonials/get", {
                    ...params,
                    ...parsedFilter
                })).data;

            } catch (err) {
                throw err;
            }
        }
    });

    const exportQuery = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.testimonials.export],
        queryFn: async () => {
            try {
                return await axios.get("/api/v1/testimonials/export", {
                    params: {
                        id: exportParams?.apiParams?.id
                    },
                    responseType: "blob"
                });
            } catch (err) {
                throw err;
            }
        },
        ...exportParams?.params
    });

    const approve = useMutation({
        mutationKey: [QueryKeys.testimonials.approve],
        mutationFn: async (params: { fid: string, tid: string; }) => {
            try {
                return await axios.post("/api/v1/testimonials/approve", params);
            } catch (err) {
                throw err;
            }
        },
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.testimonials.delete],
        mutationFn: async (params: { formId: string, ids: string[]; }) => {
            try {
                return (await axios.delete("/api/v1/testimonials", { data: params })).data;
            } catch (err) {
                throw err;
            }
        },
    });

    const updateCustomerDetailsQuery = useMutation({
        mutationKey: [QueryKeys.testimonials.updateCustomerDetails],
        mutationFn: async (body: Api.Testimonials.updateCustomerParams) => {
            try {
                return (await axios.put("/api/v1/testimonials/customer", body)).data;
            } catch (err) {
                throw err;
            }
        },
    });

    const downloadQuery = useCustomQuery({
        enabled: false,
        queryKey: [QueryKeys.testimonials.download],
        queryFn: async () => {
            try {
                return (await axios.get<Api.Testimonials.download>("/api/v1/testimonials/download", {
                    params: downloadParams?.apiParams
                })).data;
            } catch (err) {
                throw err;
            }
        },
        ...downloadParams?.params
    });

    /* case study */

    const generateCaseStudyQuery = useMutation({
        mutationKey: [QueryKeys.testimonials.caseStudy.generate],
        mutationFn: async (params: CaseStudy.State & { tid: string; }) => {
            try {
                return (await axios.post<Api.Testimonials.generateCaseStudy>("/api/v1/testimonials/case-study", {
                    fid: formId,
                    ...params
                })).data;
            } catch (err) {
                throw err;
            }
        }
    });

    const deleteCaseStudyQuery = useMutation({
        mutationKey: [QueryKeys.testimonials.caseStudy.delete],
        mutationFn: async (tid: string) => {
            try {
                return (await axios.delete("/api/v1/testimonials/case-study", { params: { fid: formId, tid } })).data;
            } catch (err) {
                throw err;
            }
        },
    });

    const publishCaseStudyQuery = useMutation({
        mutationKey: [QueryKeys.testimonials.caseStudy.publish],
        mutationFn: async (tid: string) => {
            try {
                return (await axios.put("/api/v1/testimonials/case-study", { fid: formId, tid })).data;
            } catch (err) {
                throw err;
            }
        },
    });

    return {
        getAll: getAllQuery,
        exportQuery,
        approve,
        deleteQuery,
        updateCustomerDetailsQuery,
        downloadQuery,
        /* case study */
        generateCaseStudyQuery,
        deleteCaseStudyQuery,
        publishCaseStudyQuery
    };

}