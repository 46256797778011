import { useEmbedStore } from "@store";
import { useEffect, useState } from "react";
import { EmbeddedPreview } from "../ifream";
import { cn } from "@utils/cn";


export const PopupPreview: React.FC = () => {

    const { view, size } = useEmbedStore((store) => ({
        view: store.view,
        size: store.popup.size
    }));

    const [show, setShow] = useState(false);
    const [style, setStyle] = useState<{
        height: string,
        width: string;
    }>({ height: "", width: "" });

    useEffect(() => {

        if (size === "large") {
            setStyle({ height: "100%", width: "100%" });
        } else if (size === "medium") {
            setStyle({ height: "80%", width: "80%" });
        } else if (size === "small") {
            setStyle({ height: "60%", width: "60%" });
        }

    }, [size]);

    return (
        <div
            className={`
                relative bg-white rounded-xl border border-border overflow-auto 
                flex items-center justify-center overflow-y-auto 
            `}
            style={{
                width: view === "desktop" ? "100%" : "400px",
                height: window.innerWidth < 1400 ? "500px" : "600px"
            }}
        >

            {show ?
                <div className="absolute h-full w-full bg-[#6b6b6b9a] flex items-center justify-center">

                    <EmbeddedPreview
                        height={view === "desktop" ? style.height : "100%"}
                        width={view === "desktop" ? style.width : "100%"}
                    />

                    <div
                        className={cn(`
                            text-2xl absolute top-5 right-7 bg-white rounded-full p-1 w-10 h-10 
                            flex items-center justify-center cursor-pointer border border-black
                        `, {
                            "z-50": view === "mobile"
                        })}
                        onClick={() => setShow(!show)}
                    >
                        x
                    </div>

                </div> : null
            }

            <div className="flex flex-col items-center justify-center space-y-3 cursor-pointer">

                <p className="text-sm font-normal"> click here to launch the popup </p>

                <div
                    className="bg-primary text-white px-5 py-2 text-sm font-normal outline-none rounded-md"
                    onClick={() => setShow(!show)}
                >
                    Launch
                </div>

            </div>


        </div>
    );
};