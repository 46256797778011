import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useFormStore } from "@store";


export function CustomPageTitle() {

    const title = useFormStore(state => state.customDetails.title);

    return (
        <div>

            <Text
                variant="label"
                text="Title"
            />

            <QuillInput
                valueAsHTML={title}
                placeHolder="Enter your title"
                recall={true}
                onChange={(e) => useFormStore.getState().setCustomerPageTitle(e.html)}
            />

        </div>
    );
}