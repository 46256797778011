import { ContentAiLogo } from "./logos/contentAi";
import { FormsLogo } from "./logos/forms";
import { GoZenLogo } from "./logos/gozen";
import { GrowthLogo } from "./logos/growth";
import { OptinlyLogo } from "./logos/optinly";
import { Logo } from "@components/icons/logo";

export const apps = [
    {
        icon: <GoZenLogo width="40px" height="40px" />,
        id: "gozen",
        name: "Account",
        active: false,
        redirectUrl: {
            dev: "https://dev.app.gozen.io/dashboard",
            prod: "https://app.gozen.io/dashboard"
        }
    },
    {
        icon: <ContentAiLogo width="40px" height="40px" />,
        id: "gz_content_ai",
        name: "Content Ai",
        active: false,
        redirectUrl: {
            dev: "https://app.dev.content-ai.gozen.io/",
            prod: "http://app.content.gozen.io/"
        }
    },
    {
        icon: <FormsLogo width="40px" height="40px" />,
        id: "gz_forms",
        name: "Forms",
        active: false,
        redirectUrl: {
            dev: "https://dev.app.forms.gozen.io/workspace",
            prod: "https://app.forms.gozen.io/workspace"
        }
    },
    {
        icon: <OptinlyLogo width="40px" height="40px" />,
        id: "optinly",
        name: "Optinly",
        active: false,
        redirectUrl: {
            dev: "http://app.dev.optinly.gozen.io/",
            prod: "http://app.optinly.com/dashboard"
        }
    },
    {
        icon: <GrowthLogo width="40px" height="40px" />,
        id: "gz_growth",
        name: "Growth",
        active: false,
        redirectUrl: {
            dev: "https://dev.app.growth.gozen.io/",
            prod: "https://app.growth.gozen.io/"
        }
    },
    {
        icon: <Logo height={40} width={40} />,
        id: "gz_testimonial",
        name: "Testimonial",
        active: true,
        redirectUrl: {
            dev: "https://dev.app.growth.gozen.io/",
            prod: "https://app.growth.gozen.io/"
        }
    }
];