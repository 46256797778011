import { useEffect } from "react";


export function Usage() {

    // const billing = useGetQueryData<Api.Billing.getBillingDetails>([QueryKeys.billings.getCurrentPlan]);

    // const [show, setShow] = useState({
    //     workspaces: false,
    //     responses: false,
    //     team: false,
    //     storage: false,
    //     emailNotification: false,
    //     generatedQuestions: false,
    //     customDomains: false,
    // });
    // const [used, setUsed] = useState({
    //     responses: 0,
    //     workspace: 0,
    //     team: 0,
    //     storage: 0,
    //     emailNotification: 0,
    //     generatedQuestion: 0,
    //     customDomains: 0
    // });
    // const [limit, setLimit] = useState({
    //     responses: 0,
    //     workspace: 0,
    //     team: 0,
    //     storage: 0,
    //     emailNotification: 0,
    //     generatedQuestion: 0,
    //     customDomains: 0
    // });

    const platForm = "app";

    useEffect(() => {

        if (platForm === "app") {

            // const usage = billing.

            // const plan = billing.plan;

            // setShow({
            //     ...show,
            //     workspaces: true,
            //     responses: true,
            //     team: true,
            //     storage: true,
            //     generatedQuestions: usage.generatedQuestionsLimit !== 0 ? true : false,
            //     customDomains: usage.customDomainsLimit !== 0 ? true : false
            // });

            // setUsed({
            //     ...used,
            //     workspace: Math.ceil((1 / (BillingUtils.get(plan).workspace / usage.noOfWorkspaces)) * 100),
            //     responses: Math.ceil((1 / (BillingUtils.get(plan).responseCount / usage.noOfResponses)) * 100),
            //     team: Math.ceil((1 / (BillingUtils.get(plan).users / usage.noOfUsers)) * 100),
            //     storage: Math.ceil((1 / (BillingUtils.get(plan).storage / parseInt(usage.storage))) * 100),
            //     generatedQuestion: Math.ceil((1 / (usage.generatedQuestionsLimit / usage.noOfGeneratedQuestions)) * 100),
            //     customDomains: Math.ceil((1 / (usage.customDomainsLimit / usage.noOfCustomDomains)) * 100)
            // });

            // setLimit({
            //     ...limit,
            //     workspace: BillingUtils.get(plan).workspace,
            //     responses: usage.responseLimit,
            //     team: usage.usersLimit,
            //     storage: BillingUtils.get(plan).storage,
            //     generatedQuestion: usage.generatedQuestionsLimit,
            //     customDomains: usage.customDomainsLimit
            // });

        } else if (platForm === "appSumo") {

            // setShow({
            //     ...show,
            //     responses: limitedPlans.has(plan),
            //     workspaces: limitedPlans.has(plan),
            //     team: true,
            //     storage: true,
            //     emailNotification: true,
            //     generatedQuestions: true,
            //     customDomains: true
            // });

            // setUsed({
            //     workspace: Math.ceil((1 / (AppSumoBillingPlans.get(plan).workspaces / usage.noOfWorkspaces)) * 100),
            //     responses: Math.ceil((1 / (AppSumoBillingPlans.get(plan).responses / usage.noOfResponses)) * 100),
            //     team: Math.ceil((1 / (AppSumoBillingPlans.get(plan).noOfUsers / usage.noOfUsers)) * 100),
            //     storage: Math.ceil((1 / (AppSumoBillingPlans.get(plan).storage / parseInt(usage.storage))) * 100),
            //     emailNotification: Math.ceil((1 / (usage.emailNotificationLimit / usage.noOfEmailNotification)) * 100),
            //     generatedQuestion: Math.ceil((1 / (usage.generatedQuestionsLimit / usage.noOfGeneratedQuestions)) * 100),
            //     customDomains: Math.ceil((1 / (usage.customDomainsLimit / usage.noOfCustomDomains)) * 100)
            // });

            // setLimit({
            //     workspace: AppSumoBillingPlans.get(plan).workspaces,
            //     responses: usage.responseLimit,
            //     team: usage.usersLimit,
            //     storage: AppSumoBillingPlans.get(plan).storage,
            //     emailNotification: usage.emailNotificationLimit,
            //     generatedQuestion: usage.generatedQuestionsLimit,
            //     customDomains: usage.customDomainsLimit
            // });
        }
    }, []);

    return (
        <div className="fade-in px-5 py-3 h-[90%] overflow-y-auto">

            <p className="text-xl font-medium"> Your Plan Usage </p>

            {/* <p className="text-sm font-normal mt-5"> current plan: <b> {plan} </b> </p> */}

            <div className="mt-3 grid grid-cols-3 gap-y-4 items-center justify-items-center w-full">

                {/* workspaces */}
                {/* {show.workspaces ?
                    <UsageCard
                        title="Workspaces"
                        usedPercentage={used.workspace}
                        usedCount={usage.noOfWorkspaces}
                        maxLimit={limit.workspace}
                    /> : null
                } */}

                {/* responses */}
                {/* {show.responses ?
                    <UsageCard
                        title="Responses"
                        usedPercentage={used.responses}
                        usedCount={usage.noOfResponses}
                        maxLimit={limit.responses}
                    /> : null
                } */}

                {/* team */}
                {/* {show.team ?
                    <UsageCard
                        title="Team Members"
                        usedPercentage={used.team}
                        usedCount={usage.noOfUsers}
                        maxLimit={limit.team}
                    /> : null
                } */}

                {/* storage */}
                {/* {show.storage ?
                    <UsageCard
                        title="File upload"
                        usedPercentage={used.storage}
                        usedCount={
                            parseInt(usage.storage) > 1024 ?
                                `${Math.floor(parseInt(usage.storage) / 1000)} GB` :
                                `${usage.storage} mb`
                        }
                        maxLimit={`${Math.ceil(limit.storage / 1034)} GB`}
                    /> : null
                } */}

                {/* email notification */}
                {/* {show.emailNotification && platForm === "appSumo" && usage.emailNotificationLimit !== 0 ?
                    <UsageCard
                        title="Email notification"
                        usedPercentage={used.emailNotification}
                        usedCount={usage.noOfEmailNotification}
                        maxLimit={limit.emailNotification}
                    /> : null
                } */}

                {/* ai questions */}
                {/* {show.generatedQuestions ?
                    <UsageCard
                        title="No of generated questions"
                        usedPercentage={used.generatedQuestion}
                        usedCount={usage.noOfGeneratedQuestions}
                        maxLimit={limit.generatedQuestion}
                    /> : null
                } */}

                {/* custom domains */}
                {/* {show.customDomains && isNaN(limit.customDomains) === false && limit.customDomains !== 0 ?
                    <UsageCard
                        title="No of custom domains"
                        usedPercentage={used.customDomains}
                        usedCount={usage.noOfCustomDomains}
                        maxLimit={limit.customDomains}
                    /> : null
                } */}

            </div>

        </div>
    );
}

// type Props = {
//     title: string,
//     usedPercentage: number,
//     usedCount: number | string,
//     maxLimit: number | string,
// };

// const UsageCard: React.FC<Props> = ({
//     title,
//     usedPercentage,
//     usedCount,
//     maxLimit,
// }) => {
//     return (
//         <div className="rounded-xl shadow-sm border bg-gray-50 w-[94%] h-[120px] px-4 py-5">

//             <div className="flex items-center justify-between">

//                 <p className="text-lg font-medium"> {title} </p>

//                 <p className="font-medium text-md"> {usedPercentage > 100 ? 100 : usedPercentage}% </p>

//             </div>

//             <div className="w-full h-[10px] mt-2 mb-2 rounded-md bg-gray-200">

//                 <div
//                     className="w-full h-full my-4 rounded-lg bg-primary"
//                     style={{
//                         width: `${usedPercentage > 100 ? 100 : usedPercentage}%`
//                     }}
//                 />

//                 <div className="mt-2 text-sm font-normal">
//                     {usedCount} of {maxLimit}
//                 </div>

//             </div>

//         </div>
//     );
// };