import { SkeletonLoader } from "@components/skeleton";
import { cn } from "@utils/cn";
import { MdMoving } from "react-icons/md";

type Props = {
    isLoading: boolean,
    number: string,
    title: string,
    percent: number,
    dir: "up" | "down";
};

export function StatsCard({ isLoading, number, percent, title, dir }: Props) {
    return (
        <div className={cn(`
            bg-white w-[25%] rounded-lg px-4 py-3 shadow-md 
            border border-gray-200 space-y-2 flex flex-col items-start justify-start
        `)}>

            {isLoading ?
                <SkeletonLoader className="h-[32px] w-[30%]" /> :
                <h1
                    className={cn(`text-2xl font-bold tracking-wide transition-all duration-300 ease-in-out`, {
                        " bg-gray-200 rounded-full": isLoading
                    })}
                    style={{
                        fontFamily: `'Roboto Mono', monospace`
                    }}
                >
                    {number}
                </h1>
            }

            {isLoading ?
                <SkeletonLoader className="h-[22px] w-[70%]" /> :
                <p className="text-sm font-medium text-gray-700 tracking-wide transition-all duration-300 ease-in-out">
                    {title}
                </p>
            }

            {isLoading ?
                <SkeletonLoader className="h-[22px] w-[90%]" /> :
                <div className="flex items-center justify-center space-x-2 my-2 transition-all duration-300 ease-in-out">

                    <div className="bg-gray-100 rounded-lg p-1 inline-flex items-center justify-center space-x-1">

                        {dir === "up" ?
                            <MdMoving className="w-4 h-4 text-gray-400" /> :
                            <MdMoving className="w-4 h-4 text-gray-400" />
                        }

                        <p className="text-xs font-medium"> {percent}% </p>

                    </div>

                    <p className="text-xs tracking-wide"> In Last 30 days </p>


                </div>
            }

        </div >
    );
}