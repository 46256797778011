import { MdOutlineAccountCircle, MdSecurity } from "react-icons/md";
import { PiLinkBold } from "react-icons/pi";
import { Summary } from "./summary";
import { Plans } from "./plans";
import { Usage } from "./usage";
import { Tabs } from "@components/tabs";
import { useBillingApiClient } from "@hooks";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react";
import { Loading } from "@components/loading";

const tabs = [
    {
        icon: <MdOutlineAccountCircle className="w-4 h-4 mx-1" />,
        name: "Summary",
        value: "summary",
        content: (<Summary />)
    },
    {
        icon: <MdSecurity className="w-4 h-4 mx-1" />,
        name: "Plans",
        value: "plans",
        content: (<Plans />)
    },
    {
        icon: <PiLinkBold className="w-4 h-4 mx-1" />,
        name: "Usage",
        value: "usage",
        content: (<Usage />)
    },
];

export function Billings() {

    const { getCurrentPlanDetails } = useBillingApiClient({});

    const [searchParams, setSearchParams] = useSearchParams();

    const activeScreen = searchParams.get("tab") ?? "summary";


    return (
        <div className="px-2 py-1 h-[80%]">

            <Tabs
                className="mt-4 mx-0"
                defaultValue={activeScreen}
            >

                <Tabs.List className="space-x-2 px-2">

                    {tabs.map((tab, idx) => (
                        <Tabs.Trigger
                            key={idx}
                            value={tab.value}
                            className="data-[state=active]:bg-primary data-[state=active]:text-white text-sm"
                            onClick={() => {
                                searchParams.set("tab", tab.value);
                                setSearchParams(searchParams);
                            }}
                        >

                            {/* {tab.icon} */}

                            <p>
                                {tab.name}
                            </p>

                        </Tabs.Trigger>
                    ))}

                </Tabs.List>

                {getCurrentPlanDetails.isPending || getCurrentPlanDetails.isFetching ? <Loading /> :
                    <Fragment>

                        {tabs.map((tab, idx) => (
                            <Tabs.Content key={idx} value={tab.value}>

                                {tab.content}

                            </Tabs.Content>
                        ))}

                    </Fragment>
                }

            </Tabs>

        </div>
    );
}