import { Button, SecondaryButton } from "@components/button";
import { Input } from "@components/input";
import { Popup } from "@components/popup";
import { QueryKeys, useDomainsApiClient, useInvalidateQuery } from "@hooks";
import { useCommonStore } from "@store";
import { cn } from "@utils/cn";
import { useState } from "react";
import toast from "react-hot-toast";

const tabs = [
    {
        value: "custom",
        label: "Custom Domain"
    },
    {
        value: "sub",
        label: "Sub Domain"
    }
];


export function AddDomainPopup() {

    const show = useCommonStore((store) => store.activePopup === "add-domain");

    const [domain, setDomain] = useState("");
    const [error, setError] = useState("");
    const [type, setType] = useState<"sub" | "custom">("sub");

    const { addQuery } = useDomainsApiClient({});

    const { invalidate } = useInvalidateQuery();

    const onAdd = async () => {
        try {

            const valid = new RegExp(/^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/).test(domain);

            if (!valid) {
                setError("Enter a valid domain.");
                return;
            }

            await addQuery.mutateAsync({ domain, type: "custom" });

            invalidate([QueryKeys.domains.get]);

            useCommonStore.getState().setActivePopup("");

            toast.success("Successfully, add your domain.");

        } catch (err: any) {
            toast.error(err);
        }
    };

    if (show === false) return null;

    return (
        <Popup
            width="35%"
            name="Add Domain"
            onClose={() => useCommonStore.getState().setActivePopup("")}
        >

            <ul className="flex flex-wrap -mb-px border-b">

                {tabs.map((tab, idx) => (
                    <li
                        key={idx}
                        className={cn("me-2 text-sm font-medium")}
                        onClick={() => setType(tab.value as any)}
                    >

                        <a
                            href="#"
                            className={cn(`
                                inline-block p-2 border-b-2 border-transparent tracking-wide
                                rounded-t-lg hover:text-gray-600 hover:border-gray-300 
                            `, {
                                "text-blue-600 border-blue-600 active": tab.value === type,
                            })}
                        >
                            {tab.label}
                        </a>

                    </li>
                ))}

            </ul>

            <div className="mt-4">

                {type === "custom" ?
                    <Input
                        type="text"
                        placeholder="test.com"
                        label="Your domain"
                        required={true}
                        value={domain}
                        error={error}
                        onChange={(e) => setDomain(e.target.value)}
                    /> :
                    <div className={cn(`
                        flex items-center p-1 rounded-md border border-gray-300 bg-transparent px-2 text-sm py-2 
                        hover:outline-none hover:ring-1 hover:ring-blue-400 hover:ring-offset-1
                    `, {
                        "border-red-300 hover:ring-red-300": error
                    })}>

                        <input
                            type="text"
                            className="outline-none w-[90%] px-1 text-sm"
                            placeholder="Enter your public link"
                            onClick={() => { }}
                        />

                        <p className="text-sm w-full text-gray-600"> .gozen-testimonial.io </p>

                    </div>
                }


                <div className="flex items-center justify-end space-x-2 mt-4">

                    <SecondaryButton
                        disabled={addQuery.isPending}
                        className="w-auto text-sm font-medium"
                        onClick={() => useCommonStore.getState().setActivePopup("")}
                    >
                        Cancel
                    </SecondaryButton>

                    <Button
                        disabled={addQuery.isPending}
                        loading={addQuery.isPending}
                        className="w-auto text-sm font-medium"
                        onClick={() => onAdd()}
                    >
                        Add
                    </Button>

                </div>

            </div>

        </Popup>
    );
}