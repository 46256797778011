import { TextToggle } from "@components/toggle";
import { useEffect } from "react";
import { Condition } from "./condition";
import { Message } from "./message";
import { Theme } from "./theme";
import { Segments } from "./segments";
import { FiInfo } from "react-icons/fi";
import { cn } from "@utils/cn";
import { Tooltip } from "react-tooltip";
import { useFormStore } from "@store";
import { PageSettings } from "./page";
import { ClaimRewardButton } from "./button";


export function Reward() {

    const allowReward = useFormStore((store) => store.reward.allow);

    useEffect(() => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "currentScreen",
                payload: { screen: "reward-cta" }
            } as Messages.Types, "*");

            ele.scrollTop = ele.scrollHeight;

        }

    }, []);

    return (
        <div className="space-y-3">

            <TextToggle
                isActive={allowReward}
                name="Enable Rewards"
                onClick={() => useFormStore.getState().setAllowReward(!allowReward)}
            >

                <FiInfo
                    className={cn(`text-gray-600`)}
                    id={`allow-sharing-tooltip`}
                />

                <Tooltip
                    anchorSelect={`#allow-sharing-tooltip`}
                    place="top"
                    style={{
                        width: 340,
                        textAlign: "left"
                    }}
                >
                    Collect 2x more testimonials when they use rewards.
                    Enable rewards to encourage your customers to leave a review.
                </Tooltip>

            </TextToggle>

            {allowReward ?
                <div className="space-y-2">

                    <Condition />

                    <Message />

                    <ClaimRewardButton />

                    <Theme />

                    <PageSettings />

                    <Segments />

                </div> : null
            }

        </div>
    );
}