import { QueryKeys, useGetQueryData } from "@hooks";
import { ProfileSection } from "./profile";
import { TestimonialSection } from "./testimonial";
import { useSocialProofStore } from "@store";
import { cn } from "@utils/cn";
import { useParams } from "react-router-dom";
import { Fragment } from "react";

export function ListTestimonials() {

    const { type } = useParams();

    const { selectedTestimonialIds } = useSocialProofStore(store => ({
        selectedTestimonialIds: store.selectedTestimonials.map(item => item.id)
    }));

    const testimonialsQuery = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);

    return (
        <div className="h-[88%] max-h-full overflow-y-auto space-y-4 mt-2 pb-14">

            {testimonialsQuery?.testimonials?.length ?
                <Fragment>
                    {testimonialsQuery?.testimonials.map((item, idx) => {

                        const isSelected = selectedTestimonialIds.includes(item.id);

                        return (
                            <div
                                key={idx}
                                className={cn(`
                            bg-white p-4 rounded-lg flex border-2 hover:border-blue-600 cursor-pointer 
                            transition-all duration-300 ease-in-out
                        `, {
                                    "border-blue-600": isSelected
                                })}
                                onClick={() => {

                                    if (isSelected) {

                                        useSocialProofStore.getState().removeSelectedTestimonial(item.id);

                                        if (type === "image") {

                                            const testimonialType = item.type;

                                            const text = item?.testimonial.text?.content ?? "";
                                            const videoTranscript = item?.testimonial.video?.transcribed ? item?.testimonial.video?.transcribedText : "";
                                            const audioTranscript = item?.testimonial.audio?.transcribed ? item?.testimonial.audio?.transcribedText : "";

                                            useSocialProofStore.getState().setImageTemplateContent({
                                                company: (item?.customerDetails["company"]?.answer as string) ?? "",
                                                customerName: (item?.customerDetails["name"]?.answer as string) ?? "",
                                                profileUrl: (item?.customerDetails["profile"]?.answer as string) ?? "",
                                                rating: (item?.testimonial?.rating) ?? 0,
                                                role: (item?.customerDetails["role"]?.answer as string) ?? "",
                                                text: testimonialType === "text" ? text : testimonialType === "video" ? videoTranscript : audioTranscript,
                                            });
                                        }

                                    } else {

                                        useSocialProofStore.getState().setSelectedTestimonial({ ...item });

                                    }

                                }}
                            >

                                <ProfileSection customer={item.customerDetails} />

                                <TestimonialSection response={item} />

                            </div>
                        );
                    })}
                </Fragment> : null
            }

        </div>
    );
}