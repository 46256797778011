import { SecondaryButton } from "@components/button";
import { DropdownMenu } from "@components/dropDown";
import { Text } from "@components/text";
import { QueryKeys, useGetQueryData, } from "@hooks";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { DeleteSpacePopup } from "./deleteSpace.popup";
import { useCommonStore } from "@store";


export function SpacesHeader() {

    const spacesQuery = useGetQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

    const name = spacesQuery?.activeSpace?.name ?? "";

    if (spacesQuery && spacesQuery?.spaces?.length === 0) {
        return (
            <div className="flex items-center justify-between">

                <p className="text-lg font-semibold tracking-wide my-2 px-2">
                    Hi,
                    <span className="text-primary"> Richer </span>
                    <span className="waving-hand w-6 h-6">👋</span>
                </p>

            </div>
        );
    }

    return (
        <div className="w-full flex items-center p-2 px-6 space-x-3">

            <Text
                text={name ?? ""}
                className="text-lg font-semibold tracking-wide capitalize"
            />

            {spacesQuery && spacesQuery?.activeSpace?.primarySpace === false && spacesQuery?.spaces?.length > 1 ?
                <DropdownMenu dir="ltr">

                    <DropdownMenu.Trigger className="outline-none">

                        <SecondaryButton className="outline-none w-auto p-[4px]">

                            <HiOutlineDotsVertical className="w-4 h-4" />

                        </SecondaryButton>

                    </DropdownMenu.Trigger>

                    <DropdownMenu.Content
                        side="left"
                        sideOffset={5}
                        align="start"
                        className="absolute top-7 left-[6px] w-[160px]"
                    >

                        <DropdownMenu.Item
                            className="hover:bg-red-100 text-red-600"
                            onClick={() => useCommonStore.getState().setActivePopup("delete-space")}
                        >
                            Delete Space
                        </DropdownMenu.Item>

                    </DropdownMenu.Content>

                </DropdownMenu> : null
            }

            <DeleteSpacePopup />

        </div>
    );
}