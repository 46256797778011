import { ApiError } from "src/type";

export function ParseAxiosError(error: ApiError): string {

    const errorMessage = error.response?.data?.message ?? null;

    if (errorMessage === null) return "";

    if (typeof error === "object" && typeof errorMessage === "object") {
        return errorMessage[0] as string;
    }

    if (typeof errorMessage === "string") return errorMessage;

    return error.message;

}