import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { motion } from "framer-motion";

interface Props extends React.HTMLProps<HTMLDivElement> {
    children: ReactNode;
}

const Main: React.FC<Props> = ({ children, className, }) => {
    return (
        <motion.div
            className={cn(`
                absolute right-0 top-[50px] text-left rounded-lg bg-white shadow-xl z-20 border w-[200px]
                p-2
            `, className)}
            style={{
                boxShadow: "rgba(0, 0, 0, 0.08) 0px 2px 4px, rgba(0, 0, 0, 0.06) 0px 2px 12px;"
            }}
            initial={{
                scale: 0.5,
                opacity: 0
            }}
            animate={{
                transformOrigin: "top right",
                scale: 1,
                opacity: 1,
                transition: {
                    duration: 0.1,
                    stiffness: 100,
                    ease: "easeInOut"
                }
            }}
            exit={{
                scale: 0,
                opacity: 0,
            }}
        >

            {children}

        </motion.div>
    );
};

interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
    icon?: ReactNode,
    text: string,
    textClassName?: string,
}

const Item: React.FC<ItemProps> = ({ children, className, textClassName, icon, text, ...props }) => {
    return (
        <div
            {...props}
            className={cn(`
                h-10 px-3.5 flex flex-row items-center text-primary-gray cursor-pointer rounded-md
                hover:bg-blue-50 space-x-1.5 group transition-all duration-300 ease-in-out
            `, className)}
        >

            {icon ?
                <div className="w-[10%] mr-2 transition-all duration-200 ease-in-out">
                    {icon}
                </div> : null
            }

            <p
                className={cn(`
                    text-sm font-normal tracking-wide text-black group-hover:text-primary
                    transition-all duration-200 ease-in-out
                `, textClassName)}
            >
                {text}
            </p>

        </div>

    );
};

export const Menu = Object.assign(Main, { Item });