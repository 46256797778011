import { useNavigate, useSearchParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { SecondaryButton } from "@components/button";
import { EmbedSection } from "./embed/embed.main";
import { Tabs } from "@components/tabs";
import { ShareSection } from "./shareSection/share.main";

const tabs = [
    {
        label: "Share",
        value: "share",
        content: <></>
    },
    {
        label: "Embed",
        value: "embed",
        content: <></>
    }
];


export function ShareForm() {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get("tab") ?? "share";

    const onChange = (val: string) => {
        searchParams.set("tab", val);
        setSearchParams(searchParams);
    };

    return (
        <div className="w-full h-full bg-gray-100 flex items-center justify-center relative">

            <SecondaryButton
                className="absolute top-5 left-6 bg-white space-x-2 px-1 inline-block w-[6%] text-sm mt-0"
                onClick={() => navigate(-1)}
            >

                <IoArrowBack className="w-5 h-5" />

                <p> Back </p>

            </SecondaryButton>

            <Tabs
                className="mx-0 h-full w-full flex flex-col items-center py-2"
                defaultValue={activeTab}
            >

                <Tabs.List className="space-x-2 px-2 h-auto bg-white">

                    {tabs.map((tab, idx) => (
                        <Tabs.Trigger
                            key={idx}
                            value={tab.value}
                            className="data-[state=active]:bg-primary data-[state=active]:text-white space-x-2 border"
                            onClick={() => onChange(tab.value)}
                        >

                            {tab.label}

                        </Tabs.Trigger>
                    ))}

                </Tabs.List>

                {tabs.map((tab, idx) => (
                    <Tabs.Content
                        key={idx}
                        value={tab.value}
                        className="w-full h-[95%] mt-2"
                    >

                        {activeTab === "share" ? <ShareSection /> : <EmbedSection />}

                    </Tabs.Content>
                ))}

            </Tabs>

        </div>
    );
}