import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { StoreKeys } from "../enum";

const State: Integrations.State = {
    googleSheet: {
        activeScreen: "auth",
        useExistingIntegrationId: ""
    },
    pabbly: {
        existingIntegrationId: "",
    }
};

export const useIntegrationsStore = create<Integrations.Type>()(devtools(immer(set => ({
    ...State,
    setGoogleSheetActiveScreen: (screen) => set((state) => {

        state.googleSheet.activeScreen = screen;

    },
        false,
        "integrations/setGoogleSheetActiveScreen"
    ),
    setUseExistingIntegrationId: (id) => set((state) => {

        state.googleSheet.useExistingIntegrationId = id;

    },
        false,
        "integrations/setUseExistingIntegrationId"
    ),
    setUsePabblyIntegrationId: (id) => set((state) => {

        state.pabbly.existingIntegrationId = id;

    },
        false,
        "integrations/setUsePabblyIntegrationId"
    ),
})), {
    name: StoreKeys.integrations,
    enabled: import.meta.env.DEV
}));