import { QuillInput } from "@components/quillInput";
import { Text } from "@components/text";
import { useFormStore } from "@store";


export function Message() {

    const message = useFormStore((state) => state.reward.message);

    const onClick = () => {

        const ele = document.getElementById("testimonial-preview") as HTMLIFrameElement;

        if (ele) {

            ele.contentWindow?.postMessage({
                type: "currentScreen",
                payload: { screen: "reward-welcome" }
            } as Messages.Types, "*");

            ele.scrollTop = ele.scrollHeight;

        }

    };

    return (
        <div
            className="space-y-2"
            onClick={() => onClick()}
        >

            <Text
                text={"Message"}
                variant="label"
            />

            <QuillInput
                valueAsHTML={message}
                placeHolder="Enter your message"
                recall={false}
                onChange={(e) => useFormStore.getState().setRewardMessage(e.html)}
            />

        </div>
    );
}