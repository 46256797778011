import { motion } from "framer-motion";
import { TestimonialSource } from "./source";
import { Rating } from "@components/rating";
import { TextTestimonial } from "./type/text";
import { TestimonialProfile } from "./profile";

export function PreviewSection() {

    /* 
        # TODO
        - get form data 
    */

    const data = {
        "img": [
            "https://pbs.twimg.com/profile_images/1615753720691556375/3IlAzsa0_x96.jpg",
            "https://pbs.twimg.com/media/GWjwdEAWQAA854L?format=jpg&name=medium"
        ],
        "video": [],
        "timestamp": "2024-09-03T14:34:21.000Z",
        "name": "Alvaro Cintas",
        "handle": "@dr_cintas",
        "tweetTextWithImage": "Cursor with Claude 3.5 Sonnet is a major shift.\n\nPeople keep building and sharing crazy use cases with it.\n\n10 wild examples:",
        "ProfilePicSrc": {}
    };



    const profileUrl = data.img[0];
    const name = data.name;
    const handel = data.handle;
    const rating = 0;
    const type: Api.Testimonials.Type["type"] = "text";
    const submittedAt = data.timestamp;

    return (
        <motion.div
            className="h-[82%] w-[94%] bg-red-300 rounded-lg mt-2 flex items-center justify-center dotted-bg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >

            <div className="w-[80%] flex flex-col items-center justify-center bg-white px-2 py-[6px] rounded-xl shadow-md">

                <div className="w-full p-3 flex items-center justify-between">

                    <TestimonialProfile
                        name={name}
                        profileUrl={profileUrl}
                        secondaryName={handel}
                    />

                    <TestimonialSource
                        source="x"
                        sourceLink=""
                    />

                </div>

                {rating ?
                    <Rating
                        rated={rating}
                        readonly={true}
                        iconProps={{
                            style: {
                                width: "28px",
                                height: "28px",
                                marginLeft: "3px"
                            }
                        }}
                    /> : null
                }

                <div className="w-full h-full px-1">

                    {type === "text" ?
                        <TextTestimonial
                            content={data.tweetTextWithImage}
                            imageAttachments={[data.img[1]]}
                        /> : null
                    }

                    {/* {type === "audio" ? <AudioTestimonial /> : null} */}

                    {/* {type === "video" ? <VideoTestimonial /> : null} */}

                </div>

                <div className="pt-2 pb-2 flex items-start w-full">

                    <p className="text-xs font-medium tracking-wide text-gray-600 px-4">
                        {new Date(submittedAt).toLocaleString("EN", { dateStyle: "medium" })}
                    </p>

                </div>

            </div>

        </motion.div>
    );
}