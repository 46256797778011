import { Input } from "@components/input";
import { Text } from "@components/text";
import { TextToggle } from "@components/toggle";
import { useFormStore } from "@store";
import { useState } from "react";

export function SubmissionSettings() {

    const submissions = useFormStore((store) => store.settings.submissions);

    const [showLimit, setShowLimit] = useState(submissions.limit !== 0);

    const onToggle = () => {

        setShowLimit(!showLimit);

        if (!showLimit === false) {

            useFormStore.getState().setSubmissionsSettings({ limit: 0 });

        }

    };

    return (
        <div className="space-y-2">

            <Text
                text="Submissions"
            />

            <div className="space-y-2">

                <TextToggle
                    isActive={showLimit}
                    name="Limit submissions"
                    tooltip={{
                        id: "limit-submissions",
                        tip: "limit submissions"
                    }}
                    onClick={() => onToggle()}
                />

                {showLimit ?
                    <Input
                        type="number"
                        placeholder="Enter limit response"
                        min={0}
                        value={submissions.limit}
                        onChange={(e) => useFormStore.getState().setSubmissionsSettings({ limit: parseInt(e.target.value) })}
                    /> : null
                }

            </div>

            <TextToggle
                isActive={submissions.allowMultipleSubsBrowser}
                name="Allow multiple submissions"
                tooltip={{
                    id: "multiple-submissions",
                    tip: "allow multiple submissions from same browser"
                }}
                onClick={() => useFormStore.getState().setSubmissionsSettings({
                    allowMultipleSubsBrowser: !submissions.allowMultipleSubsBrowser
                })}
            />

            <TextToggle
                isActive={submissions.close}
                name="Close Form"
                tooltip={{
                    id: "close-form",
                    tip: "close form from new submissions"
                }}
                onClick={() => useFormStore.getState().setSubmissionsSettings({ close: !submissions.close })}
            />

        </div>
    );
}