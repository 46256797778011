import { UndefinedInitialDataOptions, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { QueryKeys } from "./keys";
import { ParseAxiosError } from "@lib/axios";

type Params = {
    getAllParams?: {
        params: Partial<UndefinedInitialDataOptions>,
    },
    getDetailsParams?: {
        params: Partial<UndefinedInitialDataOptions>,
    },
    checkSlugParams?: {
        apiParams: {
            slug: string,
        },
        params: Partial<UndefinedInitialDataOptions>,
    };
};

export function useSpacesApiClient({ getAllParams, getDetailsParams, checkSlugParams }: Params) {

    const queryClient = useQueryClient();

    const getAll = useQuery({
        queryKey: [QueryKeys.spaces.getAll],
        enabled: false,
        queryFn: async () => {
            try {
                return ((await axios.get<Api.SpacesClient.getAll>("/api/v1/spaces")).data);
            } catch (err: any) {
                throw err;
            }
        },
        ...getAllParams?.params
    });

    const getDetails = useQuery({
        queryKey: [QueryKeys.spaces.getDetails],
        enabled: false,
        queryFn: async () => {
            try {

                const spacesQuery = queryClient.getQueryData<Api.SpacesClient.getAll>([QueryKeys.spaces.getAll]);

                const spaceId = spacesQuery?.activeSpace?.id ?? "";

                return (await axios.get<Api.SpacesClient.getDetails>("/api/v1/spaces/detail", {
                    params: {
                        id: spaceId
                    }
                })).data;

            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
        ...getDetailsParams?.params
    });

    const checkSlug = useQuery({
        enabled: false,
        queryKey: [QueryKeys.spaces.checkSlug],
        queryFn: async () => {
            try {
                return (await axios.get<Api.SpacesClient.checkSlug>("/api/v1/spaces/slug", {
                    params: {
                        slug: checkSlugParams?.apiParams.slug
                    }
                })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        },
    });

    const create = useMutation({
        mutationKey: [QueryKeys.spaces.create],
        mutationFn: async (data: Api.SpacesClient.create) => {
            try {
                return (await axios.post<Api.SpacesClient.create>("/api/v1/spaces", data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const update = useMutation({
        mutationKey: [QueryKeys.spaces.update],
        mutationFn: async (spaces: Api.SpacesClient.updateParams) => {
            try {
                return (await axios.put("/api/v1/spaces", spaces)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    const deleteQuery = useMutation({
        mutationKey: [QueryKeys.spaces.delete],
        mutationFn: async (id: string) => {
            try {
                return (await axios.delete(`/api/v1/spaces/${id}`)).data;
            } catch (err: any) {
                throw ParseAxiosError(err);
            }
        }
    });

    return {
        getAll,
        getDetails,
        create,
        update,
        deleteQuery,
        checkSlug
    };

}