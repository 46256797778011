import { DateRangeFilter } from "./dateRange";
import { DeleteTestimonialsBtn } from "./delete.popup";
import { ExpandAndCollapse } from "./expand";
import { TableSettings } from "./settings";
import { OtherFilters } from './other/other.main';

export function Filter() {
    return (
        <div className="flex items-center justify-between space-x-6">

            <div className="flex items-center space-x-4">

                <DateRangeFilter />

                <OtherFilters />

            </div>

            <div className="space-x-3 flex">

                <ExpandAndCollapse />

                <TableSettings />

                <DeleteTestimonialsBtn />

            </div>

        </div>
    );
}