import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RequestInterceptor, onErrorResponseInterceptor } from '@lib/axios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';


const queryClient = new QueryClient();

axios.interceptors.request.use((config: AxiosRequestConfig) => RequestInterceptor(config));

axios.interceptors.response.use((response: AxiosResponse) => response, (error) => onErrorResponseInterceptor(error));

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <React.StrictMode>
    <BrowserRouter>

        {/* <ErrorBoundary
            fallback={<p> something went wrong!... </p>}
            onError={(err) => console.log(err)}
        > */}

        <QueryClientProvider client={queryClient}>


            <App />

            <ReactQueryDevtools />

        </QueryClientProvider>

        {/* </ErrorBoundary> */}

    </BrowserRouter>
    // </React.StrictMode>,
);
