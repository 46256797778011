type Props = {
    height?: string,
    width?: string;
};

export function GoZenLogo({ height, width }: Props) {
    return (
        <svg width={height ?? "400"} height={width ?? "400"} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M379.418 304.348V400H283.766C258.397 400 234.068 389.922 216.13 371.984C198.191 354.046 188.114 329.716 188.114 304.348C188.114 278.979 198.191 254.65 216.13 236.712C234.068 218.773 258.397 208.696 283.766 208.696C309.134 208.696 333.464 218.773 351.402 236.712C369.34 254.65 379.418 278.979 379.418 304.348Z" fill="#0C233F" />
            <path d="M96.8117 246.377C117.183 246.377 136.721 254.47 151.126 268.875C165.531 283.279 173.623 302.817 173.623 323.188C173.623 343.56 165.531 363.097 151.126 377.502C136.721 391.907 117.183 400 96.8117 400H20.0002V323.188C20.0002 302.817 28.0928 283.279 42.4977 268.875C56.9027 254.47 76.44 246.377 96.8117 246.377Z" fill="#17417F" />
            <path d="M324.346 191.304C309.739 191.304 295.732 185.502 285.404 175.174C275.075 164.846 269.273 150.838 269.273 136.232C269.273 121.626 275.075 107.618 285.404 97.2897C295.732 86.9617 309.739 81.1594 324.346 81.1594H379.418V136.232C379.418 150.838 373.616 164.846 363.288 175.174C352.96 185.502 338.952 191.304 324.346 191.304Z" fill="#205BB2" />
            <path d="M20.002 0H135.944C166.694 0 196.184 12.2153 217.927 33.9586C239.671 55.7019 251.886 85.1922 251.886 115.942C251.886 146.692 239.671 176.182 217.927 197.925C196.184 219.669 166.694 231.884 135.944 231.884C105.194 231.884 75.7039 219.669 53.9606 197.925C32.2173 176.182 20.002 146.692 20.002 115.942L20.002 0Z" fill="#2563EB" />
        </svg>
    );
};