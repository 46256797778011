import { Button } from "@components/button";
import { Input } from "@components/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthApiClient } from "@hooks";
import { Auth0 } from "@lib/auth0";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";


const ResendMailSchema = z.object({
    email: z.string().email()
});

type SchemaType = z.infer<typeof ResendMailSchema>;

export function ResendVerificationMail() {

    const { formState, register, handleSubmit, clearErrors } = useForm({
        resolver: zodResolver(ResendMailSchema),
        defaultValues: {
            email: ""
        }
    });

    const [email, setEmail] = useState("");

    const { resendVerificationMail } = useAuthApiClient({
        resendVerificationMailParams: {
            apiParams: {
                email
            },
            params: {
                onSuccess: () => {

                    toast.success("Verification mail has been send to you. Please verify your account.");

                    Auth0.logout();

                }
            }
        }
    });

    const isLoading = resendVerificationMail.isFetching || resendVerificationMail.isLoading;

    const onSubmit: SubmitHandler<SchemaType> = (e) => {
        setEmail(e.email);
        resendVerificationMail.refetch();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center justify-center w-full h-full p-10"
        >

            <p className="text-left text-sm font-normal mb-2 w-[38%]"> Email </p>

            <Input
                register={register("email")}
                error={formState.errors.email?.message as string}
                type="email"
                placeholder="Enter your email here"
                onClick={() => clearErrors("email")}
            />

            <Button
                type="submit"
                disabled={isLoading}
                loading={isLoading}
            >
                Resend Email
            </Button>

        </form>
    );
}