import { Popup } from "@components/popup";
import { useCommonStore } from "@store";
import { RecordVideo } from "./main";
import { useState } from "react";

type Props = {
    type: "welcome" | "thankyou";
};

export function RecordVideoPopup({ type }: Props) {

    const showPopup = useCommonStore((store) => store.activePopup === "record-video");

    const [close, setClose] = useState(false);

    if (!showPopup) return <></>;

    return (
        <Popup
            width="35%"
            name="Record Video"
            onClose={() => {
                setClose(true);
                useCommonStore.getState().setActivePopup("");
            }}
        >

            <RecordVideo
                type={type}
                close={close}
            />

        </Popup>
    );
}