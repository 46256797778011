import { Text } from "@components/text";
import { useEffect, useState } from "react";
import { MdAlternateEmail, MdOutlinePhotoCameraFront } from "react-icons/md";
import { Button, SecondaryButton } from "@components/button";
import { FiEdit3 } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import { CustomerField } from "./field";
import { QueryKeys, useActiveSpace, useGetQueryData, useTestimonialsApiClient } from "@hooks";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useFormStore, useTestimonialStore } from "@store";
import { Config } from "@config";

const notAllowedField = ["companyLogo", "photo"];

export function CustomerDetails() {

    const [searchParams] = useSearchParams();

    const tid = searchParams.get("id") ?? "";

    const { formId } = useActiveSpace();

    const customerDetails = useFormStore((store) => store.customDetails);
    const updatedCustomerDetails = useTestimonialStore((store) => store.customerDetails);

    const testimonialsReq = useGetQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll]);
    const queryClient = useQueryClient();

    const [edit, setEdit] = useState(false);
    const [customerFields, setCustomerFields] = useState<Api.Testimonials.Type["customerDetails"]>({});

    const { updateCustomerDetailsQuery } = useTestimonialsApiClient({});

    const isPending = updateCustomerDetailsQuery.isPending;

    useEffect(() => {

        const testimonialId = searchParams.get("id");

        if (testimonialId) {

            const testimonial = testimonialsReq.testimonials.filter(item => item.id === testimonialId)[0];

            setCustomerFields(testimonial.customerDetails);

        }

    }, []);

    const onSave = async () => {

        await updateCustomerDetailsQuery.mutateAsync({
            fid: formId,
            tid,
            customerDetails: { ...updatedCustomerDetails }
        });

        if (updateCustomerDetailsQuery.isError) {
            toast.error(updateCustomerDetailsQuery.error.message);
            return;
        }

        setCustomerFields({ ...customerFields, ...updatedCustomerDetails });

        const updatedTestimonials = testimonialsReq.testimonials.map(item => {

            return item.id === tid ? {
                ...item,
                customerDetails: { ...item.customerDetails, ...updatedCustomerDetails }
            } : item;

        });

        queryClient.setQueryData<Api.Testimonials.getAll>([QueryKeys.testimonials.getAll], (oldData) => {
            return {
                ...oldData,
                testimonials: updatedTestimonials
            } as Api.Testimonials.getAll;
        });

        toast.success("Customer details updated successfully");

        setEdit(false);

    };

    /* 
        
        validated field 
    
    */

    return (
        <div className="border border-gray-200 bg-white shadow-sm px-4 py-2 rounded-lg">

            <div className="flex items-center justify-between">

                <Text variant="primary" size="lg" className="font-medium">
                    Customer Details
                </Text>

                <SecondaryButton
                    className="space-x-2 w-auto text-sm font-medium px-2 mt-0"
                    onClick={() => setEdit(!edit)}
                >

                    <FiEdit3 className="w-4 h-4" />

                </SecondaryButton>

            </div>

            <div className="space-y-3 my-2">

                {Object.keys(customerFields).map((key, idx) => {

                    if (notAllowedField.includes(key)) return null;

                    const field = customerFields[key];

                    const isPrimaryField = key in customerDetails.fields;

                    const customField = customerDetails.customFields.filter(field => field.id === key)[0]?.label ?? "";

                    console.log({ isPrimaryField });

                    const question = isPrimaryField ? key : customField;

                    if (field.type === "upload") {
                        return (
                            <CustomerField
                                key={idx}
                                id={key}
                                edit={edit}
                                question={question}
                                field={field}
                                icon={<MdOutlinePhotoCameraFront className="w-4 h-4 text-blue-600" />}
                            >

                                <div>
                                    <img
                                        src={Config.misc.placeHolderLogo}
                                        className="w-10 h-10"
                                    />
                                </div>

                            </CustomerField>
                        );
                    }

                    return (
                        <CustomerField
                            key={idx}
                            id={key}
                            edit={edit}
                            question={question}
                            field={field}
                            icon={<MdAlternateEmail className="w-4 h-4 text-blue-600" />}
                        />
                    );

                })}

            </div>

            {edit ?
                <div className="flex items-center justify-end space-x-3 mt-4">

                    <SecondaryButton
                        disabled={isPending}
                        className="text-sm font-normal w-auto"
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </SecondaryButton>

                    <Button
                        disabled={isPending}
                        loading={isPending}
                        className="text-sm font-normal w-auto mt-0"
                        onClick={() => onSave()}
                    >
                        Save
                    </Button>

                </div> : null
            }

        </div>
    );
}