import { CommonSettings } from "./common";
import { EmailNotification } from "./emailNotification/emailNotification";
import { CustomLabels } from "./customLabels/main.customLabels";
import { SubmissionSettings } from "./submissions";


export function Settings() {
    return (
        <div className="space-y-3">

            <CommonSettings />

            <CustomLabels />

            <SubmissionSettings />

            <EmailNotification />

        </div>
    );
}