import { Input } from "@components/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFormStore } from "@store";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { motion } from "framer-motion";

type Props = {
    idx: number;
};

const schema = z.object({
    name: z.string().min(1, "Field required."),
    coupon: z.string().min(1, "Field required."),
    probability: z.string().refine((val: any) => !isNaN(val), { message: "Enter a valid number." })
});

export function Coupon({ idx }: Props) {

    const segment = useFormStore((store) => store.reward.segments[idx]);

    const { formState: { errors }, register, watch } = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            name: segment.title,
            coupon: segment.code,
            probability: segment.probability.toString(),
        }
    });

    // const fields = watch();

    const name = watch("name");
    const code = watch("coupon");
    const probability = watch("probability");

    useEffect(() => {

        useFormStore.getState().setRewardSegment({
            id: segment.id,
            code,
            title: name,
            probability: parseInt(probability),
        });

    }, [name, code, probability]);

    return (
        <motion.div
            className="space-y-2"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >

            <Input
                {...register("name")}
                type="text"
                placeholder="Display name"
                label="Display Name"
                required={true}
                error={errors.name?.message}
            />

            <Input
                {...register("coupon")}
                type="text"
                placeholder="COUPON20"
                label="Code / Result"
                required={true}
                error={errors.coupon?.message}
            />

            <Input
                {...register("probability")}
                type="number"
                placeholder="10%"
                label="Percentage"
                tooltip={{
                    id: segment.id,
                    tip: "Probability percentage"
                }}
                min={0}
                max={100}
                error={errors.probability?.message}
            />

        </motion.div>
    );
}