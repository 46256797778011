import { Input } from "@components/input";
import { useFormStore } from "@store";
import { DefaultLabels } from "./defaultLabels";

export function WelcomePageLabels() {

    const { recordVideoBtn, writeTestimonialBtn } = useFormStore((store) => ({
        recordVideoBtn: store.customLabels?.recordVideoBtn,
        writeTestimonialBtn: store.customLabels?.writeTestimonialBtn
    }));

    return (
        <div className="space-y-2 pb-4 border-b">

            <Input
                type="text"
                label="Record a video button"
                placeholder="Enter new label"
                className="h-auto"
                value={recordVideoBtn ? recordVideoBtn : DefaultLabels?.recordVideoBtn}
                onChange={(e) => useFormStore.getState().setCustomLabel("recordVideoBtn", e.target.value)}
            />

            <Input
                type="text"
                label="Write a review button"
                placeholder="Enter new label"
                className="h-auto"
                value={writeTestimonialBtn ? writeTestimonialBtn : DefaultLabels?.recordVideoBtn}
                onChange={(e) => useFormStore.getState().setCustomLabel("writeTestimonialBtn", e.target.value)}
            />

        </div>
    );
}