import { Badge } from "@components/badge";
import { TableSelect } from "@components/select";
import { QueryKeys, useEmailInvitesApiClient, useGetQueryData, useGetQueryStatus } from "@hooks";
import {
    ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel,
    getPaginationRowModel, useReactTable
} from "@tanstack/react-table";
import { GenerateProfilePic } from "@utils/generateProfilePic";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Pagination } from "./pagination";
import { useInvitesStore, useUserStore } from "@store";
import { Loading } from "@components/loading";
import { DateTime } from "luxon";
import { cn } from "@utils/cn";


export function EmailInvitesTable() {

    const ref = useRef<HTMLDivElement>(null);
    const width = ref.current?.offsetWidth ?? 1000;

    const { getAll } = useEmailInvitesApiClient({});
    const getAllInvitesQuery = useGetQueryData<Api.EmailInvites.GetAll>([QueryKeys.emailInvites.getAll]);
    const status = useGetQueryStatus([QueryKeys.testimonials.getAll]);

    const userName = useUserStore((store) => store.name);

    const columns = useMemo<ColumnDef<Api.EmailInvites.Type>[]>(() => (
        [
            {
                id: "select",
                size: 30,
                header: ({ table }) => (
                    <div className="w-full h-full flex items-center justify-center cursor-pointer">

                        <TableSelect
                            {...{
                                checked: table.getIsAllRowsSelected(),
                                indeterminate: table.getIsSomeRowsSelected(),
                                onChange: table.getToggleAllRowsSelectedHandler(),
                            }}
                        />

                    </div>
                ),
                cell: ({ row }) => (
                    <div className="w-full h-full flex items-center justify-center cursor-pointer">

                        <TableSelect
                            {...{
                                checked: row.getIsSelected(),
                                disabled: !row.getCanSelect(),
                                indeterminate: row.getIsSomeSelected(),
                                onChange: row.getToggleSelectedHandler(),
                            }}
                        />

                    </div>
                ),
                enableResizing: false
            },
            {
                id: "inviteDetails",
                accessorKey: "inviteDetails",
                enableResizing: false,
                size: Math.floor(width * 0.20),
                header: () => <p className="text-base font-medium tracking-wide"> Profile </p>,
                cell: col => {

                    const name = col.row.original["name"];
                    const email = col.row.original["emailId"];
                    const profilePicture = GenerateProfilePic(name[0]);

                    return (
                        <div className="flex items-center justify-start space-x-3 cursor-default">

                            <img
                                src={profilePicture}
                                className="w-12 h-12 rounded-full mx-1 border border-gray-300 bg-gray-300"
                            />

                            <div className="text-left">

                                <p className="text-sm font-medium tracking-wide">
                                    {name ?? ""}
                                </p>

                                <span className="text-xs font-medium text-gray-600 tracking-wide">

                                    {email ?? ""}

                                </span>

                            </div>

                        </div>
                    );
                }
            },
            {
                id: "emailContent",
                accessorKey: "emailContent",
                enableResizing: false,
                size: Math.floor(width * 0.40),
                header: () => <p className="text-base font-medium tracking-wide"> Email </p>,
                cell: col => {

                    // const status = col.row.original.status;
                    // const sendAt = col.row.original.sendAt;

                    const dateTime = DateTime.fromISO(col.row.original.createdAt);

                    const relativeTime = dateTime.toRelative();

                    const template = (getAll.data as Api.EmailInvites.GetAll).template;

                    if (template === null) return <></>;

                    return (
                        <div className="flex items-start justify-start cursor-default">

                            {/* <div className="flex items-center justify-start text-xs font-medium text-gray-600 text-left space-x-1">

                                <p> Subject: </p>

                                <p dangerouslySetInnerHTML={{ __html: template.subject }} />

                            </div> */}

                            <p className="text-sm font-medium text-gray-500 text-left">
                                <span className="text-gray-800"> {userName} </span>  invited <span className="text-gray-800"> {col.row.original.name} </span> to leave testimonial
                            </p>

                            <p
                                data-tooltip-id="testimonial-submitted-at"
                                className={cn(`
                                    text-left text-gray-800 text-sm font-medium mx-2 cursor-pointer 
                                    border-b border-dashed border-gray-400 inline-block mt-0
                                `)}
                            >
                                send {relativeTime}
                            </p>

                        </div>
                    );
                }
            },
            {
                id: "status",
                accessorKey: "status",
                enableResizing: false,
                size: Math.floor(width * 0.10),
                header: () => <p className="text-base font-medium tracking-wide"> Status </p>,
                cell: col => {

                    const status = col.row.original["status"];

                    return (
                        <div className="flex items-center justify-center">

                            <Badge
                                type={status === "queued" ? "danger" :
                                    status === "processing" ? "warning" :
                                        "success"
                                }
                                text={status}
                            />

                        </div>
                    );

                }
            }
        ]
    ), []);

    const [data, setData] = useState<Api.EmailInvites.Type[]>([]);
    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {

        if (status.isAlreadyFetched === false && getAllInvitesQuery.invites.length === 0) {
            getAll.refetch();
        }

        setData(status.isLoading === false && getAllInvitesQuery && getAllInvitesQuery?.invites ?
            [...getAllInvitesQuery.invites] :
            []
        );

    }, []);

    useEffect(() => {

        const ids: string[] = [];

        Object.keys(rowSelection).forEach(idx => ids.push(getAllInvitesQuery.invites[parseInt(idx)].id));

        useInvitesStore.getState().setSelectedRowsToDelete(ids);

    }, [JSON.stringify(rowSelection)]);

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        enableRowSelection: true,
        columnResizeDirection: "ltr",
        columnResizeMode: "onChange",
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: import.meta.env.DEV,
        debugHeaders: import.meta.env.DEV,
        debugColumns: import.meta.env.DEV,
    });

    // const onShowDetails = (id: string) => {

    //     searchParams.set("id", id);

    //     setSearchParams(searchParams);

    //     useCommonStore.getState().setActivePopup("email-invite-detail");

    // };

    return (
        <div
            ref={ref}
            className="my-4 h-full space-y-4 overflow-x-auto rounded-lg"
        >

            {getAll.isLoading || getAll.isFetching ?
                <div className="h-[50vh]">

                    <Loading />

                </div> :
                <Fragment>

                    <div className="min-h-[75vh]">

                        <table
                            style={{
                                width: "100%",
                            }}
                            className="w-full rounded-md bg-white"
                        >

                            <thead className="select-none w-f">

                                {table.getHeaderGroups().map(headerGroup => (
                                    <tr key={headerGroup.id}>

                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...{
                                                    key: column.id,
                                                    colSpan: column.colSpan,
                                                    style: {
                                                        width: column.getSize(),
                                                    },
                                                }}
                                                className="relative p-4 border fade-in-30"
                                            >

                                                {column.isPlaceholder ?
                                                    null :
                                                    flexRender(
                                                        column.column.columnDef.header,
                                                        column.getContext()
                                                    )
                                                }

                                                {column.getContext().column.getCanResize() ?
                                                    <div
                                                        {...{
                                                            onDoubleClick: () => column.column.resetSize(),
                                                            onMouseDown: column.getResizeHandler(),
                                                            onTouchStart: column.getResizeHandler(),
                                                            className: `resizer ${table.options.columnResizeDirection} ${column.column.getIsResizing() ? 'isResizing' : ''}`,
                                                            style: {
                                                                transform: '',
                                                            },
                                                        }}
                                                        className="cursor-col-resize w-2 h-full absolute top-0 right-0"
                                                    /> : null
                                                }

                                            </th>
                                        ))}
                                    </tr>
                                ))}

                            </thead>

                            <tbody>

                                {table.getRowModel().rows.map((row) => {
                                    return (
                                        <tr
                                            key={row.id}
                                            className="hover:bg-gray-100 transition-all duration-300 ease-in-out"
                                        // onClick={(e) => {
                                        //     e.stopPropagation();
                                        //     onShowDetails(row.original.id);
                                        // }}
                                        >

                                            {row.getVisibleCells().map(cell => (
                                                <td
                                                    key={cell.id}
                                                    className="border text-center px-4 py-3"
                                                    style={{
                                                        maxWidth: Math.floor(width * 0.40)
                                                    }}
                                                >
                                                    {/* {cell.renderValue("cell")} */}
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}

                                        </tr>
                                    );
                                })}

                            </tbody>

                        </table>

                    </div>


                    <Pagination
                        pageCount={table.getPageCount()}
                        pageIdx={table.getState().pagination.pageIndex + 1}
                        disableNextButton={table.getCanPreviousPage()}
                        onPreviousPage={() => table.previousPage()}
                        disablePreviousButton={table.getCanNextPage()}
                        onNextPage={() => table.nextPage()}
                    />

                    {/* <EmailInviteDetails /> */}

                </Fragment>
            }

        </div>
    );
}