import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";


export function CompanyUrlField() {

    const companyUrl = useImportTestimonialStore((store) => store.testimonial.companyUrl);

    return (
        <Input
            type="text"
            placeholder="https://pied-piper.com"
            label="Company Url"
            mainClassName="w-[50%]"
            value={companyUrl}
            onChange={(e) => useImportTestimonialStore.getState().setCompanyUrl(e.target.value)}
        />
    );
}