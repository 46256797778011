import { Input } from "@components/input";
import { useImportTestimonialStore } from "@store";

type Props = {
    label?: string,
    placeholder?: string,
};

export function SourceUrlField({ label, placeholder }: Props) {

    const sourceUrl = useImportTestimonialStore((store) => store.testimonial.sourceUrl);

    return (
        <Input
            type="text"
            placeholder={placeholder ?? "https://example.com"}
            label={label ?? "Source Url"}
            mainClassName="w-[100%]"
            value={sourceUrl}
            onChange={(e) => useImportTestimonialStore.getState().setSourceUrl(e.target.value)}
        />
    );
}