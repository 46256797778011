import { Tab } from "@components/tab";
import { useFormStore } from "@store";
import { useState } from "react";
import { Coupon } from "./coupon";
import { RewardPopup } from "./popup";
import { cn } from "@utils/cn";
import { MdDelete } from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import { EmbedPreview } from "@utils/embedPreview";

type Props = {
    id: string,
    idx: number,
};

export function Segment({ id, idx }: Props) {

    const [active, setActive] = useState<"coupon" | "popup">("coupon");

    const onChangeScreen = () => {

        if (active === "coupon") EmbedPreview.changeScreen("reward-preview");

    };

    return (
        <AnimatePresence initial={false}>

            <motion.div
                className="space-y-4 border border-gray-200 px-4 py-2 pb-4 rounded-lg bg-gray-50"
                initial={{
                    height: 0
                }}
                animate={{ height: "auto" }}
                onMouseOver={() => onChangeScreen()}
            >

                <div className="relative">

                    <Tab
                        active={active}
                        tabs={[
                            { name: "Coupon", value: "coupon" },
                            { name: "Popup", value: "popup" }
                        ]}
                        onChange={(e) => {

                            if (e === "Coupon") EmbedPreview.changeScreen("reward-preview");

                            setActive(e as any);

                        }}
                    />

                    <div
                        className={cn(`
                        absolute top-0 right-2
                        bg-gray-200 p-1 rounded-md flex items-center hover:bg-red-300 group 
                        transition-all duration-300 ease-in-out
                    `)}
                        onClick={() => useFormStore.getState().deleteRewardSegment(id)}
                    >

                        <MdDelete className="w-5 h-5 group-hover:text-red-900" />

                    </div>

                </div>

                {active === "coupon" ?
                    <Coupon idx={idx} /> :
                    <RewardPopup
                        idx={idx}
                        id={id}
                    />
                }

            </motion.div>

        </AnimatePresence>
    );

}