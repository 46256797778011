import { Input } from "@components/input";
import { ImportCard } from "../manuallyImport/main.layout";


export function TiktokImport() {


    return (
        <ImportCard
            title="Import from Tiktok"
            onImport={() => { }}
        >

            <Input
                type="text"
                label="Tiktok vide URL"
                required={true}
            />

        </ImportCard>
    );
}